/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import MaterialTable, { MTableToolbar, EditComponentProps } from 'material-table';
import { 
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Breadcrumbs,
    Link,
    Typography,
    Chip
} from '@material-ui/core';
import { Colors, hashString } from '../../tasksSummary/board/BoardCardDraggable';
import { SemanticCOLORS } from 'semantic-ui-react';
import { RouterProps, showNotification } from '../../App';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import gql from 'graphql-tag';
import { client } from '../..';
import { firmSubscription } from './LookupTeamsMaintenance';
import { Icons } from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { useStyles } from '../../lookupConfiguration/common/tabsStyle';
import { FirmData, Member } from '../../lookupConfiguration/models/Firm';
import { AvailableOfficeMemberSelector } from '../../lookupConfiguration/selectors/AvailableOfficeMemberSelector';
import { FetchPolicy } from 'apollo-client';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import PersonIcon from '@material-ui/icons/Person';

interface LookupOfficeMemberMaintenanceProps extends RouterProps {}

interface TableData extends Member {
    tableData: { 
        checked: boolean;
        id: number;
    };
}

interface LookupOfficeMemberMaintenanceState {
    // tslint:disable-next-line: no-any
    data: Member[];
    isLoading: boolean;
    onDeleteItems: TableData[];
}

export default function LookupOfficeMemberMaintenance(props: LookupOfficeMemberMaintenanceProps) {

    const classes = useStyles();

    const [state, setState] = useState<LookupOfficeMemberMaintenanceState>({
        isLoading: false,
        data: [],
        onDeleteItems: [],
    });

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [isRefreshData, setIsRefreshData] = useState(false);

    useEffect(() => {
        
        // groupId
        const { command } = props.match.params;

        const guID = command.split(';key=', 2)[1];

        if (command) {
            fetchData(guID);
        }

        const subscribeFirmObj = subscribeFirm();

        return () => {
            subscribeFirmObj.unsubscribe();
        };
    // tslint:disable-next-line: align
    }, []);

    useEffect(() => {
        if (isRefreshData) {
            const { command } = props.match.params;

            const guID = command.split(';key=', 2)[1];
    
            if (command) {
                fetchData(guID);
            }

            setIsRefreshData(false);
        }        
    // tslint:disable-next-line: align
    }, [isRefreshData]);

    const tableIcons: Icons = {
        Add: forwardRef((iconProps, ref) => <AddBox {...iconProps} ref={ref}  className="check-button" />),
        Check: forwardRef((iconProps, ref) => <Check {...iconProps} ref={ref}  className="check-button" />),
        Clear: forwardRef((iconProps, ref) => <Clear {...iconProps} ref={ref}  className="check-button" />),
        Delete: forwardRef((iconProps, ref) => <DeleteOutline {...iconProps} ref={ref}  className="check-button" />),
        DetailPanel: forwardRef((iconProps, ref) => <ChevronRight {...iconProps} ref={ref}  className="check-button" />),
        Edit: forwardRef((iconProps, ref) => <Edit {...iconProps} ref={ref}  className="check-button" />),
        Export: forwardRef((iconProps, ref) => <SaveAlt {...iconProps} ref={ref}  className="check-button" />),
        Filter: forwardRef((iconProps, ref) => <FilterList {...iconProps} ref={ref}  className="check-button" />),
        FirstPage: forwardRef((iconProps, ref) => <FirstPage {...iconProps} ref={ref}  className="check-button" />),
        LastPage: forwardRef((iconProps, ref) => <LastPage {...iconProps} ref={ref}  className="check-button" />),
        NextPage: forwardRef((iconProps, ref) => <ChevronRight {...iconProps} ref={ref}  className="check-button" />),
        PreviousPage: forwardRef((iconProps, ref) => <ChevronLeft {...iconProps} ref={ref}  className="check-button" />),
        ResetSearch: forwardRef((iconProps, ref) => <Clear {...iconProps} ref={ref}  className="check-button" />),
        Search: forwardRef((iconProps, ref) => <Search {...iconProps} ref={ref}  className="check-button" />),
        SortArrow: forwardRef((iconProps, ref) => <ArrowDownward {...iconProps} ref={ref} />),
        ThirdStateCheck: forwardRef((iconProps, ref) => <Remove {...iconProps} ref={ref}  className="check-button" />),
        ViewColumn: forwardRef((iconProps, ref) => <ViewColumn {...iconProps} ref={ref}  className="check-button" />)
    };

    const subscribeFirm = () => {
        return (
            // let subscription =
            client
                .subscribe({
                    fetchPolicy: 'no-cache',
                    query: firmSubscription,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results: any) {
                        if (results.data.lookupNotification.status) {

                            const { lookupNotification } = results.data;
                            showNotification('Success', lookupNotification.message, 'info');

                            if (lookupNotification.message.includes('member added successfully')) {
                                setIsRefreshData(true);
                                // setState((prevState) => {

                                //     const tdata = [...prevState.data];

                                //     const temp = tdata[tdata.length - 1];

                                //     tdata[tdata.length - 1] = {
                                //         ...temp,
                                //         guidID: lookupNotification.guidId
                                //     };

                                //     return {
                                //         ...prevState,
                                //         data: tdata
                                //     };
                                // });
                            }

                        } else {
                            showNotification('Failed', results.data.lookupNotification.message, 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };

    const fetchData = (groupId: string) => {

        setState({
            ...state,
            isLoading: true,
        });

        const officeMemberQueryParams: OfficeMemberQueryParams = {
            officeGuidId: groupId
        };

        retrieveOfficeMemberData(
            officeMemberQueryParams,
            true, // refresh
            (data) => onRetrieveData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
                setIsRefreshData(false);
            }
        );
    };

    const onRetrieveData = (data: FirmData) => {

        setState({
            ...state,
            data: data.firm.firmOffices.firmOffice.length > 0
                ? data.firm.firmOffices.firmOffice[0].members.member
                : [],
            isLoading: false
        });
    };

    // tslint:disable-next-line: no-any
    const insertRowData = (newData: any) => {

        // let chipsArray = [];

        // if (newData && newData.friendlyName) {           

        //     if (!Array.isArray(newData.friendlyName)) {
        //         // tslint:disable-next-line: no-any
        //         JSON.parse(newData.friendlyName).forEach( (item: any) => {
        //             chipsArray.push(item.label);
        //         });
        //     } else {
        //         chipsArray = newData.friendlyName;
        //     }
        // }     
        
        const tdata = [...state.data];

        // if (tdata.some((e: Member) => e.staffId && e.staffId.toLowerCase() === newData.friendlyName.value.toLowerCase())) {
        //     return { 
        //         ...state, 
        //     };
        // } 

        tdata.push({
            friendlyName: newData.friendlyName,
            staffId: newData.friendlyName,
            email: newData.friendlyName
        });
        
        setState((prevState) => {
            return { 
                ...prevState, 
                data: tdata
            };
        });
    };

    // tslint:disable-next-line: no-any
    const deleteRowData = (oldData: any) => {

        setState((prevState) => {
            const tdata = [...prevState.data];
            tdata.splice(tdata.indexOf(oldData), 1);
            return { 
                ...prevState, 
                data: tdata 
            };
        });
        
    };

    // tslint:disable-next-line: no-any
    const getStaffList = (newData: any) => {

        let staffArray = [];
        if (!Array.isArray(newData.friendlyName) && newData && newData.friendlyName) {
            // tslint:disable-next-line: no-any
            JSON.parse(newData.friendlyName).forEach( (item: any) => {
                staffArray.push(item);
            });
        } else {
            staffArray = newData && newData.friendlyName ? newData.friendlyName : [];
        }

        // tslint:disable-next-line: no-any
        return staffArray.map((item: any) => item.value).toString();
    };

    // tslint:disable-next-line: no-any
    const createData = (newData: any) => {
        if (newData) {

            const groupId = props.match.params.command.split(';key=', 2)[1];

            client
                .mutate({
                    mutation: AddFirmOfficeMember,
                    variables: {
                        groupId: groupId,
                        staffIdList: getStaffList(newData),
                    },
                })
                // tslint:disable-next-line: no-any
                .then((results: { data: any }) => {
                    if (results.data) {
                        if (results.data.error !== undefined) {
                            showNotification( 'Failed to Create Team Member', results.data.error, 'error');
                        }
                    }
                })
                // tslint:disable-next-line:no-any
                .catch((reason: any) => {
                    showNotification('Failed to Create Team Member', reason, 'error');
                });
        }
    };

    const deleteData = (oldData: Member) => {
        client
            .mutate({
                mutation: RemoveFirmOfficeMember,
                variables: {
                    guidId: oldData.guidID?.toString(),
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification( 'Failed to Delete Team Member', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Delete Team Member', reason, 'error');
            });
    };

    const handleBreadCrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        props.history.goBack();
    };

    // tslint:disable-next-line: no-any
    const onDeleteData = (data: any) => {

        setShowDeleteConfirm(true);

        setState((prevState) => {
            return {
                ...prevState,
                onDeleteItems: data,
            };
        });
        // onDeleteItems = data;

    };

    const onDeleteConfirm = (agree: boolean) => {
        if (agree) {
            // tslint:disable-next-line: no-any
            state.onDeleteItems.forEach((selected: any) => {

                // delete check data
                deleteData(selected);

                setState((prevState) => {
                    const tdata = [...prevState.data];
                    tdata.splice(tdata.indexOf(selected), 1);
                    return { 
                        ...prevState, 
                        data: tdata 
                    };
                });

            });

            setState((prevState) => {
                return {
                    ...prevState,
                    onDeleteItems: []
                };
            });

        }

        setShowDeleteConfirm(false);
    };

    // tslint:disable-next-line: no-any
    const getUser = (data: any) => {

        const { friendlyName, email } = data;

        if (!Array.isArray(friendlyName) ) {
            let bColor: SemanticCOLORS = Colors[(friendlyName === '' || friendlyName === null) ? 
            Colors[Colors.length] : (hashString(friendlyName) % Colors.length) - 1];
            let stringSplit = friendlyName
            .replace(/[^\w\s]/gi, '') // remove special character
            .split(' ')
            // tslint:disable-next-line: no-any
            .map((n: any) => n[0])
            .join('');

            let stringInitials = stringSplit.slice(0, 2);
            
            return (
                <React.Fragment>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                style={{ backgroundColor: bColor ? bColor : 'grey', color: '#fff' }}
                                className={classes.avatar}
                            >
                                {stringInitials}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={friendlyName} secondary={email} />
                    </ListItem>
                </React.Fragment>
            );
        } else {

            return (
                <React.Fragment>
                    <ListItemText primary={'Adding members...'} />
                </React.Fragment>
            );
            /*
            let chipsArray = [];
            if (!Array.isArray(friendlyName)) {
                // tslint:disable-next-line: no-any
                JSON.parse(friendlyName).forEach( (item: any) => {
                    chipsArray.push(item);
                });
            } else {
                chipsArray = friendlyName;
            }

            const chips: React.ReactNode = (
                <div className={classes.chips}>
                    {
                        // tslint:disable-next-line: no-any
                        chipsArray.map((chipData: any) => {    
                            return (
                                // tslint:disable-next-line: no-unused-expression
                                <Chip 
                                    className={classes.chip} 
                                    key={chipData.value} 
                                    label={chipData.label}
                                />
                            );
                        })
                    }
                </div>
            );
            return chips;
            */
        }
    };

    // tslint:disable-next-line: no-any
    const getUserIcon = (data: any) => {
        const {  isActive } = data;

        if (isActive) {
            return (
                <React.Fragment>
                    <PersonIcon className={classes.personIcons}/>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <PersonOutlineOutlinedIcon className={classes.personIcons}/>
                </React.Fragment>
            );
        }
    };

    // tslint:disable-next-line: no-any
    const removeItems = (itemId: string, name: string, componentProps: EditComponentProps<any> | any) => {
        let officeMemberList = componentProps.value;

        // tslint:disable-next-line: no-any
        const itemToRemove = officeMemberList.findIndex((item: any) => item.value.toString().toLowerCase() === itemId.toString().toLowerCase());
        officeMemberList.splice(itemToRemove, 1);        

        componentProps.onChange(officeMemberList);
    };

    // tslint:disable-next-line: no-any
    const getUserComponent = (componentProps: EditComponentProps<any> | any) => {

        const groupId = props.match.params.command.split(';key=', 2)[1];

        return (
            <React.Fragment>
                <AvailableOfficeMemberSelector 
                    name="member"
                    label="Member"
                    onSelectedItem={(selectionList: IAutoCompleteItem[], name: string) => {
                        componentProps.onChange(selectionList);
                    }}
                    onRemoveItem={(itemId: string, name: string) => {
                        removeItems(itemId, name, componentProps);
                    }}
                    // value={componentProps.value 
                    //     ? componentProps.value.value 
                    //     : undefined}
                    required={true}
                    helperText={componentProps.helperText}
                    error={componentProps.error}
                    groupId={groupId}
                />
            </React.Fragment>
        );
    };

    const tableHeading = () => {

        const title = decodeURIComponent(props.match.params.command.split(';key=', 2)[0]);

        return (
            <div className={classes.breadcrumbsWrapper}>
                <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Link color="inherit" href="/" onClick={handleBreadCrumbClick}>
                        Offices
                    </Link>
                    <Typography color="textPrimary" className={classes.breadcrumbsTitle}>{title}</Typography>
                </Breadcrumbs>
                <Chip className={classes.chip} label={state.data.length} />
            </div>
        );
    };

    return (
        <div className={classes.root}>
            <DialogBox
                title="Offices Member"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to delete the selected row?`}
                show={showDeleteConfirm}
                isAgree={onDeleteConfirm}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
                className={classes.dialogBox}
            />
            <MaterialTable
                isLoading={state.isLoading}
                title={tableHeading()}
                icons={tableIcons}
                columns={[
                    { 
                        title: 'Name', 
                        field: 'friendlyName', 
                        cellStyle: { flex: 3 }, 
                        headerStyle: { flex: 3 },
                        render: (rowData) => getUser(rowData), 
                        editComponent: (componentData) => getUserComponent(componentData),
                        validate: (rowData) => rowData.hasOwnProperty('friendlyName') && rowData.friendlyName.length > 0 
                            ? true 
                            : { isValid: false, helperText: 'Member is required'},
                    },
                    // { title: 'friendlyName', field: 'friendlyName', searchable: true },
                    { 
                        title: '', 
                        field: 'isActive', 
                        hidden: false, 
                        searchable: false,
                        editable: 'never',
                        render: (rowData) => getUserIcon(rowData)
                    },
                    { title: 'groupGuidId', field: 'groupGuidId', hidden: true, searchable: true },
                    { title: 'guidID', field: 'guidID', hidden: true, searchable: true },
                    { title: 'userID', field: 'userID', hidden: true, searchable: true },
                    { title: 'email', field: 'email', hidden: true, searchable: true }
                ]}
                data={state.data}  
                components={
                    {
                        // tslint:disable-next-line: max-line-length
                        Toolbar: toolbarProps => (
                            <div className={classes.customToolbar}>
                                <MTableToolbar classes={{ highlight: classes.highlight, actions: classes.actions }} {...toolbarProps} />
                            </div>
                        )
                    }
                }      
                options={{
                    addRowPosition: 'first',
                    // search: true,
                    selection: true,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 40, 50],
                    // showTitle: true,
                    // toolbar: true,
                    actionsColumnIndex: -1,
                }}
                editable={{
                    onRowAdd: (newData) =>
                        // tslint:disable-next-line: no-any
                        new Promise((resolve: any) => {
                            setTimeout(() => {
                                resolve();
                                if (newData) {
                                    createData(newData);
                                    insertRowData(newData);
                                }
                            // tslint:disable-next-line: align
                            }, 600);
                        }),
                    onRowDelete: (oldData) =>
                        // tslint:disable-next-line: no-any
                        new Promise((resolve: any) => {
                            setTimeout(() => {
                                resolve();
                                if (oldData) {
                                    deleteData(oldData);
                                    deleteRowData(oldData);
                                }
                            // tslint:disable-next-line: align
                            }, 600);
                        }),
                    isDeletable: (rowData) => {
                        if (Array.isArray(rowData.friendlyName)) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                }}               
                actions={[
                    {
                        tooltip: 'Remove All Selected Users',
                        icon: DeleteOutline,
                            // tslint:disable-next-line: no-any
                        onClick: (evt: any, data: any) => onDeleteData(data)
                    }
                ]}
            />
        </div>
    );
}

export interface OfficeMemberQueryParams {
    officeGuidId: string | null;
}

export function retrieveOfficeMemberData(
    query: OfficeMemberQueryParams,
    refreshData: boolean,
    onSuccess: (data: FirmData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void): void {
    
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
        client
            .query({
                query: FirmOfficeMember,
                variables: {
                    officeGuidId: query.officeGuidId
                },
                fetchPolicy: fetchPolicy,
            })
            // tslint:disable-next-line:no-any
            .then((results: { data: any }) => {
                onSuccess(results.data);
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                onError(reason);
            });
}

const FirmOfficeMember = gql`
query FirmOfficeMember($officeGuidId: String){
    firm{
        firmOffices(officeGuidId: $officeGuidId) {
            firmOffice{
                guidID,
                description
                members{
                    recordCount,
                    member{
                        guidID,
                        staffId,
                        groupGuidId,
                        friendlyName,
                        userId,
                        email,
                        isActive
                    }
                }
            }
        }
    }
}`;

const AddFirmOfficeMember = gql`
    mutation AddFirmOfficeMember($groupId: String, $staffIdList: String) {
        addFirmOfficeMember(groupId: $groupId, staffIdList: $staffIdList)
    }
`;
  
const RemoveFirmOfficeMember = gql`
    mutation RemoveFirmOfficeMember($guidId: String){
        removeFirmOfficeMember(guidId: $guidId)
    }
`;