import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import glamorous from 'glamorous';
import { styles } from './styles';
import { WithStyles, CardContent, Card, Typography, CardMedia, FormControlLabel, Switch, withStyles, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// import MUIRichTextEditor from 'mui-rte';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';
import { VideoModal } from '../components/VideoModal';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { FeatureNotesDialog } from './dialogs/FeatureNotesDialog';

// import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
import { convertToDraftWysiwygContent } from '../helpers/commonHelper';
import { Editor } from '@tinymce/tinymce-react';
import { FeatureNotesPreviewDialog } from './dialogs/FeatureNotesPreviewDialog';

// tslint:disable-next-line: no-any
const CardDraggable = glamorous.div<{theme?: any}> ((props) => ({
    // paddingRight: '25px', 
}));

// tslint:disable-next-line: no-any
const TrendingUpIconWrapper = glamorous(TrendingUpIcon)<{ theme?: any }>((props) => ({
    color: props.theme.TemplateColor.Primary
}));

// tslint:disable-next-line: no-any
const SwitchWrapper = glamorous(Switch)<{ theme?: any }>((props) => ({
    color: props.theme.TemplateColor.Primary
}));

// tslint:disable-next-line: no-any
// const InfoIconWrapper = glamorous(InfoIcon)<{ theme?: any }>((props) => ({
//     color: props.theme.TemplateColor.Primary,
//     cursor: 'pointer'
// }));

export interface FeatureLayoutCardDraggableProps extends WithStyles<typeof styles> {
    featureGuid: string;
    feature: string;
    title: string;
    description: string;
    isHidden: boolean;
    sequence: number;
    index: number;
    onIsHiddenUpdated: (featureGuid: string, isHidden: boolean) => void;
    itemPerRow: number;
    windowInnterWidth: number;
    isDragDisabled?: boolean;
    infoUrl: string | null;
    isInternalAdmin: boolean;
}

export interface FeatureLayoutCardDraggableState {
    isVisible: boolean;
    hiddenText: string;
    open: boolean;
    anchorEl: Element | ((element: Element) => Element) | null | undefined;
    isVideoModalOpen: boolean;
    isEditNotesOpen: boolean;
    isNotesPreviewOpen: boolean;
}

class FeatureLayoutCardDraggable 
    extends React.Component<FeatureLayoutCardDraggableProps, FeatureLayoutCardDraggableState>  {
    
        // tslint:disable-next-line: no-any
        // private refValue: any;
    
        constructor(props: FeatureLayoutCardDraggableProps, state: FeatureLayoutCardDraggableState) {
        super(props, state);

        this.onHiddenChange = this.onHiddenChange.bind(this);
        this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
        this.handlePopoverClose = this.handlePopoverClose.bind(this);
        this.getWidthPerRow = this.getWidthPerRow.bind(this);
        // this.handleClick = this.handleClick.bind(this);
        this.onClickVideoToggle = this.onClickVideoToggle.bind(this);
        this.onToggleNotesDialog = this.onToggleNotesDialog.bind(this);
        this.getTooltip = this.getTooltip.bind(this);
        this.onPreviewNotes = this.onPreviewNotes.bind(this);
        
        this.state = {
            isVisible: false,
            hiddenText: 'Hidden',
            open: false,
            anchorEl: undefined,
            isVideoModalOpen: false,
            isEditNotesOpen: false,
            isNotesPreviewOpen: false
        };

        // this.refValue = React.createRef();
    }

    // tslint:disable-next-line: no-empty
    public componentDidMount() {
        this.setState({
            isVisible: !this.props.isHidden,
            hiddenText: this.props.isHidden ? 'Hidden' : 'Visible'
        });
    }

    public UNSAFE_componentWillReceiveProps(nextProps: FeatureLayoutCardDraggableProps) {
        if (this.props.isHidden !== nextProps.isHidden) {
            this.setState({
                isVisible: !nextProps.isHidden,
            });
        }
    }

    render() {
        const { classes } = this.props;

        const hiddenText = this.state.isVisible ? 'Visible' : 'Hidden';

        return (
           <React.Fragment>
                {this.state.isEditNotesOpen ? (
                    <FeatureNotesDialog 
                        open={this.state.isEditNotesOpen}
                        description={this.props.description}
                        title={this.props.title}
                        featureGuid={this.props.featureGuid}
                        feature={this.props.feature}
                        infoUrl={this.props.infoUrl}
                        onClose={this.onToggleNotesDialog(false)}
                    />
                ) : undefined}

                {this.state.isNotesPreviewOpen ? (
                    <FeatureNotesPreviewDialog 
                        open={this.state.isNotesPreviewOpen}
                        description={this.props.description}
                        onClose={this.onPreviewNotes}
                    />
                ) : undefined}

                {this.state.isVideoModalOpen && this.props.infoUrl && (
                    <VideoModal 
                        open={this.state.isVideoModalOpen}
                        onClose={this.onClickVideoToggle(false)}
                        url={this.props.infoUrl}
                    />
                )}
                <Draggable
                    draggableId={this.props.featureGuid}
                    index={this.props.index}
                    isDragDisabled={this.props.isDragDisabled}
                >
                    {(provided, snapshot) => (
                        <CardDraggable
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            innerRef={provided.innerRef} 
                            // isDragging={snapshot.isDragging}  
                            style={{
                                width: '100%',
                                height: '100%',                            
                                display: 'flex', 
                                padding: '8px',
                                ...provided.draggableProps.style,   
                                // maxWidth: 400,
                                // minWidth: 400, 
                                minWidth: this.getWidthPerRow(this.props.itemPerRow, this.props.windowInnterWidth),
                                maxWidth: this.getWidthPerRow(this.props.itemPerRow, this.props.windowInnterWidth),
                                cursor: this.props.isDragDisabled ? 'pointer' : 'grab'
                            }}                                             
                        >
                            {/* tslint:disable-next-line: jsx-alignment */}
                            <div 
                                className={classes.cardWrapper}
                                // ref={this.refValue}
                                // onClick={this.handleClick}
                            >
                                <Card 
                                    className={classes.cardContainer}
                                    style={{
                                        backgroundColor: !this.state.isVisible ? '#C7C7C7' : 'inherit'
                                    }}
                                >
                                    <div className={classes.cardMedia}>
                                        <div className="image">
                                            <CardMedia
                                                className={classes.cardCover}                                                
                                                // image={                                                   
                                                //     require('../images/TrendingUp.png')
                                                // }                                                
                                                title="Sample Image"
                                            >                                       
                                                <TrendingUpIconWrapper fontSize={'large'}/>
                                            </CardMedia>  
                                        </div>
                                        <div className="text">
                                            <CardContent className={classes.cardContent}>
                                                <Typography variant="subtitle1">
                                                    {this.props.title}
                                                </Typography>
                                            </CardContent>
                                        </div>
                                    </div>
                                    <div className={classes.controlContainer}>
                                        <FormControlLabel
                                            control={
                                                <SwitchWrapper
                                                    size="small"
                                                    checked={this.state.isVisible}
                                                    onChange={this.onHiddenChange}
                                                    value={this.state.isVisible}
                                                    color="primary"
                                                />
                                            }
                                            label={hiddenText}
                                        />
                                        <div className={classes.rightControl}>
                                            {this.props.infoUrl && (
                                                <IconButton 
                                                    aria-label="learn more" 
                                                    size="small" 
                                                    className={classes.videoButton}
                                                    onClick={this.onClickVideoToggle(true)}
                                                >
                                                    <VideoLibraryOutlinedIcon />
                                                </IconButton>
                                            )}

                                            {this.props.isInternalAdmin ? (
                                                <IconButton 
                                                    aria-label="edit" 
                                                    size="small" 
                                                    className={classes.videoButton}
                                                    onClick={this.onToggleNotesDialog(true)}
                                                >
                                                    <EditOutlinedIcon />
                                                </IconButton>
                                            ) : null}

                                            <IconButton 
                                                aria-label="edit" 
                                                size="small" 
                                                className={classes.videoButton}
                                                onClick={this.onPreviewNotes}
                                            >
                                                <InfoIcon />
                                            </IconButton>
                                          
                                            {/* <InfoIconWrapper
                                                key={this.props.index}
                                                aria-label="More Info"
                                                onMouseEnter={this.handlePopoverOpen}
                                                onMouseLeave={this.handlePopoverClose}
                                            />
                                            <Popover
                                                id="mouse-over-popover"
                                                className={classes.popover}
                                                classes={{
                                                    paper: classes.paper,
                                                }}
                                                open={this.props.description && this.props.description.length > 0 ? this.state.open : false}
                                                anchorEl={this.state.anchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                onClose={this.handlePopoverClose}
                                                disableRestoreFocus={true}
                                            >
                                                {this.getTooltip(this.props.description ?? '')}
                                            </Popover> */}
                                        </div>
                                    </div>                               
                                </Card>
                            </div>
                        </CardDraggable>                        
                    )}
                </Draggable>
           </React.Fragment>
        );
    }

    private getTooltip(description: string) {

        let tempDesription = description;

        if (description.indexOf('blocks":') !== -1) {
            const draftContent = convertToDraftWysiwygContent(JSON.parse(description));
            const contentState = convertFromRaw(draftContent);
            tempDesription = draftToHtml(convertToRaw(EditorState.createWithContent(contentState).getCurrentContent()));
        } 

        // const contentBlock = htmlToDraft(description);

        // const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        
        // const editorState = EditorState.createWithContent(contentState);

        return (
            <>
                <Editor
                    apiKey={'k74nddluc9fmtf75en31ew8mxzqcvovgpjkzomdtgeje7b0h'}            
                    initialValue={tempDesription}
                    init={{
                        width: 900,
                        // height: 500,
                        menubar: false,
                        disabled: true, // Set the editor in read-only mode
                        plugins: 'autoresize', // No plugins needed for read-only mode
                        toolbar: '', // No toolbar needed for read-only mode
                        statusbar: false, // Hide the status bar
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        toolbar_sticky: true,
                        autosave_ask_before_unload: true,
                        autosave_interval: '30s',
                        autosave_prefix: '{path}{query}-{id}-',
                        autosave_restore_when_empty: false,
                        autosave_retention: '2m',
                        image_advtab: true,
                        content_css: '//www.tiny.cloud/css/codepen.min.css',
                        image_class_list: [
                            { title: 'None', value: '' },
                            { title: 'Some class', value: 'class-name' }
                        ],
                        importcss_append: true,
                        min_height: 300,
                        min_width: 300,
                    }}
                />
            </>
        );

        // return (
        //     <>
        //         <Editor 
        //             editorState={editorState}
        //             readOnly={true}
        //             toolbarHidden={true}
        //         />
        //     </>
        // );
    }

    private getWidthPerRow(itemPerRow: number, windowInnterWidth: number) {
        if (itemPerRow === 4) {
            return `${(windowInnterWidth - 173) / 4}px`;
        } else if (itemPerRow === 3) {
            if (windowInnterWidth >= 1025) {
                return `${(windowInnterWidth - 173) / 3}px`;
            } else {
                return `${(windowInnterWidth - 105) / 3}px`;
            }
        } else if (itemPerRow === 2) {
            return `${(windowInnterWidth - 105) / 2}px`;
        } else {
            return `${(windowInnterWidth - 105)}px`;
        }
    }

    private onHiddenChange(event: React.FormEvent<HTMLInputElement>) {
        this.setState({
            isVisible: !this.state.isVisible,
            hiddenText: !this.state.isVisible ? 'Visible' : 'Hidden'
        });

        if (this.props.onIsHiddenUpdated) {
            this.props.onIsHiddenUpdated(this.props.featureGuid, !this.state.isVisible);
        }
    }

    // tslint:disable-next-line: no-any
    private handlePopoverOpen(event: any) {
        this.setAnchorEl(event.currentTarget);
    }
    
    private handlePopoverClose = () => {
        this.setAnchorEl(null);
    }

    // tslint:disable-next-line: no-any
    private setAnchorEl(anchorElValue: any) {
        this.setState({
            anchorEl: anchorElValue,
            open: Boolean(anchorElValue)
        });
    } 

    private onClickVideoToggle = (toggle: boolean) => () => {
        this.setState({
            isVideoModalOpen: toggle
        });
    }

    private onToggleNotesDialog = (toggle: boolean) => () => {
        this.setState({
            isEditNotesOpen: toggle
        });
    }

    private onPreviewNotes = () => {
        this.setState({
            isNotesPreviewOpen: !this.state.isNotesPreviewOpen
        });
    }

    // private handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    //     // click was used as a part of the drag
    //     if (event.defaultPrevented) {
    //         return;
    //     }
        
    //     this.refValue.current.focus();
    // }    
}

// tslint:disable-next-line: no-any
export default withStyles(styles as any, { withTheme: true })(FeatureLayoutCardDraggable as any) as any;