/* eslint-disable react-hooks/exhaustive-deps */
import { Button, createStyles, FormControlLabel, FormGroup, Grid, makeStyles, Switch, TextField, Theme } from '@material-ui/core';
import { FormApi } from 'final-form';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { StrengthEnum } from '../../components/PasswordStrengthIndicator';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { PassowrdStrengtthSelector } from '../selectors/PassowrdStrengtthSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        }
    })
);

interface FormState {
    mFA: boolean;
    passwordStrength: {label: string, value: number} | null;
    passwordLength: number;
}
interface SecurityTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

export default function SecurityTab() {

    const classes = useStyles();

    const [state, setState] = useState<SecurityTabState>({
        isLoading: true,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState, setFormState] = useState<FormState>({
        mFA: false,
        passwordStrength: null,
        passwordLength: 0,
    });

    const applicationSecuritySettings = useQuery<ApplicationSecuritySettingsData>(APPLICATION_SECURITY_SETTINGS_QUERY);

    // tslint:disable-next-line: no-any
    const [updateApplicationSecuritySettings] = useMutation<any, ApplicationSecuritySettingsMutationParams>(APPLICATION_SECURITY_SETTINGS_MUTATION);

    useEffect(() => {
    
        // tslint:disable-next-line: max-line-length
        if (!applicationSecuritySettings.loading && applicationSecuritySettings.data) {

            const { 
                mFA,
                passwordLength,
                passwordStrength,
            } = applicationSecuritySettings.data.settings.systems.applicationSecuritySettings.config;

            // console.log('test', applicationSecuritySettings.data);

            setFormState((prevFormState) => {
                return {
                    ...prevFormState,
                    mFA: mFA,
                    passwordLength: passwordLength,
                    passwordStrength: getPasswordStrength(passwordStrength),
                };
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false
                };
            });
        }
    },        [applicationSecuritySettings.data, applicationSecuritySettings.loading]);

    const getPasswordStrength = (_value: StrengthEnum): { label: string, value: number } | null => {
        if (_value === StrengthEnum.ONE) {
            return {
                label: 'Basic',
                value: StrengthEnum.ONE
            };
        } 
        if (_value === StrengthEnum.TWO) {
            return {
                label: 'Good',
                value: StrengthEnum.TWO
            };
        } 
        if (_value === StrengthEnum.THREE) {
            return {
                label: 'Strong',
                value: StrengthEnum.THREE
            };
        } 
        if (_value === StrengthEnum.FOUR) {
            return {
                label: 'Stronger',
                value: StrengthEnum.FOUR
            };
        } 
        if (_value === StrengthEnum.FIVE) {
            return {
                label: 'Very Strong',
                value: StrengthEnum.FIVE
            };
        } 
        return null;
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {
        // console.log('submit');
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true,
            };
        });

        updateApplicationSecuritySettings({
            variables: {
                mFA: values.mFA,
                passwordStrength: values.passwordStrength ? values.passwordStrength.value : 0, 
                passwordLength: values.passwordLength
            }
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info');     

                form.setConfig('keepDirtyOnReinitialize', false);

                const tempInitial: FormState = {
                    mFA: values.mFA,
                    passwordStrength: values.passwordStrength,
                    passwordLength: values.passwordLength
                };

                form.batch(() => {
                    form.reset(tempInitial);
                });
                
                form.setConfig('keepDirtyOnReinitialize', true);

                setState((_prevState) => {
                    return {
                        ..._prevState,
                        onSubmitLoading: false
                    };
                });
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update application security settings', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{
                        submitting: true,
                        pristine: true
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values
                    }) => (
                            <form onSubmit={handleSubmit} id="securitytab">
                                <FormSpy 
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={12} justify="flex-start">
                                        <FormGroup
                                            className={classes.formGroup}
                                        >
                                            <Field
                                                name="mFA"
                                                subscription={{touched: true, error: true, value: true}}
                                            >                                            
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                {...input}
                                                                checked={input.value}
                                                                onChange={input.onChange}
                                                                name="mFA" 
                                                                color="primary"
                                                            />
                                                        }
                                                        label="Multi-Factor Authentication"
                                                    />
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="passwordLength"
                                            subscription={{touched: true, error: true, value: true}}
                                            // validate={form.getFieldState('passwordLength')?.value !== null ? required : undefined}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Password Length"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Password Length is required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="passwordStrength"
                                            subscription={{touched: true, error: true, value: true}}
                                            // validate={form.getFieldState('passwordStrength')?.value !== null ? required : undefined}
                                        >
                                            {({ input, meta }) => (
                                                <PassowrdStrengtthSelector 
                                                    {...input}
                                                    label="Password Strength"
                                                    onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                        input.onChange(selection)
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || pristine || state.onSubmitLoading}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="button"
                                                disabled={submitting || pristine}
                                                onClick={onReset(form)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                                    {/* {printJson(values)} */}
                            </Grid>
                            </form>
                        )}
                />
            )}
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

export interface ApplicationSecuritySettingsData {
    settings: Settings;
}

export interface Settings {
    id:      number;
    systems: Systems;
}

export interface Systems {
    applicationSecuritySettings: ApplicationSecuritySettings;
}

export interface ApplicationSecuritySettings {
    id:     number;
    config: Config;
}

export interface Config {
    id:               number;
    mFA:              boolean;
    passwordLength:   number;
    passwordStrength: number;
}

const APPLICATION_SECURITY_SETTINGS_QUERY = gql`
    query ApplicationSecuritySettings {
        settings {
            id
            systems {
                applicationSecuritySettings {
                    id
                    config {
                        id
                        mFA
                        passwordLength
                        passwordStrength
                    }
                }
            }
        }
    }
`;

interface ApplicationSecuritySettingsMutationParams {
    passwordLength: number | null;
    passwordStrength: number | null;
    mFA: Boolean;
}

const APPLICATION_SECURITY_SETTINGS_MUTATION = gql`
    mutation ApplicationSecuritySettingsMutation(
        $mFA: Boolean,
        $passwordStrength: Int,
        $passwordLength: Int,
    ){
        settings {
            applicationSecuritySettings {
                update (
                    mFA: $mFA,
                    passwordStrength: $passwordStrength,
                    passwordLength: $passwordLength,
                ) {
                    status
                    error
                }
            }
        }
    }
`;