import * as React from 'react';
import glamorous from 'glamorous';
import { MatterTerminology } from '../MatterTerminology';
import { debounce } from 'lodash';
import { fetchScopeFeatureData, DropDownData, 
    DropDownSearchData, 
    fetchFeatureLayoutConfigurationData, FeatureLayoutConfigurationQueryParams, 
    FeatureLayoutConfigurationListData, 
    FeatureLayoutConfiguration, 
    RoleCategoryType,
    UpdateFeatureSubscription
} from './FeatureLayoutConfigurationRepository';
import { showNotification } from '../App';
import { RvLoader } from '../components/Loader';
import { DashboardLayoutScopeSelector } from '../components/DashboardLayoutScopeSelector';
import { IAutoCompleteItem } from '../typings/autoComplete';
import { WithStyles, withStyles, Theme, createStyles, Grid, Button, TextField, IconButton } from '@material-ui/core';
import { RoleSelector } from '../components/RoleSelector';
import { IDownShiftItem } from 'mui-downshift';
import { RoleCategorySelector } from '../components/RoleCategorySelector';
import UserSelector from '../components/UserSelector';
import DialogBox from '../Dashboard/Component/DialogBox';
import { PanelSelector } from '../lookupConfiguration/selectors/PanelSelector';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import { TypeOfDashboard } from './FeatureLayoutConfigurationPage';
import { UserAutocompleteSelector } from '../components/UserAutocompleteSelector';
import { client } from '..';
import { Subscription } from 'apollo-client/util/Observable';

const StyledContainer = glamorous.div<{ theme?: object }>((props) => ({
    padding: '30px',
    position: 'relative',
    [`& .${`rvloader-col`}`]: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    }    
}));

const ControlWrapper = glamorous(Grid)<{ theme?: object }>((props) => ({
    '& .downshift-wrapper': {
        maxWidth: '350px',
    },
    '& .scope-selector': {
        '& .MuiInputBase-formControl::before, .MuiInputBase-formControl::after, .MuiInputBase-formControl:after': {
            borderBottom: '1px solid #5173FF !important',
        }
    },
    '& .MuiAutocomplete-popupIndicator': {
        color: '#5173FF',
    }
}));

// tslint:disable-next-line:no-any
const SearchWrapper = glamorous.div<{ theme?: any }>((props) => ({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    flexFlow: 'row wrap',
    width: '100%',
    [`& .${`checkbox`}`]: {
        color: props.theme.BrandColors.TemplatePrimary,
        padding: '0 16px'
    },
    [`& .${`input`}`]: {
        height: '48px',
        width: '100%', // '336px',
        border: '1px solid ' + props.theme.NeutralColors.BackgroundGrey1,
        borderRadius: '4px',
        boxShadow: '0 0 4px 0 ' + props.theme.NeutralColors.BackgroundGrey2,
        margin: 0
    },
    '& .downshift-wrapper': {
        width: '100%'
    }    
}));

const styles = (theme: Theme) => createStyles({
    backgroundColor: {
        // backgroundColor: fade(theme.palette.primary.main, 0.03),
        paddingTop: '15px',
        position: 'relative',
    },
    button: {
        margin: theme.spacing(),
    },
    buttonContainer: {
        margin: '0 -10px 0 5px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        '& .button-primary': {
            // backgroundColor: '#5173FF',
            color: '#5173FF',
            border: '1px solid rgba(81,115,255, 0.5)'
        },
        '& .button': {
            margin: '0 5px',
        }
    },
    buttonWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '15px',
    },
    card: {
        marginBottom: '15px',
        padding: '0 15px',
    },
    container: {
        bottom: 0,
        left: 0,
        overflowY: 'auto',
        // padding: '12px', // Commented to keep it consistant with RecordView
        position: 'absolute',
        right: 0,
        top: 0,
    },
    devider: {
        borderLeft: '1px solid' + theme.palette.grey[400],
        height: '100%',
        margin: '0 auto',
        width: '1px',
    },
    extendedIcon: {
        marginRight: theme.spacing(),
    },
    formContainer: {
        flex: 1,
        padding: '12px',
    },
    formControl: {
        margin: '0 10px',
        minWidth: '250px',
    },
    generateWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        textAlign: 'center',
    },
    mainContainer: {
        marginBottom: '10px',
        marginTop: '10px',
    },
    subContainer: {
        borderTop: '1px solid' + theme.palette.grey[400],
        marginTop: '25px',
        paddingTop: '10px',
    },
    table: {
        
    },
    tableWrapper: {
        '& .ag-header': {
            color: theme.palette.secondary.main,
            fontSize: '14px'
        },
        display: 'inline-block',
        height: '100%',
        maxHeight: '200px',
        minHeight: '200px',
        width: '100%',
    },
    tableWrapperDetails: {
        '& .ag-header': {
            color: theme.palette.secondary.main,
            fontSize: '14px'
        },
        display: 'inline-block',
        height: '100%',
        maxHeight: '400px',
        minHeight: '400px',
        width: '100%',
    },
    textField: {

    },
    upperButton: {
        position: 'fixed',
        right: 0,
        top: '180px',
    },
    panelSelector: {
        width: '100%'
    },
    rightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    searchField: {
        '& .MuiInputBase-input': {
            paddingTop: '8px',
            paddingBottom: '8px',
        }
    }
});

// tslint:disable-next-line: no-any
// const FieldContainer = glamorous.div<{theme?: any}> ((props) => ({
//     width: '250px',
//     marginRight: '10px'    
// }));

interface FeatureLayoutConfigurationControlPanelProps extends WithStyles<typeof styles>  {
    layoutId: number;
    isSearchAgain: boolean;
    isShowFilterControls?: boolean;

    onFeatureLayoutConfigurationFetch?: (
        featureLayoutConfigurationList: FeatureLayoutConfiguration[],
        filteredFeatureLayoutConfigurationList: FeatureLayoutConfiguration[],
        isHideAllOperation: boolean
    ) => void;
    onSearchCriteriaChanged?: (
        layoutId: number, 
        isInternalRole: boolean | null, 
        isAdministrator: boolean | null,
        userEmail: string | null, 
        roleId: string | null, 
        isPersonal: boolean | null,
        dashboardPanel: IAutoCompleteItem | null,
    ) => void;
    handleSave?: () => void;
    handleReset?: () => void; 
    dashboardPanel: IAutoCompleteItem | null;
    dashboardScope: string | null;
    personalise: boolean;
    isInternalUser: boolean;
}

export interface FeatureLayoutConfigurationControlPanelState {
    featureLayoutConfigurationList: FeatureLayoutConfiguration[];
    roleCategoryList: DropDownData[];
    userList: DropDownSearchData[];

    isLoading: boolean;
    isUserLoading: boolean;
    isSearchAgain: boolean;
    isDisableReset: boolean;
    layoutId: number;
    scope: string;

    isInternalRole: string; // RoleCategory
    roleId: string;
    user: string;
    userLabel: string;
    userEmail: string;

    isHideAll: boolean;
    showConfirm: boolean;
    dashboardPanel: IAutoCompleteItem | null;
    search: string;
    personalise: boolean;
    externalUser?: IAutoCompleteItem;
}

class FeatureLayoutConfigurationControlPanel 
    extends React.Component<FeatureLayoutConfigurationControlPanelProps, FeatureLayoutConfigurationControlPanelState>  {
    
        private featureSubscription: Subscription;

        constructor(props: FeatureLayoutConfigurationControlPanelProps, state: FeatureLayoutConfigurationControlPanelState) {
        super(props, state);

        this.handleChangeFilters = this.handleChangeFilters.bind(this);
        this.onDownShiftSelect = this.onDownShiftSelect.bind(this);
        this.constructSecondDropDown = this.constructSecondDropDown.bind(this);
        // this.handleUserResultSelect = this.handleUserResultSelect.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.onSelection = this.onSelection.bind(this);
        this.onHideAllChange = this.onHideAllChange.bind(this);
        this.handleDeleteConfirm = this.handleDeleteConfirm.bind(this);
        this.onPanelSelection = this.onPanelSelection.bind(this);

        this.onSearchChange = this.onSearchChange.bind(this);

        this.handleFeatureLayoutConfigurationSearch = debounce(this.handleFeatureLayoutConfigurationSearch.bind(this), 500);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.onUserSelection = this.onUserSelection.bind(this);
        this.subscribeToUpdateFeature = this.subscribeToUpdateFeature.bind(this);

        let scope = this.props.dashboardScope ? this.props.dashboardScope : '';

        this.state = {
            featureLayoutConfigurationList: [],
            roleCategoryList: [],
            userList: [],

            isLoading: false,
            isUserLoading: false,
            isSearchAgain: false,
            isDisableReset: scope === 'DashboardSecurityScope' ? true : false,
            layoutId: this.props.layoutId,
            scope: scope,
            isInternalRole: '',
            roleId: '',
            user: '',
            userLabel: '',
            userEmail: '',
            isHideAll: false,
            showConfirm: false,
            dashboardPanel: this.props.dashboardPanel,
            search: '',
            personalise: this.props.personalise,
        };
    }

    componentDidMount() {
        this.fetchScopeData();

        this.subscribeToUpdateFeature();
    
    }

    public componentWillUnmount() {
        if (this.featureSubscription) {
            this.featureSubscription.unsubscribe();
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: FeatureLayoutConfigurationControlPanelProps) {

        if (this.props.layoutId !== nextProps.layoutId ||
            this.state.isSearchAgain !== nextProps.isSearchAgain ||
            this.props.dashboardScope  !== nextProps.dashboardScope) {
                // let scope = this.props.dashboardScope ? this.props.dashboardScope : this.state.scope;

                this.setState({
                    layoutId: nextProps.layoutId,
                    isSearchAgain : nextProps.isSearchAgain,
                    dashboardPanel: nextProps.dashboardPanel
                    // scope: this.props.dashboardScope ? this.props.dashboardScope : this.state.scope,
                    // isDisableReset: scope === 'DashboardSecurityScope' ? true : false
                });

                if (this.state.isSearchAgain !== nextProps.isSearchAgain) {
                    this.handleFeatureLayoutConfigurationSearch(this.state.scope);
                }
        }
        if (this.props.dashboardPanel && nextProps.dashboardPanel && this.props.dashboardPanel.value !== nextProps.dashboardPanel.value) {
            // let scope = this.props.dashboardScope ? this.props.dashboardScope : this.state.scope;

            this.setState({
                layoutId: nextProps.layoutId,
                isSearchAgain : nextProps.isSearchAgain,
                dashboardPanel: nextProps.dashboardPanel,
                // scope: this.props.dashboardScope ? this.props.dashboardScope : this.state.scope,
                // isDisableReset: scope === 'DashboardSecurityScope' ? true : false
            });

            this.onPanelSelection(nextProps.dashboardPanel, '');
        }
    }

    render() {
        const { classes } = this.props;
        const terminology = { ...MatterTerminology };

        var loader = this.state.isLoading ? <RvLoader /> : undefined;
        var secondDropDown = this.constructSecondDropDown(terminology);

        let isHideAll = !(this.state.featureLayoutConfigurationList.filter( x => x.isHidden === false).length > 0);

        let isShowFilterControls = this.props.isShowFilterControls === undefined ? true : this.props.isShowFilterControls;

        return (
            <StyledContainer className={'butoncontainer '}>
                <ControlWrapper>
                    <DialogBox 
                        title="Reset Configuration Layout"
                        // tslint:disable-next-line:max-line-length
                        content={'Are you sure that you want to reset this Configuration Layout?'}
                        show={this.state.showConfirm}
                        isAgree={this.handleDeleteConfirm}
                        disAgreeLabel={'No'}
                        agreeLabel={'Yes'}
                    />
                    <Grid container={true} spacing={1}>
                        <Grid item={true} xs={12} md={5} lg={5}>
                            <Grid container={true} spacing={2}>
                                { isShowFilterControls === true && this.props.layoutId !== TypeOfDashboard.lead && this.props.layoutId !== TypeOfDashboard.client
                                ?   ( 
                                        <React.Fragment>
                                            <Grid item={true} xs={12} md={4} lg={4}>
                                                <DashboardLayoutScopeSelector
                                                    label={terminology.Scope}
                                                    name="scopeId"
                                                    value={this.state.scope}
                                                    className="scope-selector"
                                                    onSelection={this.onSelection}
                                                />
                                            </Grid>
                                            {secondDropDown}
                                        </React.Fragment>  
                                    ) 
                                    : null
                                } 
                                {!!(isShowFilterControls === true && this.props.layoutId === TypeOfDashboard.client) && this.props.isInternalUser && (
                                    <Grid item={true} xs={12} md={4} lg={4}>
                                        <UserAutocompleteSelector 
                                            label="User"
                                            name="externalUser"
                                            value={this.state.externalUser}
                                            isExternal={true}
                                            onSelection={this.onUserSelection}
                                        />
                                    </Grid>
                                )}
                                <Grid item={true} xs={12} md={4} lg={4}>
                                    <SearchWrapper>
                                        {/* <Form.Field required={false} > */}
                                            {/* <FieldContainer> */}
                                                <PanelSelector 
                                                    label="Panel"
                                                    name="dashboardPanel"
                                                    value={this.state.dashboardPanel}
                                                    onSelection={this.onPanelSelection}
                                                    className={classes.panelSelector}
                                                    dashboardPanelUsedBy={this.state.layoutId}
                                                />          
                                            {/* </FieldContainer> */}
                                        {/* </Form.Field> */}
                                    </SearchWrapper>
                                </Grid>     
                            </Grid>
                        </Grid>
                           
                        <Grid item={true} xs={12} md={2} lg={2} >
                            {loader}
                        </Grid>  

                        <Grid item={true} xs={12} md={5} lg={5} >
                            <Grid container={true} spacing={1}>
                                <Grid item={true} xs={12} md={12} lg={12}>
                                    <div className={classes.rightContainer}>
                                        <TextField 
                                            size="small"
                                            variant="outlined" 
                                            placeholder="Search..." 
                                            className={classes.searchField}
                                            value={this.state.search}
                                            onChange={this.onSearchChange}
                                            InputProps={{
                                                endAdornment: this.state.search.length <= 0 ? 
                                                (
                                                    <SearchOutlinedIcon fontSize="small" />
                                                )
                                                :
                                                (
                                                    <IconButton
                                                        onClick={this.onClearSearch}
                                                        edge="end"
                                                        size="small"
                                                    >
                                                        <CloseOutlinedIcon fontSize="small" />
                                                    </IconButton>
                                                )
                                            }}
                                        />
                                        <div className={classes.buttonContainer}>
                                            <Button 
                                                type="button" 
                                                variant="outlined"
                                                onClick={this.onHideAllChange} 
                                                className="button"
                                                disabled={this.state.search.length !== 0}
                                            >
                                                {isHideAll ? 'Show All' : 'Hide All'}
                                            </Button>
                                            <Button 
                                                type="submit" 
                                                variant="outlined"
                                                onClick={this.handleSave} 
                                                className="button button-primary"
                                                // className={'custom-button button-save'} 
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                type="button" 
                                                variant="outlined"
                                                onClick={this.handleReset}
                                                color={'secondary'}
                                                className="button"
                                                disabled={this.state.isDisableReset}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>                 
                    </Grid>
                </ControlWrapper>
            </StyledContainer>   
        );
    }

    private fetchScopeData() {
        let scopeList = fetchScopeFeatureData(undefined, this.state.personalise);

        const scopeData = scopeList.map( (scope: DropDownData) => ({value: scope.value, label: scope.text }));

        if (scopeList != null && scopeList.length > 0) {
             // If there is no value is set, then set the default value to first in scopeData
            const defaultValue = this.state.scope !== '' 
                                ? this.state.scope 
                                : scopeData[0].value;
            // let isDisableReset = false;
            // if (defaultValue === 'DashboardSecurityScope'  && this.props.layoutId !== TypeOfDashboard.client) {
            //     isDisableReset = true;
            // }

            // this.setState({
            //     scope: defaultValue,
            //     isDisableReset: isDisableReset
            // });

            this.handleChangeFilters(defaultValue, '', 'scopeId');
        }
    }

    // tslint:disable-next-line: no-any
    private constructSecondDropDown(terminology: any): JSX.Element {
        if (this.state.scope === 'DashboardSecurityRoleCategory') {
            return (
                <Grid item={true} xs={12} md={4} lg={4}>
                    <SearchWrapper>
                        {/* <Form.Field required={false} > */}
                            {/* <FieldContainer> */}
                                <RoleCategorySelector
                                    label={terminology.RoleCategory}
                                    name="roleCategoryId"
                                    value={this.state.isInternalRole}
                                    isIncludeAdministrator={true}
                                    // className={classes.textField}
                                    onSelection={this.onSelection}
                                />            
                            {/* </FieldContainer> */}
                        {/* </Form.Field> */}
                    </SearchWrapper>
                </Grid>
            );
        } else if (this.state.scope === 'DashboardSecurityRole') {        
            return (
                <Grid item={true} xs={12} md={4} lg={4}>
                    <SearchWrapper>
                        {/* <Form.Field required={false} > */}
                            {/* <FieldContainer> */}
                                <RoleSelector
                                    label={terminology.Role}
                                    name="roleId"
                                    value={this.state.roleId}
                                    // className={classes.textField}
                                    onSelection={this.onSelection}
                                /> 
                            {/* </FieldContainer> */}
                        {/* </Form.Field> */}
                    </SearchWrapper>
                </Grid>
            );
        } else if (this.state.scope === 'DashboardSecurityUser') {
            return (
                <Grid item={true} xs={12} md={4} lg={4}>
                    <SearchWrapper>
                        {/* <Form.Field required={false} > */}
                            {/* <FieldContainer> */}
                                <UserSelector
                                    label={terminology.User}
                                    name="userId"
                                    onSelection={this.onDownShiftSelect}
                                    value={this.state.user}
                                    displayLabel={this.state.userLabel}
                                    isAuthorized={true}
                                />           
                            {/* </FieldContainer> */}
                        {/* </Form.Field> */}
                    </SearchWrapper>
                </Grid>
            );
        } else {
            return <div/>;
        }
    }

    private onHideAllChange() {
        const featureLayoutConfigurationList = this.state.featureLayoutConfigurationList;
        featureLayoutConfigurationList.forEach(featureLayoutConfig => {
            featureLayoutConfig.isHidden = !this.state.isHideAll;
        });
        
        this.setState({
            featureLayoutConfigurationList: featureLayoutConfigurationList,
            isHideAll: !this.state.isHideAll
        });

         // Pass this to the parent component.
        if (this.props.onFeatureLayoutConfigurationFetch) {
            this.props.onFeatureLayoutConfigurationFetch(featureLayoutConfigurationList, featureLayoutConfigurationList, true);
        }
    }

    // tslint:disable-next-line: no-any
    private onUserSelection(selection: IAutoCompleteItem | any) {
        if (selection) {
            // Pass this to the parent component.
            if (this.props.onFeatureLayoutConfigurationFetch) {
                this.props.onFeatureLayoutConfigurationFetch([], [], false);
            }

            let isInternalRoleParam = null;
            let isAdministratorParam = null;
            let userEmailParam = selection.email;
            let roleIdParam = null;
            let isPersonal = false;
            let dashboardPanel = { label: 'Home', value: 'all' };

            if (this.props.layoutId === TypeOfDashboard.client) {

                this.setState({
                    isDisableReset: (userEmailParam as string).length > 0 ? false : true,
                    userEmail: userEmailParam,
                    dashboardPanel: dashboardPanel,
                });
            } else {
                this.setState({
                    userEmail: userEmailParam,
                    dashboardPanel: dashboardPanel,
                });
            }

            if (this.props.onSearchCriteriaChanged) {
                this.props.onSearchCriteriaChanged(
                    this.state.layoutId, 
                    isInternalRoleParam, 
                    isAdministratorParam, 
                    userEmailParam, 
                    roleIdParam, 
                    isPersonal,
                    dashboardPanel,
                );
            }

            this.handleFeatureLayoutConfigurationSearch(this.state.scope);

        } else {
            this.setState({
                userEmail: '',
                isDisableReset: true
            });
        }
    }

    private onPanelSelection(selection: IAutoCompleteItem, name: string) {
        
        if (this.props.onFeatureLayoutConfigurationFetch) {
            this.props.onFeatureLayoutConfigurationFetch([], [], false);
        }

        if (selection) {

            this.setState({
                dashboardPanel: selection
            });
            
            if (this.props.onSearchCriteriaChanged) {
                
                let isInternalRoleParam = null;
                let isAdministratorParam = null;
                if (this.state.scope === 'DashboardSecurityRoleCategory') {
                   // tslint:disable-next-line: max-line-length
                   isInternalRoleParam =  this.state.isInternalRole !== RoleCategoryType.Administrator.toString() ? this.state.isInternalRole === '1' : null;
                   isAdministratorParam = this.state.isInternalRole === RoleCategoryType.Administrator.toString() ? true : null;
                }
                let roleIdParam = (this.state.roleId !== undefined && this.state.roleId.length > 0) ? this.state.roleId : null;
                let userEmailParam = (this.state.userEmail !== undefined && this.state.userEmail.length > 0) ? this.state.userEmail : null;
                let isPersonal = (this.state.scope === 'DashboardSecurityPersonal') 
                                    ? true 
                                    : (this.state.scope === 'DashboardSecurityUser' ? false : (userEmailParam !== null ? false : null ));

                this.props.onSearchCriteriaChanged(
                    this.state.layoutId, 
                    isInternalRoleParam, 
                    isAdministratorParam, 
                    userEmailParam, 
                    roleIdParam, 
                    isPersonal,
                    selection,
                );
            }
            this.handleFeatureLayoutConfigurationSearch(this.state.scope);
        }
    }

    private onSelection(selection: IAutoCompleteItem, name: string) {
        this.handleChangeFilters(selection.value, '', name);
    }

    private onDownShiftSelect(selection: IDownShiftItem, name: string) {
        this.handleChangeFilters(selection.value === undefined ? '' : selection.value, selection.label, name);
    }

    // tslint:disable-next-line: no-any
    private handleChangeFilters(value: any, label: string, name: string) {
        if (value !== undefined) {
            let isScopeChanged = false;
            let scope = '';
            let isInternalRole = '';
            let userEmail = '';
            let userLabel = '';
            let roleId = '';
            let isDisableReset = true;
            
            let dashboardPanel: IAutoCompleteItem | null = null;

            if (this.props.layoutId === TypeOfDashboard.client) {
                dashboardPanel = this.state.dashboardPanel;
            } else {
                dashboardPanel = this.state.dashboardPanel ? this.state.dashboardPanel : { label: 'Home', value: 'all' };
            }

            if (name === 'scopeId') {

                isScopeChanged = true;
                scope = value;     
                
                if (value === 'DashboardSecurityPersonal') {
                    isDisableReset = false;
                } else {
                    isDisableReset = true;
                }

            } else if (this.state.scope === 'DashboardSecurityRoleCategory' && name === 'roleCategoryId') {
                
                isInternalRole = value;      

                if (value.toString().length > 0) {
                    isDisableReset = false;
                } 
                
            } else if (this.state.scope === 'DashboardSecurityRole' && name === 'roleId') {
                
                roleId = value;

                if (typeof value === 'string' && value.length > 0) {
                    isDisableReset = false;
                }

            } else if (this.state.scope === 'DashboardSecurityUser' && name === 'userId') {
                userEmail = value;
                userLabel = label;

                if (typeof value === 'string' && value.length > 0) {
                    isDisableReset = false;
                }
            }
            
            // if (name === 'scopeId') {
            //     isScopeChanged = true;
            //     scope = value;     
                
            //     if (value === 'DashboardSecurityScope' || value === '') {
            //         isDisableReset = true;
            //     }

            // } else if (name === 'roleCategoryId') {
            //     isInternalRole = value;                            
            // } else if (name === 'roleId') {
            //     roleId = value;
            // } else if (name === 'userId') {
            //     userEmail = value;
            //     userLabel = label;
            // }

            scope = isScopeChanged ? scope : this.state.scope;
            
            this.setState({
                isDisableReset: isDisableReset,
                scope: scope,
                isInternalRole: isInternalRole,              
                userEmail: userEmail,
                user: userEmail === '' ? '' : this.state.user,
                userLabel: userLabel,
                roleId: roleId,   
                dashboardPanel: dashboardPanel ? dashboardPanel : this.state.dashboardPanel      
            }); 

            // Pass this to the parent component.
            if (this.props.onFeatureLayoutConfigurationFetch) {
                this.props.onFeatureLayoutConfigurationFetch([], [], false);
            }

            if (value === 'DashboardSecurityScope' || value === 'DashboardSecurityPersonal' ||
                (name === 'roleCategoryId' && value !== '') || 
                ( name === 'roleId' && value !== '') || 
                ( name === 'userId' && value !== '' ) ||
                ( name === 'dashboardPanel' && value !== '')) {
                this.handleFeatureLayoutConfigurationSearch(scope);
            }

            if (this.props.onSearchCriteriaChanged) {   
                let isInternalRoleParam =  null;
                let isAdministratorParam = null;

                if (name === 'roleCategoryId') {
                    if (isInternalRole === RoleCategoryType.Administrator.toString()) {
                        isAdministratorParam = true;
                    } else {
                        isInternalRoleParam = isInternalRole === '1';
                    }
                }

                let roleIdParam = (name === 'roleId' && value !== undefined && value.length > 0) ? value : null;
                let userEmailParam = (name === 'userId' && value !== undefined && value.length > 0) ? value : null;
                let isPersonal = (value === 'DashboardSecurityPersonal') 
                                    ? true 
                                    : (value === 'DashboardSecurityUser' ? false : (userEmailParam !== null ? false : null ));

                this.props.onSearchCriteriaChanged(
                    this.state.layoutId, 
                    isInternalRoleParam, 
                    isAdministratorParam, 
                    userEmailParam, 
                    roleIdParam, 
                    isPersonal,
                    dashboardPanel ? dashboardPanel : this.state.dashboardPanel,
                );
            }
        }
    }

    // tslint:disable-next-line: no-any
    // private handleUserResultSelect(event: any, data: SearchResultData) {
    //     if (data.result === undefined) {
    //         return;            
    //     }
        
    //     this.setState({
    //         isUserLoading: false,            
    //         isInternalRole: '',
    //         roleId: '',
    //         userEmail: data.result.description.substring(0, data.result.description.indexOf('|')).trim(),
    //         user: data.result.title,
    //     });

    //     this.handleFeatureLayoutConfigurationSearch();

    //     if (this.props.onSearchCriteriaChanged) {
    //         let userEmail = data.result.description.substring(0, data.result.description.indexOf('|')).trim();                

    //         this.props.onSearchCriteriaChanged(this.state.layoutId, null, null, userEmail, null, null);
    //     }
    // }
    
    private handleFeatureLayoutConfigurationSearch(scope: string) {
        
        this.setState({
            isLoading: true,
            featureLayoutConfigurationList: [], // clear before setting the data again
            isHideAll: false
        });

        // Pass this to the parent component to clear the records
        if (this.props.onFeatureLayoutConfigurationFetch) {
            this.props.onFeatureLayoutConfigurationFetch([], [], false);
        }

        let isAdministrator = null;
        let isInternalRole = null;

        if (this.state.isInternalRole.length > 0) {
            if (this.state.isInternalRole === RoleCategoryType.Administrator.toString()) {
                isAdministrator = true;
            } else {
                isInternalRole = this.state.isInternalRole === '1';
            }
        }

        let isShowFilterControls = this.props.isShowFilterControls === undefined ? true : this.props.isShowFilterControls;

        var queryParams: FeatureLayoutConfigurationQueryParams = {
            includeHidden: true,
            layoutId: this.state.layoutId, // This is hardcoded now. 
            isInternalRole: isShowFilterControls === false ? null : isInternalRole,
            isAdministrator:  isShowFilterControls === false ? null : isAdministrator,
            userEmail:  isShowFilterControls === false ? null : (this.state.userEmail.length > 0 ? this.state.userEmail : null),
            roleId:  isShowFilterControls === false ? null : ( this.state.roleId.length > 0 ? this.state.roleId : null ),
            isPersonal:  isShowFilterControls === false 
                ? true 
                : (scope === 'DashboardSecurityPersonal' ? true : (this.state.userEmail.length > 0 ? false : null) ),
            dashboardPanelId: this.state.dashboardPanel ? this.state.dashboardPanel.value === 'all' ? null : this.state.dashboardPanel.value : null
        };

        fetchFeatureLayoutConfigurationData(queryParams, 
                                            true, 
                                            (data: FeatureLayoutConfigurationListData) => { 
                                                this.handleFeatureLayoutConfigurationFetch(data); 
                                            }, 
                                            (reason) => { 
                                                this.setState({
                                                    isLoading: false
                                                });

                                                showNotification('Failed to Fetch Layout Configuration', reason, 'error'); 
                                            });
    }

    private handleFeatureLayoutConfigurationFetch(data: FeatureLayoutConfigurationListData) {
        if (data.loading) {
            this.setState({
                isLoading: true
            });
        }

        if (data && data.featureLayoutConfigurationList !== undefined && 
            data.featureLayoutConfigurationList.featureLayoutConfiguration !== undefined) {

                // This is due to what is being returned from Graphql is a readonly list. 
                // This is a intentional in Graphql Apollo. But we need this to be editable.
                // So we make a deep copy.
                const featureLayoutConfigurationList = JSON.parse(JSON.stringify(
                    data.featureLayoutConfigurationList.featureLayoutConfiguration));

                let isHideAll = !(data.featureLayoutConfigurationList.featureLayoutConfiguration.filter( x => x.isHidden === false).length > 0);

                let filteredItems: FeatureLayoutConfiguration[] = featureLayoutConfigurationList;

                if (this.state.search.length > 0) {
                    filteredItems = featureLayoutConfigurationList.filter((item: FeatureLayoutConfiguration) => {
                        return item.title.toLowerCase().includes(this.state.search.toLowerCase().trim());
                    });
                }

                this.setState({
                    featureLayoutConfigurationList: filteredItems,
                    isLoading: false,
                    isSearchAgain: false,
                    isHideAll: isHideAll
                });

                // Pass this to the parent component.
                if (this.props.onFeatureLayoutConfigurationFetch) {
                    this.props.onFeatureLayoutConfigurationFetch(featureLayoutConfigurationList, filteredItems, false);
                }
        }
    }

    private handleSave() {
        if (this.props.handleSave) {
            this.props.handleSave();
        }
    }

    private handleReset() {
        this.setState({
            showConfirm: true
        });
    }

    private handleDeleteConfirm(agree: boolean) {     
        if (agree) {
            if (this.props.handleReset) {
                this.props.handleReset();
            }
        }

        this.setState({showConfirm: false});
    }

    private onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {

        const search = event.target.value;
        this.setState({
            search: search
        });
        
        this.handleFeatureLayoutConfigurationSearch(this.state.scope);
        
        // const filteredFeatureLayoutConfigurationList = this.state.featureLayoutConfigurationList;

        // if (this.props.onFeatureLayoutConfigurationFetch) {
        //     this.props.onFeatureLayoutConfigurationFetch(filteredFeatureLayoutConfigurationList, filteredItems);
        // }
    }

    private onClearSearch() {
        this.setState({
            search: ''
        });

        this.handleFeatureLayoutConfigurationSearch(this.state.scope);
    }

    private subscribeToUpdateFeature() {

        const vm = this;      
        
        this.featureSubscription = client.subscribe({
            query: UpdateFeatureSubscription
        }).subscribe({
            // tslint:disable-next-line:no-any
            next(results: any) {
                // tslint:disable-next-line:no-console
                console.log(results);
                if (results.data.featureUpdateNotification.status) {
                    
                    showNotification('Success', results.data.featureUpdateNotification.message, 'info');
                    
                    vm.fetchScopeData();

                } else {
                    showNotification('Failed', results.data.featureUpdateNotification.message, 'error');
                }
            },            
            // tslint:disable-next-line:no-any
            error(err: any) {
                // tslint:disable-next-line:no-console
                console.error(err);
                showNotification(null, err, 'error');
            }
        });
        
    }
}

// tslint:disable-next-line: no-any
export default withStyles(styles as any, { withTheme: true })(FeatureLayoutConfigurationControlPanel as any) as any;