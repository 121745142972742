import * as React from 'react';
import glamorous from 'glamorous';
import { RvContainer } from '../components/Container';
import { FeatureLayoutConfigurationHeader } from './FeatureLayoutConfigurationHeader';
import FeatureLayoutConfigurationControlPanel  from './FeatureLayoutConfigurationControlPanel';
import { FeatureLayoutConfigurationList } from './FeatureLayoutConfigurationList';
import { FeatureLayoutConfiguration, UpdateFeatureLayoutConfigData, featureLayoutConfigSubscription, 
    DeleteFeatureLayoutConfigData, 
    fetchCurrentUserData,
    CurrentUserData } from './FeatureLayoutConfigurationRepository';
import { client } from '..';
import { showNotification, RouterProps } from '../App';
import { Subscription } from 'apollo-client/util/Observable';
import { MatterTerminology } from '../MatterTerminology';
import { IAutoCompleteItem } from '../typings/autoComplete';

const GridWrapper = glamorous.div<{theme?: object}>((props) => ({
    [`& .${`pushable`}`]: {
        overflow: 'visible',
        [`& .${`sidebar`}`]: {
            top: '0px !important',
            width: '500px !important'
        },
    },
    [`& .${`segment`}`]: {
        // overflow: 'visible',
        // top: '10px !important',
        padding: 0,
        // background: props.theme.NeutralColors.BackgroundGrey5
    }        
}));

const ContentWrapper = glamorous.div <{ theme?: object }>((props) => ({
    padding: '0 34px',
    '@media (max-width: 1024px)': {
        padding: 0
    }
}));

// tslint:disable-next-line:no-any
const StyledSummaryContent = glamorous.div <{ theme?: any }>((props) => ({
    borderRadius: '8px',	
    backgroundColor: props.theme.NeutralColors.BackgroundWhite,	
    boxShadow: '0 0 14px 0 ' + props.theme.NeutralColors.BackgroundGrey4,
    // height: window.innerHeight - 300,
    height: '100%'
}));

const SummaryViewWrapper = glamorous.div<{ theme?: object }>((props) => ({
    paddingLeft: '30px',
    paddingRight: '30px',
    paddingBottom: '20px',
}));

export enum TypeOfDashboard {
    firm = 0,
    lead = 1,
    feeEarner = 2,
    task = 3,
    client = 4
}

export interface FeatureLayoutConfigurationProps extends RouterProps {
    // tslint:disable-next-line: no-any
    location: any;
}

interface FeatureLayoutConfigurationState {  
    layoutId: number;  
    isInternalRole: boolean | null; // RoleCategory
    isAdministrator: boolean | null; // RoleCategory
    roleId: string | null;
    userEmail: string | null;
    isPersonal: boolean | null;
    featureLayoutConfigurationList: FeatureLayoutConfiguration[];
    filteredFeatureLayoutConfigurationList: FeatureLayoutConfiguration[];
    isSearchAgain: boolean;
    isSearchChanged: boolean;
    isHideAllOperation: boolean;
    dashboardPanel: IAutoCompleteItem | null;
    dashboardScope: string | null;
    personalise: boolean;
    isCurrentUserLoading: boolean;
    isInternalUser: boolean;
    userId: string | null;
    isInternalAdmin: boolean;
}

export class FeatureLayoutConfigurationPage extends React.Component<FeatureLayoutConfigurationProps, FeatureLayoutConfigurationState>  {
    private featureLayoutConfigurationSubscription: Subscription;
    private isSubscribed: boolean;
    private isShowFilterControls: boolean = true;
    private hasBack: boolean = true;

    constructor(props: FeatureLayoutConfigurationProps, state: FeatureLayoutConfigurationState) {
        super(props, state);
        
        this.onFeatureLayoutConfigurationFetch = this.onFeatureLayoutConfigurationFetch.bind(this);
        this.onSearchCriteriaChanged = this.onSearchCriteriaChanged.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.saveFeatureLayoutConfiguration = this.saveFeatureLayoutConfiguration.bind(this);
        this.deleteFeatureLayoutConfiguration = this.deleteFeatureLayoutConfiguration.bind(this);
        this.subscribeToFeatureLayoutConfig = this.subscribeToFeatureLayoutConfig.bind(this);
        this.onClickBack = this.onClickBack.bind(this);
        this.fetchCurrentUser = this.fetchCurrentUser.bind(this);
        this.onRetreivedCurrentUser = this.onRetreivedCurrentUser.bind(this);
        this.onVisibilityChanged = this.onVisibilityChanged.bind(this);

        this.isSubscribed = false;

        let personaliseStorage = sessionStorage.getItem('personalise');
        // let layoutId = sessionStorage.getItem('layoutId');
        let dashboardScope = sessionStorage.getItem('dashboardScope');
        let dashboardPanelValue = sessionStorage.getItem('dashboardPanelValue');
        let dashboardPanelLabel = sessionStorage.getItem('dashboardPanelLabel');

        let layoutId = props.location.state && props.location.state.layoutId ? props.location.state.layoutId : Number(sessionStorage.getItem('layoutId'));

        if (personaliseStorage === 'noback') {
            this.hasBack = false;
        }

        let dashboardPanel: IAutoCompleteItem | null = { label: 'Home', value: 'all' };

        if (dashboardPanelValue && dashboardPanelLabel ) {
            dashboardPanel =  { label: dashboardPanelLabel, value: dashboardPanelValue };
        } 
        
        this.state = {
            layoutId: props.location.state && props.location.state.layoutId ? props.location.state.layoutId : Number(layoutId),
            isInternalRole: null,
            isAdministrator: null,
            roleId: null,
            userEmail: null,
            isPersonal: dashboardScope === 'DashboardSecurityPersonal' ? true : null,
            featureLayoutConfigurationList: [],
            filteredFeatureLayoutConfigurationList: [],
            isSearchAgain: false,
            isSearchChanged: false,
            isHideAllOperation: false,
            dashboardPanel: dashboardPanel,
            dashboardScope: dashboardScope,
            personalise: personaliseStorage === 'noback',
            isCurrentUserLoading: true,
            isInternalUser: true,
            userId: null,
            isInternalAdmin: false
        };

        // After assigning the values, clear them from session storage
        sessionStorage.removeItem('layoutId');
        sessionStorage.removeItem('dashboardScope');
        sessionStorage.removeItem('dashboardPanelValue');
        sessionStorage.removeItem('dashboardPanelLabel');
    }

    // tslint:disable-next-line: no-empty
    public componentDidMount() {
        this.fetchCurrentUser();
        if (!this.isSubscribed) {
            this.isSubscribed = true;
            this.subscribeToFeatureLayoutConfig();   
        }
    }

    // tslint:disable-next-line: no-empty
    public componentWillUnmount() {
        if (this.featureLayoutConfigurationSubscription) {
            this.featureLayoutConfigurationSubscription.unsubscribe();
        } 
    }

    // tslint:disable-next-line: no-empty
    public UNSAFE_componentWillReceiveProps(nextProps: FeatureLayoutConfigurationProps) {
        // let layoutId = sessionStorage.getItem('layoutId');
        // let dashboardScope = sessionStorage.getItem('dashboardScope');

        // this.setState({
        //     layoutId: layoutId ? Number(layoutId) : 0,
        //     dashboardScope: dashboardScope
        // });        
    }

    public render() { 
        let title = MatterTerminology.FirmDashboardLayout;
        this.isShowFilterControls = true;

        if (this.state.layoutId === TypeOfDashboard.lead) {
            title = MatterTerminology.EnquiryDashboardLayout;
        } else if (this.state.layoutId === TypeOfDashboard.feeEarner) {
            title = MatterTerminology.FeeEarnerDashboardLayout;
            // this.isShowFilterControls = false;
        } else if (this.state.layoutId === TypeOfDashboard.task) {
            title = MatterTerminology.TaskDashboardLayout;
        } else if (this.state.layoutId === TypeOfDashboard.client) {
            title = MatterTerminology.ClientDashboardLayout;
        }

        // If the list is filtered using the search, then show filtered list
        const isSearch = this.state.featureLayoutConfigurationList.length !== this.state.filteredFeatureLayoutConfigurationList.length;

        return (
            <>
                <GridWrapper className="gridwrapper mattersummry-wrapper">
                    <RvContainer rvClassName="banner-container">
                        <FeatureLayoutConfigurationHeader 
                            key="flcdlh" 
                            onClickBack={this.onClickBack}
                            title={title}
                            hasBack={this.hasBack}
                        />
                    </RvContainer>
                    <RvContainer rvClassName="content-container">
                        <ContentWrapper className="content-wrapper">
                            <StyledSummaryContent className="summarytablediv">
                                {/* Control Panel or Controls */}
                                <FeatureLayoutConfigurationControlPanel
                                    layoutId={this.state.layoutId}
                                    onFeatureLayoutConfigurationFetch={this.onFeatureLayoutConfigurationFetch}
                                    handleSave={this.handleSave}
                                    handleReset={this.handleReset}
                                    onSearchCriteriaChanged={this.onSearchCriteriaChanged}
                                    isSearchAgain={this.state.isSearchAgain}
                                    isShowFilterControls={this.isShowFilterControls}
                                    dashboardPanel={this.state.dashboardPanel}
                                    dashboardScope={this.state.dashboardScope}
                                    personalise={this.state.personalise}
                                    isInternalUser={this.state.isInternalUser}
                                />
                                
                                <SummaryViewWrapper className="summaryview-wrapper">                                
                                    {/* Draggable control */}
                                    <FeatureLayoutConfigurationList
                                        // tslint:disable-next-line: max-line-length
                                        featureLayoutConfigurationList={isSearch 
                                            ? this.state.filteredFeatureLayoutConfigurationList 
                                            : this.state.featureLayoutConfigurationList}
                                        isSearch={isSearch}
                                        onDragEnd={this.onDragEnd}  
                                        isDragDisabled={isSearch}
                                        isHideAllOperation={this.state.isHideAllOperation}
                                        isSearchChanged={this.state.isSearchChanged}
                                        onVisibilityChanged={this.onVisibilityChanged}
                                        isInternalAdmin={this.state.isInternalAdmin}
                                    />
                                </SummaryViewWrapper>
                            </StyledSummaryContent>
                        </ContentWrapper>   
                    </RvContainer>                           
                </GridWrapper>
            </>
        );
    }

    private onClickBack() {
        this.props.history.goBack();
    }

    private onFeatureLayoutConfigurationFetch(
        featureLayoutConfigurationList: FeatureLayoutConfiguration[],
        filteredFeatureLayoutConfigurationList: FeatureLayoutConfiguration[],
        isHideAllOperation: boolean
    ) {
        
        this.setState({
            featureLayoutConfigurationList: featureLayoutConfigurationList,
            filteredFeatureLayoutConfigurationList: filteredFeatureLayoutConfigurationList,
            isSearchAgain: false,
            isSearchChanged: false,
            isHideAllOperation: isHideAllOperation
        });
    }

    private onSearchCriteriaChanged(
        layoutId: number, 
        isInternalRole: boolean | null, 
        isAdministrator: boolean | null,
        userEmail: string | null, 
        roleId: string | null, 
        isPersonal: boolean | null,
        dashboardPanel: IAutoCompleteItem | null,
        userId: string | null
    ) {

        // console.log(
        //     'dashboardPanel', dashboardPanel,
        //     'isInternalRole', isInternalRole,
        //     'isAdministrator', isAdministrator,
        //     'userEmail', userEmail,
        //     'roleId', roleId,
        //     'isPersonal', isPersonal,
        // );

        this.setState({
            isInternalRole: isInternalRole,
            isAdministrator: isAdministrator,
            userEmail: userEmail,
            roleId: roleId,
            isPersonal: isPersonal,
            dashboardPanel: dashboardPanel,
            isSearchChanged: true,
            userId: userId && userId.length > 0 ? userId : null,
        });
    }

    private onDragEnd(featureLayoutConfigurationList: FeatureLayoutConfiguration[]) {
        
        this.setState({
            featureLayoutConfigurationList: featureLayoutConfigurationList,
            // isConfigChanged: true
        });

        // sessionStorage.setItem(ChangedPageEnum.DashboardLayoutConfiguration, 'true');
    }

    private onVisibilityChanged() {
        this.setState({
            // isConfigChanged: true
        });
        // sessionStorage.setItem(ChangedPageEnum.DashboardLayoutConfiguration, 'true');
    }

    private handleSave() {
        this.saveFeatureLayoutConfiguration();
    }

    private handleReset() {
        this.deleteFeatureLayoutConfiguration();
    }
    
    private saveFeatureLayoutConfiguration() {    
        if (this.state.featureLayoutConfigurationList !== undefined) {

            const featureLayoutConfigurationList = this.state.featureLayoutConfigurationList;
            const filteredFeatureLayoutConfigurationList = this.state.filteredFeatureLayoutConfigurationList;

            if (featureLayoutConfigurationList.length !== filteredFeatureLayoutConfigurationList.length) {
                filteredFeatureLayoutConfigurationList.forEach((filtered: FeatureLayoutConfiguration) => {
                    
                    const objIndex = featureLayoutConfigurationList.findIndex((obj => obj.featureGuid === filtered.featureGuid));

                    featureLayoutConfigurationList[objIndex].isHidden = filtered.isHidden;
                    
                });
            }

            // eslint-disable-next-line
            let featureLayoutConfigList = new Array();

            // To save the hidden features at the end of the list. 
            const featureList = featureLayoutConfigurationList.filter((item: FeatureLayoutConfiguration) => {
                return !item.isHidden;
            });

            const hiddenFeatureList = featureLayoutConfigurationList.filter((item: FeatureLayoutConfiguration) => {
                return item.isHidden;
            });

             // eslint-disable-next-line array-callback-return
            featureList.map((featureLayout, index) => {
                featureLayoutConfigList.push(
                    {
                        featureGuid: featureLayout.featureGuid,
                        sequence: index,
                        isHidden: featureLayout.isHidden
                    }
                );
            });

             // eslint-disable-next-line array-callback-return
            hiddenFeatureList.map((featureLayout, index) => {
                featureLayoutConfigList.push(
                    {
                        featureGuid: featureLayout.featureGuid,
                        sequence: featureList.length + index,
                        isHidden: featureLayout.isHidden
                    }
                );
            });

            client.mutate({
                mutation: UpdateFeatureLayoutConfigData,
                variables: {
                    featureLayoutConfigurationList: featureLayoutConfigList,
                    layoutId: this.state.layoutId,
                    isInternalRole: this.isShowFilterControls === false ? null : this.state.isInternalRole,
                    isAdministrator: this.isShowFilterControls === false ? null : this.state.isAdministrator,
                    roleId: this.isShowFilterControls === false ? null : this.state.roleId,
                    userEmail: this.isShowFilterControls === false ? null : this.state.userEmail,
                    isPersonal: this.isShowFilterControls === false ? true : this.state.isPersonal,
                    dashboardPanelId: this.state.dashboardPanel ? this.state.dashboardPanel.value === 'all' ? null : this.state.dashboardPanel.value : null,
                    userId: this.state.userId ? this.state.userId : null
                }
            }) // tslint:disable-next-line:no-any
            .then((results: { data: any; }) => { 
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info'); 
                    } else {
                        showNotification('Failed to Save Feature Layout Configuration', results.data.error, 'error'); 
                    }
                }            
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => { showNotification('Failed to Save Feature Layout Configuration', reason, 'error'); });
        }
    }

    private deleteFeatureLayoutConfiguration() {    
        if (this.state.featureLayoutConfigurationList !== undefined) {
            client.mutate({
                mutation: DeleteFeatureLayoutConfigData,
                variables: {
                    layoutId: this.state.layoutId,
                    isInternalRole: this.isShowFilterControls === false ? null : this.state.isInternalRole,
                    isAdministrator: this.isShowFilterControls === false ? null : this.state.isAdministrator,
                    roleId: this.isShowFilterControls === false ? null : this.state.roleId,
                    userEmail: this.isShowFilterControls === false ? null : this.state.userEmail,
                    isPersonal: this.isShowFilterControls === false ? true : this.state.isPersonal,
                    dashboardPanelId: this.state.dashboardPanel ? this.state.dashboardPanel.value === 'all' ? null : this.state.dashboardPanel.value : null
                }
            }) // tslint:disable-next-line:no-any
            .then((results: { data: any; }) => { 
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info'); 
                    } else {
                        showNotification('Failed to Reset Feature Layout Configuration', results.data.error, 'error'); 
                    }
                }            
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => { showNotification('Failed to Reset Feature Layout Configuration', reason, 'error'); });
        }
    }

    private subscribeToFeatureLayoutConfig() {
        const vm = this;      
        this.featureLayoutConfigurationSubscription = 
            client.subscribe({
                query: featureLayoutConfigSubscription
            }).subscribe({
                // tslint:disable-next-line:no-any
                next(results: any) {
                    if (results.data.featureLayoutConfigurationNotification.status) {
                        showNotification('Success', results.data.featureLayoutConfigurationNotification.message, 'info');
                        // Fetch again
                        vm.setState({
                            isSearchAgain: true,
                            isHideAllOperation: false
                        });
                    } else {
                        showNotification('Failed', results.data.featureLayoutConfigurationNotification.message, 'error');
                    }
                },            
                // tslint:disable-next-line:no-any
                error(err: any) {
                    // tslint:disable-next-line:no-console
                    console.error(err);
                    showNotification(null, err, 'error');
                }
            });
    }

    private fetchCurrentUser() {
        fetchCurrentUserData(
            false,
            (data: CurrentUserData) => this.onRetreivedCurrentUser(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    }

    private onRetreivedCurrentUser(data: CurrentUserData) {
        this.setState({

            isCurrentUserLoading: false,
            isInternalUser: data.appUser.isInternal,
            isInternalAdmin: data.appUser.isInternalAdmin
        });
    }
}