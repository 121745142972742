/* eslint-disable react-hooks/exhaustive-deps */
import { Button, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { FormApi } from 'final-form';
import gql from 'graphql-tag';
import { showNotification } from '../../App';
import React, { useEffect, useState } from 'react';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import NumberFormat from 'react-number-format';
import { client } from '../..';
import { RvLoader } from '../../components/Loader';
import { FetchPolicy } from 'apollo-client';
import { firmSubscription } from '../../lookupConfiguration/tabs/LookupNameTagTypeMaintenance';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        },
        radioGroup: {
            flexDirection: 'row'
        }
    })
);

interface FormState {
    completionDate: string | null;
    instructionDate: string | null;
    internalImportanceLevel?: number | null;
    externalImportanceLevel?: number | null;
    clientNoteActivityCategory?: number | null;
    clientNoteActivityType?: number | null;
    fileNoteActivityCategory?: number | null;
    fileNoteActivityType?: number | null;
    applicationNumber?: string | null;
    grantNumber?: string | null;
    publicationNumber?: string | null;
}

interface InprotechTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

export default function InprotechTab() {

    const classes = useStyles();

    const [state, setState] = useState<InprotechTabState>({
        isLoading: false,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState, setFormState] = useState<FormState>();

    useEffect(() => {
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {
        fetchInProtectionSettings(
            true,
            (data: SettingsData) => onInProtechRetrievedData(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    useEffect(() => {
        const subscribeFirmObj = inProtechSettingsNotification();

        return () => {
            subscribeFirmObj.unsubscribe();
        };
        // tslint:disable-next-line: align
    }, []);

    const inProtechSettingsNotification = () => {
        return (
            // let subscription =
            client
                .subscribe({
                    fetchPolicy: 'no-cache',
                    query: firmSubscription,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results: any) {
                        if (results.data.lookupNotification.status) {
                            const { lookupNotification } = results.data;
                            showNotification('Success', lookupNotification.message, 'info');
                        } else {
                            showNotification('Failed', results.data.lookupNotification.message, 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };

    const onInProtechRetrievedData = (data: SettingsData) => {
        if (data && data.settings && data.settings.systems && data.settings.systems.inprotechSettings && data.settings.systems.inprotechSettings.config) {
            const config = data.settings.systems.inprotechSettings.config;
            setFormState((prevFormState) => {
                return {
                    ...prevFormState,
                    completionDate: config.completionDate,
                    instructionDate: config.instructionDate,
                    internalImportanceLevel: config.externalImportanceLevel ?? 5,
                    externalImportanceLevel: config.internalImportanceLevel ?? 10,
                    clientNoteActivityCategory: config.clientNoteActivityCategory,
                    clientNoteActivityType: config.clientNoteActivityType,
                    fileNoteActivityCategory: config.fileNoteActivityCategory,
                    fileNoteActivityType: config.fileNoteActivityType,
                    applicationNumber: config.applicationNumber,
                    grantNumber: config.grantNumber,
                    publicationNumber: config.publicationNumber
                };
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {
        // console.log('values', values);
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true,
            };
        });

        showNotification('Success', 'Successfully submitted.', 'info');

        client.mutate({
            mutation: INPROTECH_MUTATION,
            variables: {
                registrationDate: values.completionDate,
                filingDate: values.instructionDate,
                internalImportanceLevel: values.internalImportanceLevel,
                externalImportanceLevel: values.externalImportanceLevel,
                clientNoteActivityCategory: values.clientNoteActivityCategory,
                clientNoteActivityType: values.clientNoteActivityType,
                fileNoteActivityCategory: values.fileNoteActivityCategory,
                fileNoteActivityType: values.fileNoteActivityType,
                grantNumber: values.grantNumber,
                applicationNumber: values.applicationNumber,
                publicationNumber: values.publicationNumber
            }
        // tslint:disable-next-line: no-any
        }).then((result: {data: any}) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    onSubmitLoading: false,
                };
            });
        // tslint:disable-next-line: no-any
        }).catch((reason: any) => {
            showNotification('Failed to upadte InProtech Settings.', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : formState && (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{
                        submitting: true,
                        pristine: true
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values
                    }) => (
                            <form onSubmit={handleSubmit}>
                                <FormSpy 
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="completionDate"
                                            // tslint:disable-next-line: max-line-length
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Registration Date"
                                                    name="completionDate"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Completion Date Required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="instructionDate"
                                            // tslint:disable-next-line: max-line-length
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Filing Date"
                                                    name="instructionDate"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Instruction Date Required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="internalImportanceLevel"
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Internal Importance Level (1-10)"
                                                    name="internalImportanceLevel"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Value should be between 0 and 10'
                                                            : ''
                                                    }
                                                    InputProps={{
                                                        // tslint:disable-next-line: no-any
                                                        inputComponent: CustomNumberFormat as any,
                                                        inputProps: {
                                                            minValue: 0,
                                                            maxValue: 10
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="externalImportanceLevel"
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="External Importance Level (1-10)"
                                                    name="externalImportanceLevel"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Value should be between 0 and 10'
                                                            : ''
                                                    }
                                                    InputProps={{
                                                        // tslint:disable-next-line: no-any
                                                        inputComponent: CustomNumberFormat as any,
                                                        inputProps: {
                                                            minValue: 0,
                                                            maxValue: 10
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="clientNoteActivityCategory"
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Client Note Activity Category"
                                                    name="clientNoteActivityCategory"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'External Importance Level is required'
                                                            : ''
                                                    }
                                                    InputProps={{
                                                        // tslint:disable-next-line: no-any
                                                        inputComponent: CustomNumberFormat as any
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="clientNoteActivityType"
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Client Note Activity Type"
                                                    name="clientNoteActivityType"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'External Importance Level is required'
                                                            : ''
                                                    }
                                                    InputProps={{
                                                        // tslint:disable-next-line: no-any
                                                        inputComponent: CustomNumberFormat as any
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="fileNoteActivityCategory"
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="File Note Activity Category"
                                                    name="fileNoteActivityCategory"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'External Importance Level is required'
                                                            : ''
                                                    }
                                                    InputProps={{
                                                        // tslint:disable-next-line: no-any
                                                        inputComponent: CustomNumberFormat as any
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="fileNoteActivityType"
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="File Note Activity Type"
                                                    name="fileNoteActivityType"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'External Importance Level is required'
                                                            : ''
                                                    }
                                                    InputProps={{
                                                        // tslint:disable-next-line: no-any
                                                        inputComponent: CustomNumberFormat as any
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="applicationNumber"
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Application Number"
                                                    name="applicationNumber"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="grantNumber"
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Grant Number"
                                                    name="grantNumber"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="publicationNumber"
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Publication Number"
                                                    name="publicationNumber"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || pristine || state.onSubmitLoading}
                                            >
                                                Save
                                            </Button>

                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="button"
                                                disabled={submitting || pristine}
                                                onClick={onReset(form)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                            </Grid>
                            </form>
                        )}
                />
            )}
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

const fetchInProtectionSettings = (
    refreshData: boolean,
    onSuccess: (data: SettingsData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
    ) => {
    
    var fetchPolicy: FetchPolicy =  refreshData ? 'network-only' : 'cache-first';

    client.query({
        query: INPROTECH_QUERY,
        variables: {},
        fetchPolicy: fetchPolicy
    })
    .then((results: {data: SettingsData}) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any) => { onError(reason); });
};

interface CustomNumberFormatProps {
    inputRef: (instance: NumberFormat | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    minValue?: number;
    maxValue?: number;
}

function CustomNumberFormat(props: CustomNumberFormatProps) {
    const { inputRef, onChange, minValue, maxValue, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString={true}
        isAllowed={(values) => {
            const { formattedValue, floatValue } = values;
            if (floatValue == null) {
                return formattedValue === '';
            } else {
                return (floatValue >= (minValue ?? Math.max()) && floatValue <= (maxValue ?? Math.min()));
            }
        }}
      />
    );
  }

interface InProtechSettings {
    id: number;
    config: {
        completionDate: string;
        instructionDate: string;
        externalImportanceLevel?: number;
        internalImportanceLevel?: number;
        clientNoteActivityCategory?: number;
        clientNoteActivityType?: number;
        fileNoteActivityCategory?: number;
        fileNoteActivityType?: number;
        grantNumber?: string;
        applicationNumber?: string;
        publicationNumber?: string;
    };
}

interface InProtech {
    id: number;
    inprotechSettings: InProtechSettings;
}

interface SystemSettings {
    id: number;
    systems: InProtech;
}

interface SettingsData {
    settings: SystemSettings;
}

const INPROTECH_MUTATION = gql`
    mutation InProtechSettings (
        $filingDate: String,
        $registrationDate: String,
        $internalImportanceLevel: Int,
        $externalImportanceLevel: Int,
        $clientNoteActivityCategory: Int,
        $clientNoteActivityType: Int,
        $fileNoteActivityCategory: Int,
        $fileNoteActivityType: Int,
        $grantNumber: String,
        $applicationNumber: String,
        $publicationNumber: String
    ) {
        settings {
            inprotechSettings {
                update (
                    filingDate: $filingDate,
                    registrationDate: $registrationDate,
                    internalImportanceLevel: $internalImportanceLevel,
                    externalImportanceLevel: $externalImportanceLevel,
                    clientNoteActivityCategory: $clientNoteActivityCategory,
                    clientNoteActivityType: $clientNoteActivityType,
                    fileNoteActivityCategory: $fileNoteActivityCategory,
                    fileNoteActivityType: $fileNoteActivityType,
                    grantNumber: $grantNumber,
                    applicationNumber: $applicationNumber,
                    publicationNumber: $publicationNumber
                ) {
                    error
                    status
                }
            }
        }
    }
`;

const INPROTECH_QUERY = gql`
query InproTechSettings {
    settings {
        id
        systems {
            id
            inprotechSettings  {
                id
                config {
                    id
                    completionDate
                    instructionDate
                    externalImportanceLevel
                    internalImportanceLevel
                    clientNoteActivityCategory
                    clientNoteActivityType
                    fileNoteActivityCategory
                    fileNoteActivityType
                    grantNumber
                    applicationNumber
                  	publicationNumber
                }
            }
        }
    }
}
`;