/* eslint-disable react-hooks/exhaustive-deps */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    createStyles,
    FormControlLabel,
    FormGroup,
    Grid,
    makeStyles,
    Theme,
    Typography,
} from '@material-ui/core';
import { FormApi } from 'final-form';
import React, { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { RvLoader } from '../../components/Loader';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ReminderSelector } from '../../components/ReminderSelector';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { mainTheme } from '../../Theme';
import MomentUtils from '@date-io/moment';
import {
    retrieveTaskDefaultConfigData,
    TaskAndPhoneMessageDefaultConfigMutationParams,
    TaskDefaultsData,
    UPDATE_TASK_DEFAULT_CONFIG_MUTATION,
} from '../FirmSettingConfigurationRepository';
import { showNotification } from '../../App';
import { client } from '../..';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 600,
            color: theme.palette.primary.main,
        },
        accordionDetails: {
            flexFlow: 'column',
        },
        radioGroup: {
            flexFlow: 'row wrap',
        },
        formControl: {
            margin: '10px 0',
        },
        optionContainer: {},
        optionList: {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 0',
            margin: '0 -5px',
            '& > span': {
                margin: '0 5px',
            },
            '& > div': {
                margin: '0 5px',
            },
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            },
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        reminderTextField: {
            width: '30%',
        },
        radioIcon: {
            color: mainTheme.BrandColors.TemplatePrimary,
        },
    }),
);

interface FormState {
    reminder?: IAutoCompleteItem;
    overdueReminder?: IAutoCompleteItem;
    isSendReminderOnWeekend: boolean;
    isSendReminderOnWeekendForOverDueReminders: boolean;
}
interface TaskDefaultConfigurationTabState {
    isFormChanged: boolean;
    onSubmitLoading: boolean;
    isLoading: boolean;
}

export const TaskDefaultConfigurationTab: React.FC = () => {
    const classes = useStyles();

    const [state, setState] = useState<TaskDefaultConfigurationTabState>({
        isFormChanged: false,
        onSubmitLoading: false,
        isLoading: true,
    });

    const [formState, setFormState] = useState<FormState>({
        reminder: { value: 'None', label: 'None' },
        overdueReminder: { value: 'None', label: 'None' },
        isSendReminderOnWeekend: false,
        isSendReminderOnWeekendForOverDueReminders: false,
    });

    useEffect(() => {
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {
        retrieveTaskDefaultConfigData(
            true,
            // tslint:disable-next-line: no-console
            (data: TaskDefaultsData) => onTaskDefaultConfigRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onTaskDefaultConfigRetrieved = (data: TaskDefaultsData) => {
        const config = data.settings.systems.taskDefaults.config;

        setFormState((prevFormState) => {

            return {
                ...prevFormState,
                reminder: config.reminder ? { value: config.reminder, label: config.reminder } : formState.reminder,
                overdueReminder: config.overdueReminder ? { value: config.overdueReminder, label: config.overdueReminder } : formState.overdueReminder,
                isSendReminderOnWeekend: config.isSendReminderOnWeekend,
                isSendReminderOnWeekendForOverDueReminders: config.isSendReminderOnWeekendForOverDueReminders
            };
        });
        setState((prevState) => {

            return {
                ...prevState,
                isLoading: false
            };
        });

    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true,
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: FormState) => {
        // tslint:disable-next-line: no-console
        // console.log('submit', values);
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true,
            };
        });

        client
            // tslint:disable-next-line: no-any
            .mutate<any, TaskAndPhoneMessageDefaultConfigMutationParams>({
                mutation: UPDATE_TASK_DEFAULT_CONFIG_MUTATION,
                variables: {
                    reminder: values.reminder ? values.reminder.value : 'None',
                    overdueReminder: values.overdueReminder ? values.overdueReminder.value : 'None',
                    isSendReminderOnWeekend: values.isSendReminderOnWeekend,
                    isSendReminderOnWeekendForOverDueReminders: values.isSendReminderOnWeekendForOverDueReminders,
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    showNotification(null, 'Successfully submitted', 'info');
                    setState((_prevState) => {
                        return {
                            ..._prevState,
                            onSubmitLoading: false,
                        };
                    });

                    // reset the field and set to the current changes after saved.
                    form.setConfig('keepDirtyOnReinitialize', false);
                    form.batch(() => {
                        form.reset(values);
                    });
                    form.setConfig('keepDirtyOnReinitialize', true);
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to update enquiry default', reason, 'error');
                setState((_prevState) => {
                    return {
                        ..._prevState,
                        onSubmitLoading: false,
                    };
                });
            });
    };

    if (state.isLoading) {
        return (
            <div className={classes.root}>
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            </div>
        );
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={classes.root}>
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{ submitting: true, pristine: true }}
                    // validate={(values: FormState) => {
                    //     let errors = {
                    //         addDays: '',
                    //         addHours: ''
                    //     };

                    //     if (errors.addDays === '' && errors.addHours === '') {
                    //         return undefined;
                    //     }

                    //     return errors;
                    // }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <FormSpy
                                subscription={{ pristine: true }}
                                // tslint:disable-next-line: no-shadowed-variable
                                onChange={(props) => {
                                    onFormValueChanged(props);
                                }}
                            />

                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={12} md={12}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                            <Typography className={classes.heading}>Task Reminder Default</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                            <Grid container={true} spacing={3}>
                                                <Grid md={12} item={true}>
                                                    <FormGroup className={classes.radioGroup}>
                                                        <Field
                                                            name="isSendReminderOnWeekend"
                                                            subscription={{ touched: true, error: true, value: true }}
                                                        >
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            checked={input.value}
                                                                            onChange={input.onChange}
                                                                            checkedIcon={<CheckBoxIcon color="primary" />}
                                                                        />
                                                                    }
                                                                    label="Send reminder on weekend"
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid md={12} item={true}>
                                                    <div className={classes.optionContainer}>
                                                        <div className={classes.optionList}>
                                                            <span className="text">Send reminder</span>
                                                            <Field
                                                                name="reminder"
                                                                allowNull={true}
                                                                // validate={form.getFieldState('reminder')?.value !== null ? required : undefined}
                                                            >
                                                                {({ input, meta }) => (
                                                                    <ReminderSelector
                                                                        {...input}
                                                                        // label="Reminder"
                                                                        className={classes.reminderTextField}
                                                                        onSelection={(selection: IAutoCompleteItem) => {
                                                                            input.onChange(selection);
                                                                        }}
                                                                        error={meta.error && meta.touched}
                                                                        helperText={meta.error && meta.touched ? meta.error : ''}
                                                                        size="small"
                                                                    />
                                                                )}
                                                            </Field>
                                                            <span className="text">before task due</span>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item={true} xs={12} md={12}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                            <Typography className={classes.heading}>Task Overdue Reminder Default</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                            <Grid container={true} spacing={3}>
                                                <Grid md={12} item={true}>
                                                    <FormGroup className={classes.radioGroup}>
                                                        <Field
                                                            name="isSendReminderOnWeekendForOverDueReminders"
                                                            subscription={{ touched: true, error: true, value: true }}
                                                        >
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            checked={input.value}
                                                                            onChange={input.onChange}
                                                                            checkedIcon={<CheckBoxIcon color="primary" />}
                                                                        />
                                                                    }
                                                                    label="Send reminder on weekend"
                                                                />
                                                            )}
                                                        </Field>
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item={true} md={12}>
                                                    <div className={classes.optionContainer}>
                                                        <div className={classes.optionList}>
                                                            <span className="text">Send overdue reminder</span>
                                                            <Field
                                                                name="overdueReminder"
                                                                allowNull={true}
                                                                // validate={form.getFieldState('overdueReminder')?.value !== null ? required : undefined}
                                                            >
                                                                {({ input, meta }) => (
                                                                    <ReminderSelector
                                                                        {...input}
                                                                        // label="Overdue Reminder"
                                                                        className={classes.reminderTextField}
                                                                        onSelection={(selection: IAutoCompleteItem) => {
                                                                            input.onChange(selection);
                                                                        }}
                                                                        error={meta.error && meta.touched}
                                                                        helperText={meta.error && meta.touched ? meta.error : ''}
                                                                        size="small"
                                                                    />
                                                                )}
                                                            </Field>
                                                            <span className="text">after task due</span>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item={true} xs={12} md={12} justify="flex-end">
                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            type="submit"
                                            disabled={submitting || pristine || state.onSubmitLoading}
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            type="button"
                                            disabled={submitting || pristine}
                                            onClick={onReset(form)}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                </Grid>
                                {/* {printJson(values)} */}
                            </Grid>
                        </form>
                    )}
                />
            </div>
        </MuiPickersUtilsProvider>
    );
};

export default TaskDefaultConfigurationTab;

// tslint:disable-next-line: no-any
// export function printJson(values: any) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }
