/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, createStyles, FormControl, FormControlLabel, FormLabel, Grid, makeStyles, Radio, RadioGroup, TextField, Theme } from '@material-ui/core';
import { FormApi } from 'final-form';
import React, { useEffect, useState } from 'react';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { RvLoader } from '../../components/Loader';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { mainTheme } from '../../Theme';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { showNotification } from '../../App';
import { TimesheetActivitiesSelector } from '../selectors/TimesheetActivitiesSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        },
        radioGroup: {
            flexDirection: 'row'
        },
        radioIcon: {
            color: mainTheme.BrandColors.TemplatePrimary
        },
    })
);

interface FormState {
    defaultDurationMinutes: number | null;
    defaultDurationUnits: number | null;
    phoneCallActivity: IAutoCompleteItem | null;
    emailActivity: IAutoCompleteItem | null;
    submissionType: string | null;
    // mobileOpeningPage: string | null;
    timeSelectionMinuteInterval: number | null;
    // isShowFavouriteActivitiesFirst: boolean;
    // isShowFavouriteMattersFirst: boolean;
    useSmartSettings: boolean;
    suggestCustomButtons: boolean;
    timeCaptureMode: string | null;
}
interface TimeRecordingDefaultsTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

export default function TimeRecordingDefaultsTab() {

    const classes = useStyles();

    const [state, setState] = useState<TimeRecordingDefaultsTabState>({
        isLoading: true,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState, setFormState] = useState<FormState>({
        defaultDurationMinutes: null,
        defaultDurationUnits: null,
        phoneCallActivity: null,
        emailActivity: null,
        submissionType: null,
        // mobileOpeningPage: null,
        timeSelectionMinuteInterval: null,
        // isShowFavouriteActivitiesFirst: false,
        // isShowFavouriteMattersFirst: false,
        useSmartSettings: false,
        suggestCustomButtons: false,
        timeCaptureMode: null
    });

    const timeRecordingDefaults = useQuery<TimeRecordingDefaultsData>(TIME_RECORDING_DEFAULTS);

    // tslint:disable-next-line: no-any
    const [updateTimeRecordingDefaults] = useMutation<any, TimeRecordingDefaultsMutationParams>(TIME_RECORD_DEFAULTS_MUTATION);

    useEffect(() => {
    
        // tslint:disable-next-line: max-line-length
        if (!timeRecordingDefaults.loading && timeRecordingDefaults.data && timeRecordingDefaults.data.settings && timeRecordingDefaults.data.settings.systems.timeRecordingDefaults && timeRecordingDefaults.data.settings.systems.timeRecordingDefaults.config) {

            var { 
                defaultDurationMinutes,
                defaultDurationUnits,
                emailActivity,
                phoneCallActivity,
                submissionType,
                suggestCustomButtons,
                tenantTimeCaptureMode,
                timeCaptureMode,
                timeSelectionMinuteInterval,
                useSmartSettings
            } = timeRecordingDefaults.data.settings.systems.timeRecordingDefaults.config;
            
            setFormState((prevFormState) => {
                return {
                    ...prevFormState,
                    defaultDurationMinutes,
                    defaultDurationUnits,
                    emailActivity : emailActivity ? { label: emailActivity, value: emailActivity} : null,
                    phoneCallActivity: phoneCallActivity ? { label: phoneCallActivity, value: phoneCallActivity} : null,
                    submissionType,
                    suggestCustomButtons,
                    tenantTimeCaptureMode,
                    timeCaptureMode,
                    timeSelectionMinuteInterval,
                    useSmartSettings
                };
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false
                };
            });
        }
    },        [timeRecordingDefaults.data, timeRecordingDefaults.loading]);

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {
        
        updateTimeRecordingDefaults({
            variables: {
                defaultDurationMinutes: values.defaultDurationMinutes,
                defaultDurationUnits: values.defaultDurationUnits,
                emailActivity: values.emailActivity ? values.emailActivity.value : null,
                phoneCallActivity: values.phoneCallActivity ? values.phoneCallActivity.value : null,
                submissionType: values.submissionType,
                suggestCustomButtons: values.suggestCustomButtons,
                tenantTimeCaptureMode: values.tenantTimeCaptureMode,
                timeCaptureMode: values.timeCaptureMode,
                timeSelectionMinuteInterval: values.timeSelectionMinuteInterval,
                useSmartSettings: values.useSmartSettings
            }
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info');     
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update time recording defaults.', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    // tslint:disable-next-line: no-any
    const RadioComp = (compProps: any) => {

        const { input } = compProps;        
        return (
                <Radio
                    {...input}
                    checkedIcon={<RadioButtonCheckedIcon className={classes.radioIcon} />}
                />
        );
    };

    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{
                        submitting: true,
                        pristine: true
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values,
                    }) => (
                            <form onSubmit={handleSubmit}>
                                <FormSpy 
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Submission Type</FormLabel>
                                            <RadioGroup
                                                className={classes.radioGroup}
                                                
                                            >
                                                <FormControlLabel 
                                                    control={
                                                        <Field 
                                                            subscription={{touched: true, error: true, value: true}}
                                                            name="submissionType" 
                                                            component={RadioComp}
                                                            type="radio"
                                                        />
                                                    }
                                                    value="Automatic"
                                                    label="Submit Only" 
                                                />
                                                <FormControlLabel 
                                                    control={
                                                        <Field 
                                                            subscription={{touched: true, error: true, value: true}}
                                                            name="submissionType" 
                                                            component={RadioComp}
                                                            type="radio"
                                                        />
                                                    }
                                                    value="Draft"
                                                    label="Allow Draft" 
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        {/* <FormControl component="fieldset">
                                            <FormLabel component="legend">Mobile Opening Page</FormLabel>
                                            <RadioGroup
                                                // defaultValue={state.mobileOpeningPage}
                                                className={classes.radioGroup}
                                            >
                                                <FormControlLabel 
                                                    control={
                                                        <Field 
                                                            subscription={{touched: true, error: true, value: true}}
                                                            name="mobileOpeningPage" 
                                                            component={RadioComp}
                                                            type="radio"
                                                        />
                                                    }
                                                    value="Home"
                                                    label="Home" 
                                                />
                                                <FormControlLabel 
                                                    control={
                                                        <Field 
                                                            subscription={{touched: true, error: true, value: true}}
                                                            name="mobileOpeningPage" 
                                                            component={RadioComp}
                                                            type="radio"
                                                        />
                                                    }
                                                    value="Timesheet"
                                                    label="Timesheet" 
                                                />
                                            </RadioGroup>
                                        </FormControl> */}
                                    </Grid>
                                    <Grid item={true} xs={12} md={4} lg={4} sm={12}>
                                        <Field
                                            name="defaultDurationUnits"
                                            subscription={{touched: true, error: true, value: true}}
                                            // tslint:disable-next-line: radix
                                            parse={value => parseInt(value)}
                                            // validate={form.getFieldState('durationUnits')?.value !== null ? required : undefined}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Duration/Units" 
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? meta.error
                                                            : ''
                                                    }
                                                    type="number"
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={4} lg={4} sm={12}>
                                        <Field
                                            name="defaultDurationMinutes"
                                            subscription={{touched: true, error: true, value: true}}
                                            // tslint:disable-next-line: radix
                                            parse={value => parseInt(value)}
                                            // validate={form.getFieldState('durationUnits')?.value !== null ? required : undefined}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Duration/Minutes" 
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? meta.error
                                                            : ''
                                                    }
                                                    type="number"
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={4} lg={4} sm={12}>
                                        <Field
                                            name="timeSelectionMinuteInterval"
                                            subscription={{touched: true, error: true, value: true}}
                                            // tslint:disable-next-line: radix
                                            parse={value => parseInt(value)}
                                            // validate={form.getFieldState('durationUnits')?.value !== null ? required : undefined}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Time Selection Minute Interval" 
                                                    name="timeSelectionMinuteInterval"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? meta.error
                                                            : ''
                                                    }
                                                    type="number"
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="phoneCallActivity"
                                            subscription={{touched: true, error: true, value: true}}
                                            // validate={form.getFieldState('phoneCallActivity')?.value !== null ? required : undefined}
                                        >
                                            {({ input, meta }) => (
                                                <TimesheetActivitiesSelector 
                                                    {...input}
                                                    label="Phone Call Activity" 
                                                    onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                        input.onChange(selection)
                                                    }
                                                    className={classes.textField}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="emailActivity"
                                            subscription={{touched: true, error: true, value: true}}
                                            // validate={form.getFieldState('emailActivity')?.value !== null ? required : undefined}
                                        >
                                            {({ input, meta }) => (
                                                <TimesheetActivitiesSelector 
                                                    {...input}
                                                    label="Email Activity" 
                                                    onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                        input.onChange(selection)
                                                    }
                                                    className={classes.textField}
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Grid container={true} spacing={3}>
                                            {/* <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                                <Field
                                                    name="isShowFavouriteActivitiesFirst"
                                                    subscription={{touched: true, error: true, value: true}}
                                                >                                            
                                                    {({ input, meta }) => (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    {...input}
                                                                    checked={input.value}
                                                                    onChange={input.onChange}
                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                />
                                                            }
                                                            disabled={true}
                                                            label="Show Favourite Activities First" 
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                                <Field
                                                    name="isShowFavouriteMattersFirst"
                                                    subscription={{touched: true, error: true, value: true}}
                                                >                                            
                                                    {({ input, meta }) => (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    {...input}
                                                                    checked={input.value}
                                                                    onChange={input.onChange}
                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                />
                                                            }
                                                            disabled={true}
                                                            label="Show Favourite Matters First"
                                                        />
                                                    )}
                                                </Field>
                                            </Grid> */}
                                            <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                                <Field
                                                    name="useSmartSettings"
                                                    subscription={{touched: true, error: true, value: true}}
                                                >                                            
                                                    {({ input, meta }) => (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    {...input}
                                                                    checked={input.value}
                                                                    onChange={input.onChange}
                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                />
                                                            }
                                                            label="Use Smart Settings"
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                            <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                                <Field
                                                    name="suggestCustomButtons"
                                                    subscription={{touched: true, error: true, value: true}}
                                                >                                            
                                                    {({ input, meta }) => (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    {...input}
                                                                    checked={input.value}
                                                                    onChange={input.onChange}
                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                />
                                                            }
                                                            label="Suggest Custom Buttons"
                                                        />
                                                    )}
                                                </Field>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Time Capture Mode</FormLabel>
                                            <RadioGroup
                                                className={classes.radioGroup}
                                            >
                                                <FormControlLabel 
                                                    control={
                                                        <Field 
                                                            subscription={{touched: true, error: true, value: true}}
                                                            name="timeCaptureMode" 
                                                            component={RadioComp}
                                                            type="radio"
                                                        />
                                                    }
                                                    value="Duration"
                                                    label="Duration" 
                                                />
                                                <FormControlLabel 
                                                    control={
                                                        <Field 
                                                            subscription={{touched: true, error: true, value: true}}
                                                            name="timeCaptureMode" 
                                                            component={RadioComp}
                                                            type="radio"
                                                        />
                                                    }
                                                    value="Units"
                                                    label="Units" 
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Tenant Time Capture Mode</FormLabel>
                                            <RadioGroup
                                                className={classes.radioGroup}
                                            >
                                                <FormControlLabel 
                                                    control={
                                                        <Field 
                                                            subscription={{touched: true, error: true, value: true}}
                                                            name="tenantTimeCaptureMode" 
                                                            component={RadioComp}
                                                            type="radio"
                                                        />
                                                    }
                                                    value="Both"
                                                    label="Both" 
                                                />
                                                <FormControlLabel 
                                                    control={
                                                        <Field 
                                                            subscription={{touched: true, error: true, value: true}}
                                                            name="tenantTimeCaptureMode" 
                                                            component={RadioComp}
                                                            type="radio"
                                                        />
                                                    }
                                                    value="Duration"
                                                    label="Duration" 
                                                />
                                                <FormControlLabel 
                                                    control={
                                                        <Field 
                                                            subscription={{touched: true, error: true, value: true}}
                                                            name="tenantTimeCaptureMode" 
                                                            component={RadioComp}
                                                            type="radio"
                                                        />
                                                    }
                                                    value="Units"
                                                    label="Units" 
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || pristine || state.onSubmitLoading}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="button"
                                                disabled={submitting || pristine}
                                                onClick={onReset(form)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                                    {/* {printJson(values)} */}
                            </Grid>
                            </form>
                        )}
                />
            )}
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

export interface TimeRecordingDefaultsData {
    settings: Settings;
}

export interface Settings {
    id:      number;
    systems: Systems;
}

export interface Systems {
    timeRecordingDefaults: TimeRecordingDefaults;
}

export interface TimeRecordingDefaults {
    id:     number;
    config: Config;
}

export interface Config {
    id:                          number;
    defaultDurationMinutes:      number;
    defaultDurationUnits:        number;
    emailActivity:               null;
    phoneCallActivity:           null;
    submissionType:              string;
    suggestCustomButtons:        boolean;
    tenantTimeCaptureMode:       string;
    timeCaptureMode:             string;
    timeSelectionMinuteInterval: number;
    useSmartSettings:            boolean;
}

const TIME_RECORDING_DEFAULTS = gql`
    query timeRecordingDefaults {
        settings {
            id
            systems {
                timeRecordingDefaults {
                    id
                    config {
                        id
                        defaultDurationMinutes
                        defaultDurationUnits
                        emailActivity
                        phoneCallActivity
                        submissionType
                        suggestCustomButtons
                        tenantTimeCaptureMode
                        timeCaptureMode
                        timeSelectionMinuteInterval
                        useSmartSettings

                    }
                }
            }
        }
    }
`;

interface TimeRecordingDefaultsMutationParams {
   tenantTimeCaptureMode: string | null;
   submissionType: string | null;
   defaultDurationUnits: number | null;
   defaultDurationMinutes: number | null;
   phoneCallActivity: string | null;
   emailActivity: string | null;
   suggestCustomButtons: Boolean;
   useSmartSettings: Boolean;
   timeCaptureMode: string | null;
   timeSelectionMinuteInterval: number | null;
}

const TIME_RECORD_DEFAULTS_MUTATION = gql`
    mutation TimeRecordingDefaultsMutation(
        $tenantTimeCaptureMode: String,
        $submissionType: String,
        $defaultDurationUnits: Int,
        $defaultDurationMinutes: Int,
        $phoneCallActivity: String,
        $emailActivity: String,
        $suggestCustomButtons: Boolean,
        $useSmartSettings: Boolean,
        $timeCaptureMode: String,
        $timeSelectionMinuteInterval: Int
    ){
    settings {
            timeRecordingDefaults {
                update (
                    tenantTimeCaptureMode: $tenantTimeCaptureMode,
                    submissionType: $submissionType,
                    defaultDurationUnits: $defaultDurationUnits,
                    defaultDurationMinutes: $defaultDurationMinutes,
                    phoneCallActivity: $phoneCallActivity,
                    emailActivity: $emailActivity,
                    suggestCustomButtons: $suggestCustomButtons,
                    useSmartSettings: $useSmartSettings,
                    timeCaptureMode: $timeCaptureMode,
                    timeSelectionMinuteInterval: $timeSelectionMinuteInterval
                ) {
                    status
                    error
                }
            }
        }
    }
`;