/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import MaterialTable, { EditComponentProps, MTableToolbar } from 'material-table';
import { 
    Avatar, 
    ListItem, 
    ListItemAvatar, 
    ListItemText, 
    Badge,
    Typography, 
    Chip
} from '@material-ui/core';
import { Colors, hashString } from '../../tasksSummary/board/BoardCardDraggable';
import { SemanticCOLORS } from 'semantic-ui-react';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { browserHistory } from '../../components/AppContent';
import { showNotification } from '../../App';
import { Icons } from 'material-table';
import { forwardRef } from 'react';
 
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { client } from '../..';
import gql from 'graphql-tag';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { useStyles } from '../../lookupConfiguration/common/tabsStyle';
import { FirmData, Office } from '../../lookupConfiguration/models/Firm';
import { retrieveOfficeData } from '../../lookupConfiguration/selectors/FirmOfficeSelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { TimezoneSelector } from '../../lookupConfiguration/selectors/TimezoneSelector';

interface OfficeItem extends Office {
    timezoneSelector: IAutoCompleteItem | null;
}
interface LookupOfficesMaintenanceState {
    // tslint:disable-next-line: no-any
    selectedRow: any | null;
    data: OfficeItem[];
    isLoading: boolean;
}

// tslint:disable-next-line: no-any
let onDeleteItems: any = [];

export default function LookupOfficesMaintenance() {

    const classes = useStyles();

    const [state, setState] = useState<LookupOfficesMaintenanceState>({
        selectedRow: null,
        data: [],
        isLoading: false,
    });

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    useEffect(() => {   
        fetchData();

        const subscribeFirmObj = subscribeFirm();

        return () => {
            subscribeFirmObj.unsubscribe();
        };
    // tslint:disable-next-line: align
    }, []);

    const tableIcons: Icons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}  className="check-button" />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref}  className="check-button" />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}  className="check-button" />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}  className="check-button" />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}  className="check-button" />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}  className="check-button" />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}  className="check-button" />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}  className="check-button" />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}  className="check-button" />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}  className="check-button" />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}  className="check-button" />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}  className="check-button" />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}  className="check-button" />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref}  className="check-button" />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}  className="check-button" />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}  className="check-button" />)
    };

    const subscribeFirm = () => {
        return (
            // let subscription =
            client
                .subscribe({
                    fetchPolicy: 'no-cache',
                    query: firmSubscription,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results: any) {
                        if (results.data.lookupNotification.status) {

                            const { lookupNotification } = results.data;
                            showNotification('Success', lookupNotification.message, 'info');

                            if (lookupNotification.message.includes('created successfully')) {
                                setState((prevState) => {

                                    const tdata = [...prevState.data];

                                    const temp = tdata[tdata.length - 1];

                                    tdata[tdata.length - 1] = {
                                        ...temp,
                                        guidID: lookupNotification.guidId
                                    };

                                    return {
                                        ...prevState,
                                        data: tdata
                                    };
                                });
                            }

                        } else {
                            showNotification('Failed', results.data.lookupNotification.message, 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true,
        });
        retrieveOfficeData(
            undefined,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveData = (data: FirmData) => {
        setState({
            ...state,
            data: data.firm.firmOffices.firmOffice.map((office: Office) => {
                return {
                    ...office,
                    timezoneSelector: office.timezone && office.timezoneDescription ? { label: office.timezoneDescription, value: office.timezone } : null
                };
            }),
            isLoading: false
        });
    };

    // tslint:disable-next-line: no-any
    const getSection = (data: any) => {

        const { description } = data;

        let bColor: SemanticCOLORS = Colors[(description === '' || description === null) ? 
        Colors[Colors.length] : (hashString(description) % Colors.length) - 1];
        let stringSplit = description
        .replace(/[^\w\s]/gi, '') // remove special character
        .split(' ')
        // tslint:disable-next-line: no-any
        .map((n: any) => n[0])
        .join('');

        let stringInitials = stringSplit.slice(0, 2);
        
        return (
            <div>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar
                            style={{ backgroundColor: bColor ? bColor : 'grey', color: '#fff' }}
                            className={classes.avatar}
                        >
                            {stringInitials}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={description} />
                </ListItem>
            </div>
        );
    };

    // tslint:disable-next-line: no-any
    const getMember = (data: any) => {

        let staffCount = 0;

        if (data) {
            staffCount = data.staffCount;
        }

        return (
            <div>
                <Badge color="primary" badgeContent={staffCount}>
                    <PeopleOutlineIcon />
                </Badge>
            </div>
        );
    };

    // tslint:disable-next-line: no-any
    const handleRowClick = (event?: React.MouseEvent, rowData?: any, toggleDetailPanel?: (panelIndex?: number) => void) => {

        // tslint:disable-next-line: no-console
        console.log('rowData', rowData);

        setState({
            ...state,
            selectedRow: rowData.tableData.id
        });

        browserHistory.push(`/firmStructureConfiguration/offices/${encodeURIComponent(rowData.description)};key=${rowData.guidID}`);
    };

    const updateRowData = (newData: OfficeItem, oldData: OfficeItem) => {

        setState((prevState) => {

            const tdata = [...prevState.data];
            
            // if (tdata.some((e: OfficeItem) => e.description.toLowerCase() === newData.description.toLowerCase())) {
            //     return { 
            //         ...prevState, 
            //     };
            // } 

            tdata[tdata.indexOf(oldData)] = newData;
            
            return { 
                ...prevState, 
                data: tdata
            };
            
        });
    };

    const updateData = (rowData: OfficeItem) => {
        if (rowData) {
            client
                .mutate({
                    mutation: updateFirmOffice,
                    variables: {
                        guidId: rowData.guidID,
                        description: rowData.description,
                        timezone: rowData.timezoneSelector ? rowData.timezoneSelector.value : null
                    },
                })
                // tslint:disable-next-line: no-any
                .then((results: { data: any }) => {
                    if (results.data) {
                        if (results.data.error !== undefined) {
                            showNotification( 'Failed to Update Office', results.data.error, 'error');
                        }
                    }
                })
                // tslint:disable-next-line:no-any
                .catch((reason: any) => {
                    showNotification('Failed to Update Office', reason, 'error');
                });
        }
    };

    const deleteRowData = (oldData: OfficeItem) => {

        setState((prevState) => {
            const tdata = [...prevState.data];
            tdata.splice(tdata.indexOf(oldData), 1);
            return { 
                ...prevState, 
                data: tdata 
            };
        });
        
    };

    const deleteData = (oldData: Office) => {
        client
            .mutate({
                mutation: deleteFirmOffice,
                variables: {
                    guidId: oldData.guidID,
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification( 'Failed to Delete Office', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Delete Office', reason, 'error');
            });
    };

    // tslint:disable-next-line: no-any
    const insertRowData = (newData: OfficeItem) => {

        setState((prevState) => {

            const tdata = prevState.data;

            if (tdata.some((e: OfficeItem) => e.description.toLowerCase() === newData.description.toLowerCase())) {
                return { 
                    ...prevState, 
                };
            } 
            tdata.push(newData);

            return {    
                ...prevState,
                data: tdata
            };

        });
    };

    // tslint:disable-next-line: no-any
    const createData = (newData: OfficeItem) => {
        if (newData) {
            client
                .mutate({
                    mutation: createFirmOffice,
                    variables: {
                        description: newData.description,
                        timezone: newData.timezoneSelector ? newData.timezoneSelector.value : null
                    },
                })
                // tslint:disable-next-line: no-any
                .then((results: { data: any }) => {
                    if (results.data) {
                        if (results.data.error !== undefined) {
                            showNotification( 'Failed to Create Office', results.data.error, 'error');
                        }
                    }
                })
                // tslint:disable-next-line:no-any
                .catch((reason: any) => {
                    showNotification('Failed to Create Office', reason, 'error');
                });
        }
    };

    // tslint:disable-next-line: no-any
    const onDeleteData = (data: any) => {

        setShowDeleteConfirm(true);

        onDeleteItems = data;

    };

    const onDeleteConfirm = (agree: boolean) => {
        if (agree) {
            // tslint:disable-next-line: no-any
            onDeleteItems.forEach((selected: any) => {

                // delete check data
                deleteData(selected);

                setState((prevState) => {
                    const tdata = [...prevState.data];
                    tdata.splice(tdata.indexOf(selected), 1);
                    return { 
                        ...prevState, 
                        data: tdata 
                    };
                });

            });
        }

        setShowDeleteConfirm(false);
    };

    const getTitle = (title: string, count: number) => {
        return (
            <div className={classes.tableTitle}>
                <Typography className={classes.tableHeading}>{title}</Typography>
                <Chip className={classes.chip} label={count} />
            </div>
        );
    };

    const timezoneRender = (data: OfficeItem) => {
        // console.log('timezoneRender', data);
        if (data.timezoneSelector) {
            return <div>{data.timezoneSelector.label}</div>;
        }
        return undefined;
    };

    // tslint:disable-next-line: no-any
    const timezoneSelectorComponent = (componentProps: EditComponentProps<any>) => {

        return (
            <TimezoneSelector 
                name="timezone"
                onSelection={(selection: IAutoCompleteItem, name: string) => {
                    componentProps.onChange(selection);
                }}
                className={classes.textField}
                error={componentProps.error}
                value={componentProps.value}
            />
        );
    };

    return (
        <div className={classes.root}>
            <DialogBox
                title="Offices"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to delete the selected row?`}
                show={showDeleteConfirm}
                isAgree={onDeleteConfirm}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
                className={classes.dialogBox}
            />
            <MaterialTable
                isLoading={state.isLoading}
                title={getTitle('Offices', state.data.length)}
                icons={tableIcons}
                onRowClick={handleRowClick}
                columns={[
                    // tslint:disable-next-line: max-line-length
                    { 
                        title: 'Offices', 
                        field: 'description', 
                        render: (rowData) => getSection(rowData), 
                        cellStyle: { flex: 3 }, 
                        headerStyle: { flex: 3 },
                        validate: (rowData) => rowData.hasOwnProperty('description') && rowData.description 
                            ? true 
                            : { isValid: false, helperText: 'Office is required'},
                    },
                    { 
                        title: 'Timezone', 
                        field: 'timezoneSelector', 
                        cellStyle: { flex: 3 }, 
                        headerStyle: { flex: 3 },
                        // tslint:disable-next-line: max-line-length
                        // validate: (rowData) => rowData.hasOwnProperty('areaOfLaw') && rowData.areaOfLaw ? true : { isValid: false, helperText: 'Area of Law is required'},
                        render: (rowData) => timezoneRender(rowData), 
                        editComponent: (componentData) => timezoneSelectorComponent(componentData),
                    },
                    // tslint:disable-next-line: max-line-length
                    { title: 'Members Count', field: 'staffCount', render: (rowData) => getMember(rowData), editable: 'never', cellStyle: { justifyContent: 'center' }, headerStyle: { justifyContent: 'center' } },
                ]}
                components={
                    {
                        Toolbar: props => (
                            <div className={classes.customToolbar}>
                                <MTableToolbar classes={{ highlight: classes.highlight, actions: classes.actions }} {...props} />
                            </div>
                        )
                    }
                }
                data={state.data}        
                options={{
                    addRowPosition: 'first',
                    search: true,
                    selection: true,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 40, 50],
                    showTitle: true,
                    toolbar: true,
                    actionsColumnIndex: -1,
                    rowStyle: rowData => ({
                        backgroundColor: (state.selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                    })
                }}
                editable={{
                    onRowAdd: (newData: OfficeItem) =>
                        // tslint:disable-next-line: no-any
                        new Promise((resolve: any) => {
                            setTimeout(() => {
                                resolve();
                                if (newData.description) {
                                    createData(newData);
                                    insertRowData(newData);
                                }
                            // tslint:disable-next-line: align
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        // tslint:disable-next-line: no-any
                        new Promise((resolve: any) => {
                            setTimeout(() => {
                            resolve();
                            if (oldData) {
                                updateData(newData);
                                updateRowData(newData, oldData);
                            }
                            // tslint:disable-next-line: align
                            }, 600);
                        }),
                    onRowDelete: (oldData) =>
                        // tslint:disable-next-line: no-any
                        new Promise((resolve: any) => {
                            setTimeout(() => {
                                resolve();
                                if (oldData) {
                                    deleteData(oldData);
                                    deleteRowData(oldData);
                                }
                            // tslint:disable-next-line: align
                            }, 600);
                        }),
                }}
                actions={[
                    {
                        tooltip: 'Remove All Selected Office',
                        icon: DeleteOutline,
                        // tslint:disable-next-line: no-any
                        onClick: (evt: any, data: any) => onDeleteData(data)
                    },
                ]}
            />
        </div>
    );
}

const createFirmOffice = gql`
    mutation createFirmOffice($description: String, $timezone: String) {
        createFirmOffice(description: $description, timezone: $timezone)
    }
`;

const updateFirmOffice = gql`
    mutation updateFirmOffice($description: String, $guidId: String, $timezone: String) {
        updateFirmOffice(description:$description, guidId: $guidId, timezone: $timezone)
    }
`;

const deleteFirmOffice = gql`
    mutation deleteFirmOffice($guidId: String) {
        deleteFirmOffice(guidId: $guidId)
    }  
`;

const firmSubscription = gql`
    subscription firm{
        lookupNotification{
            id,
            guidId,
            lookupDescription,
            message,
            status
        }
    }
`;