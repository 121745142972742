/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { RouterProps, showNotification } from '../App';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { CircularProgress, makeStyles } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import AccessibleOutlinedIcon from '@material-ui/icons/AccessibleOutlined';
import { FurmStructureConfigurationBanner } from './FurmStructureConfigurationBanner';
import LookupTeamsMaintenance from './tabs/LookupTeamsMaintenance';
import LookupTeamMemberMaintenance from './tabs/LookupTeamMemberMaintenance';
import LookupOfficesMaintenance from './tabs/LookupOfficesMaintenance';
import LookupOfficeMemberMaintenance from './tabs/LookupOfficeMemberMaintenance';
import LookupDepartmentMaintenance from './tabs/LookupDepartmentMaintenance';
import LookupDepartmentMemberMaintenance from './tabs/LookupDepartmentMemberMaintenance';
import LookupUserAssistantMaintenance from './tabs/LookupUserAssistantMaintenance';
import LookupUserAssistantMemberMaintenance from './tabs/LookupUserAssistantMemberMaintenance';
import { client } from '..';
import { UserData } from '../configurationDashboard/ConfigurationDashboardPage';
import { LearnMoreEnum } from '../learnMore/LearnMorePage';

const useStyles = makeStyles({
    root: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
    },
    wrapper: {
        flex: 1,
        display: 'flex',
        backgroundColor: '#fff',
        margin: '0 -15px',
        padding: '15px',
    },
    sidebar: {
        padding: '0 15px',
        maxWidth: '400px',
        width: '100%',
        position: 'relative',
        flex: 1,
        display: 'flex',
    },
    sidebarInner: {
        position: 'relative',
        flex: 1,
        // left: 0,
        // right: 0,
        // top: 0,
        // bottom: 0,
        // overflow: 'auto',
    },
    sidebarList: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        overflow: 'auto',
        paddingRight: '10px',
        '& .Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        '& .Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
    },
    content: {
        padding: '0 15px',
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
        '& > div': {
            flexGrow: 1,
            maxWidth: '800px',
        }
    }
});

export enum TableList {
    offices = 0,
    department,
    teams,
    userAssistant,
}

interface FirmStructureConfigurationPageState {
    selectedTable: TableList;
}

interface FirmStructureConfigurationPageProps extends RouterProps {

}

export default function FirmStructureConfigurationPage(props: FirmStructureConfigurationPageProps) {

    const classes = useStyles();

    var routeTab: TableList = TableList[props.match.params.tab];
    
    const [pageState, setPageState] = useState<FirmStructureConfigurationPageState>({
        selectedTable: routeTab ? routeTab : TableList.department
    });

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isInternalAdmin, setIsInternalAdmin] = useState<boolean>(false);

    // tslint:disable-next-line: no-any
    let routeCommand = props.match.params.command;

    useEffect(() => {
        fetchAppUserData();
    // tslint:disable-next-line: align
    }, []);

    const fetchAppUserData = () => {
        client.query({
            query: UserData,
            fetchPolicy: 'network-only'
            // tslint:disable-next-line:no-any
        }).then((results: {
            // tslint:disable-next-line:no-any
            data: any;
            }) => {
                if (results.data.appUser) {
                    setIsInternalAdmin(results.data.appUser.isInternalAdmin);
                }
                setIsLoading(false);
            // tslint:disable-next-line: no-any
            }).catch((reason: any) => {
                showNotification(null, reason, 'error');
        });
    };

    const onClickBack = () => {
        props.history.push('/configurationDashboard');
    };

    const onClickTab = (value: TableList) => () => {
        setPageState({
            ...pageState,
            selectedTable: value
        });

        props.history.push(`/firmStructureConfiguration/${TableList[value]}`);
    };

    const onLearnMore = () => {
        props.history.push({
            pathname: `/learnMore/${LearnMoreEnum[LearnMoreEnum.FIRM_STRUCTURE]}`, 
        });
    };
    
    return (
        <React.Fragment>
            {isLoading 
                ? (
                    <div className={classes.root}>
                        <CircularProgress color="primary" size={20} /> 
                    </div>                    
                )
                : (
                    <div className={classes.root}>
                        <FurmStructureConfigurationBanner 
                            title="Firm Structure"
                            onClickBack={onClickBack}
                            onLearnMore={onLearnMore}
                        />
                        <div className={classes.wrapper}>
                            <div className={classes.sidebar}>
                                <div className={classes.sidebarInner}>
                                    <List className={classes.sidebarList}>
                                        {(UserFeature.HasAccess(UserFeatureEnum.hasFirmDepartmentLookupMaintenance) || isInternalAdmin ) && (
                                            <ListItem 
                                                button={true} 
                                                onClick={onClickTab(TableList.department)}
                                                selected={pageState.selectedTable === TableList.department}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <HomeWorkIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Firm Departments"
                                                    secondary="Departments in the firm"
                                                />
                                            </ListItem>
                                        )}
                                        {(UserFeature.HasAccess(UserFeatureEnum.hasFirmOfficeLookupMaintenance) || isInternalAdmin ) && (
                                            <ListItem 
                                                button={true} 
                                                onClick={onClickTab(TableList.offices)}
                                                selected={pageState.selectedTable === TableList.offices}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <BusinessIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Firm Offices"
                                                    secondary="Offices in the firm"
                                                />
                                            </ListItem>
                                        )}
                                        {(UserFeature.HasAccess(UserFeatureEnum.hasFirmTeamLookupMaintenance) || isInternalAdmin ) && (
                                            <ListItem 
                                                button={true} 
                                                onClick={onClickTab(TableList.teams)}
                                                selected={pageState.selectedTable === TableList.teams}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <GroupIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="Firm Teams"
                                                    secondary="Teams in the firm"
                                                />
                                            </ListItem>
                                        )}
                                        {(UserFeature.HasAccess(UserFeatureEnum.hasUserAssistantLookupMaintenance) || isInternalAdmin ) && (
                                            <ListItem 
                                                button={true} 
                                                onClick={onClickTab(TableList.userAssistant)}
                                                selected={pageState.selectedTable === TableList.userAssistant}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        <AccessibleOutlinedIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary="User Assistant"
                                                    secondary="Assistants for staff members."
                                                />
                                            </ListItem>
                                        )}
                                    </List>
                                </div>
                            </div>
                            <div className={classes.content}>
                                {(pageState.selectedTable === TableList.teams && routeCommand === undefined) && (<LookupTeamsMaintenance />)}
                                {/* tslint:disable-next-line: max-line-length */}
                                {(pageState.selectedTable === TableList.teams && routeCommand !== undefined) && (<LookupTeamMemberMaintenance {...props}/>)}
                                {/* tslint:disable-next-line: max-line-length */}
                                {(pageState.selectedTable === TableList.offices && routeCommand === undefined) && (<LookupOfficesMaintenance />)}
                                {/* tslint:disable-next-line: max-line-length */}
                                {(pageState.selectedTable === TableList.offices && routeCommand !== undefined) && (<LookupOfficeMemberMaintenance {...props}/>)}
                                {/* tslint:disable-next-line: max-line-length */}
                                {(pageState.selectedTable === TableList.department && routeCommand === undefined) && (<LookupDepartmentMaintenance />)}
                                {(pageState.selectedTable === TableList.department 
                                    && routeCommand !== undefined) && (<LookupDepartmentMemberMaintenance {...props}/>)}
                                {/* tslint:disable-next-line: max-line-length */}
                                {(pageState.selectedTable === TableList.userAssistant && routeCommand === undefined) && (<LookupUserAssistantMaintenance />)}
                                {(pageState.selectedTable === TableList.userAssistant 
                                    && routeCommand !== undefined) && (<LookupUserAssistantMemberMaintenance {...props}/>)}
                            </div>
                        </div>
                    </div>
                )
            }
        </React.Fragment>
    );
}