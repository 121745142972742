import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { FormApi } from 'final-form';
import React, { useEffect, useState } from 'react';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { RvLoader } from '../../components/Loader';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { mainTheme } from '../../Theme';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { showNotification } from '../../App';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { TimesheetActivitiesSelector } from '../selectors/TimesheetActivitiesSelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
        justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        },
        radioGroup: {
            flexDirection: 'row'
        },
        radioIcon: {
            color: mainTheme.BrandColors.TemplatePrimary
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 600,
            color: theme.palette.primary.main
        },
        accordionDetails: {
            flexFlow: 'column',
        },
    })
);

interface FormState {
    unitsPerHour: number | null;
    standardHoursPerDay: number | null;
    expectedHoursPerDay: number | null;
    warningOnExceedingHours: boolean;
    timeCaptureOptions: string | null;
    useStartEndTime: boolean;
    copyActivityAsNote: boolean;
    thresholdLevel: number | null;

    // defaults fields
    defaultDurationMinutes: number | null;
    defaultDurationUnits: number | null;
    phoneCallActivity: IAutoCompleteItem | null;
    emailActivity: IAutoCompleteItem | null;
    submissionType: string | null;
    // mobileOpeningPage: string | null;
    timeSelectionMinuteInterval: number | null;
    // isShowFavouriteActivitiesFirst: boolean;
    // isShowFavouriteMattersFirst: boolean;
    useSmartSettings: boolean;
    suggestCustomButtons: boolean;
    timeCaptureMode: string | null;

    // warning fields
    warnOnDefaultTime: boolean;
    warnOnExceedingHours: boolean;
    warnIfNotToday: boolean;
}
interface TimeRecordingTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

export default function TimeRecordingTab() {

    const classes = useStyles();

    const [state, setState] = useState<TimeRecordingTabState>({
        isLoading: true,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState, setFormState] = useState<FormState>({
        unitsPerHour: null,
        standardHoursPerDay: null,
        expectedHoursPerDay: null,
        useStartEndTime: false,
        warningOnExceedingHours: false,
        timeCaptureOptions: 'both',
        copyActivityAsNote: false,
        thresholdLevel: null,

        defaultDurationMinutes: null,
        defaultDurationUnits: null,
        phoneCallActivity: null,
        emailActivity: null,
        submissionType: null,
        // mobileOpeningPage: null,
        timeSelectionMinuteInterval: null,
        // isShowFavouriteActivitiesFirst: false,
        // isShowFavouriteMattersFirst: false,
        useSmartSettings: false,
        suggestCustomButtons: false,
        timeCaptureMode: null,

        warnOnDefaultTime: false,
        warnOnExceedingHours: false,
        warnIfNotToday: false,
    });

    const timeRecordingSettings = useQuery<TimeRecordingSettingsData>(TIME_RECORDING_SETTINGS_QUERY);

    // tslint:disable-next-line: no-any
    const [updateTimeRecordingSettings] = useMutation<any, TimeRecordingSettingsMutationParams>(TIME_RECORDING_SETTINGS_MUTATION);

    useEffect(() => {
    
        // tslint:disable-next-line: max-line-length
        if (!timeRecordingSettings.loading && timeRecordingSettings.data) {

            const { 
                copyActivityAsNote,
                expectedHoursPerDay,
                standardHoursPerDay,
                unitsPerHour,
                useStartEndTime,
                thresholdLevel,

                // defaults fields
                defaultDurationMinutes,
                defaultDurationUnits,
                emailActivity,
                phoneCallActivity,
                submissionType,
                suggestCustomButtons,
                tenantTimeCaptureMode,
                timeCaptureMode,
                timeSelectionMinuteInterval,
                useSmartSettings,

                // warning fields
                warnIfNotToday,
                warnOnExceedingHours,

            } = timeRecordingSettings.data.settings.systems.timeRecordingSettings.config;

            setFormState((prevFormState) => {
                return {
                    ...prevFormState,
                    copyActivityAsNote,
                    expectedHoursPerDay,
                    standardHoursPerDay,
                    unitsPerHour,
                    useStartEndTime,
                    thresholdLevel,
                    // passwordStrength: passwordStrength,

                    // defaults fields
                    defaultDurationMinutes,
                    defaultDurationUnits,
                    emailActivity : emailActivity ? { label: emailActivity, value: emailActivity} : null,
                    phoneCallActivity: phoneCallActivity ? { label: phoneCallActivity, value: phoneCallActivity} : null,
                    submissionType,
                    suggestCustomButtons,
                    tenantTimeCaptureMode,
                    timeCaptureMode,
                    timeSelectionMinuteInterval,
                    useSmartSettings,

                    // warning fields
                    warnIfNotToday: warnIfNotToday,
                    warnOnExceedingHours: warnOnExceedingHours,
                };
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false
                };
            });
        }
    },        [timeRecordingSettings.data, timeRecordingSettings.loading]);

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {
        // console.log('submit');
        updateTimeRecordingSettings({
            variables: {
                copyActivityAsNote: values.copyActivityAsNote,
                expectedHoursPerDay: values.expectedHoursPerDay,
                standardHoursPerDay: values.standardHoursPerDay,
                unitsPerHour: values.unitsPerHour,
                useStartEndTime: values.useStartEndTime,
                thresholdLevel: values.thresholdLevel,

                // defaults fields
                defaultDurationMinutes: values.defaultDurationMinutes,
                defaultDurationUnits: values.defaultDurationUnits,
                emailActivity: values.emailActivity ? values.emailActivity.value : null,
                phoneCallActivity: values.phoneCallActivity ? values.phoneCallActivity.value : null,
                submissionType: values.submissionType,
                suggestCustomButtons: values.suggestCustomButtons,
                tenantTimeCaptureMode: values.tenantTimeCaptureMode,
                timeCaptureMode: values.timeCaptureMode,
                timeSelectionMinuteInterval: values.timeSelectionMinuteInterval,
                useSmartSettings: values.useSmartSettings,

                // warning fields
                warnIfNotToday: values.warnIfNotToday,
                warnOnExceedingHours: values.warnOnExceedingHours,
            }
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info');     
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update time recording.', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    // tslint:disable-next-line: no-any
    const RadioComp = (compProps: any) => {

        const { input } = compProps;        
        return (
                <Radio
                    {...input}
                    checkedIcon={<RadioButtonCheckedIcon className={classes.radioIcon} />}
                />
        );
    };
    
    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{
                        submitting: true,
                        pristine: true,
                    }}
                    validate={(values) => {
                        let errors = {
                            unitsPerHour: '',
                            standardHoursPerDay: '',
                            expectedHoursPerDay: '',
                        };
                        if (values.unitsPerHour !== null) {
                            if (!(values.unitsPerHour >= 0 && values.unitsPerHour <= 60)) {
                                errors.unitsPerHour = 'Unit per hour should be between 0 and 60';
                            }
                        }
                        if (values.standardHoursPerDay !== null) {
                            if (!(values.standardHoursPerDay >= 0 && values.standardHoursPerDay <= 24)) {
                                errors.standardHoursPerDay = 'Standard hours per day should be between 0 and 24';
                            }
                        }
                        if (values.expectedHoursPerDay !== null) {
                            if (!(values.expectedHoursPerDay >= 0 && values.expectedHoursPerDay <= 24)) {
                                errors.expectedHoursPerDay = 'Expected hours per day should be between 0 and 24';
                            }
                        }
                        if (errors.unitsPerHour === '' && errors.standardHoursPerDay === '' && errors.expectedHoursPerDay === '') {
                            return undefined;
                        }
                        return errors;
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => (
                        <form onSubmit={handleSubmit}>
                            <FormSpy
                                subscription={{ pristine: true }}
                                // tslint:disable-next-line: no-shadowed-variable
                                onChange={(props) => {
                                    onFormValueChanged(props);
                                }}
                            />

                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography className={classes.heading}>Time Recording</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                            <Grid container={true} spacing={3}>
                                                <Grid item={true} xs={12} md={4} lg={4} sm={12}>
                                                    <Field
                                                        name="unitsPerHour"
                                                        subscription={{ touched: true, error: true, value: true }}
                                                        // validate={form.getFieldState('unitsPerHour')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                label="Units per Hour"
                                                                name="unitsPerHour"
                                                                className={classes.textField}
                                                                error={meta.error && meta.touched}
                                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                                type="number"
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={12} md={4} lg={4} sm={12}>
                                                    <Field
                                                        name="standardHoursPerDay"
                                                        subscription={{ touched: true, error: true, value: true }}
                                                        // validate={form.getFieldState('standardHoursPerDay')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                label="Standard Hours per Day"
                                                                name="standardHoursPerDay"
                                                                className={classes.textField}
                                                                error={meta.error && meta.touched}
                                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={12} md={4} lg={4} sm={12}>
                                                    <Field
                                                        name="expectedHoursPerDay"
                                                        subscription={{ touched: true, error: true, value: true }}
                                                        // validate={form.getFieldState('expectedHoursPerDay')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                label="Expected Hours per Day"
                                                                name="expectedHoursPerDay"
                                                                className={classes.textField}
                                                                error={meta.error && meta.touched}
                                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                                type="number"
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                                    <FormGroup className={classes.radioGroup}>
                                                        <Field name="useStartEndTime" subscription={{ touched: true, error: true, value: true }}>
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            checked={input.value}
                                                                            onChange={input.onChange}
                                                                            checkedIcon={<CheckBoxIcon color="primary" />}
                                                                        />
                                                                    }
                                                                    label="Use Start & End Time"
                                                                />
                                                            )}
                                                        </Field>
                                                        <Field name="copyActivityAsNote" subscription={{ touched: true, error: true, value: true }}>
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            checked={input.value}
                                                                            onChange={input.onChange}
                                                                            checkedIcon={<CheckBoxIcon color="primary" />}
                                                                        />
                                                                    }
                                                                    label="Copy Activity As Note"
                                                                />
                                                            )}
                                                        </Field>
                                                        {/* <Field
                                                            name="warningonExceedingHours"
                                                            subscription={{touched: true, error: true, value: true}}
                                                        >                                            
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    disabled={true}
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            checked={input.value}
                                                                            onChange={input.onChange}
                                                                            checkedIcon={<CheckBoxIcon color="primary" />}
                                                                        />
                                                                    }
                                                                    label="Warning on Exceeding Hours" 
                                                                />
                                                            )}
                                                        </Field> */}
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography className={classes.heading}>Default</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                            <Grid container={true} spacing={3}>
                                                <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Submission Type</FormLabel>
                                                        <RadioGroup
                                                            className={classes.radioGroup}
                                                            
                                                        >
                                                            <FormControlLabel 
                                                                control={
                                                                    <Field 
                                                                        subscription={{touched: true, error: true, value: true}}
                                                                        name="submissionType" 
                                                                        component={RadioComp}
                                                                        type="radio"
                                                                    />
                                                                }
                                                                value="Automatic"
                                                                label="Submit Only" 
                                                            />
                                                            <FormControlLabel 
                                                                control={
                                                                    <Field 
                                                                        subscription={{touched: true, error: true, value: true}}
                                                                        name="submissionType" 
                                                                        component={RadioComp}
                                                                        type="radio"
                                                                    />
                                                                }
                                                                value="Draft"
                                                                label="Allow Draft" 
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                                    {/* <FormControl component="fieldset">
                                                        <FormLabel component="legend">Mobile Opening Page</FormLabel>
                                                        <RadioGroup
                                                            // defaultValue={state.mobileOpeningPage}
                                                            className={classes.radioGroup}
                                                        >
                                                            <FormControlLabel 
                                                                control={
                                                                    <Field 
                                                                        subscription={{touched: true, error: true, value: true}}
                                                                        name="mobileOpeningPage" 
                                                                        component={RadioComp}
                                                                        type="radio"
                                                                    />
                                                                }
                                                                value="Home"
                                                                label="Home" 
                                                            />
                                                            <FormControlLabel 
                                                                control={
                                                                    <Field 
                                                                        subscription={{touched: true, error: true, value: true}}
                                                                        name="mobileOpeningPage" 
                                                                        component={RadioComp}
                                                                        type="radio"
                                                                    />
                                                                }
                                                                value="Timesheet"
                                                                label="Timesheet" 
                                                            />
                                                        </RadioGroup>
                                                    </FormControl> */}
                                                </Grid>
                                                <Grid item={true} xs={12} md={4} lg={4} sm={12}>
                                                    <Field
                                                        name="defaultDurationUnits"
                                                        subscription={{touched: true, error: true, value: true}}
                                                        // tslint:disable-next-line: radix
                                                        parse={value => parseInt(value)}
                                                        // validate={form.getFieldState('durationUnits')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                label="Duration/Units" 
                                                                className={classes.textField}
                                                                error={meta.error && meta.touched}
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? meta.error
                                                                        : ''
                                                                }
                                                                type="number"
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={12} md={4} lg={4} sm={12}>
                                                    <Field
                                                        name="defaultDurationMinutes"
                                                        subscription={{touched: true, error: true, value: true}}
                                                        // tslint:disable-next-line: radix
                                                        parse={value => parseInt(value)}
                                                        // validate={form.getFieldState('durationUnits')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                label="Duration/Minutes" 
                                                                className={classes.textField}
                                                                error={meta.error && meta.touched}
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? meta.error
                                                                        : ''
                                                                }
                                                                type="number"
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={12} md={4} lg={4} sm={12}>
                                                    <Field
                                                        name="timeSelectionMinuteInterval"
                                                        subscription={{touched: true, error: true, value: true}}
                                                        // tslint:disable-next-line: radix
                                                        parse={value => parseInt(value)}
                                                        // validate={form.getFieldState('durationUnits')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                label="Time Selection Minute Interval" 
                                                                name="timeSelectionMinuteInterval"
                                                                className={classes.textField}
                                                                error={meta.error && meta.touched}
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? meta.error
                                                                        : ''
                                                                }
                                                                type="number"
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                                    <Field
                                                        name="phoneCallActivity"
                                                        subscription={{touched: true, error: true, value: true}}
                                                        // validate={form.getFieldState('phoneCallActivity')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TimesheetActivitiesSelector 
                                                                {...input}
                                                                label="Phone Call Activity" 
                                                                onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                                    input.onChange(selection)
                                                                }
                                                                className={classes.textField}
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                                    <Field
                                                        name="emailActivity"
                                                        subscription={{touched: true, error: true, value: true}}
                                                        // validate={form.getFieldState('emailActivity')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TimesheetActivitiesSelector 
                                                                {...input}
                                                                label="Email Activity" 
                                                                onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                                    input.onChange(selection)
                                                                }
                                                                className={classes.textField}
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                                    <Grid container={true} spacing={3}>
                                                        {/* <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                                            <Field
                                                                name="isShowFavouriteActivitiesFirst"
                                                                subscription={{touched: true, error: true, value: true}}
                                                            >                                            
                                                                {({ input, meta }) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...input}
                                                                                checked={input.value}
                                                                                onChange={input.onChange}
                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                            />
                                                                        }
                                                                        disabled={true}
                                                                        label="Show Favourite Activities First" 
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Grid>
                                                        <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                                            <Field
                                                                name="isShowFavouriteMattersFirst"
                                                                subscription={{touched: true, error: true, value: true}}
                                                            >                                            
                                                                {({ input, meta }) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...input}
                                                                                checked={input.value}
                                                                                onChange={input.onChange}
                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                            />
                                                                        }
                                                                        disabled={true}
                                                                        label="Show Favourite Matters First"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Grid> */}
                                                        <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                                            <Field
                                                                name="useSmartSettings"
                                                                subscription={{touched: true, error: true, value: true}}
                                                            >                                            
                                                                {({ input, meta }) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...input}
                                                                                checked={input.value}
                                                                                onChange={input.onChange}
                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                            />
                                                                        }
                                                                        label="Use Smart Settings"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Grid>
                                                        <Grid item={true} xs={12} sm={12} md={6} lg={6}>
                                                            <Field
                                                                name="suggestCustomButtons"
                                                                subscription={{touched: true, error: true, value: true}}
                                                            >                                            
                                                                {({ input, meta }) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...input}
                                                                                checked={input.value}
                                                                                onChange={input.onChange}
                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                            />
                                                                        }
                                                                        label="Suggest Custom Buttons"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Time Capture Mode</FormLabel>
                                                        <RadioGroup
                                                            className={classes.radioGroup}
                                                        >
                                                            <FormControlLabel 
                                                                control={
                                                                    <Field 
                                                                        subscription={{touched: true, error: true, value: true}}
                                                                        name="timeCaptureMode" 
                                                                        component={RadioComp}
                                                                        type="radio"
                                                                    />
                                                                }
                                                                value="Duration"
                                                                label="Duration" 
                                                            />
                                                            <FormControlLabel 
                                                                control={
                                                                    <Field 
                                                                        subscription={{touched: true, error: true, value: true}}
                                                                        name="timeCaptureMode" 
                                                                        component={RadioComp}
                                                                        type="radio"
                                                                    />
                                                                }
                                                                value="Units"
                                                                label="Units" 
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Tenant Time Capture Mode</FormLabel>
                                                        <RadioGroup
                                                            className={classes.radioGroup}
                                                        >
                                                            <FormControlLabel 
                                                                control={
                                                                    <Field 
                                                                        subscription={{touched: true, error: true, value: true}}
                                                                        name="tenantTimeCaptureMode" 
                                                                        component={RadioComp}
                                                                        type="radio"
                                                                    />
                                                                }
                                                                value="Both"
                                                                label="Both" 
                                                            />
                                                            <FormControlLabel 
                                                                control={
                                                                    <Field 
                                                                        subscription={{touched: true, error: true, value: true}}
                                                                        name="tenantTimeCaptureMode" 
                                                                        component={RadioComp}
                                                                        type="radio"
                                                                    />
                                                                }
                                                                value="Duration"
                                                                label="Duration" 
                                                            />
                                                            <FormControlLabel 
                                                                control={
                                                                    <Field 
                                                                        subscription={{touched: true, error: true, value: true}}
                                                                        name="tenantTimeCaptureMode" 
                                                                        component={RadioComp}
                                                                        type="radio"
                                                                    />
                                                                }
                                                                value="Units"
                                                                label="Units" 
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography className={classes.heading}>Warnings</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.accordionDetails}>
                                            <Grid container={true} spacing={3}>
                                                <Grid item={true} xs={12} md={8} lg={8} sm={12}>
                                                    <FormGroup>
                                                        <Field
                                                            name="warnOnExceedingHours"
                                                            subscription={{touched: true, error: true, value: true}}
                                                        >                                            
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            checked={input.value}
                                                                            onChange={input.onChange}
                                                                            checkedIcon={<CheckBoxIcon color="primary" />}
                                                                        />
                                                                    }
                                                                    label="Warning on Exceeding Hours" 
                                                                />
                                                            )}
                                                        </Field>
                                                        <Field
                                                            name="warnIfNotToday"
                                                            subscription={{touched: true, error: true, value: true}}
                                                        >                                            
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            checked={input.value}
                                                                            onChange={input.onChange}
                                                                            checkedIcon={<CheckBoxIcon color="primary" />}
                                                                        />
                                                                    }
                                                                    label="Warn if not today"  
                                                                />
                                                            )}
                                                        </Field>
                                                        {/* <Field
                                                            name="warnOnDefaultTime"
                                                            subscription={{touched: true, error: true, value: true}}
                                                        >                                            
                                                            {({ input, meta }) => (
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            {...input}
                                                                            checked={input.value}
                                                                            onChange={input.onChange}
                                                                            checkedIcon={<CheckBoxIcon color="primary" />}
                                                                        />
                                                                    }
                                                                    label="Warn on Default Time" 
                                                                />
                                                            )}
                                                        </Field> */}
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item={true} xs={12} md={4} lg={4} sm={12}>
                                                    <Field
                                                        name="thresholdLevel"
                                                        subscription={{ touched: true, error: true, value: true }}
                                                        // validate={form.getFieldState('expectedHoursPerDay')?.value !== null ? required : undefined}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                label="Matter Estimate Threshold %"
                                                                name="thresholdLevel"
                                                                className={classes.textField}
                                                                error={meta.error && meta.touched}
                                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                                type="number"
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item={true} xs={12} md={12} justify="flex-end">
                                    <div className={classes.buttonWrapper}>
                                        <Button variant="outlined" color="primary" type="submit" disabled={submitting || pristine || state.onSubmitLoading}>
                                            Save
                                        </Button>
                                        <Button variant="outlined" color="primary" type="button" disabled={submitting || pristine} onClick={onReset(form)}>
                                            Reset
                                        </Button>
                                    </div>
                                </Grid>
                                {/* {printJson(values)} */}
                            </Grid>
                        </form>
                    )}
                />
            )}
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

export interface TimeRecordingSettingsData {
    settings: Settings;
}

export interface Settings {
    id:      number;
    systems: Systems;
}

export interface Systems {
    timeRecordingSettings: TimeRecordingSettings;
}

export interface TimeRecordingSettings {
    id:     number;
    config: Config;
}

export interface Config {
    id:                  number;
    copyActivityAsNote:  boolean;
    expectedHoursPerDay: number;
    standardHoursPerDay: number;
    unitsPerHour:        number;
    useStartEndTime:     boolean;
    thresholdLevel:      number;

    defaultDurationMinutes:      number;
    defaultDurationUnits:        number;
    emailActivity:               string;
    phoneCallActivity:           string;
    submissionType:              string;
    suggestCustomButtons:        boolean;
    tenantTimeCaptureMode:       string;
    timeCaptureMode:             string;
    timeSelectionMinuteInterval: number;
    useSmartSettings:            boolean;
    warnIfNotToday:              boolean;
    warnOnExceedingHours:        boolean;

}

const TIME_RECORDING_SETTINGS_QUERY = gql`
    query timeRecordingSettings {
        settings {
            id
            systems {
                timeRecordingSettings {
                  id
                  config {
                    id
                    copyActivityAsNote
                    expectedHoursPerDay
                    standardHoursPerDay
                    unitsPerHour
                    useStartEndTime
                    thresholdLevel
                    
                    defaultDurationMinutes
                    defaultDurationUnits
                    emailActivity
                    phoneCallActivity
                    submissionType
                    suggestCustomButtons
                    tenantTimeCaptureMode
                    timeCaptureMode
                    timeSelectionMinuteInterval
                    useSmartSettings
                    
                    warnIfNotToday
                    warnOnExceedingHours
                  }
                }
            }
        }
    }
`;

interface TimeRecordingSettingsMutationParams {
    unitsPerHour: number | null;
    standardHoursPerDay: number | null;
    expectedHoursPerDay: number | null;
    useStartEndTime: Boolean;
    copyActivityAsNote: Boolean;
    thresholdLevel: number | null;

    // defaults fields
    tenantTimeCaptureMode: string | null;
    submissionType: string | null;
    defaultDurationUnits: number | null;
    defaultDurationMinutes: number | null;
    phoneCallActivity: string | null;
    emailActivity: string | null;
    suggestCustomButtons: Boolean;
    useSmartSettings: Boolean;
    timeCaptureMode: string | null;
    timeSelectionMinuteInterval: number | null;

    // warning fields
    warnOnExceedingHours: Boolean;
    warnIfNotToday: Boolean;
}

const TIME_RECORDING_SETTINGS_MUTATION = gql`
    mutation TimeRecordingSettingsMutation(
        $unitsPerHour: Int,
        $standardHoursPerDay: Decimal,
        $expectedHoursPerDay: Int,
        $useStartEndTime: Boolean,
        $copyActivityAsNote: Boolean,
        $thresholdLevel: Decimal,
  
       	$tenantTimeCaptureMode: String,
        $submissionType: String,
        $defaultDurationUnits: Int,
        $defaultDurationMinutes: Int,
        $phoneCallActivity: String,
        $emailActivity: String,
        $suggestCustomButtons: Boolean,
        $useSmartSettings: Boolean,
        $timeCaptureMode: String,
        $timeSelectionMinuteInterval: Int
  
  			$warnIfNotToday: Boolean,
        $warnOnExceedingHours: Boolean,
    ){
        settings {
            timeRecordingSettings {
                update (
                    unitsPerHour: $unitsPerHour,
                    standardHoursPerDay: $standardHoursPerDay,
                    expectedHoursPerDay: $expectedHoursPerDay,
                    useStartEndTime: $useStartEndTime,
                    copyActivityAsNote: $copyActivityAsNote,
                    thresholdLevel: $thresholdLevel,
                  
                  	tenantTimeCaptureMode: $tenantTimeCaptureMode,
                    submissionType: $submissionType,
                    defaultDurationUnits: $defaultDurationUnits,
                    defaultDurationMinutes: $defaultDurationMinutes,
                    phoneCallActivity: $phoneCallActivity,
                    emailActivity: $emailActivity,
                    suggestCustomButtons: $suggestCustomButtons,
                    useSmartSettings: $useSmartSettings,
                    timeCaptureMode: $timeCaptureMode,
                    timeSelectionMinuteInterval: $timeSelectionMinuteInterval,
                  
                  	warnIfNotToday: $warnIfNotToday,
                    warnOnExceedingHours: $warnOnExceedingHours,
                ) {
                    status
                    error
                }
            }
        }
    }
`;