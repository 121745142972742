import React, { useState, useMemo } from 'react';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, GetTagProps, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress, Chip } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import gql from 'graphql-tag';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { useQuery } from 'react-apollo';

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         removedAvatar: {
//             maxWidth: '15px',
//             maxHeight: '15px',
//         },
//     }),
// );

interface SubjectSelectorProps {
    disabled?: boolean;
    required?: boolean;
    // tslint:disable-next-line: no-any
    value?: SubjectAutoCompleteItem | SubjectAutoCompleteItem[] | any; // value only available in single select
    error?: boolean;
    onSelection?: (value?: SubjectAutoCompleteItem | SubjectAutoCompleteItem[], name?: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    style?: React.CSSProperties;
    disablePortal?: boolean;
    variant?: 'standard' | 'filled' | 'outlined';
    size?: 'small' | 'medium';
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    multiple?: boolean;
    isApplyMatterAccess?: boolean;
}

export interface SubjectAutoCompleteItem extends IAutoCompleteItem, ClientAndMatterNoteSubject {}

interface SubjectSelectorState {
    data: SubjectAutoCompleteItem[];
}

export const SubjectSelector: React.FC<SubjectSelectorProps> = (props) => {
    // const classes = useStyles();

    const [state, setState] = useState<SubjectSelectorState>({
        data: [],
    });

    const clientAndMatterNoteSubjectQuery = useQuery<ClientAndMatterNoteSubjectData>(ClientAndMatterNoteSubjectQuery, {
        variables: {
            isApplyMatterAccess: props.isApplyMatterAccess,
        },
        fetchPolicy: 'cache-first',
    });

    useMemo(() => {
        if (
            !clientAndMatterNoteSubjectQuery.loading &&
            !clientAndMatterNoteSubjectQuery.error &&
            clientAndMatterNoteSubjectQuery.data &&
            clientAndMatterNoteSubjectQuery.data.lookup &&
            clientAndMatterNoteSubjectQuery.data.lookup.clientAndMatterNoteSubject
        ) {
            setState((prevState) => {
                const { clientAndMatterNoteSubject } = clientAndMatterNoteSubjectQuery.data!.lookup;

                const items: SubjectAutoCompleteItem[] = clientAndMatterNoteSubject.map((country: ClientAndMatterNoteSubject) => {
                    return {
                        ...country,
                        label: country.description,
                        value: country.id.toString(),
                    };
                });
                return {
                    ...prevState,
                    data: items,
                };
            });
        }
        // tslint:disable-next-line: align
    }, [clientAndMatterNoteSubjectQuery.loading, clientAndMatterNoteSubjectQuery.data, clientAndMatterNoteSubjectQuery.error]);

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return {
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                    {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                </React.Fragment>
            ),
        };
    };

    const onRenderTags = (value: SubjectAutoCompleteItem[], getTagProps: GetTagProps): React.ReactNode => {
        let renderTagsValue = value.map((option: SubjectAutoCompleteItem, index: number) => {
            let label = option.label;
            if (option.label.length > 20) {
                label = `${option.label.substring(0, 20)}...`;
            }

            return (
                <Chip
                    {...getTagProps({ index })}
                    key={index}
                    label={label}
                    // variant="outlined"
                    // size="small"
                />
            );
        });

        return renderTagsValue;
    };

    if (props.multiple) {
        return (
            <Autocomplete
                style={props.style}
                className={props.className}
                value={props.value}
                loading={clientAndMatterNoteSubjectQuery.loading}
                disabled={props.disabled}
                onChange={handleChange}
                options={state.data}
                disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                // tslint:disable-next-line: jsx-no-lambda
                getOptionLabel={(option: SubjectAutoCompleteItem) => option.label}
                // tslint:disable-next-line: jsx-no-lambda
                onBlur={props.onBlur}
                multiple={true}
                renderTags={onRenderTags}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={props.label}
                        variant={props.variant}
                        fullWidth={true}
                        margin="none"
                        required={props.required}
                        InputProps={getInputProps(params, clientAndMatterNoteSubjectQuery.loading)}
                        error={props.error}
                        size={props.size}
                    />
                )}
                // tslint:disable-next-line: jsx-no-lambda
                renderOption={(option, { inputValue }) => {
                    const matches = match(option.label, inputValue);
                    const parts = parse(option.label, matches);

                    return (
                        <div>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    );
                }}
            />
        );
    }

    return (
        <Autocomplete
            style={props.style}
            className={props.className}
            value={props.value}
            loading={clientAndMatterNoteSubjectQuery.loading}
            disabled={props.disabled}
            onChange={handleChange}
            options={state.data}
            disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
            // tslint:disable-next-line: jsx-no-lambda
            getOptionLabel={(option: SubjectAutoCompleteItem) => option.label}
            // tslint:disable-next-line: jsx-no-lambda
            onBlur={props.onBlur}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.label}
                    variant={props.variant}
                    fullWidth={true}
                    margin="none"
                    required={props.required}
                    InputProps={getInputProps(params, clientAndMatterNoteSubjectQuery.loading)}
                    error={props.error}
                    size={props.size}
                />
            )}
            // tslint:disable-next-line: jsx-no-lambda
            renderOption={(option, { inputValue }) => {
                const matches = match(option.label, inputValue);
                const parts = parse(option.label, matches);

                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
    );
};

export type ClientAndMatterNoteSubjectData = {
    lookup: Lookup;
};

export type Lookup = {
    clientAndMatterNoteSubject: ClientAndMatterNoteSubject[];
};

export type ClientAndMatterNoteSubject = {
    id: number;
    description: string;
    sequence: number;
};

const ClientAndMatterNoteSubjectQuery = gql`
    query ClientAndMatterNoteSubject {
        lookup {
            clientAndMatterNoteSubject {
                id
                description
                sequence
            }
        }
    }
`;
