/* eslint-disable react-hooks/exhaustive-deps */
import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    Button, 
    Checkbox, 
    createStyles, 
    FormControl, 
    FormControlLabel, 
    FormGroup, 
    FormLabel, 
    Grid, 
    makeStyles, 
    Radio, 
    RadioGroup, 
    TextField, 
    Theme, 
    Typography 
} from '@material-ui/core';
import { FormApi } from 'final-form';
import React, { useEffect, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { RvLoader } from '../../components/Loader';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Alert } from '@material-ui/lab';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ReminderSelector } from '../../components/ReminderSelector';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { mainTheme } from '../../Theme';
import MomentUtils from '@date-io/moment';
import { retrieveEnquiryDefaultConfigData, SystemSettingsNamesData, UpdateEnquiryDefaultConfigMutation } from '../FirmSettingConfigurationRepository';
import { showNotification } from '../../App';
import moment from 'moment';
import { client } from '../..';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 600,
            color: theme.palette.primary.main
        },
        accordionDetails: {
            flexFlow: 'column',
        },
        radioGroup: {
            flexFlow: 'row wrap',
        },
        formControl: {
            margin: '10px 0',
        },
        optionWrapper: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
        },
        optionContainer: {
            flex: 1,
        },
        optionList: {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 0',
            margin: '0 -5px',
            '& > span': {
                margin: '0 5px',
            },
            '& > div': {
                margin: '0 5px',
            }
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        reminderTextField: {
            width: '30%'
        },
        radioIcon: {
            color: mainTheme.BrandColors.TemplatePrimary
        },
    })
);

interface FormState {
    dueDateOption: 'isManualEntry' | 'isCalculatedDays' | 'isCalculatedHours';
    timeOfDay: MaterialUiPickersDate;
    addDays: number;
    addHours: number;
    defaultMeetingLength: MaterialUiPickersDate;
    reminder?: IAutoCompleteItem;
    overdueReminder?: IAutoCompleteItem;
    isSendReminderOnWeekend: boolean;
    isSendInvitationToAssignee: boolean;
    isSendInvitationToEnquirer: boolean;
    isSendReminderOnWeekendForOverDueReminders: boolean;
    isLocationFieldMandatory: boolean;
    
    // unassigned enquiry notification
    periodBeforeFirstUnassignedReminder?: IAutoCompleteItem;
    periodAfterFirstUnassignedReminder?: IAutoCompleteItem;
    isSendFistUnassignedReminderOnWeekends: Boolean;
    isSendUnassignedAfterFirstReminderOnWeekends: Boolean;
}
interface EnquiryDefaultTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

export default function EnquiryDefaultTab() {

    const classes = useStyles();

    const [state, setState] = useState<EnquiryDefaultTabState>({
        isLoading: true,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState, setFormState] = useState<FormState>({
        dueDateOption: 'isManualEntry',
        timeOfDay: null,
        addDays: 0,
        addHours: 0,
        defaultMeetingLength: null,
        reminder: { value: 'None', label: 'None' },
        overdueReminder: { value: 'None', label: 'None' },
        isSendReminderOnWeekend: false,
        isSendInvitationToAssignee: true,
        isSendInvitationToEnquirer: true,
        isSendReminderOnWeekendForOverDueReminders: false,
        isLocationFieldMandatory: false,
        isSendFistUnassignedReminderOnWeekends: false,
        isSendUnassignedAfterFirstReminderOnWeekends: false,
        periodAfterFirstUnassignedReminder: { value: 'None', label: 'None' },
        periodBeforeFirstUnassignedReminder: { value: 'None', label: 'None' },
    });

    useEffect(() => {
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {
        retrieveEnquiryDefaultConfigData(
            true,
            // tslint:disable-next-line: no-console
            (data: SystemSettingsNamesData) => onEnquiryDefaultConfigRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onEnquiryDefaultConfigRetrieved = (data: SystemSettingsNamesData) => {
        const config = data.settings.systems.enquiryDefaults.config;

        const isTimeTodayValid =  moment(config.timeOfDay, 'hh:mm a').isValid();

        const isDefaultMeetingLengthValid =  moment(config.defaultMeetingLength, 'HH:mm').isValid();

        setFormState((prevFormState) => {

            let dueDateOption: 'isManualEntry' | 'isCalculatedDays' | 'isCalculatedHours' = 'isManualEntry';

            if (config.isCalculatedDays) {
                dueDateOption = 'isCalculatedDays';
            }

            if (config.isCalculatedHours) {
                dueDateOption = 'isCalculatedHours';
            }

            return {
                ...prevFormState,
                dueDateOption,
                timeOfDay: isTimeTodayValid ? moment(config.timeOfDay, 'hh:mm a') : null,
                addDays: config.addDays,
                addHours: config.addHours,
                defaultMeetingLength: isDefaultMeetingLengthValid ? moment(config.defaultMeetingLength, 'hh:mm') : null,
                reminder: config.reminder ? { value: config.reminder, label: config.reminder } : formState.reminder,
                overdueReminder: config.overdueReminder ? { value: config.overdueReminder, label: config.overdueReminder } : formState.overdueReminder,
                isSendReminderOnWeekend: config.isSendReminderOnWeekend,
                isSendInvitationToAssignee: config.isSendInvitationToAssignee,
                isSendInvitationToEnquirer: config.isSendInvitationToEnquirer,
                isSendReminderOnWeekendForOverDueReminders: config.isSendReminderOnWeekendForOverDueReminders,
                isLocationFieldMandatory: config.isLocationFieldMandatory,
                isSendFistUnassignedReminderOnWeekends: config.isSendFistUnassignedReminderOnWeekends,
                isSendUnassignedAfterFirstReminderOnWeekends: config.isSendUnassignedAfterFirstReminderOnWeekends,
                periodAfterFirstUnassignedReminder: config.periodAfterFirstUnassignedReminder
                    ? { value: config.periodAfterFirstUnassignedReminder, label: config.periodAfterFirstUnassignedReminder }
                    : formState.periodAfterFirstUnassignedReminder,
                periodBeforeFirstUnassignedReminder: config.periodBeforeFirstUnassignedReminder
                    ? { value: config.periodBeforeFirstUnassignedReminder, label: config.periodBeforeFirstUnassignedReminder }
                    : formState.periodBeforeFirstUnassignedReminder,
            };
        });
        setState((prevState) => {

            return {
                ...prevState,
                isLoading: false
            };
        });

    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: FormState) => {
        // tslint:disable-next-line: no-console
        // console.log('submit', values);
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true,
            };
        });

        client
        .mutate({
            mutation: UpdateEnquiryDefaultConfigMutation,
            variables: {
                isManualEntry: values.dueDateOption === 'isManualEntry',
                isCalculatedDays: values.dueDateOption === 'isCalculatedDays',
                isCalculatedHours: values.dueDateOption === 'isCalculatedHours',
                timeOfDay: moment(values.timeOfDay).format('HH:mm'),
                addDays: values.addDays,
                addHours: values.addHours,
                defaultMeetingLength: moment(values.defaultMeetingLength).format('HH:mm'),
                reminder: values.reminder ? values.reminder.value : 'None',
                overdueReminder: values.overdueReminder ? values.overdueReminder.value : 'None',
                isSendReminderOnWeekend: values.isSendReminderOnWeekend,
                isSendInvitationToAssignee: values.isSendInvitationToAssignee,
                isSendInvitationToEnquirer: values.isSendInvitationToEnquirer,
                isSendReminderOnWeekendForOverDueReminders: values.isSendReminderOnWeekendForOverDueReminders,
                isLocationFieldMandatory: values.isLocationFieldMandatory,
                isSendFistUnassignedReminderOnWeekends: values.isSendFistUnassignedReminderOnWeekends,
                isSendUnassignedAfterFirstReminderOnWeekends: values.isSendUnassignedAfterFirstReminderOnWeekends,
                periodAfterFirstUnassignedReminder: values.periodAfterFirstUnassignedReminder ? values.periodAfterFirstUnassignedReminder.value : 'None',
                periodBeforeFirstUnassignedReminder: values.periodBeforeFirstUnassignedReminder ? values.periodBeforeFirstUnassignedReminder.value : 'None',
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info');  
                setState((_prevState) => {
                    return {
                        ..._prevState,
                        onSubmitLoading: false
                    };
                });   

                // reset the field and set to the current changes after saved.
                form.setConfig('keepDirtyOnReinitialize', false);
                form.batch(() => {
                    form.reset(values);
                });
                form.setConfig('keepDirtyOnReinitialize', true);
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update enquiry default', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    // tslint:disable-next-line: no-any
    const RadioComp = (compProps: any) => {

        const { input } = compProps;        
        return (
            <Radio
                {...input}
                checkedIcon={<RadioButtonCheckedIcon className={classes.radioIcon} />}
            />
        );
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={classes.root}>
                {state.isLoading ? (
                    <div className={classes.loaderWrapper}>
                        <RvLoader />
                    </div>
                ) : (
                    <Form
                        onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}
                        initialValues={formState}
                        initialValuesEqual={() => true}
                        keepDirtyOnReinitialize={true}
                        subscription={{ submitting: true, pristine: true }}
                        validate={(values: FormState) => {
                            let errors = {
                                addDays: '',
                                addHours: '',
                            };

                            if (values.addDays && values.addDays < 0) {
                                errors.addDays = 'Negative value is not allowed';
                            }

                            if (values.addHours && values.addHours < 0) {
                                errors.addHours = 'Negative value is not allowed';
                            }

                            if (errors.addDays === '' && errors.addHours === '') {
                                return undefined;
                            }

                            return errors;
                        }}
                        // debug={(values) => {
                        //     // tslint:disable-next-line: no-console
                        //     console.log('debug', values);
                        // }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit}>
                                <FormSpy
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={(props) => {
                                        onFormValueChanged(props);
                                    }}
                                />

                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                                <Typography className={classes.heading}>Due Dates Default</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.accordionDetails}>
                                                <Alert severity="info">All fields are optional. If none are provided, the RedView defaults are used</Alert>
                                                <FormControl component="div" className={classes.formControl}>
                                                    <FormLabel component="legend">Due Date Options</FormLabel>
                                                    <RadioGroup className={classes.radioGroup}>
                                                        <FormControlLabel
                                                            control={<Field name="dueDateOption" component={RadioComp} type="radio" />}
                                                            value={'isManualEntry'}
                                                            label="Manual Entry"
                                                        />
                                                        <FormControlLabel
                                                            control={<Field name="dueDateOption" component={RadioComp} type="radio" />}
                                                            value={'isCalculatedDays'}
                                                            label="Calculated Days"
                                                        />
                                                        <FormControlLabel
                                                            control={<Field name="dueDateOption" component={RadioComp} type="radio" />}
                                                            value={'isCalculatedHours'}
                                                            label="Calculated Hours"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                                <FormSpy subscription={{ values: true, validating: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        <>
                                                            {values.dueDateOption === 'isManualEntry' && (
                                                                <div className={classes.optionContainer}>
                                                                    <Field
                                                                        name="timeOfDay"
                                                                        allowNull={true}
                                                                        // validate={form.getFieldState('timeOfDay')?.value !== null ? required : undefined}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <KeyboardTimePicker
                                                                                {...input}
                                                                                label="Default Time"
                                                                                error={meta.error && meta.touched}
                                                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                                                size="small"
                                                                                variant="inline"
                                                                                keyboardIcon={<ScheduleIcon />}
                                                                                InputLabelProps={{ shrink: true }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                            )}
                                                            {values.dueDateOption === 'isCalculatedDays' && (
                                                                <div className={classes.optionContainer}>
                                                                    <div className={classes.optionList}>
                                                                        <span className="text">Add</span>
                                                                        <Field name="addDays" allowNull={true}>
                                                                            {({ input, meta }) => (
                                                                                <TextField
                                                                                    {...input}
                                                                                    label="Days"
                                                                                    error={meta.error && meta.touched}
                                                                                    helperText={meta.error && meta.touched ? meta.error : ''}
                                                                                    inputProps={{
                                                                                        pattern: '[0-9]*',
                                                                                    }}
                                                                                    type="number"
                                                                                    size="small"
                                                                                    variant="outlined"
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <span className="text">of working days to the current/time</span>
                                                                    </div>
                                                                    <div className={classes.optionList}>
                                                                        <span className="text">Optionally set time to </span>
                                                                        <Field
                                                                            name="timeOfDay"
                                                                            allowNull={true}
                                                                            // validate={form.getFieldState('timeOfDay')?.value !== null ? required : undefined}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <KeyboardTimePicker
                                                                                    {...input}
                                                                                    label="Time of day"
                                                                                    error={meta.error && meta.touched}
                                                                                    helperText={meta.error && meta.touched ? meta.error : ''}
                                                                                    size="small"
                                                                                    variant="inline"
                                                                                    InputLabelProps={{ shrink: true }}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {values.dueDateOption === 'isCalculatedHours' && (
                                                                <div className={classes.optionContainer}>
                                                                    <div className={classes.optionList}>
                                                                        <span>Add</span>
                                                                        <Field
                                                                            name="addHours"
                                                                            allowNull={true}
                                                                            // validate={form.getFieldState('addHours')?.value !== null ? required : undefined}
                                                                        >
                                                                            {({ input, meta }) => (
                                                                                <TextField
                                                                                    {...input}
                                                                                    label="hours"
                                                                                    error={meta.error && meta.touched}
                                                                                    helperText={meta.error && meta.touched ? meta.error : ''}
                                                                                    inputProps={{
                                                                                        pattern: '[0-9]*',
                                                                                    }}
                                                                                    type="number"
                                                                                    size="small"
                                                                                    variant="outlined"
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <span>to the current date/time</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </FormSpy>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    <Grid item={true} xs={12} md={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                                <Typography className={classes.heading}>Follow Up Reminders</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.accordionDetails}>
                                                <Grid container={true} spacing={3}>
                                                    <Grid md={12} item={true}>
                                                        <Typography>These fields define when reminders will be sent for enquiries.</Typography>
                                                    </Grid>
                                                    <Grid md={12} item={true}>
                                                        <div className={classes.optionWrapper}>
                                                            <div className={classes.optionContainer}>
                                                                <div className={classes.optionList}>
                                                                    <span className="text">Set default reminder to</span>
                                                                    <Field
                                                                        name="reminder"
                                                                        allowNull={true}
                                                                        // validate={form.getFieldState('reminder')?.value !== null ? required : undefined}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <ReminderSelector
                                                                                {...input}
                                                                                // label="Reminder"
                                                                                className={classes.reminderTextField}
                                                                                onSelection={(selection: IAutoCompleteItem) => {
                                                                                    input.onChange(selection);
                                                                                }}
                                                                                error={meta.error && meta.touched}
                                                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                                                size="small"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <span className="text">before follow up due</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <FormGroup className={classes.radioGroup}>
                                                                    <Field name="isSendReminderOnWeekend" subscription={{ touched: true, error: true, value: true }}>
                                                                        {({ input, meta }) => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...input}
                                                                                        checked={input.value}
                                                                                        onChange={input.onChange}
                                                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                    />
                                                                                }
                                                                                label="Send on Weekends"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid md={12} item={true}>
                                                        <div className={classes.optionWrapper}>
                                                            <div className={classes.optionContainer}>
                                                                <div className={classes.optionList}>
                                                                    <span className="text">Send repeat reminders every</span>
                                                                    <Field
                                                                        name="overdueReminder"
                                                                        allowNull={true}
                                                                        // validate={form.getFieldState('overdueReminder')?.value !== null ? required : undefined}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <ReminderSelector
                                                                                {...input}
                                                                                // label="Overdue Reminder"
                                                                                className={classes.reminderTextField}
                                                                                onSelection={(selection: IAutoCompleteItem) => {
                                                                                    input.onChange(selection);
                                                                                }}
                                                                                error={meta.error && meta.touched}
                                                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                                                size="small"
                                                                                isNoZeroMinutes={true}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <span className="text">after follow up is due</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <FormGroup className={classes.radioGroup}>
                                                                    <Field
                                                                        name="isSendReminderOnWeekendForOverDueReminders"
                                                                        subscription={{ touched: true, error: true, value: true }}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...input}
                                                                                        checked={input.value}
                                                                                        onChange={input.onChange}
                                                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                    />
                                                                                }
                                                                                label="Send on Weekends"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    <Grid item={true} xs={12} md={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                                <Typography className={classes.heading}>Unassigned Enquiry Notification</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.accordionDetails}>
                                                <Grid container={true} spacing={3}>
                                                    <Grid md={12} item={true}>
                                                        <Typography>
                                                            These fields define when notifications will be sent for enquiries that remain unassigned. The delivery rules for the
                                                            notifications are defined in Email Templates &gt; Unassigned Enquiry Notification
                                                        </Typography>
                                                    </Grid>
                                                    <Grid md={12} item={true}>
                                                        <div className={classes.optionWrapper}>
                                                            <div className={classes.optionContainer}>
                                                                <div className={classes.optionList}>
                                                                    <span className="text">Send your notification</span>
                                                                    <Field
                                                                        name="periodBeforeFirstUnassignedReminder"
                                                                        allowNull={true}
                                                                        // validate={form.getFieldState('reminder')?.value !== null ? required : undefined}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <ReminderSelector
                                                                                {...input}
                                                                                // label="Reminder"
                                                                                className={classes.reminderTextField}
                                                                                onSelection={(selection: IAutoCompleteItem) => {
                                                                                    input.onChange(selection);
                                                                                }}
                                                                                error={meta.error && meta.touched}
                                                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                                                size="small"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <span className="text">after remaining unassigned</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <FormGroup className={classes.radioGroup}>
                                                                    <Field name="isSendFistUnassignedReminderOnWeekends" subscription={{ touched: true, error: true, value: true }}>
                                                                        {({ input, meta }) => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...input}
                                                                                        checked={input.value}
                                                                                        onChange={input.onChange}
                                                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                    />
                                                                                }
                                                                                label="Send on Weekends"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                    <Grid md={12} item={true}>
                                                        <div className={classes.optionWrapper}>
                                                            <div className={classes.optionContainer}>
                                                                <div className={classes.optionList}>
                                                                    <span className="text">Send repeat reminders every</span>
                                                                    <Field
                                                                        name="periodAfterFirstUnassignedReminder"
                                                                        allowNull={true}
                                                                        // validate={form.getFieldState('overdueReminder')?.value !== null ? required : undefined}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <ReminderSelector
                                                                                {...input}
                                                                                // label="Overdue Reminder"
                                                                                className={classes.reminderTextField}
                                                                                onSelection={(selection: IAutoCompleteItem) => {
                                                                                    input.onChange(selection);
                                                                                }}
                                                                                error={meta.error && meta.touched}
                                                                                helperText={meta.error && meta.touched ? meta.error : ''}
                                                                                size="small"
                                                                                isNoZeroMinutes={true}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <span className="text">after the first notifications</span>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <FormGroup className={classes.radioGroup}>
                                                                    <Field
                                                                        name="isSendUnassignedAfterFirstReminderOnWeekends"
                                                                        subscription={{ touched: true, error: true, value: true }}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <FormControlLabel
                                                                                control={
                                                                                    <Checkbox
                                                                                        {...input}
                                                                                        checked={input.value}
                                                                                        onChange={input.onChange}
                                                                                        checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                    />
                                                                                }
                                                                                label="Send on Weekends"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>

                                    {/* <Grid item={true} xs={12} md={12}>
                                            <Accordion
                                                defaultExpanded={true}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                >
                                                    <Typography className={classes.heading}>Follow Up Reminder Default</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className={classes.accordionDetails}>
                                                    <Grid container={true} spacing={3}>
                                                        <Grid md={12} item={true}>
                                                            <FormGroup className={classes.radioGroup}>
                                                                <Field
                                                                    name="isSendReminderOnWeekend"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >                                            
                                                                    {({ input, meta }) => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    {...input}
                                                                                    checked={input.value}
                                                                                    onChange={input.onChange}
                                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                />
                                                                            }
                                                                            label="Send reminder on weekend" 
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid md={12} item={true}>
                                                            <div className={classes.optionContainer}>
                                                                <div className={classes.optionList}>
                                                                    <span className="text">Send reminder</span>
                                                                    <Field
                                                                        name="reminder"
                                                                        allowNull={true}
                                                                        // validate={form.getFieldState('reminder')?.value !== null ? required : undefined}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <ReminderSelector
                                                                                {...input}
                                                                                // label="Reminder" 
                                                                                className={classes.reminderTextField}
                                                                                onSelection={(selection: IAutoCompleteItem) => {
                                                                                    input.onChange(selection);
                                                                                }}
                                                                                error={meta.error && meta.touched}
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? meta.error
                                                                                        : ''
                                                                                }
                                                                                size="small"
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <span className="text">before follow up due</span>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid> */}

                                    {/* <Grid item={true} xs={12} md={12}>
                                            <Accordion
                                                defaultExpanded={true}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                >
                                                    <Typography className={classes.heading}>Follow Up Overdue Reminder Default</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails className={classes.accordionDetails}>
                                                    <Grid container={true} spacing={3}>
                                                        <Grid md={12} item={true}>
                                                            <FormGroup className={classes.radioGroup}>
                                                                <Field
                                                                    name="isSendReminderOnWeekendForOverDueReminders"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >                                            
                                                                    {({ input, meta }) => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    {...input}
                                                                                    checked={input.value}
                                                                                    onChange={input.onChange}
                                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                />
                                                                            }
                                                                            label="Send reminder on weekend" 
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </Grid>
                                                        <Grid item={true} md={12}>
                                                            <div className={classes.optionContainer}>
                                                                <div className={classes.optionList}>
                                                                    <span className="text">Send overdue reminder</span>
                                                                    <Field
                                                                        name="overdueReminder"
                                                                        allowNull={true}
                                                                        // validate={form.getFieldState('overdueReminder')?.value !== null ? required : undefined}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <ReminderSelector
                                                                                {...input}
                                                                                // label="Overdue Reminder" 
                                                                                className={classes.reminderTextField}
                                                                                onSelection={(selection: IAutoCompleteItem) => {
                                                                                    input.onChange(selection);
                                                                                }}
                                                                                error={meta.error && meta.touched}
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? meta.error
                                                                                        : ''
                                                                                }
                                                                                size="small"
                                                                                isNoZeroMinutes={true}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <span className="text">after follow up due</span>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid> */}

                                    <Grid item={true} xs={12} md={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                                <Typography className={classes.heading}>Meeting Defaults</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.accordionDetails}>
                                                <Grid container={true} spacing={3}>
                                                    <Grid md={12} item={true}>
                                                        <FormGroup className={classes.radioGroup}>
                                                            <Field name="isSendInvitationToAssignee" subscription={{ touched: true, error: true, value: true }}>
                                                                {({ input, meta }) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...input}
                                                                                checked={input.value}
                                                                                onChange={input.onChange}
                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                            />
                                                                        }
                                                                        label="Send confirmation email to staff member"
                                                                    />
                                                                )}
                                                            </Field>
                                                            <Field name="isSendInvitationToEnquirer" subscription={{ touched: true, error: true, value: true }}>
                                                                {({ input, meta }) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...input}
                                                                                checked={input.value}
                                                                                onChange={input.onChange}
                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                            />
                                                                        }
                                                                        label="Send confirmation email to enquirer"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Grid>
                                                    <Grid md={12} item={true}>
                                                        <div className={classes.optionContainer}>
                                                            <div className={classes.optionList}>
                                                                <span className="text">Default meeting length</span>
                                                                <Field
                                                                    name="defaultMeetingLength"
                                                                    allowNull={true}
                                                                    // validate={form.getFieldState('defaultMeetingLength')?.value !== null ? required : undefined}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <KeyboardTimePicker
                                                                            {...input}
                                                                            error={meta.error && meta.touched}
                                                                            helperText={meta.error && meta.touched ? meta.error : ''}
                                                                            variant="inline"
                                                                            size="small"
                                                                            format="HH:mm"
                                                                            InputLabelProps={{ shrink: true }}
                                                                            ampm={false}
                                                                            keyboardIcon={<ScheduleIcon />}
                                                                            required={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                                <Typography className={classes.heading}>Mandatory Fields</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className={classes.accordionDetails}>
                                                <Grid container={true} spacing={3}>
                                                    <Grid md={12} item={true}>
                                                        <FormGroup className={classes.radioGroup}>
                                                            <Field name="isLocationFieldMandatory" subscription={{ touched: true, error: true, value: true }}>
                                                                {({ input, meta }) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...input}
                                                                                checked={input.value}
                                                                                onChange={input.onChange}
                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                            />
                                                                        }
                                                                        label="Location"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button variant="outlined" color="primary" type="submit" disabled={submitting || pristine || state.onSubmitLoading}>
                                                Save
                                            </Button>
                                            <Button variant="outlined" color="primary" type="button" disabled={submitting || pristine} onClick={onReset(form)}>
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                                    {/* {printJson(values)} */}
                                </Grid>
                            </form>
                        )}
                    />
                )}
            </div>
        </MuiPickersUtilsProvider>
    );
}

// tslint:disable-next-line: no-any
// export function printJson(values: any) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }