import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { showNotification } from '../../App';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import gql from 'graphql-tag';
import { client } from '../..';
import { FetchPolicy } from 'apollo-client';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        minWidth: '200px',
    },
  })
);

interface TimesheetActivitiesSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: IAutoCompleteItem | IAutoCompleteItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;
}

interface TimesheetActivitiesSelectorState {
    isLoading: boolean;
    options: IAutoCompleteItem[];
}

export const TimesheetActivitiesSelector: React.FC<TimesheetActivitiesSelectorProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<TimesheetActivitiesSelectorState>({
        isLoading: false,
        options: [],
    });

    useEffect(() => {   
        fetchData();
    // tslint:disable-next-line: align
    }, []);

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true
        });

        const timesheetActivitiesParams: TimesheetActivitiesParams = {
            clientOnly: false
        };

        retrieveTimesheetActivitiesData(
            timesheetActivitiesParams,
            false, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveEnquiryStatus(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveEnquiryStatus = (data: TimesheetActivitiesData) => {
        setState({
            ...state,
            options: data.timesheetActivities.activities.map( (source: Activity) => ({value: source.stringValue, label: source.stringValue })),
            isLoading: false
        });
    };

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        // tslint:disable-next-line: no-console
        console.log('onChange', value);
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'office');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            { props.multiple ? 
                (
                    <Autocomplete
                        value={props.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}        
                        getOptionSelected={(option, value) => value.value === option.value}            
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Timesheet Activity'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        value={props.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}         
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Office'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}                            
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </div>
    );
};

export function retrieveTimesheetActivitiesData(
    params: TimesheetActivitiesParams,
    refreshData: boolean,
    onSuccess: (data: TimesheetActivitiesData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void  {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: TimesheetActivitiesQuery,
            variables: {
                clientOnly: params.clientOnly
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface TimesheetActivitiesParams {
    clientOnly: boolean;
}

export interface TimesheetActivitiesData {
    timesheetActivities: TimesheetActivities;
}

export interface TimesheetActivities {
    activities: Activity[];
}

export interface Activity {
    id:             number;
    stringValue:    string;
    isVIP:          boolean;
    isFirmActivity: boolean;
}

export const TimesheetActivitiesQuery = gql`
    query timesheetActivities {
        timesheetActivities(clientOnly: false) {
            activities {
                id
                stringValue
                isVIP
                isFirmActivity
            }
        }
    }
`;