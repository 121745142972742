import * as React from 'react';
import { FeatureLayoutConfiguration, FeatureSearchChanges } from './FeatureLayoutConfigurationRepository';
import { DragDropContext }  from 'react-beautiful-dnd';
import { FeatureLayoutConfigurationDroppable } from './FeatureLayoutConfigurationDroppable';

export interface DroppableRows {
    droppableRow: DroppableRow[];
}

export interface DroppableRow {
    id: string;
    featureList: FeatureLayoutConfiguration[];
}

interface FeatureLayoutConfigurationListProps  {
    featureLayoutConfigurationList: FeatureLayoutConfiguration[];
    isSearch: boolean; // When the List is filtered using search
    onDragEnd?: (featureLayoutConfigurationList: FeatureLayoutConfiguration[] ) => void;
    isDragDisabled?: boolean;
    isHideAllOperation: boolean;
    isSearchChanged: boolean;
    onVisibilityChanged?: () => void;
    isInternalAdmin: boolean;
}

interface FeatureLayoutConfigurationListState {
    windowInnterWidth: number;
    itemsPerRow: number;
    isLoading: boolean;
    featureLayoutConfigurationList: FeatureLayoutConfiguration[];
    droppableRows: DroppableRows;    
    featureSearchChanges: FeatureSearchChanges[];
    isSearch: boolean;
}

export class FeatureLayoutConfigurationList extends React.Component<FeatureLayoutConfigurationListProps, FeatureLayoutConfigurationListState>  {
    constructor(props: FeatureLayoutConfigurationListProps, state: FeatureLayoutConfigurationListState) {
        super(props, state);

        this.onDragEnd = this.onDragEnd.bind(this);
        this.constructRows = this.constructRows.bind(this);
        this.getDroppable = this.getDroppable.bind(this);
        this.onIsHiddenUpdated = this.onIsHiddenUpdated.bind(this);
        this.gridBreakPoints = this.gridBreakPoints.bind(this);

        this.state = {
            itemsPerRow: this.gridBreakPoints(window.innerWidth), // Math.floor(window.innerWidth / 450),
            windowInnterWidth: window.innerWidth,
            isLoading: false,
            featureLayoutConfigurationList: [],
            droppableRows: { droppableRow: [] },
            featureSearchChanges: [],
            isSearch: false
        };
    }

    public UNSAFE_componentWillReceiveProps(nextProps: FeatureLayoutConfigurationListProps) {
        if (this.state.featureLayoutConfigurationList !== nextProps.featureLayoutConfigurationList ||
            this.props.isSearch !== nextProps.isSearch) {
            this.constructRows(nextProps.featureLayoutConfigurationList.slice(), false, nextProps.isSearch, false, nextProps.isHideAllOperation);
        }

        if (nextProps.isSearchChanged === true) {
            this.setState({
                featureSearchChanges: []
            });
        }
    }

    public shouldComponentUpdate(nextProps: FeatureLayoutConfigurationListProps) {
        // Prevent render if the list is same
        if (nextProps.featureLayoutConfigurationList === this.state.featureLayoutConfigurationList) {
            return false;
        }

        return true;
    }

    public componentDidMount() {
        this.setState({
          windowInnterWidth: window.innerWidth
        });
        window.addEventListener('resize', this.updateDimensions.bind(this));
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }  

    public render() { 

        // const droppables = this.getDroppable();

        return (            
            <DragDropContext
                onDragEnd={this.onDragEnd}                    
            >  
                {/* {droppables} */}
                <React.Fragment>
                    {this.state.droppableRows.droppableRow.map((droppableRow, index) =>
                        <FeatureLayoutConfigurationDroppable
                            key={index}
                            featureLayoutConfigurationList={droppableRow.featureList}
                            droppableId={droppableRow.id}
                            onIsHiddenUpdated={this.onIsHiddenUpdated}
                            itemsPerRow={this.state.itemsPerRow}
                            windowInnterWidth={this.state.windowInnterWidth}
                            isDragDisabled={this.props.isDragDisabled}
                            isInternalAdmin={this.props.isInternalAdmin}
                        />          
                    )}  
                </React.Fragment>   
            </DragDropContext>                   
        );
    }
    
    private gridBreakPoints(width: number) {
        if (width >= 1280) {
            return 4;
        } else if (width >= 960) {
            return 3;
        } else if (width >= 600) {
            return 2;
        } else {
            return 1;
        }
    }

    private updateDimensions() {
        this.setState({
            windowInnterWidth: window.innerWidth,
            itemsPerRow: this.gridBreakPoints(window.innerWidth) // Math.floor(window.innerWidth / 450)
        });
        
        this.constructRows(this.state.featureLayoutConfigurationList, true, false, false, false);
    }

    private getDroppable() {
        return( 
            <React.Fragment>
                {this.state.droppableRows.droppableRow.map((droppableRow, index) =>
                    <FeatureLayoutConfigurationDroppable
                        key={index}
                        featureLayoutConfigurationList={droppableRow.featureList}
                        droppableId={droppableRow.id}
                        onIsHiddenUpdated={this.onIsHiddenUpdated}
                        itemsPerRow={this.state.itemsPerRow}
                        windowInnterWidth={this.state.windowInnterWidth}
                        isInternalAdmin={this.props.isInternalAdmin}
                    />          
                )}  
            </React.Fragment>       
        );
    }

    private constructRows(featureLayoutConfigurationList: FeatureLayoutConfiguration[], isReOrder: boolean, 
                          isSearch?: boolean, isHiddenUpdated?: boolean, isHideAllOperation?: boolean) {
        let featureLayoutConfigurationListCopy: FeatureLayoutConfiguration[] = featureLayoutConfigurationList;

        const featureSearchChanges = this.state.featureSearchChanges;

        // If we already had previous record in search, then apply these changes back to featureLayoutChanges
        if (this.state.featureSearchChanges.length > 0 && featureLayoutConfigurationList.length > 0 &&
            isHiddenUpdated === false && isHideAllOperation === false) {
            this.state.featureSearchChanges.forEach((featureChange: FeatureSearchChanges) => {
                featureLayoutConfigurationListCopy.forEach((feature: FeatureLayoutConfiguration) => {
                    if (feature.featureGuid.toLowerCase() === featureChange.featureGuid.toLowerCase()) {
                        feature.isHidden = featureChange.isHidden;
                    }
                });               
            });
        }

        // Construct Rows. 5 Items per Row.
        let rowNumber = 1;
        let droppableRows: DroppableRows = { droppableRow: [] };

        for (let index = 0; index < featureLayoutConfigurationListCopy.length; index++) {
            // Check the count of featureList. if it is 5, create new row
            let lastDroppableRow = droppableRows.droppableRow[droppableRows.droppableRow.length - 1];

            if (lastDroppableRow !== undefined 
                && lastDroppableRow.featureList.length === this.state.itemsPerRow) {
                rowNumber = rowNumber + 1; // Increase to next row.

                // Create a new Row
                droppableRows.droppableRow.push({
                    id: 'row-' + rowNumber.toString(),
                    featureList: []
                });
            }

            if (lastDroppableRow === undefined) {
                droppableRows.droppableRow.push({
                     id: 'row-' + rowNumber.toString(),
                     featureList: []
                 });
             }

            lastDroppableRow = droppableRows.droppableRow[droppableRows.droppableRow.length - 1];
            lastDroppableRow.featureList.push(featureLayoutConfigurationListCopy[index]);

            // if (featureLayoutConfigurationListCopy[index].feature.toLowerCase() === ('FeesBilledByPeriodVsBudgetChart').toLowerCase()) {
            //     // tslint:disable-next-line: no-console
            //     console.log('Feature', featureLayoutConfigurationListCopy[index]);
            // }
        }

        // re-order featureLayoutConfigurationList
        let featureLayoutConfigList: FeatureLayoutConfiguration[] = [];
        if (isReOrder) {           
            let newdroppableRowList = Array.from(droppableRows.droppableRow); 

            let sequence: number = 1;
            newdroppableRowList.forEach(droppableRow => {
                droppableRow.featureList.forEach(featureLayout => {   
                    featureLayout.sequence = sequence;    
                    featureLayoutConfigList.push(featureLayout);
                    sequence = sequence + 1;
                });                
            });
        }

        // Set hidden list, and maintain the feature hidden flags. This can be used again when we are using search
        if (isSearch !== undefined && isSearch === false && featureLayoutConfigurationListCopy.length > 0) {
            
            featureLayoutConfigurationListCopy.forEach((item: FeatureLayoutConfiguration) => {
                let featureSearchChange = featureSearchChanges.find((featureItem) => {
                    return featureItem.featureGuid.toLowerCase() === item.featureGuid.toLowerCase();
                });

                if (featureSearchChange === undefined) {
                    featureSearchChanges.push({
                        feature: item.feature,
                        featureGuid: item.featureGuid,
                        isHidden: item.isHidden,
                        sequence: item.sequence
                    });
                } else {
                    featureSearchChange.isHidden = item.isHidden;
                }
            });

            // const feesBilledByPeriodVsBudgetChartGuid = 
            // featureSearchChanges.find(feature => feature.featureGuid.toLowerCase() === ('C4A8B6C2-3D48-4BC5-8BF7-DB4B8AF8E118').toLowerCase());
            // // tslint:disable-next-line: no-console
            // console.log('Feature Inside Push Guid', feesBilledByPeriodVsBudgetChartGuid);   

            // const feesBilledByPeriodVsBudgetChart = 
            // featureSearchChanges.find(feature => feature.feature.toLowerCase() === ('FeesBilledByPeriodVsBudgetChart').toLowerCase());
            
            // // tslint:disable-next-line: no-console
            // console.log('Feature Inside Push', feesBilledByPeriodVsBudgetChart);            

            this.setState({
                droppableRows: droppableRows,
                featureLayoutConfigurationList: isReOrder ? featureLayoutConfigList : featureLayoutConfigurationListCopy,
                featureSearchChanges: featureSearchChanges,
                isSearch: isSearch
            });
        } else {
            featureLayoutConfigurationListCopy.forEach((item: FeatureLayoutConfiguration) => {
                featureSearchChanges.forEach(featureSearch => {
                    if (featureSearch.featureGuid.toLowerCase() === item.featureGuid.toLowerCase()) {
                        featureSearch.isHidden = item.isHidden;
                    }
                });
            });

            const feesBilledByPeriodVsBudgetChart = featureSearchChanges.find(feature => feature.feature.toLowerCase() === ('FeesBilledByPeriodVsBudgetChart').toLowerCase());
            
            // tslint:disable-next-line: no-console
            console.log('Feature Inside Update', feesBilledByPeriodVsBudgetChart);

            this.setState({
                droppableRows: droppableRows,
                featureLayoutConfigurationList: isReOrder ? featureLayoutConfigList : featureLayoutConfigurationListCopy,
                featureSearchChanges: featureSearchChanges,
                isSearch: isSearch === undefined ? false : isSearch
            });
        }        

        if (isReOrder) {
            if (this.props.onDragEnd) {
                this.props.onDragEnd(featureLayoutConfigurationListCopy);
            }
        }
    }
 
    // tslint:disable-next-line: no-any
    private onDragEnd(result: any) {
        const {destination, source, draggableId} = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        // Get current source object & with draggable feature
        const sourceDroppable = this.state.droppableRows.droppableRow.filter(x => x.id === source.droppableId);
        const draggableFeatures = sourceDroppable[0].featureList.filter( x => x.featureGuid === draggableId);
        const draggableFeature = draggableFeatures[0];

        // Get destination object
        const destinationDroppable = this.state.droppableRows.droppableRow.filter(x => x.id === destination.droppableId);

        // Remove from current source
        sourceDroppable[0].featureList.splice(source.index, 1); // Remove the draggable from it's previous location

        // Insert the draggable in the new destination
        destinationDroppable[0].featureList.splice(destination.index, 0, draggableFeature);

        // Select all features from rows into a object
        let featureLayoutConfigurationList: FeatureLayoutConfiguration[] = [];

        this.state.droppableRows.droppableRow.forEach(droppableRow => {
            droppableRow.featureList.forEach(featureLayout => {
                featureLayoutConfigurationList.push(featureLayout);
            });            
        });

        // call constuctRows again to order
        this.constructRows(featureLayoutConfigurationList, true, false, false, false);
    }

    private onIsHiddenUpdated(featureGuid: string, isHidden: boolean) {
        if (this.state.featureLayoutConfigurationList !== undefined) {
            let newfeatureLayoutConfigurationList = this.state.featureLayoutConfigurationList;
            let featureLayout = this.state.featureLayoutConfigurationList.filter(x => x.featureGuid === featureGuid);

            if (featureLayout.length > 0) {
                featureLayout[0].isHidden = isHidden;

                this.setState({
                    featureLayoutConfigurationList: newfeatureLayoutConfigurationList
                });
            }

            this.constructRows(this.state.featureLayoutConfigurationList, false, false, true, false);

            if (this.props.onVisibilityChanged) {
                this.props.onVisibilityChanged();
            }
        }
    }
}