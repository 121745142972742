/* eslint-disable react-hooks/exhaustive-deps */
import { Button, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FormApi } from 'final-form';
import React, { useEffect, useState } from 'react';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { client } from '../..';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { retrieveEmailConfigData, SystemSettingsNamesData, UpdateEmailConfigMutation } from '../FirmSettingConfigurationRepository';
import { UserFeature, UserFeatureEnum } from '../../types/UserFeature';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        }
    })
);

interface FormState {
    fromName: string | null;
    replyTo: string | null;
    sendEmailOnBehalfOf: string | null;
    confirmationBcc: string | null;
}
interface EmailingTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

export default function EmailingTab() {

    const classes = useStyles();

    const [state, setState] = useState<EmailingTabState>({
        isLoading: true,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState, setFormState] = useState<FormState>({
        fromName: null,
        replyTo: null,
        sendEmailOnBehalfOf: null,
        confirmationBcc: null
    });

    useEffect(() => {
        fetchData();
    // tslint:disable-next-line: align
    }, []);
    
    useEffect(() => {
        fetchData();
    // tslint:disable-next-line: align
    }, []);
    
    const fetchData = () => {
        retrieveEmailConfigData(
            true,
            (data: SystemSettingsNamesData) => onEmailConfigRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onEmailConfigRetrieved = (data: SystemSettingsNamesData) => {
        if (data && data.settings && data.settings.systems && data.settings.systems.emailDefaults && data.settings.systems.emailDefaults.config) {
            
            const {fromName, sendEmailOnBehalfOf, replyTo, confirmationBcc} = data.settings.systems.emailDefaults.config;
            
            setFormState((prevFormState) => {
                return {
                    ...prevFormState,
                    fromName: fromName,
                    sendEmailOnBehalfOf: sendEmailOnBehalfOf,
                    confirmationBcc: confirmationBcc,
                    replyTo: replyTo,
                };
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false
                };
            });
        }
    };

    // const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     const name = event.target.name;

    //     setState((prevState) => {
    //         return {
    //             ...prevState,
    //             [name]: value
    //         };
    //     });
        
    // };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {

        setState((_prevState) => {
            return {
                ..._prevState,
                onSubmitLoading: true
            };
        });

        client
            .mutate({
                mutation: UpdateEmailConfigMutation,
                variables: {
                    sendEmailOnBehalfOf: values.sendEmailOnBehalfOf,
                    fromName: values.fromName,
                    replyTo: values.replyTo,
                    confirmationBcc: values.confirmationBcc
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    showNotification(null, 'Successfully submitted', 'info');   
                    setState((_prevState) => {
                        return {
                            ..._prevState,
                            onSubmitLoading: false
                        };
                    });  
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to update firm details', reason, 'error');
                setState((_prevState) => {
                    return {
                        ..._prevState,
                        onSubmitLoading: false
                    };
                });
            });
    };

    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{
                        submitting: true,
                        pristine: true
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values
                    }) => (
                            <form onSubmit={handleSubmit}>
                                <FormSpy 
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={12}>
                                        <Alert severity="info">All fields are optional. If none are provided, the RedView defaults are used</Alert>
                                    </Grid>
                                    {UserFeature.HasAccess(UserFeatureEnum.hasEmailSendOrReplyDefinition) ? (
                                        <Grid item={true} xs={12} md={12}>
                                            <Field
                                                name="sendEmailOnBehalfOf"
                                                // validate={required}
                                            >
                                                {({ input, meta }) => (
                                                    <TextField
                                                        {...input}
                                                        label="Send Email"
                                                        name="sendEmailOnBehalfOf"
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Send Email is required'
                                                                : 'Address to be sent on behalf of'
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    ) : null}
                                    <Grid item={true} xs={12} md={12}>
                                        <Field
                                            name="fromName"
                                            // validate={required}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="From Name"
                                                    name="fromName"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'From Name is required'
                                                            : 'The name of the sender to appear as from on emails'
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    {UserFeature.HasAccess(UserFeatureEnum.hasEmailSendOrReplyDefinition) ? (
                                        <Grid item={true} xs={12} md={12}>
                                            <Field
                                                name="replyTo"
                                                // validate={required}
                                            >
                                                {({ input, meta }) => (
                                                    <TextField
                                                        {...input}
                                                        label="Reply To Email"
                                                        name="replyTo"
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? 'Reply To Email is required'
                                                                : 'Reply are sent to this email address'
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    ) : null}
                                    <Grid item={true} xs={12} md={12}>
                                        <Field
                                            name="confirmationBcc"
                                            // validate={required}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Confirmation/Acknowledgement BCC"
                                                    name="confirmationBcc"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={

                                                            'Automatic BCC for confirmation/acknowledgement emails'
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || pristine || state.onSubmitLoading}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="button"
                                                disabled={submitting || pristine}
                                                onClick={onReset(form)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                                    {/* {printJson(values)} */}
                            </Grid>
                            </form>
                        )}
                />
            )}
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}