/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { FormApi } from 'final-form';
import { RvLoader } from '../../components/Loader';
import DialogBox from '../../Dashboard/Component/DialogBox';
// import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { convertToDraftWysiwygContent } from '../../helpers/commonHelper';
import { useUpdateFeatureMutation } from '../hooks/useUpdateFeatureMutation';
import { showNotification } from '../../App';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            },
        },
        header: {
            textTransform: 'capitalize',
        },
        content: {
            flex: 1,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        hidden: {
            display: 'none',
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '20px 0',
        },
        formLabel: {
            paddingBottom: '10px',
        },
        editorClassName: {
            padding: '0 10px',
            minHeight: '300px',
            maxHeight: '500px',
        },
        wrapperClassName: {
            border: '1px solid #949494',
            display: 'flex',
            flexFlow: 'column-reverse',
        },
        toolbarClassName: {
            border: 0,
            // borderBottom: '1px solid #F1F1F1',
            margin: 0,
            padding: 0,
            '& .rdw-dropdown-wrapper': {
                position: 'relative',
            },
            '& .rdw-dropdown-optionwrapper': {
                position: 'absolute',
            },
        },
        paperRoot: {
            overflow: 'visible',
            minHeight: 'calc(100vh - 50px)',
            display: 'flex',
            flexFlow: 'column',
        },
        dialogContent: {
            overflow: 'inherit',
            display: 'flex',
            flexFlow: 'column',
        },
        taskForm: {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
        }
    }),
);

interface FeatureNotesDialogProps {
    open: boolean;
    onClose?: () => void;
    title: string;
    description: string;
    featureGuid: string;
    feature?: string;
    infoUrl?: string | null;
}

export interface FeatureNotesDialogForm {
    notes: string; // EditorState;
}
  
export const FeatureNotesDialog: React.FC<FeatureNotesDialogProps> = (props) => {
    const classes = useStyles();

    const updateFeature = useUpdateFeatureMutation();
    
    const [isLoading] = useState<boolean>(false);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [showDiscard, setShowDiscard] = useState<boolean>(false);

    useEffect(() => {
        
        if (updateFeature.data && updateFeature.data.updateFeature && !updateFeature.loading && !updateFeature.error) {
            showNotification(null, 'Successfully submitted', 'info');
            if (props.onClose) {
                props.onClose();
            }
        }

        if (updateFeature.error) {
            showNotification('Failed to Save Enquiry', updateFeature.error.message, 'error');
        }

    },        [updateFeature.data, updateFeature.error, updateFeature.loading]);

    let tempDesription = props.description;

    if (props.description) {
        if (props.description.indexOf('blocks":') !== -1) {
            const draftContent = convertToDraftWysiwygContent(JSON.parse(props.description));
            const contentState = convertFromRaw(draftContent);
            tempDesription = draftToHtml(convertToRaw(EditorState.createWithContent(contentState).getCurrentContent()));
        } 
        
        // else {
        //     const contentBlock = htmlToDraft(props.description);

        //     if (contentBlock) {
        //         const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        //         editorState = EditorState.createWithContent(contentState);
        //     }
        // }
    }

    const initialValues: FeatureNotesDialogForm = {
        notes: tempDesription // draftToHtml(convertToRaw(editorState.getCurrentContent()))
    };

    const onSubmit = (form: FormApi<FeatureNotesDialogForm>, values: FeatureNotesDialogForm) => {

        // const notes = draftToHtml(convertToRaw(values.notes.getCurrentContent()));

        updateFeature.updateData({
            variables: {
                input: {
                    guid: props.featureGuid,
                    description: values.notes,
                }
            }
        });
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<FeatureNotesDialogForm>, changeProps: any) => {
        if (!changeProps.pristine) {
            setIsChanged(true);
        }
    };

    const onClose = () => {
        if (isChanged) {
            setShowDiscard(true);
        } else {
            if (props.onClose) {
                props.onClose();
            }
        }
    };

    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            if (props.onClose) {
                props.onClose();
            }
        }
        setShowDiscard(false);
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    // const onFocusEditor = (event: SyntheticEvent) => {
    //     setHideToolbar(false);
    // };

    // const onBlurEditor = (event: SyntheticEvent) => {
    //     setHideToolbar(true);
    // };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<FeatureNotesDialogForm>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: AnyObject,
    ) => {
        return (
            <div className={classes.buttonContainer}>
                {/* <RenderCount /> */}
                <div>
                    <Button
                        color="primary"
                        type="submit"
                        className={classes.button}
                        onClick={() => {
                            // code here..
                        }}
                        disabled={submitting || pristine}
                    >
                        Update
                    </Button>
                </div>
                <div>
                    <Button color="primary" type="button" className={classes.button} onClick={onClose}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <>
            <DialogBox
                title="Task Type"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to close the form?`}
                show={showDiscard}
                isAgree={onDiscardChanges}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
            />

            <Dialog
                open={props.open}
                onClose={onClose}
                maxWidth="md"
                fullWidth={true}
                className={classes.dialogRoot}
                scroll={'paper'}
                // disablePortal={false}
                disableScrollLock={true}
                disableEnforceFocus={true}
                disableAutoFocus={true}
                classes={{
                    paper: classes.paperRoot,
                }}
            >
                <DialogTitle className={classes.header}>{props.title}</DialogTitle>
                {isLoading ? (
                    <DialogContent dividers={true}>
                        <div className={classes.loaderWrapper}>
                            <RvLoader size="small" label="Loading..." />
                        </div>
                    </DialogContent>
                ) : (
                    <Form
                        onSubmit={(values, form: FormApi<FeatureNotesDialogForm>) => onSubmit(form, values)}
                        initialValues={initialValues}
                        initialValuesEqual={() => true}
                        keepDirtyOnReinitialize={true}
                        subscription={{ submitting: true, pristine: true }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit} id="taskForm" className={classes.taskForm}>
                                <FormSpy
                                    subscription={{ pristine: true, values: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={(props) => {
                                        onFormValueChanged(form, props);
                                    }}
                                />
                                <DialogContent dividers={true} className={classes.dialogContent}>
                                    <Grid container={true} spacing={3} style={{ flex: 1 }}>
                                        {/* <Grid item={true} xs={12} md={12}>
                                            <Field name="notes">
                                                {({ input, meta }) => (
                                                    <>
                                                        <FormLabel component="legend" className={classes.formLabel}>
                                                            Notes
                                                        </FormLabel>
                                                        <Editor
                                                            editorState={input.value}
                                                            // tslint:disable-next-line: no-console
                                                            // onFocus={onFocusEditor}
                                                            // onBlur={onBlurEditor}
                                                            // toolbarHidden={hideToolbar}
                                                            toolbarClassName={`${classes.toolbarClassName} toolbarClassName`}
                                                            wrapperClassName={`${classes.wrapperClassName} wrapperClassName`}
                                                            editorClassName={`${classes.editorClassName} editorClassName`}
                                                            onEditorStateChange={input.onChange}
                                                        />
                                                    </>
                                                )}
                                            </Field>
                                        </Grid> */}
                                        <Grid item={true} xs={12} md={12}>
                                            <Field name="notes">
                                                {({ input, meta }) => (
                                                    <TinyEditor
                                                        apiKey={'k74nddluc9fmtf75en31ew8mxzqcvovgpjkzomdtgeje7b0h'}
                                                        value={input.value}
                                                        // disabled={input.disabled}
                                                        init={{
                                                            height: '100%',
                                                            autoresize_min_height: '100%',
                                                            autoresize_max_height: '100%',
                                                            menubar: 'file edit view insert format tools table help custom',
                                                            imagetools_cors_hosts: ['picsum.photos'],
                                                            plugins:
                                                                'table code advtable lists fullscreen image media link preview export pagebreak lists advlist checklist charmap directionality',
                                                            toolbar:
                                                                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | ' +
                                                                'forecolor backcolor | alignleft aligncenter alignright alignjustify indent outdent rtl ltr | bullist numlist checklist | ' +
                                                                'emoticons image media table link hr charmap | preview | fullscreen | export pagebreak',
                                                            // plugins: [
                                                            //     'print preview paste importcss searchreplace autolink autosave save directionality code ' +
                                                            //     'visualblocks visualchars fullscreen image link media template codesample table charmap ' +
                                                            //     'hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools ' +
                                                            //     'textpattern noneditable help charmap quickbars emoticons'
                                                            // ],
                                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                            toolbar_sticky: true,
                                                            autosave_ask_before_unload: true,
                                                            autosave_interval: '30s',
                                                            autosave_prefix: '{path}{query}-{id}-',
                                                            autosave_restore_when_empty: false,
                                                            autosave_retention: '2m',
                                                            image_advtab: true,
                                                            content_css: '//www.tiny.cloud/css/codepen.min.css',
                                                            image_class_list: [
                                                                { title: 'None', value: '' },
                                                                { title: 'Some class', value: 'class-name' },
                                                            ],
                                                            importcss_append: true,
                                                            // tslint:disable-next-line: typedef
                                                            // file_picker_callback: function (callback, value, meta) {
                                                            //     /* Provide file and text for the link dialog */
                                                            //     if (meta.filetype === 'file') {
                                                            //       callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                                                            //     }

                                                            //     /* Provide image and alt text for the image dialog */
                                                            //     if (meta.filetype === 'image') {
                                                            //       callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                                                            //     }

                                                            //     /* Provide alternative source and posted for the media dialog */
                                                            //     if (meta.filetype === 'media') {
                                                            //       callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                                                            //     }
                                                            // },
                                                            templates: [
                                                                {
                                                                    title: 'New Table',
                                                                    description: 'creates a new table',
                                                                    content:
                                                                        '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>',
                                                                },
                                                                { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                                                                {
                                                                    title: 'New list with dates',
                                                                    description: 'New List with dates',
                                                                    content:
                                                                        '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>',
                                                                },
                                                            ],
                                                            template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                                                            template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                                                            image_caption: true,
                                                            quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                                            noneditable_noneditable_class: 'mceNonEditable',
                                                            toolbar_mode: 'sliding',
                                                            contextmenu: 'link image imagetools table',
                                                            // menu: {
                                                            //     custom: { title: 'Merge Fields', items: getMergeFieldItems() }
                                                            // },
                                                            // tslint:disable-next-line: no-any
                                                            // setup: function (editor: any) {
                                                            //     // var toggleState = false;
                                                            //     getMergeFieldMenuItems(editor);

                                                            //     // editor.ui.registry.addMenuItem('firstName', {
                                                            //     //     text: 'First Name',
                                                            //     //     onAction: function () {
                                                            //     //       editor.insertContent('{firstName}');
                                                            //     //     },
                                                            //     // });

                                                            //     // comment
                                                            //     // tslint:disable-next-line: no-any
                                                            //     var onAction = function (autocompleteApi: any, rng: any, value: any) {
                                                            //         editor.selection.setRng(rng);
                                                            //         editor.insertContent(value);
                                                            //         autocompleteApi.hide();
                                                            //     };

                                                            //     // tslint:disable-next-line: no-any
                                                            //     var getMatchedChars = function (pattern: any) {
                                                            //         const mergedFields = getMergeFields();
                                                            //         if (mergedFields) {
                                                            //             // tslint:disable-next-line: no-any
                                                            //             return mergedFields.filter(function (char: any) {
                                                            //                 if (pattern && char.text) {
                                                            //                     return char.text.toLowerCase().indexOf(pattern.toLowerCase()) !== -1;
                                                            //                 } else {
                                                            //                     return char.text.toLowerCase().indexOf(pattern) !== -1;
                                                            //                 }
                                                            //             });
                                                            //         } else {
                                                            //             return [];
                                                            //         }
                                                            //     };

                                                            //     /* An autocompleter that allows you to insert special characters */
                                                            //     editor.ui.registry.addAutocompleter('specialchars', {
                                                            //         ch: '{',
                                                            //         minChars: 0,
                                                            //         columns: 1,
                                                            //         highlightOn: ['char_name'],
                                                            //         onAction: onAction,
                                                            //         // tslint:disable-next-line: no-any
                                                            //         fetch: function (pattern: any) {
                                                            //             // tslint:disable-next-line: no-any
                                                            //             return new Promise(function (resolve: any) {
                                                            //                 // tslint:disable-next-line: no-any
                                                            //                 var results = getMatchedChars(pattern).map(function (char: any) {
                                                            //                     return {
                                                            //                         type: 'cardmenuitem',
                                                            //                         value: char.value,
                                                            //                         label: char.text,
                                                            //                         items: [
                                                            //                             {
                                                            //                                 type: 'cardcontainer',
                                                            //                                 direction: 'vertical',
                                                            //                                 items: [
                                                            //                                     {
                                                            //                                         type: 'cardtext',
                                                            //                                         text: char.text,
                                                            //                                         name: 'char_name'
                                                            //                                     },
                                                            //                                     // Don't display value of the merged field
                                                            //                                     // {
                                                            //                                     //     type: 'cardtext',
                                                            //                                     //     text: char.value
                                                            //                                     // }
                                                            //                                 ]
                                                            //                             }
                                                            //                         ]
                                                            //                     };
                                                            //                 });
                                                            //                 resolve(results);
                                                            //             });
                                                            //         }
                                                            //     });
                                                            // },
                                                        }}
                                                        onEditorChange={(content: string) => {
                                                            input.onChange(content);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>{actionButton(form, submitting, pristine, values)}</DialogActions>
                            </form>
                        )}
                    />
                )}
            </Dialog>
        </>
    );
};
