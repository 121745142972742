import { WatchQueryFetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

export const useUpdateFeatureMutation = (fetchPolicy?: WatchQueryFetchPolicy) => {

    const [updateData, updateResult] = useMutation<UpdateFeatureData, UpdateFeatureParams>(UpdateFeature);

    return {...updateResult, updateData};
};

export interface UpdateFeatureInput {
    guid: string | null;
    feature?: string | null;
    description: string | null;
    title?: string | null;
    infoUrl?: string | null;
}

export interface UpdateFeatureParams {
    input: UpdateFeatureInput;
}

export interface UpdateFeatureData {
    updateFeature: boolean;
}

const UpdateFeature = gql`
    mutation updateFeature($input: UpdateFeatureInput) {
        updateFeature(input: $input)
    }
`;