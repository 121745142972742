import * as React from 'react';
import { FeatureLayoutConfiguration } from './FeatureLayoutConfigurationRepository';
import FeatureLayoutCardDraggable from './FeatureLayoutCardDraggable';
import { Droppable, DroppableProvided, DroppableStateSnapshot, 
    DraggableRubric, DraggableStateSnapshot, DraggableProvided }  from 'react-beautiful-dnd';
import glamorous from 'glamorous';
import FeatureLayoutCardClone from './FeatureLayoutCardClone';

// tslint:disable-next-line: no-any
const FeatureListContainer = glamorous.div<{theme?: any}> ((props) => ({
}));

// tslint:disable-next-line: no-any
const FeatureList = glamorous.div<{theme?: any}> ((props) => ({
    flexGrow: 1,
    display: 'flex',
    // justifyContent: 'space-evenly',
    // flexFlow: 'row wrap',
}));

// tslint:disable-next-line: no-any
const CloneItem = glamorous.div<{theme?: any}> ((props) => ({
    display: 'flex',
    // flex: 1,
}));

// tslint:disable-next-line: no-any
const FeatureLayoutCardDraggableWrapper = glamorous.div<{theme?: any}> ((props) => ({
    // flex: 1,
}));

interface FeatureLayoutConfigurationDroppableProps  {
    featureLayoutConfigurationList: FeatureLayoutConfiguration[];
    droppableId: string;
    onIsHiddenUpdated?: (featureGuid: string, isHidden: boolean) => void;
    itemsPerRow: number;
    windowInnterWidth: number;
    isDragDisabled?: boolean;
    isInternalAdmin: boolean;
}

interface FeatureLayoutConfigurationDroppableState {
    featureLayoutConfigurationList: FeatureLayoutConfiguration[];
}

export class FeatureLayoutConfigurationDroppable 
    extends React.Component<FeatureLayoutConfigurationDroppableProps, FeatureLayoutConfigurationDroppableState>  {
    constructor(props: FeatureLayoutConfigurationDroppableProps, state: FeatureLayoutConfigurationDroppableState) {
        super(props, state);

        this.getFeatureLayoutCardDraggable = this.getFeatureLayoutCardDraggable.bind(this);
        this.onIsHiddenUpdated = this.onIsHiddenUpdated.bind(this);

        this.state = {
            featureLayoutConfigurationList: this.props.featureLayoutConfigurationList
        };
    }

    public shouldComponentUpdate(nextProps: FeatureLayoutConfigurationDroppableProps) {
        // If we don't have this, the draggable will always try to render
        if (nextProps.featureLayoutConfigurationList === this.props.featureLayoutConfigurationList) {
            return false;
        }

        return true;
    }

    public UNSAFE_componentWillReceiveProps(nextProps: FeatureLayoutConfigurationDroppableProps) {
        if (this.props.featureLayoutConfigurationList !== nextProps.featureLayoutConfigurationList) {
            this.setState({
                featureLayoutConfigurationList: nextProps.featureLayoutConfigurationList
            });
        }
    }

    public render() { 
        const featureLayouts = this.getFeatureLayoutCardDraggable();

        return (
            <React.Fragment>
                <FeatureListContainer className="FeatureListContainer">
                    <Droppable
                        droppableId={this.props.droppableId}
                        direction={'horizontal'}
                        // mode="virtual"                        
                        renderClone={(provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) => (
                            snapshot.isDragging ? this.getCloneItem(rubric.draggableId, provided, snapshot, rubric) 
                            : this.getCloneItem(rubric.draggableId, provided, snapshot, rubric)
                        )}
                    >                                      
                        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (   
                            <FeatureList 
                                innerRef={provided.innerRef}
                                {...provided.droppableProps}                                
                                // isDraggingOver={snapshot.isDraggingOver}                                
                            >
                                {featureLayouts}                                                                  
                                {provided.placeholder}                                
                                {/* To Increase the space in droppable while drag */}                            
                            </FeatureList>                              
                            
                        )}
                    </Droppable>
                </FeatureListContainer>
            </React.Fragment>
        );
    }

    private getCloneItem(featureGuid: string, provided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) {
        const featureLayout = this.state.featureLayoutConfigurationList.filter( x => x.featureGuid === featureGuid)[0];
        // const index = this.state.featureLayoutConfigurationList.indexOf(featureLayout);
        return (
            <React.Fragment>
                {
                    <CloneItem
                        key={featureLayout.featureGuid}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        innerRef={provided.innerRef}   
                    >
                        <FeatureLayoutCardClone
                            key={featureLayout.featureGuid}
                            featureGuid={featureLayout.featureGuid}
                            feature={featureLayout.feature}
                            title={featureLayout.title}
                            description={featureLayout.description}
                            isHidden={featureLayout.isHidden}
                            sequence={featureLayout.sequence}
                            onIsHiddenUpdated={this.onIsHiddenUpdated}    
                            itemPerRow={this.props.itemsPerRow}
                            windowInnterWidth={this.props.windowInnterWidth} 
                            infoUrl={featureLayout.infoUrl}                   
                        />
                    </CloneItem>              
                })
            </React.Fragment>
        );
    }

    private getFeatureLayoutCardDraggable() {
        return (
            <React.Fragment>
                {                        
                    this.state.featureLayoutConfigurationList.map((featureLayout, index) => {
                        return (    
                            // <Grid.Column key={index}>      
                            <FeatureLayoutCardDraggableWrapper 
                                className="FeatureLayoutCardDraggableWrapper" 
                                key={index}
                            >
                                <FeatureLayoutCardDraggable
                                    index={index}
                                    key={featureLayout.featureGuid}
                                    featureGuid={featureLayout.featureGuid}
                                    feature={featureLayout.feature}
                                    title={featureLayout.title}
                                    description={featureLayout.description}
                                    isHidden={featureLayout.isHidden}
                                    sequence={featureLayout.sequence}
                                    onIsHiddenUpdated={this.onIsHiddenUpdated}
                                    itemPerRow={this.props.itemsPerRow}
                                    windowInnterWidth={this.props.windowInnterWidth}
                                    isDragDisabled={this.props.isDragDisabled}
                                    infoUrl={featureLayout.infoUrl}
                                    isInternalAdmin={this.props.isInternalAdmin}

                                />
                            </FeatureLayoutCardDraggableWrapper> 
                        );
                })}
            </React.Fragment>
        );
    }

    private onIsHiddenUpdated(featureGuid: string, isHidden: boolean) {
        if (this.props.onIsHiddenUpdated) {
            this.props.onIsHiddenUpdated(featureGuid, !isHidden);
        }
    }
}