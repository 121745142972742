/* eslint-disable react-hooks/exhaustive-deps */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    makeStyles,
    TextField,
    Theme,
    Typography,
} from '@material-ui/core';
import { FormApi } from 'final-form';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { RvLoader } from '../../components/Loader';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DocumentFile } from '../../types/DocumentFile';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { showNotification } from '../../App';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        },
        radioGroup: {
            flexDirection: 'row'
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 600,
            color: theme.palette.primary.main
        },
    })
);

interface FormState {
    staffContactFilter: string | null;
    welcomeMessage: string | null;
    isEmailNotificationDocuments: boolean;
    isEmailNotificationMessages: boolean;
    isPushNotificationDocuments: boolean;
    isPushNotificationMessages: boolean;
    isExternalMessagesToActing: boolean;
    isExternalMessagesToResponsible: boolean;
    isExternalMessagesToAssisting: boolean;
    alternativeLogo: DocumentFile[];
    isExternalMessageToActing: boolean;
    isExternalMessageToResponsible: boolean;
    isExternalMessageToAssisting: boolean;
    isFirmToClientPushNewDocumentNotifications: boolean;
    isFirmToClientPushNewMessageNotifications: boolean;
}
interface ConnectTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

export default function ConnectTab() {

    const classes = useStyles();

    const [state, setState] = useState<ConnectTabState>({
        isLoading: true,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState, setFormState] = useState<FormState>({
        staffContactFilter: null,
        welcomeMessage: null,
        isEmailNotificationDocuments: false,
        isEmailNotificationMessages: false,
        isPushNotificationDocuments: false,
        isPushNotificationMessages: false,
        isExternalMessagesToActing: false,
        isExternalMessagesToResponsible: false,
        isExternalMessagesToAssisting: false,
        isExternalMessageToActing: true,
        isExternalMessageToAssisting: false,
        isExternalMessageToResponsible: false,
        isFirmToClientPushNewDocumentNotifications: false,
        isFirmToClientPushNewMessageNotifications: false,
        alternativeLogo: []
    });

    const connectSettingQuery = useQuery<ConnectSettingQueryData>(ConnectSettingQuery);
    // tslint:disable-next-line: no-any
    const [updateConnectSetting, updateConnectSettingObj] = useMutation<any, ConnectSettingMutationParams>(CONNECT_SETTINGS_MUTATION);

    useEffect(() => {
    
        // tslint:disable-next-line: max-line-length
        if (!connectSettingQuery.loading && connectSettingQuery.data) {

            const { 
                emailDocumentNotifications,
                emailMessageNotifications,
                pushDocumentNotifications,
                pushMessageNotifications,
                staffContactFilter,
                welcomeMessage,
                externalMessagesToActing,
                externalMessagesToResponsible,
                externalMessagesToAssisting,
                firmToClientPushNewDocumentNotifications,
                firmToClientPushNewMessageNotifications
            } = connectSettingQuery.data.settings.systems.connectSettings.config;

            setFormState((prevFormState) => {
                return {
                    ...prevFormState,
                    staffContactFilter: staffContactFilter,
                    welcomeMessage: welcomeMessage,
                    isEmailNotificationDocuments: emailDocumentNotifications,
                    isEmailNotificationMessages: emailMessageNotifications,
                    isPushNotificationDocuments: pushDocumentNotifications,
                    isPushNotificationMessages: pushMessageNotifications,
                    isExternalMessagesToActing: externalMessagesToActing,
                    isExternalMessagesToResponsible: externalMessagesToResponsible,
                    isExternalMessagesToAssisting: externalMessagesToAssisting,
                    isFirmToClientPushNewDocumentNotifications: firmToClientPushNewDocumentNotifications,
                    isFirmToClientPushNewMessageNotifications: firmToClientPushNewMessageNotifications
                };
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false
                };
            });
        }
    },        [connectSettingQuery.data, connectSettingQuery.loading]);

    useEffect(() => {
        if (updateConnectSettingObj.loading) {
            showNotification(null, 'Successfully submitted', 'info'); 
        }
        if (updateConnectSettingObj.error) {
            setState((prevState) => {
                return {
                    ...prevState,
                    onSubmitLoading: false,
                };
            });
    
            showNotification('Failed to update connect settings', updateConnectSettingObj.error.message, 'error');
        }
    },        [updateConnectSettingObj.data, updateConnectSettingObj.loading, updateConnectSettingObj.error]);

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {
        updateConnectSetting({
            variables: {
                emailDocumentNotifications: values.isEmailNotificationDocuments,
                emailMessageNotifications: values.isEmailNotificationMessages,
                pushDocumentNotifications: values.isPushNotificationDocuments,
                pushMessageNotifications: values.isPushNotificationMessages,
                staffContactFilter: values.staffContactFilter,
                welcomeMessage: values.welcomeMessage,
                externalMessagesToActing: values.isExternalMessagesToActing,
                externalMessagesToAssisting: values.isExternalMessagesToAssisting,
                externalMessagesToResponsible: values.isExternalMessagesToResponsible,
                firmToClientPushNewDocumentNotifications: values.isFirmToClientPushNewDocumentNotifications,
                firmToClientPushNewMessageNotifications:  values.isFirmToClientPushNewMessageNotifications
            }
        });

        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true,
            };
        });

    };

    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    validate={(values: FormState) => {
                        // tslint:disable-next-line: no-any
                        const errors: any = {
                            externalMessageToError: '',
                        };

                        if (!values.isExternalMessageToActing && !values.isExternalMessageToResponsible && !values.isExternalMessageToAssisting) {
                            errors.externalMessageToError = 'At least one checkbox must be checked';
                        }

                        if (errors.externalMessageToError === '') {
                            return undefined;
                        }

                        return errors;
                    }}
                    subscription={{
                        submitting: true,
                        pristine: true,
                        errors: true
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        errors
                    }) => (
                            <form onSubmit={handleSubmit}>
                                <FormSpy 
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="staffContactFilter"
                                            validate={form.getFieldState('staffContactFilter')?.value !== null ? required : undefined}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Staff Contact Filter"
                                                    name="staffContactFilter"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Staff Contact Filter is required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="welcomeMessage"
                                            validate={form.getFieldState('welcomeMessage')?.value !== null ? required : undefined}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Welcome Message"
                                                    name="welcomeMessage"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Welcome message is required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                <Typography className={classes.heading}>For notifications from the Client to the Firm</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container={true} spacing={1}>
                                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">Send email notification for</FormLabel>
                                                            <FormGroup className={classes.radioGroup}>
                                                                <Field
                                                                    name="isEmailNotificationDocuments"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >                                            
                                                                    {({ input, meta }) => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    {...input}
                                                                                    checked={input.value}
                                                                                    onChange={input.onChange}
                                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                />
                                                                            }
                                                                            label="New Documents"
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="isEmailNotificationMessages"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >                                            
                                                                    {({ input, meta }) => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    {...input}
                                                                                    checked={input.value}
                                                                                    onChange={input.onChange}
                                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                />
                                                                            }
                                                                            label="New Messages"
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend" error={errors && errors.externalMessageToError ? true : false}>Notify</FormLabel>
                                                            <FormGroup className={classes.radioGroup}>
                                                                <Field
                                                                    name="isExternalMessagesToActing"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >                                            
                                                                    {({ input, meta }) => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    {...input}
                                                                                    checked={input.value}
                                                                                    onChange={input.onChange}
                                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                />
                                                                            }
                                                                            label="Acting"
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="isExternalMessagesToResponsible"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >                                            
                                                                    {({ input, meta }) => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    {...input}
                                                                                    checked={input.value}
                                                                                    onChange={input.onChange}
                                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                />
                                                                            }
                                                                            label="Responsible"
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="isExternalMessagesToAssisting"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >                                            
                                                                    {({ input, meta }) => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    {...input}
                                                                                    checked={input.value}
                                                                                    onChange={input.onChange}
                                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                />
                                                                            }
                                                                            label="Assisting"
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                            {errors && errors.externalMessageToError && (
                                                                <FormHelperText error={true}>{errors.externalMessageToError}</FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>    
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                    <Accordion defaultExpanded={true}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                                <Typography className={classes.heading}>For Notifications from the Firm to the Client</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                                        <FormControl component="fieldset">
                                                            <FormLabel component="legend">Send email notification for</FormLabel>
                                                            <FormGroup className={classes.radioGroup}>
                                                                <Field
                                                                    name="isFirmToClientPushNewDocumentNotifications"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >                                            
                                                                    {({ input, meta }) => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    {...input}
                                                                                    checked={input.value}
                                                                                    onChange={input.onChange}
                                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                />
                                                                            }
                                                                            label="New Documents"
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="isFirmToClientPushNewMessageNotifications"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >                                            
                                                                    {({ input, meta }) => (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    {...input}
                                                                                    checked={input.value}
                                                                                    onChange={input.onChange}
                                                                                    checkedIcon={<CheckBoxIcon color="primary" />}
                                                                                />
                                                                            }
                                                                            label="New Messages"
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </FormGroup>
                                                        </FormControl>
                                                    </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="alternativeLogo"
                                            // subscription={{touched: true, error: true, value: true}}
                                        >
                                            {({ input, meta }) => (
                                                <DropzoneArea
                                                    {...input}
                                                    maxFileSize={15000000}
                                                    // acceptedFiles={['.msg', '.jpg', '.jpeg', '.png', '.docx', '.zip']}
                                                    showFileNames={true}
                                                    useChipsForPreview={true}
                                                    filesLimit={1}
                                                    onChange={(files: File[]) => {
                                                        let existingFiles: DocumentFile[] = [...input.value];
                                                        const newFiles = files.filter((file: File) => {
                                                            return !existingFiles.some((item: DocumentFile) => {
                                                                return item.name === file.name && item.status !== 2;
                                                            });
                                                        });
                                                        if (newFiles && newFiles.length > 0) {
                                                            newFiles.forEach((file: File) => {
                                                                const documentFile: DocumentFile = {
                                                                    guidID: null,
                                                                    file: file,
                                                                    status: 1,
                                                                    name: file.name,
                                                                    url: ''
                                                                };
                                                                existingFiles.push(documentFile);
                                                            });  
                                                            input.onChange(existingFiles);
                                                        }
                                                    }}
                                                    onDelete={(file: File) => {
                                                        let existingFiles: DocumentFile[] = [...input.value];
                                                        // eslint-disable-next-line array-callback-return
                                                        existingFiles.map((item: DocumentFile) => {
                                                            if (file.name === item.name) {
                                                                item.file = file;
                                                                item.status = 2; // deleted
                                                            }
                                                        });  
                                                        // tslint:disable-next-line: max-line-length
                                                        const filteredFiles = existingFiles.filter((item: DocumentFile) => {
                                                            return item.status !== 2 || item.guidID !== null;
                                                        });
                                                        input.onChange(filteredFiles);
                                                    }}                                                    
                                                    // tslint:disable-next-line:no-any
                                                    onDrop={(files: File[], event: any) => {
                                                        // let existingFiles: DocumentFile[] = [...input.value];
                                                        // tslint:disable-next-line: max-line-length
                                                        // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
                                                        // tslint:disable-next-line:no-console
                                                        console.log(event);
                                                    }}                                                  
                                                    initialFiles={input.value.map((file: { url: string; }) => file.url)}
                                                    dropzoneText="Alternate Mobile App Logo"
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || pristine || state.onSubmitLoading}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="button"
                                                disabled={submitting || pristine}
                                                onClick={onReset(form)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                                    {/* {printJson(values)} */}
                            </Grid>
                            </form>
                        )}
                />
            )}
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

export interface ConnectSettingQueryData {
    settings: Settings;
}

export interface Settings {
    systems: Systems;
}

export interface Systems {
    connectSettings: ConnectSettings;
}

export interface ConnectSettings {
    id:     number;
    config: Config;
}

export interface Config {
    id:                         number;
    emailDocumentNotifications: boolean;
    emailMessageNotifications:  boolean;
    pushDocumentNotifications:  boolean;
    pushMessageNotifications:   boolean;
    externalMessagesToActing:   boolean;
    externalMessagesToResponsible:   boolean;
    externalMessagesToAssisting:   boolean;
    staffContactFilter:         string;
    welcomeMessage:             string;
    firmToClientPushNewDocumentNotifications: boolean;
    firmToClientPushNewMessageNotifications: boolean;
}

const ConnectSettingQuery = gql`
    query ConnectSetting {
        settings {
            id
            systems {
            connectSettings {
                id
                config {
                    id
                    emailDocumentNotifications
                    emailMessageNotifications
                    pushDocumentNotifications
                    pushMessageNotifications
                    staffContactFilter
                    welcomeMessage
                    externalMessagesToResponsible
                    externalMessagesToActing
                    externalMessagesToAssisting
                    firmToClientPushNewDocumentNotifications
                    firmToClientPushNewMessageNotifications
                    }
                }
            }
        }
    }
`;

interface ConnectSettingMutationParams {
    staffContactFilter: String | null;
    welcomeMessage: String | null;
    emailDocumentNotifications: Boolean;
    emailMessageNotifications: Boolean;
    pushDocumentNotifications: Boolean;
    pushMessageNotifications: Boolean;
    externalMessagesToActing:   boolean;
    externalMessagesToResponsible:   boolean;
    externalMessagesToAssisting:   boolean;
    firmToClientPushNewDocumentNotifications: boolean;
    firmToClientPushNewMessageNotifications: boolean;
}

const CONNECT_SETTINGS_MUTATION = gql`
    mutation ConnectSettingsMutation(
        $staffContactFilter: String,
        $welcomeMessage: String,
        $emailDocumentNotifications: Boolean,
        $emailMessageNotifications: Boolean,
        $pushDocumentNotifications: Boolean,
        $pushMessageNotifications: Boolean,
        $externalMessagesToResponsible: Boolean,
        $externalMessagesToActing: Boolean,
        $externalMessagesToAssisting: Boolean,
        $firmToClientPushNewDocumentNotifications: Boolean,
        $firmToClientPushNewMessageNotifications: Boolean
        ){
        settings {
            connectSettings {
                update (
                    staffContactFilter: $staffContactFilter,
                    welcomeMessage: $welcomeMessage,
                    emailDocumentNotifications: $emailDocumentNotifications,
                    emailMessageNotifications: $emailMessageNotifications,
                    pushDocumentNotifications: $pushDocumentNotifications,
                    pushMessageNotifications: $pushMessageNotifications,
                    externalMessagesToResponsible: $externalMessagesToResponsible,
                    externalMessagesToActing: $externalMessagesToActing,
                    externalMessagesToAssisting: $externalMessagesToAssisting,
                    firmToClientPushNewDocumentNotifications: $firmToClientPushNewDocumentNotifications,
                    firmToClientPushNewMessageNotifications: $firmToClientPushNewMessageNotifications
                ) {
                    status
                    error
                }
            }
        }
    }
`;