/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Checkbox, createStyles, FormControlLabel, FormGroup, Grid, makeStyles, Theme } from '@material-ui/core';
import { FormApi } from 'final-form';
import React, { useEffect, useState } from 'react';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { RvLoader } from '../../components/Loader';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'react-apollo';
import { showNotification } from '../../App';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        },
        radioGroup: {
            flexDirection: 'row'
        }
    })
);

interface FormState {
    warnOnDefaultTime: boolean;
    warnOnExceedingHours: boolean;
    warnIfNotToday: boolean;
}
interface TimeRecordingWarningDefaultsTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

export default function TimeRecordingWarningDefaultsTab() {

    const classes = useStyles();

    const [state, setState] = useState<TimeRecordingWarningDefaultsTabState>({
        isLoading: true,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState, setFormState] = useState<FormState>({
        warnOnDefaultTime: false,
        warnOnExceedingHours: false,
        warnIfNotToday: false,
    });

    const timeRecordingWarnings = useQuery<TimeRecordingWarningsData>(TIME_RECORDING_WARNING_QUERY);

    // tslint:disable-next-line: no-any
    const [updateTimeRecordingWarnings] = useMutation<any, TimeRecordingWarningsMutationParams>(TIME_RECORDING_WARNING_MUTATION);

    useEffect(() => {
    
        // tslint:disable-next-line: max-line-length
        if (!timeRecordingWarnings.loading && timeRecordingWarnings.data) {

            const { 
                warnIfNotToday,
                warnOnExceedingHours,
            } = timeRecordingWarnings.data.settings.systems.timeRecordingWarnings.config;

            setFormState((prevFormState) => {
                return {
                    ...prevFormState,
                    warnIfNotToday: warnIfNotToday,
                    warnOnExceedingHours: warnOnExceedingHours,
                };
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false
                };
            });
        }
    },        [timeRecordingWarnings.data, timeRecordingWarnings.loading]);

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {
        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true,
            };
        });

        updateTimeRecordingWarnings({
            variables: {
                warnIfNotToday: values.warnIfNotToday,
                warnOnExceedingHours: values.warnOnExceedingHours,
            }
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info');     
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update time recording warnings', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{
                        submitting: true,
                        pristine: true
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values
                    }) => (
                            <form onSubmit={handleSubmit}>
                                <FormSpy 
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <FormGroup>
                                            <Field
                                                name="warnOnExceedingHours"
                                                subscription={{touched: true, error: true, value: true}}
                                            >                                            
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...input}
                                                                checked={input.value}
                                                                onChange={input.onChange}
                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                            />
                                                        }
                                                        label="Warning on Exceeding Hours" 
                                                    />
                                                )}
                                            </Field>
                                            <Field
                                                name="warnIfNotToday"
                                                subscription={{touched: true, error: true, value: true}}
                                            >                                            
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...input}
                                                                checked={input.value}
                                                                onChange={input.onChange}
                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                            />
                                                        }
                                                        label="Warn if not today"  
                                                    />
                                                )}
                                            </Field>
                                            {/* <Field
                                                name="warnOnDefaultTime"
                                                subscription={{touched: true, error: true, value: true}}
                                            >                                            
                                                {({ input, meta }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...input}
                                                                checked={input.value}
                                                                onChange={input.onChange}
                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                            />
                                                        }
                                                        label="Warn on Default Time" 
                                                    />
                                                )}
                                            </Field> */}
                                        </FormGroup>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || pristine || state.onSubmitLoading}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="button"
                                                disabled={submitting || pristine}
                                                onClick={onReset(form)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                                    {/* {printJson(values)} */}
                            </Grid>
                            </form>
                        )}
                />
            )}
        </div>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

export interface TimeRecordingWarningsData {
    settings: Settings;
}

export interface Settings {
    id:      number;
    systems: Systems;
}

export interface Systems {
    timeRecordingWarnings: TimeRecordingWarnings;
}

export interface TimeRecordingWarnings {
    id:     number;
    config: Config;
}

export interface Config {
    id:                   number;
    warnIfNotToday:       boolean;
    warnOnExceedingHours: boolean;
}

const TIME_RECORDING_WARNING_QUERY = gql`
    query timeRecordingWarnings {
        settings {
            id
            systems {
                timeRecordingWarnings {
                id
                config {
                    id
                    warnIfNotToday
                    warnOnExceedingHours
                }
                }
            }
        }
    }
`;

interface TimeRecordingWarningsMutationParams {
    warnOnExceedingHours: Boolean;
    warnIfNotToday: Boolean;
}

const TIME_RECORDING_WARNING_MUTATION = gql`
    mutation timeRecordingWarnings(
        $warnIfNotToday: Boolean,
        $warnOnExceedingHours: Boolean,
    ){
        settings {
            timeRecordingWarnings {
                update (
                    warnIfNotToday: $warnIfNotToday,
                    warnOnExceedingHours: $warnOnExceedingHours,
                ) {
                    status
                    error
                }
            }
        }
    }
`;