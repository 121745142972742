/* eslint-disable react-hooks/exhaustive-deps */
import MomentUtils from '@date-io/moment';
import { Button, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FormApi } from 'final-form';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { CountryData, CountryDataInterface } from '../common/CountryData';
import { CountrySelector } from '../selectors/CountrySelector';
import { CurrencyCodeSelector } from '../selectors/CurrencyCodeSelector';
import { CurrencySymbolSelector } from '../selectors/CurrencySymbolSelector';
import { TimeZoneSelector } from '../selectors/TimeZoneSelector';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        }
    })
);

interface FormState {
    homeCountry: IAutoCompleteItem | null;
    timeZone: IAutoCompleteItem | null;
    currencyCode: IAutoCompleteItem | null;
    currencySymbol: IAutoCompleteItem | null;
    financialYearStart: Date | null;
    taxLabel: string | null;
    taxPercentage: number | null;
}
interface RegionalTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

export default function RegionalTab() {

    const classes = useStyles();

    const [state, setState] = useState<RegionalTabState>({
        isLoading: true,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState, setFormState] = useState<FormState>({
        homeCountry: null,
        timeZone: null,
        currencyCode: null,
        currencySymbol: null,
        financialYearStart: null,
        taxLabel: null,
        taxPercentage: null,
    });

    const regionSettingsQuery = useQuery<RegionSettingsData>(REGION_SETTINGS_QUERY);

    // tslint:disable-next-line: no-any
    const [upRegionSettingsQuery] = useMutation<any, RegionSettingsMutationParams>(REGION_SETTINGS_MUTATION);
    
    const getCountryDetails = (value: string, type: 'countryCode' | 'country') => {

        let renderType: 'countryCode' | 'country' = 'country';
        if (type === 'country') {
            renderType = 'countryCode';
        }

        const countryData: CountryDataInterface | undefined = CountryData.find((item: CountryDataInterface) => item[renderType] === value);

        // tslint:disable-next-line: no-console
        console.log('getCountryDetails', value, type, countryData);

        return countryData ? countryData[type] : '';
    };

    useEffect(() => {
    
        // tslint:disable-next-line: max-line-length
        if (!regionSettingsQuery.loading && regionSettingsQuery.data) {

            const { 
                currencyCode,
                currencySymbol,
                financialYearStart,
                homeCountry,
                timeZone,
                timeZoneDescription,
                taxLabel,
                taxPercentage
            } = regionSettingsQuery.data.settings.systems.regionSettings.config;

            setFormState((prevFormState) => {
                return {
                    ...prevFormState,
                    financialYearStart,
                    currencyCode: currencyCode ? { label: currencyCode, value: currencyCode } : null,
                    currencySymbol: currencySymbol ? { label: currencySymbol, value: currencySymbol } : null,
                    // tslint:disable-next-line: max-line-length
                    homeCountry: homeCountry && homeCountry.length > 0 ? { label: getCountryDetails(homeCountry, 'country'), value: getCountryDetails(homeCountry, 'country') } : null,
                    timeZone: timeZone && timeZoneDescription ? { label: timeZoneDescription, value: timeZone } : null,
                    taxLabel: taxLabel,
                    taxPercentage: taxPercentage
                };
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false
                };
            });
        }
    },        [regionSettingsQuery.data, regionSettingsQuery.loading]);

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    // const required = (value: any) => {
    //     return value ? undefined : 'Required';
    // };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {

        upRegionSettingsQuery({
            variables: {
                homeCountry: values.homeCountry ? getCountryDetails(values.homeCountry.label, 'countryCode') : null,
                currencySymbol: values.currencySymbol ? values.currencySymbol.value : null,
                currencyCode: values.currencyCode ? values.currencyCode.value : null,
                financialYearStart: values.financialYearStart ? values.financialYearStart : null,
                timeZone: values.timeZone ? values.timeZone.value : null,
                taxLabel: values.taxLabel ? values.taxLabel : null,
                taxPercentage: values.taxPercentage ? values.taxPercentage : null
            }
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any }) => {
            if (results.data) {
                showNotification(null, 'Successfully submitted', 'info');     
            }
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            showNotification('Failed to update region settings.', reason, 'error');
            setState((_prevState) => {
                return {
                    ..._prevState,
                    onSubmitLoading: false
                };
            });
        });
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <div className={classes.root}>
                {state.isLoading ? (
                    <div className={classes.loaderWrapper}>
                        <RvLoader />
                    </div>
                ) : (
                    <Form
                        onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                        initialValues={formState}
                        initialValuesEqual={() => true}
                        keepDirtyOnReinitialize={true}
                        subscription={{
                            submitting: true,
                            pristine: true
                        }}
                        render={({
                            handleSubmit,
                            form,
                            submitting,
                            pristine,
                            values
                        }) => (
                                <form onSubmit={handleSubmit}>
                                    <FormSpy 
                                        subscription={{ pristine: true }}
                                        // tslint:disable-next-line: no-shadowed-variable
                                        onChange={props => {
                                            onFormValueChanged(props);
                                        }}
                                    />
                                    
                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                            <Field
                                                name="homeCountry"
                                                subscription={{touched: true, error: true, value: true}}
                                                // validate={form.getFieldState('homeCountry')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <CountrySelector 
                                                        {...input}
                                                        label="Home Country" 
                                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                            input.onChange(selection)
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                            <Field
                                                name="timeZone"
                                                subscription={{touched: true, error: true, value: true}}
                                                // validate={form.getFieldState('timeZone')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <TimeZoneSelector 
                                                        {...input}
                                                        label="Time Zone" 
                                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                            input.onChange(selection)
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                            <Field
                                                name="currencyCode"
                                                subscription={{touched: true, error: true, value: true}}
                                                // validate={form.getFieldState('currencyCode')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <CurrencyCodeSelector 
                                                        {...input}
                                                        label="Currency Code" 
                                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                            input.onChange(selection)
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                            <Field
                                                name="currencySymbol"
                                                subscription={{touched: true, error: true, value: true}}
                                                // validate={form.getFieldState('currencySymbol')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <CurrencySymbolSelector 
                                                        {...input}
                                                        label="Currency Symbol" 
                                                        onSelection={(selection: IAutoCompleteItem, name: string) => 
                                                            input.onChange(selection)
                                                        }
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                            <Field
                                                name="financialYearStart"
                                                subscription={{touched: true, error: true, value: true}}
                                                // validate={form.getFieldState('financialYearStart')?.value !== null ? required : undefined}
                                            >
                                                {({ input, meta }) => (
                                                    <KeyboardDatePicker
                                                        {...input}
                                                        label="Financial Year Start"
                                                        error={meta.error && meta.touched}
                                                        helperText={
                                                            meta.error &&
                                                            meta.touched
                                                                ? meta.error
                                                                : ''
                                                        }
                                                        size="small"
                                                        variant="inline"
                                                        InputLabelProps={{ shrink: true }}
                                                        format="DD/MM/YYYY"
                                                    />
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                            <Grid container={true} spacing={3}>
                                                <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                                    <Field
                                                        name="taxLabel"
                                                        // validate={required}
                                                        subscription={{touched: true, error: true, value: true}}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                label="Tax Label"
                                                                // required={true}
                                                                className={classes.textField}
                                                                error={meta.error && meta.touched}
                                                                autoComplete="abcd"
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? 'Tax Label is required'
                                                                        : ''
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                                <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                                    <Field
                                                        name="taxPercentage"
                                                        // validate={required}
                                                        subscription={{touched: true, error: true, value: true}}
                                                        parse={value => parseFloat(value)}
                                                    >
                                                        {({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                label="Tax Percentage"
                                                                type="number"
                                                                className={classes.textField}
                                                                error={meta.error && meta.touched}
                                                                autoComplete="abcd"
                                                                helperText={
                                                                    meta.error &&
                                                                    meta.touched
                                                                        ? 'Tax Percentage is required'
                                                                        : ''
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item={true} xs={12} md={12} justify="flex-end">
                                            <div className={classes.buttonWrapper}>
                                                <Button 
                                                    variant="outlined" 
                                                    color="primary"
                                                    type="submit"
                                                    disabled={submitting || pristine || state.onSubmitLoading}
                                                >
                                                    Save
                                                </Button>
                                                <Button 
                                                    variant="outlined" 
                                                    color="primary"
                                                    type="button"
                                                    disabled={submitting || pristine}
                                                    onClick={onReset(form)}
                                                >
                                                    Reset
                                                </Button>
                                            </div>
                                        </Grid>
                                        {/* {printJson(values)} */}
                                </Grid>
                                </form>
                            )}
                    />
                )}
            </div>
        </MuiPickersUtilsProvider>
    );
}

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}

export interface RegionSettingsData {
    settings: Settings;
}

export interface Settings {
    id:      number;
    systems: Systems;
}

export interface Systems {
    regionSettings: RegionSettings;
}

export interface RegionSettings {
    id:     number;
    config: Config;
}

export interface Config {
    id: number;
    currencyCode: string;
    currencySymbol: string;
    financialYearStart: Date;
    homeCountry: string;
    timeZone: string;
    timeZoneDescription: string;
    baseUtcOffset: number;
    taxLabel: string;
    taxPercentage: number;
}

const REGION_SETTINGS_QUERY = gql`
    query regionSettings {
        settings {
            id
            systems {
                regionSettings {
                    id
                    config {
                        id
                        currencyCode
                        currencySymbol
                        financialYearStart
                        homeCountry
                        timeZone
                        timeZoneDescription
                        taxLabel
                        taxPercentage
                    }
                }
            }
        }
    }
`;

interface RegionSettingsMutationParams {
    homeCountry: string | null;
    timeZone: string | null;
    currencyCode: string | null;
    currencySymbol: string | null;
    financialYearStart: Date | null;
    taxLabel: string | null;
    taxPercentage: number | null;
}

const REGION_SETTINGS_MUTATION = gql`
    mutation RegionSettingsMutation(
        $homeCountry: String,
        $timeZone: String,
        $currencyCode: String,
        $currencySymbol: String,
        $financialYearStart: DateTime,
        $taxLabel: String,
        $taxPercentage: Decimal
    ){
        settings {
            regionSettings {
                update (
                    homeCountry: $homeCountry,
                    timeZone: $timeZone,
                    currencyCode: $currencyCode,
                    currencySymbol: $currencySymbol,
                    financialYearStart: $financialYearStart,
                    taxLabel: $taxLabel,
                    taxPercentage: $taxPercentage
                ) {
                    status
                    error
                }
            }
        }
    }
`;