import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { RvButtonFabric, RvButtonType } from '../components/Button';
import { RvLabel, LabelStyle } from '../components/Label/Label';
import { mainTheme } from '../Theme';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: '0 15px'
    },
    container: {},
    buttonContainer: {
      position: 'relative',
      padding: '15px 80px',
      display: 'flex',
      alignItems: 'center',
      color: mainTheme.BrandColors.TextPrimary,
      '@media (max-width: 1024px)': {
        padding: '15px 30px'
      },
      '& .rvlabel': {
        // paddingLeft: '82px',
        flex: 1,
        fontFamily: mainTheme.Font.Main,
        fontSize: mainTheme.FontSize.PX.size8,
        letterSpacing: '0.86px',
        lineHeight: mainTheme.LineHeight.ExtraLarge
      },
      '& .back-button': {
        paddingRight: '32px',
        display: 'flex',
        alignItems: 'center',
        // position: 'absolute',
        // top: '50%',
        // transform: 'translateY(-50%)',
        '& .ms-Button': {
          width: '50px',
          height: '50px'
        },
        '& .ms-Button-icon': {
          fontSize: mainTheme.FontSize.PX.size10,
          color: mainTheme.BrandColors.TemplatePrimary
        }
      }
    },
    button: {
      margin: '0 10px',
      textTransform: 'none',
      fontSize: '14px',
    }
  })
);

interface BannerProps {
  title: string;
  onClickBack?: () => void;
  onLearnMore?: () => void;
}

export const FurmStructureConfigurationBanner: React.FC<BannerProps> = ({
  title,
  onClickBack,
  onLearnMore
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.buttonContainer}>
          <RvButtonFabric
            rvKey="close"
            rvbuttonType={RvButtonType.icon}
            onClick={onClickBack}
            rvIcon={'NavigateForwardMirrored'}
            rvClassName="back-button"
          />
          <RvLabel label={title} style={LabelStyle.Heading4} />
          {onLearnMore && (
            <Button 
                variant="text" 
                color="primary"
                size="small"
                className={classes.button}
                onClick={onLearnMore}
                startIcon={<VideoLibraryOutlinedIcon />}
            >
                Learn More
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
