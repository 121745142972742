export interface TimeZoneDataProps {
    name: string;
    timeZoneId: string;
}

export const TimeZoneData: TimeZoneDataProps[] = [
      {timeZoneId: 'Dateline Standard Time', 				name: '(UTC -12:00) Dateline Standard Time'}
    , {timeZoneId: 'UTC-11', 								name: '(UTC -11:00) UTC-11'}
    , {timeZoneId: 'Aleutian Standard Time', 				name: '(UTC -09:00) Aleutian Standard Time'}
    , {timeZoneId: 'Hawaiian Standard Time',				name: '(UTC -10:00) Hawaiian Standard Time'}
    , {timeZoneId: 'Marquesas Standard Time', 			    name: '(UTC -09:30) Marquesas Standard Time'}
    , {timeZoneId: 'Alaskan Standard Time', 				name: '(UTC -08:00) Alaskan Standard Time'}
    , {timeZoneId: 'UTC-09', 								name: '(UTC -09:00) UTC-09'}
    , {timeZoneId: 'Pacific Standard Time (Mexico)', 		name: '(UTC -07:00) Pacific Standard Time (Mexico)'}
    , {timeZoneId: 'UTC-08', 								name: '(UTC -08:00) UTC-08'}
    , {timeZoneId: 'Pacific Standard Time', 				name: '(UTC -07:00) Pacific Standard Time'}
    , {timeZoneId: 'US Mountain Standard Time', 			name: '(UTC -07:00) US Mountain Standard Time'}
    , {timeZoneId: 'Mountain Standard Time (Mexico)', 	    name: '(UTC -07:00) Mountain Standard Time (Mexico)'}
    , {timeZoneId: 'Mountain Standard Time', 				name: '(UTC -06:00) Mountain Standard Time'}
    , {timeZoneId: 'Yukon Standard Time', 				    name: '(UTC -07:00) Yukon Standard Time'}
    , {timeZoneId: 'Central America Standard Time', 		name: '(UTC -06:00) Central America Standard Time'}
    , {timeZoneId: 'Central Standard Time', 				name: '(UTC -05:00) Central Standard Time'}
    , {timeZoneId: 'Easter Island Standard Time', 		    name: '(UTC -05:00) Easter Island Standard Time'}
    , {timeZoneId: 'Central Standard Time (Mexico)', 		name: '(UTC -06:00) Central Standard Time (Mexico)'}
    , {timeZoneId: 'Canada Central Standard Time', 		    name: '(UTC -06:00) Canada Central Standard Time'}
    , {timeZoneId: 'SA Pacific Standard Time', 			    name: '(UTC -05:00) SA Pacific Standard Time'}
    , {timeZoneId: 'Eastern Standard Time (Mexico)', 		name: '(UTC -05:00) Eastern Standard Time (Mexico)'}
    , {timeZoneId: 'Eastern Standard Time', 				name: '(UTC -04:00) Eastern Standard Time'}
    , {timeZoneId: 'Haiti Standard Time', 				    name: '(UTC -04:00) Haiti Standard Time'}
    , {timeZoneId: 'Cuba Standard Time', 					name: '(UTC -04:00) Cuba Standard Time'}
    , {timeZoneId: 'US Eastern Standard Time', 			    name: '(UTC -04:00) US Eastern Standard Time'}
    , {timeZoneId: 'Turks And Caicos Standard Time', 		name: '(UTC -04:00) Turks And Caicos Standard Time'}
    , {timeZoneId: 'Paraguay Standard Time', 				name: '(UTC -03:00) Paraguay Standard Time'}
    , {timeZoneId: 'Atlantic Standard Time', 				name: '(UTC -03:00) Atlantic Standard Time'}
    , {timeZoneId: 'Venezuela Standard Time', 			    name: '(UTC -04:00) Venezuela Standard Time'}
    , {timeZoneId: 'Central Brazilian Standard Time', 	    name: '(UTC -04:00) Central Brazilian Standard Time'}
    , {timeZoneId: 'SA Western Standard Time', 			    name: '(UTC -04:00) SA Western Standard Time'}
    , {timeZoneId: 'Pacific SA Standard Time', 			    name: '(UTC -03:00) Pacific SA Standard Time'}
    , {timeZoneId: 'Newfoundland Standard Time', 			name: '(UTC -02:30) Newfoundland Standard Time'}
    , {timeZoneId: 'Tocantins Standard Time', 			    name: '(UTC -03:00) Tocantins Standard Time'}
    , {timeZoneId: 'E. South America Standard Time', 		name: '(UTC -03:00) E. South America Standard Time'}
    , {timeZoneId: 'SA Eastern Standard Time', 			    name: '(UTC -03:00) SA Eastern Standard Time'}
    , {timeZoneId: 'Argentina Standard Time', 			    name: '(UTC -03:00) Argentina Standard Time'}
    , {timeZoneId: 'Greenland Standard Time', 			    name: '(UTC -03:00) Greenland Standard Time'}
    , {timeZoneId: 'Montevideo Standard Time', 			    name: '(UTC -03:00) Montevideo Standard Time'}
    , {timeZoneId: 'Magallanes Standard Time', 			    name: '(UTC -03:00) Magallanes Standard Time'}
    , {timeZoneId: 'Saint Pierre Standard Time', 			name: '(UTC -02:00) Saint Pierre Standard Time'}
    , {timeZoneId: 'Bahia Standard Time', 				    name: '(UTC -03:00) Bahia Standard Time'}
    , {timeZoneId: 'UTC-02', 								name: '(UTC -02:00) UTC-02'}
    , {timeZoneId: 'Mid-Atlantic Standard Time', 			name: '(UTC -02:00) Mid-Atlantic Standard Time'}
    , {timeZoneId: 'Azores Standard Time', 				    name: '(UTC -01:00) Azores Standard Time'}
    , {timeZoneId: 'Cape Verde Standard Time', 			    name: '(UTC -01:00) Cape Verde Standard Time'}
    , {timeZoneId: 'UTC', 								    name: '(UTC +00:00) UTC'}
    , {timeZoneId: 'GMT Standard Time', 					name: '(UTC +00:00) GMT Standard Time'}
    , {timeZoneId: 'Greenwich Standard Time', 			    name: '(UTC +00:00) Greenwich Standard Time'}
    , {timeZoneId: 'Sao Tome Standard Time', 				name: '(UTC +00:00) Sao Tome Standard Time'}
    , {timeZoneId: 'Morocco Standard Time',				    name: '(UTC +01:00) Morocco Standard Time'}
    , {timeZoneId: 'W. Europe Standard Time', 			    name: '(UTC +01:00) W. Europe Standard Time'}
    , {timeZoneId: 'Central Europe Standard Time', 		    name: '(UTC +01:00) Central Europe Standard Time'}
    , {timeZoneId: 'Romance Standard Time', 				name: '(UTC +01:00) Romance Standard Time'}
    , {timeZoneId: 'Central European Standard Time', 		name: '(UTC +01:00) Central European Standard Time'}
    , {timeZoneId: 'W. Central Africa Standard Time', 	    name: '(UTC +01:00) W. Central Africa Standard Time'}
    , {timeZoneId: 'Jordan Standard Time', 				    name: '(UTC +02:00) Jordan Standard Time'}
    , {timeZoneId: 'GTB Standard Time', 					name: '(UTC +02:00) GTB Standard Time'}
    , {timeZoneId: 'Middle East Standard Time', 			name: '(UTC +02:00) Middle East Standard Time'}
    , {timeZoneId: 'Egypt Standard Time', 				    name: '(UTC +02:00) Egypt Standard Time'}
    , {timeZoneId: 'E. Europe Standard Time', 			    name: '(UTC +02:00) E. Europe Standard Time'}
    , {timeZoneId: 'Syria Standard Time', 				    name: '(UTC +02:00) Syria Standard Time'}
    , {timeZoneId: 'West Bank Standard Time', 			    name: '(UTC +02:00) West Bank Standard Time'}
    , {timeZoneId: 'South Africa Standard Time', 			name: '(UTC +02:00) South Africa Standard Time'}
    , {timeZoneId: 'FLE Standard Time', 					name: '(UTC +02:00) FLE Standard Time'}
    , {timeZoneId: 'Israel Standard Time', 				    name: '(UTC +02:00) Israel Standard Time'}
    , {timeZoneId: 'South Sudan Standard Time', 			name: '(UTC +02:00) South Sudan Standard Time'}
    , {timeZoneId: 'Kaliningrad Standard Time', 			name: '(UTC +02:00) Kaliningrad Standard Time'}
    , {timeZoneId: 'Sudan Standard Time', 				    name: '(UTC +02:00) Sudan Standard Time'}
    , {timeZoneId: 'Libya Standard Time', 				    name: '(UTC +02:00) Libya Standard Time'}
    , {timeZoneId: 'Namibia Standard Time', 				name: '(UTC +02:00) Namibia Standard Time'}
    , {timeZoneId: 'Arabic Standard Time', 				    name: '(UTC +03:00) Arabic Standard Time'}
    , {timeZoneId: 'Turkey Standard Time', 				    name: '(UTC +03:00) Turkey Standard Time'}
    , {timeZoneId: 'Arab Standard Time', 					name: '(UTC +03:00) Arab Standard Time'}
    , {timeZoneId: 'Belarus Standard Time', 				name: '(UTC +03:00) Belarus Standard Time'}
    , {timeZoneId: 'Russian Standard Time', 				name: '(UTC +03:00) Russian Standard Time'}
    , {timeZoneId: 'E. Africa Standard Time', 			    name: '(UTC +03:00) E. Africa Standard Time'}
    , {timeZoneId: 'Volgograd Standard Time', 			    name: '(UTC +03:00) Volgograd Standard Time'}
    , {timeZoneId: 'Iran Standard Time', 					name: '(UTC +03:30) Iran Standard Time'}
    , {timeZoneId: 'Arabian Standard Time', 				name: '(UTC +04:00) Arabian Standard Time'}
    , {timeZoneId: 'Astrakhan Standard Time', 			    name: '(UTC +04:00) Astrakhan Standard Time'}
    , {timeZoneId: 'Azerbaijan Standard Time', 			    name: '(UTC +04:00) Azerbaijan Standard Time'}
    , {timeZoneId: 'Russia Time Zone 3', 					name: '(UTC +04:00) Russia Time Zone 3'}
    , {timeZoneId: 'Mauritius Standard Time', 			    name: '(UTC +04:00) Mauritius Standard Time'}
    , {timeZoneId: 'Saratov Standard Time', 				name: '(UTC +04:00) Saratov Standard Time'}
    , {timeZoneId: 'Georgian Standard Time', 				name: '(UTC +04:00) Georgian Standard Time'}
    , {timeZoneId: 'Caucasus Standard Time', 				name: '(UTC +04:00) Caucasus Standard Time'}
    , {timeZoneId: 'Afghanistan Standard Time', 			name: '(UTC +04:30) Afghanistan Standard Time'}
    , {timeZoneId: 'West Asia Standard Time', 			    name: '(UTC +05:00) West Asia Standard Time'}
    , {timeZoneId: 'Ekaterinburg Standard Time', 			name: '(UTC +05:00) Ekaterinburg Standard Time'}
    , {timeZoneId: 'Pakistan Standard Time', 				name: '(UTC +05:00) Pakistan Standard Time'}
    , {timeZoneId: 'Qyzylorda Standard Time', 			    name: '(UTC +05:00) Qyzylorda Standard Time'}
    , {timeZoneId: 'India Standard Time', 				    name: '(UTC +05:30) India Standard Time'}
    , {timeZoneId: 'Sri Lanka Standard Time', 			    name: '(UTC +05:30) Sri Lanka Standard Time'}
    , {timeZoneId: 'Nepal Standard Time', 				    name: '(UTC +05:45) Nepal Standard Time'}
    , {timeZoneId: 'Central Asia Standard Time', 			name: '(UTC +06:00) Central Asia Standard Time'}
    , {timeZoneId: 'Bangladesh Standard Time', 			    name: '(UTC +06:00) Bangladesh Standard Time'}
    , {timeZoneId: 'Omsk Standard Time', 					name: '(UTC +06:00) Omsk Standard Time'}
    , {timeZoneId: 'Myanmar Standard Time', 				name: '(UTC +06:30) Myanmar Standard Time'}
    , {timeZoneId: 'SE Asia Standard Time', 				name: '(UTC +07:00) SE Asia Standard Time'}
    , {timeZoneId: 'Altai Standard Time', 				    name: '(UTC +07:00) Altai Standard Time'}
    , {timeZoneId: 'W. Mongolia Standard Time', 			name: '(UTC +07:00) W. Mongolia Standard Time'}
    , {timeZoneId: 'North Asia Standard Time', 			    name: '(UTC +07:00) North Asia Standard Time'}
    , {timeZoneId: 'N. Central Asia Standard Time', 		name: '(UTC +07:00) N. Central Asia Standard Time'}
    , {timeZoneId: 'Tomsk Standard Time', 				    name: '(UTC +07:00) Tomsk Standard Time'}
    , {timeZoneId: 'China Standard Time', 				    name: '(UTC +08:00) China Standard Time'}
    , {timeZoneId: 'North Asia East Standard Time', 		name: '(UTC +08:00) North Asia East Standard Time'}
    , {timeZoneId: 'Singapore Standard Time', 			    name: '(UTC +08:00) Singapore Standard Time'}
    , {timeZoneId: 'W. Australia Standard Time', 			name: '(UTC +08:00) W. Australia Standard Time'}
    , {timeZoneId: 'Taipei Standard Time', 				    name: '(UTC +08:00) Taipei Standard Time'}
    , {timeZoneId: 'Ulaanbaatar Standard Time', 			name: '(UTC +08:00) Ulaanbaatar Standard Time'}
    , {timeZoneId: 'Aus Central W. Standard Time', 		    name: '(UTC +08:45) Aus Central W. Standard Time'}
    , {timeZoneId: 'Transbaikal Standard Time', 			name: '(UTC +09:00) Transbaikal Standard Time'}
    , {timeZoneId: 'Tokyo Standard Time', 				    name: '(UTC +09:00) Tokyo Standard Time'}
    , {timeZoneId: 'North Korea Standard Time', 			name: '(UTC +09:00) North Korea Standard Time'}
    , {timeZoneId: 'Korea Standard Time', 				    name: '(UTC +09:00) Korea Standard Time'}
    , {timeZoneId: 'Yakutsk Standard Time', 				name: '(UTC +09:00) Yakutsk Standard Time'}
    , {timeZoneId: 'Cen. Australia Standard Time', 		    name: '(UTC +10:30) Cen. Australia Standard Time'}
    , {timeZoneId: 'AUS Central Standard Time', 			name: '(UTC +09:30) AUS Central Standard Time'}
    , {timeZoneId: 'E. Australia Standard Time', 			name: '(UTC +10:00) E. Australia Standard Time'}
    , {timeZoneId: 'AUS Eastern Standard Time', 			name: '(UTC +11:00) AUS Eastern Standard Time'}
    , {timeZoneId: 'West Pacific Standard Time', 			name: '(UTC +10:00) West Pacific Standard Time'}
    , {timeZoneId: 'Tasmania Standard Time', 				name: '(UTC +11:00) Tasmania Standard Time'}
    , {timeZoneId: 'Vladivostok Standard Time', 			name: '(UTC +10:00) Vladivostok Standard Time'}
    , {timeZoneId: 'Lord Howe Standard Time', 			    name: '(UTC +11:00) Lord Howe Standard Time'}
    , {timeZoneId: 'Bougainville Standard Time', 			name: '(UTC +11:00) Bougainville Standard Time'}
    , {timeZoneId: 'Russia Time Zone 10', 				    name: '(UTC +11:00) Russia Time Zone 10'}
    , {timeZoneId: 'Magadan Standard Time', 				name: '(UTC +11:00) Magadan Standard Time'}
    , {timeZoneId: 'Norfolk Standard Time', 				name: '(UTC +12:00) Norfolk Standard Time'}
    , {timeZoneId: 'Sakhalin Standard Time', 				name: '(UTC +11:00) Sakhalin Standard Time'}
    , {timeZoneId: 'Central Pacific Standard Time', 		name: '(UTC +11:00) Central Pacific Standard Time'}
    , {timeZoneId: 'Russia Time Zone 11', 				    name: '(UTC +12:00) Russia Time Zone 11'}
    , {timeZoneId: 'New Zealand Standard Time', 			name: '(UTC +13:00) New Zealand Standard Time'}
    , {timeZoneId: 'UTC+12', 								name: '(UTC +12:00) UTC+12'}
    , {timeZoneId: 'Fiji Standard Time', 					name: '(UTC +12:00) Fiji Standard Time'}
    , {timeZoneId: 'Kamchatka Standard Time', 			    name: '(UTC +12:00) Kamchatka Standard Time'}
    , {timeZoneId: 'Chatham Islands Standard Time', 		name: '(UTC +13:45) Chatham Islands Standard Time'}
    , {timeZoneId: 'UTC+13', 								name: '(UTC +13:00) UTC+13'}
    , {timeZoneId: 'Tonga Standard Time', 				    name: '(UTC +13:00) Tonga Standard Time'}
    , {timeZoneId: 'Samoa Standard Time', 				    name: '(UTC +14:00) Samoa Standard Time'}
    , {timeZoneId: 'Line Islands Standard Time', 			name: '(UTC +14:00) Line Islands Standard Time'}
];