// tslint:disable
export interface CurrencyDataProps {
	currency: string;
	symbol: string;
	digitalCode: string;
	name: string;
	country: string;
}

export const CurrenciesData: CurrencyDataProps[] = [
    {
        currency: 'AED',
        symbol: 'د.إ',
        digitalCode: '784',
        name: 'UAE Dirham',
        country: 'UAE',
    },
    {
        currency: 'AFN',
        symbol: 'Af',
        digitalCode: '971',
        name: 'Afghani',
        country: 'Afghanistan',
    },
    {
    	currency: 'ALL',
		symbol: 'L',
		digitalCode: '008',
		name: 'Lek',
		country: 'Albania',
    },
    {
        currency: 'AMD',
        symbol: 'Դ',
        digitalCode: '051',
        name: 'Armenian Dram',
        country: 'Armenia',
    },
    {
        currency: 'AOA',
        symbol: 'Kz',
        digitalCode: '973',
        name: 'Kwanza',
        country: 'Angola',
    },
	{
		currency: 'ARS',
		symbol: '$',
		digitalCode: '032',
		name: 'Argentine Peso',
		country: 'Argentina',
	},
	{
		currency: 'AUD',
		symbol: '$',
		digitalCode: '036',
		name: 'Australian Dollar',
		country: 'Australia'
	},
	{
		currency: 'AUD',
		symbol: '$',
		digitalCode: '036',
		name: 'Australian Dollar',
		country: 'Kiribati'
	},
	{
		currency: 'AUD',
		symbol: '$',
		digitalCode: '036',
		name: 'Australian Dollar',
		country: 'Coconut Islands'
	},
	{
		currency: 'AUD',
		symbol: '$',
		digitalCode: '036',
		name: 'Australian Dollar',
		country: 'Nauru'
	},
	{
		currency: 'AUD',
		symbol: '$',
		digitalCode: '036',
		name: 'Australian Dollar',
		country: 'Tuvalu'
	},
	{
		currency: 'AWG',
		symbol: 'ƒ',
		digitalCode: '533',
		name: 'Aruban Guilder/Florin',
		country: 'Aruba',
	},
	{
		currency: 'AZN',
		symbol: 'ман',
		digitalCode: '944',
		name: 'Azerbaijanian Manat',
		country: 'Azerbaijan',
	},
	{
		currency: 'BAM',
		symbol: 'КМ',
		digitalCode: '977',
		name: 'Konvertibilna Marka',
		country: 'Bosnia and Herzegovina',
	},
	{
		currency: 'BBD',
		symbol: '$',
		digitalCode: '052',
		name: 'Barbados Dollar',
		country: 'Barbados',
	},
	{
		currency: 'BDT',
		symbol: '৳',
		digitalCode: '050',
		name: 'Taka',
		country: 'Bangladesh',
	},
	{
		currency: 'BGN',
		symbol: 'лв',
		digitalCode: '975',
		name: 'Bulgarian Lev',
		country: 'Bulgaria',
	},
	{
		currency: 'BHD',
		symbol: 'ب.د',
		digitalCode: '048',
		name: 'Bahraini Dinar',
		country: 'Bahrain',
	},
	{
		currency: 'BIF',
		symbol: '₣',
		digitalCode: '108',
		name: 'Burundi Franc',
		country: 'Burundi',
	},
	{
		currency: 'BMD',
		symbol: '$',
		digitalCode: '060',
		name: 'Bermudian Dollar',
		country: 'Bermuda',
	},
	{
		currency: 'BND',
		symbol: '$',
		digitalCode: '096',
		name: 'Brunei Dollar',
		country: 'Brunei',
	},
	{
		currency: 'BND',
		symbol: '$',
		digitalCode: '096',
		name: 'Brunei Dollar',
		country: 'Singapore',
	},
	{
		currency: 'BOB',
		symbol: 'Bs.',
		digitalCode: '068',
		name: 'Boliviano',
		country: 'Bolivia',
	},
	{
		currency: 'BRL',
		symbol: 'R$',
		digitalCode: '986',
		name: 'Brazilian Real',
		country: 'Brazil',
	},
	{
		currency: 'BSD',
		symbol: '$',
		digitalCode: '044',
		name: 'Bahamian Dollar',
		country: 'Bahamas',
	},
	{
		currency: 'BTN',
		symbol: '',
		digitalCode: '064',
		name: 'Ngultrum',
		country: 'Bhutan',
	},
	{
		currency: 'BWP',
		symbol: 'P',
		digitalCode: '072',
		name: 'Pula',
		country: 'Botswana'
	},
	{
		currency: 'BYN',
		symbol: 'Br',
		digitalCode: '933',
		name: 'Belarusian Ruble',
		country: 'Belarus'
	},
	{
		currency: 'BZD',
		symbol: '$',
		digitalCode: '084',
		name: 'Belize Dollar',
		country: 'Belize'
	},
	{
		currency: 'CAD',
		symbol: '$',
		digitalCode: '124',
		name: 'Canadian Dollar',
		country: 'Canada'
	},
	{
		currency: 'CDF',
		symbol: '₣',
		digitalCode: '976',
		name: 'Congolese Franc',
		country: 'Congo (Kinshasa)'
	},
	{
		currency: 'CHF',
		symbol: '₣',
		digitalCode: '756',
		name: 'Swiss Franc',
		country: 'Lichtenstein',
	},
	{
		currency: 'CHF',
		symbol: '₣',
		digitalCode: '756',
		name: 'Swiss Franc',
		country: 'Switzerland'
	},
	{
		currency: 'CLP',
		symbol: '$',
		digitalCode: '152',
		name: 'Chilean Peso',
		country: 'Chile'
	},
	{
		currency: 'CNY',
		symbol: '¥',
		digitalCode: '156',
		name: 'Yuan',
		country: 'China'
	},
	{
		currency: 'COP',
		symbol: '$',
		digitalCode: '170',
		name: 'Colombian Peso',
		country: 'Colombia'
	},
	{
		currency: 'CRC',
		symbol: '₡',
		digitalCode: '188',
		name: 'Costa Rican Colon',
		country: 'Costa Rica'
	},
	{
		currency: 'CUP',
		symbol: '$',
		digitalCode: '192',
		name: 'Cuban Peso',
		country: 'Cuba'
	},
	{
		currency: 'CVE',
		symbol: '$',
		digitalCode: '132',
		name: 'Cape Verde Escudo',
		country: 'Cape Verde'
	},
	{
		currency: 'CZK',
		symbol: 'Kč',
		digitalCode: '203',
		name: 'Czech Koruna',
		country: 'Czech Republic'
	},
	{
		currency: 'DJF',
		symbol: '₣',
		digitalCode: '262',
		name: 'Djibouti Franc',
		country: 'Djibouti'
	},
	{
		currency: 'DKK',
		symbol: 'kr',
		digitalCode: '208',
		name: 'Danish Krone',
		country: 'Denmark'
	},
	{
		currency: 'DOP',
		symbol: '$',
		digitalCode: '214',
		name: 'Dominican Peso',
		country: 'Dominican Republic'
	},
	{
		currency: 'DZD',
		symbol: 'د.ج',
		digitalCode: '012',
		name: 'Algerian Dinar',
		country: 'Algeria'
	},
	{
		currency: 'EGP',
		symbol: '£',
		digitalCode: '818',
		name: 'Egyptian Pound',
		country: 'Egypt'
	},
	{
		currency: 'ERN',
		symbol: 'Nfk',
		digitalCode: '232',
		name: 'Nakfa',
		country: 'Eritrea'
	},
	{
		currency: 'ETB',
		symbol: '',
		digitalCode: '230',
		name: 'Ethiopian Birr',
		country: 'Ethiopia'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Akrotiri and Dhekelia'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Andorra'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Austria'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Belgium'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Cyprus'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Estonia'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Finland'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'France'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Germany'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Greece'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Ireland'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Italy'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Kosovo'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Latvia'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Lithuania'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Luxembourg'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Malta'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Monaco'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Montenegro'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Netherlands'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Portugal'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'San-Marino'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Slovakia'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Slovenia'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Spain'
	},
	{
		currency: 'EUR',
		symbol: '€',
		digitalCode: '978',
		name: 'Euro',
		country: 'Vatican'
	},
	{
		currency: 'FJD',
		symbol: '$',
		digitalCode: '242',
		name: 'Fiji Dollar',
		country: 'Fiji'
	},
	{
		currency: 'FKP',
		symbol: '£',
		digitalCode: '238',
		name: 'Falkland Islands Pound',
		country: 'Falkland Islands'
	},
	{
		currency: 'GBP',
	    symbol: '£',
		digitalCode: '826',
		name: 'Pound Sterling',
		country: 'Alderney',
	},
	{
		currency: 'GBP',
		symbol: '£',
		digitalCode: '826',
		name: 'Pound Sterling',
		country: 'British Indian Ocean Territory',
	},
	{
		currency: 'GBP',
		symbol: '£',
		digitalCode: '826',
		name: 'Pound Sterling',
		country: 'Great Britain',
	},
	{
		currency: 'GBP',
		symbol: '£',
		digitalCode: '826',
		name: 'Pound Sterling',
		country: 'Isle of Maine',
	},
	{
		currency: 'GEL',
		symbol: 'ლ',
		digitalCode: '981',
		name: 'Lari',
		country: 'Georgia'
	},
	{
		currency: 'GEL',
		symbol: 'ლ',
		digitalCode: '981',
		name: 'Lari',
		country: 'South Ossetia'
	},
	{
		currency: 'GHS',
		symbol: '₵',
		digitalCode: '936',
		name: 'Cedi',
		country: 'Ghana'
	},
	{
		currency: 'GIP',
		symbol: '£',
		digitalCode: '292',
		name: 'Gibraltar Pound',
		country: 'Gibraltar'
	},
	{
		currency: 'GMD',
		symbol: 'D',
		digitalCode: '270',
		name: 'Dalasi',
		country: 'Gambia'
	},
	{
		currency: 'GNF',
		symbol: '₣',
		digitalCode: '324',
		name: 'Guinea Franc',
		country: 'Guinea'
	},
	{
		currency: 'GTQ',
		symbol: 'Q',
		digitalCode: '320',
		name: 'Quetzal',
		country: 'Guatemala'
	},
	{
		currency: 'GYD',
		symbol: '$',
		digitalCode: '328',
		name: 'Guyana Dollar',
		country: 'Guyana'
	},
	{
		currency: 'HKD',
		symbol: '$',
		digitalCode: '344',
		name: 'Hong Kong Dollar',
		country: 'Hong Kong'
	},
	{
		currency: 'HNL',
		symbol: 'L',
		digitalCode: '340',
		name: 'Lempira',
		country: 'Honduras'
	},
	{
		currency: 'HRK',
		symbol: 'Kn',
		digitalCode: '191',
		name: 'Croatian Kuna',
		country: 'Croatia'
	},
	{
		currency: 'HTG',
		symbol: 'G',
		digitalCode: '332',
		name: 'Gourde',
		country: 'Haiti'
	},
	{
		currency: 'HUF',
		symbol: 'Ft',
		digitalCode: '348',
		name: 'Forint',
		country: 'Hungary'
	},
	{
		currency: 'IDR',
		symbol: 'Rp',
		digitalCode: '360',
		name: 'Rupiah',
		country: 'Indonesia'
	},
	{
		currency: 'ILS',
		symbol: '₪',
		digitalCode: '376',
		name: 'New Israeli Shekel',
		country: 'Israel'
	},
	{
		currency: 'ILS',
		symbol: '₪',
		digitalCode: '376',
		name: 'New Israeli Shekel',
		country: 'Palestine'
	},
	{
		currency: 'INR',
		symbol: '₹',
		digitalCode: '356',
		name: 'Indian Rupee',
		country: 'Bhutan'
	},
	{
		currency: 'INR',
		symbol: '₹',
		digitalCode: '356',
		name: 'Indian Rupee',
		country: 'India'
	},
	{
		currency: 'IQD',
		symbol: 'ع.د',
		digitalCode: '368',
		name: 'Iraqi Dinar',
		country: 'Iraq'
	},
	{
		currency: 'IRR',
		symbol: '﷼',
		digitalCode: '364',
		name: 'Iranian Rial',
		country: 'Iran'
	},
	{
		currency: 'ISK',
		symbol: 'Kr',
		digitalCode: '352',
		name: 'Iceland Krona',
		country: 'Iceland'
	},
	{
		currency: 'JMD',
		symbol: '$',
		digitalCode: '388',
		name: 'Jamaican Dollar',
		country: 'Jamaica'
	},
	{
		currency: 'JOD',
		symbol: 'د.ا',
		digitalCode: '400',
		name: 'Jordanian Dinar',
		country: 'Jordan'
	},
	{
		currency: 'JPY',
		symbol: '¥',
		digitalCode: '392',
		name: 'Yen',
		country: 'Japan'
	},
	{
		currency: 'KES',
		symbol: 'Sh',
		digitalCode: '404',
		name: 'Kenyan Shilling',
		country: 'Kenya'
	},
	{
		currency: 'KGS',
		symbol: '',
		digitalCode: '417',
		name: 'Som',
		country: 'Kyrgyzstan'
	},
	{
		currency: 'KHR',
		symbol: '៛',
		digitalCode: '116',
		name: 'Riel',
		country: 'Cambodia'
	},
	{
		currency: 'KPW',
		symbol: '₩',
		digitalCode: '408',
		name: 'North Korean Won',
		country: 'North Korea'
	},
	{
		currency: 'KRW',
		symbol: '₩',
		digitalCode: '410',
		name: 'South Korean Won',
		country: 'South Korea'
	},
	{
		currency: 'KWD',
		symbol: 'د.ك',
		digitalCode: '414',
		name: 'Kuwaiti Dinar',
		country: 'Kuwait'
	},
	{
		currency: 'KYD',
		symbol: '$',
		digitalCode: '136',
		name: 'Cayman Islands Dollar',
		country: 'Cayman Islands',
	},
	{
		currency: 'KZT',
		symbol: '〒',
		digitalCode: '398',
		name: 'Tenge',
		country: 'Kazakhstan'
	},
	{
		currency: 'LAK',
		symbol: '₭',
		digitalCode: '418',
		name: 'Kip',
		country: 'Laos'
	},
	{
		currency: 'LBP',
		symbol: 'ل.ل',
		digitalCode: '422',
		name: 'Lebanese Pound',
		country: 'Lebanon'
	},
	{
		currency: 'LKR',
		symbol: 'Rs',
		digitalCode: '144',
		name: 'Sri Lanka Rupee',
		country: 'Sri Lanka'
	},
	{
		currency: 'LRD',
		symbol: '$',
		digitalCode: '430',
		name: 'Liberian Dollar',
		country: 'Liberia'
	},
	{
		currency: 'LSL',
		symbol: 'L',
		digitalCode: '426',
		name: 'Loti',
		country: 'Lesotho'
	},
	{
		currency: 'LYD',
		symbol: 'ل.د',
		digitalCode: '434',
		name: 'Libyan Dinar',
		country: 'Libya'
	},
	{
		currency: 'MAD',
		symbol: 'د.م.',
		digitalCode: '504',
		name: 'Moroccan Dirham',
		country: 'Morocco'
	},
	{
		currency: 'MDL',
		symbol: 'L',
		digitalCode: '498',
		name: 'Moldovan Leu',
		country: 'Moldova'
	},
	{
		currency: 'MGA',
		symbol: '',
		digitalCode: '969',
		name: 'Malagasy Ariary',
		country: 'Madagascar'
	},
	{
		currency: 'MKD',
		symbol: 'ден',
		digitalCode: '807',
		name: 'Denar',
		country: 'Macedonia'
	},
	{
		currency: 'MMK',
		symbol: 'K',
		digitalCode: '104',
		name: 'Kyat',
		country: 'Myanmar (Burma)'
	},
	{
		currency: 'MNT',
		symbol: '₮',
		digitalCode: '496',
		name: 'Tugrik',
		country: 'Mongolia'
	},
	{
		currency: 'MOP',
		symbol: 'P',
		digitalCode: '446',
		name: 'Pataca',
		country: 'Macao'
	},
	{
		currency: 'MRU',
		symbol: 'UM',
		digitalCode: '929',
		name: 'Ouguiya',
		country: 'Mauritania'
	},
	{
		currency: 'MUR',
		symbol: '₨',
		digitalCode: '480',
		name: 'Mauritius Rupee',
		country: 'Mauritius'
	},
	{
		currency: 'MVR',
		symbol: 'ރ.',
		digitalCode: '462',
		name: 'Rufiyaa',
		country: 'Maldives'
	},
	{
		currency: 'MWK',
		symbol: 'MK',
		digitalCode: '454',
		name: 'Kwacha',
		country: 'Malawi'
	},
	{
		currency: 'MXN',
		symbol: '$',
		digitalCode: '484',
		name: 'Mexican Peso',
		country: 'Mexico'
	},
	{
		currency: 'MYR',
		symbol: 'RM',
		digitalCode: '458',
		name: 'Malaysian Ringgit',
		country: 'Malaysia'
	},
	{
		currency: 'MZN',
		symbol: 'MTn',
		digitalCode: '943',
		name: 'Metical',
		country: 'Mozambique'
	},
	{
		currency: 'NAD',
		symbol: '$',
		digitalCode: '516',
		name: 'Namibia Dollar',
		country: 'Namibia'
	},
	{
		currency: 'NGN',
		symbol: '₦',
		digitalCode: '566',
		name: 'Naira',
		country: 'Nigeria'
	},
	{
		currency: 'NIO',
		symbol: 'C$',
		digitalCode: '558',
		name: 'Cordoba Oro',
		country: 'Nicaragua'
	},
	{
		currency: 'NOK',
		symbol: 'kr',
		digitalCode: '578',
		name: 'Norwegian Krone',
		country: 'Norway'
	},
	{
		currency: 'NPR',
		symbol: '₨',
		digitalCode: '524',
		name: 'Nepalese Rupee',
		country: 'Nepal'
	},
	{
		currency: 'NZD',
		symbol: '$',
		digitalCode: '554',
		name: 'New Zealand Dollar',
		country: 'Cook Islands'
	},
	{
		currency: 'NZD',
		symbol: '$',
		digitalCode: '554',
		name: 'New Zealand Dollar',
		country: 'Cook Islands'
	},
	{
		currency: 'NZD',
		symbol: '$',
		digitalCode: '554',
		name: 'New Zealand Dollar',
		country: 'New Zealand'
	},
	{
		currency: 'NZD',
		symbol: '$',
		digitalCode: '554',
		name: 'New Zealand Dollar',
		country: 'Niue'
	},
	{
		currency: 'NZD',
		symbol: '$',
		digitalCode: '554',
		name: 'New Zealand Dollar',
		country: 'Pitcairn Island'
	},
	{
		currency: 'OMR',
		symbol: 'ر.ع.',
		digitalCode: '512',
		name: 'Rial Omani',
		country: 'Oman'
	},
	{
		currency: 'PAB',
		symbol: 'B/.',
		digitalCode: '590',
		name: 'Balboa',
		country: 'Panama'
	},
	{
		currency: 'PEN',
		symbol: 'S/.',
		digitalCode: '604',
		name: 'Nuevo Sol',
		country: 'Peru'
	},
	{
		currency: 'PGK',
		symbol: 'K',
		digitalCode: '598',
		name: 'Kina',
		country: 'Papua New Guinea'
	},
	{
		currency: 'PHP',
		symbol: '₱',
		digitalCode: '608',
		name: 'Philippine Peso',
		country: 'Philippines'
	},
	{
		currency: 'PKR',
		symbol: '₨',
		digitalCode: '586',
		name: 'Pakistan Rupee',
		country: 'Pakistan'
	},
	{
		currency: 'PLN',
		symbol: 'zł',
		digitalCode: '985',
		name: 'PZloty',
		country: 'Poland'
	},
	{
		currency: 'PYG',
		symbol: '₲',
		digitalCode: '600',
		name: 'Guarani',
		country: 'Paraguay'
	},
	{
		currency: 'QAR',
		symbol: 'ر.ق',
		digitalCode: '634',
		name: 'Qatari Rial',
		country: 'Qatar'
	},
	{
		currency: 'RON',
		symbol: 'L',
		digitalCode: '946',
		name: 'Leu',
		country: 'Romania'
	},
	{
		currency: 'RSD',
		symbol: 'din',
		digitalCode: '941',
		name: 'Serbian Dinar',
		country: 'Kosovo'
	},
	{
		currency: 'RSD',
		symbol: 'din',
		digitalCode: '941',
		name: 'Serbian Dinar',
		country: 'Serbia'
	},
	{
		currency: 'RUB',
		symbol: 'р. ',
		digitalCode: '643',
		name: 'Russian Ruble',
		country: 'Russia'
	},
	{
		currency: 'RUB',
		symbol: 'р. ',
		digitalCode: '643',
		name: 'Russian Ruble',
		country: 'South Ossetia'
	},
	{
		currency: 'RWF',
		symbol: '₣',
		digitalCode: '646',
		name: 'Rwanda Franc',
		country: 'Rwanda'
	},
	{
		currency: 'SAR',
		symbol: 'ر.س',
		digitalCode: '682',
		name: 'Saudi Riyal',
		country: 'Saudi Arabia'
	},
	{
		currency: 'SBD',
		symbol: '$',
		digitalCode: '090',
		name: 'Solomon Islands Dollar',
		country: 'Solomon Islands'
	},
	{
		currency: 'SCR',
		symbol: '₨',
		digitalCode: '690',
		name: 'Seychelles Rupee',
		country: 'Seychelles'
	},
	{
		currency: 'SDG',
		symbol: '£',
		digitalCode: '938',
		name: 'Sudanese Pound',
		country: 'Sudan'
	},
	{
		currency: 'SEK',
		symbol: 'kr',
		digitalCode: '752',
		name: 'Swedish Krona',
		country: 'Sweden'
	},
	{
		currency: 'SGD',
		symbol: '$',
		digitalCode: '702',
		name: 'Singapore Dollar',
		country: 'Brunei'
	},
	{
		currency: 'SGD',
		symbol: '$',
		digitalCode: '702',
		name: 'Singapore Dollar',
		country: 'Singapore'
	},
	{
		currency: 'SHP',
		symbol: '£',
		digitalCode: '654',
		name: 'Saint Helena Pound',
		country: 'Ascension Island'
	},
	{
		currency: 'SHP',
		symbol: '£',
		digitalCode: '654',
		name: 'Saint Helena Pound',
		country: 'Saint Helena'
	},
	{
		currency: 'SHP',
		symbol: '£',
		digitalCode: '654',
		name: 'Saint Helena Pound',
		country: 'Tristan da Cunha'
	},
	{
		currency: 'SLL',
		symbol: 'Le',
		digitalCode: '694',
		name: 'Leone',
		country: 'Sierra Leone'
	},
	{
		currency: 'SOS',
		symbol: 'Sh',
		digitalCode: '706',
		name: 'Somali Shilling',
		country: 'Somalia'
	},
	{
		currency: 'SRD',
		symbol: '$',
		digitalCode: '968',
		name: 'Suriname Dollar',
		country: 'Suriname'
	},
	{
		currency: 'STN',
		symbol: 'Db',
		digitalCode: '930',
		name: 'Dobra',
		country: 'Sao Tome and Principe'
	},
	{
		currency: 'SYP',
		symbol: 'ل.س',
		digitalCode: '760',
		name: 'Syrian Pound',
		country: 'Syria'
	},
	{
		currency: 'SZL',
		symbol: 'L',
		digitalCode: '748',
		name: 'Lilangeni',
		country: 'Swaziland'
	},
	{
		currency: 'THB',
		symbol: '฿',
		digitalCode: '764',
		name: 'Baht',
		country: 'Thailand'
	},
	{
		currency: 'TJS',
		symbol: 'ЅМ',
		digitalCode: '972',
		name: 'Somoni',
		country: 'Tajikistan'
	},
	{
		currency: 'TMT',
		symbol: 'm',
		digitalCode: '934',
		name: 'Manat',
		country: 'Turkmenistan'
	},
	{
		currency: 'TND',
		symbol: 'د.ت',
		digitalCode: '788',
		name: 'Tunisian Dinar',
		country: 'Tunisia'
	},
	{
		currency: 'TOP',
		symbol: 'T$',
		digitalCode: '776',
		name: 'Pa’anga',
		country: 'Tonga'
	},
	{
		currency: 'TRY',
		symbol: '₤',
		digitalCode: '949',
		name: 'Turkish Lira',
		country: 'North Cyprus'
	},
	{
		currency: 'TRY',
		symbol: '₤',
		digitalCode: '949',
		name: 'Turkish Lira',
		country: 'Turkey'
	},
	{
		currency: 'TTD',
		symbol: '$',
		digitalCode: '780',
		name: 'Trinidad and Tobago Dollar',
		country: 'Trinidad and Tobago'
	},
	{
		currency: 'TWD',
		symbol: '$',
		digitalCode: '901',
		name: 'Taiwan Dollar',
		country: 'Taiwan'
	},
	{
		currency: 'TZS',
		symbol: 'Sh',
		digitalCode: '834',
		name: 'Tanzanian Shilling',
		country: 'Tanzania'
	},
	{
		currency: 'UAH',
		symbol: '₴',
		digitalCode: '980',
		name: 'Hryvnia',
		country: 'Ukraine'
	},
	{
		currency: 'UGX',
		symbol: 'Sh',
		digitalCode: '800',
		name: 'Uganda Shilling',
		country: 'Uganda'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'American Samoa'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'British Indian Ocean Territory'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'British Virgin Islands'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'Guam'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'Haiti'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'Marshall Islands'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'Micronesia'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'Northern Mariana Islands'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'Pacific Remote islands'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'Palau'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'Panama'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'Puerto Rico'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'Turks and Caicos Islands'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'United States of America'
	},
	{
		currency: 'USD',
		symbol: '$',
		digitalCode: '840',
		name: 'US Dollar',
		country: 'US Virgin Islands'
	},
	{
		currency: 'UYU',
		symbol: '$',
		digitalCode: '858',
		name: 'Peso Uruguayo',
		country: 'Uruguay'
	},
	{
		currency: 'UZS',
		symbol: '',
		digitalCode: '860',
		name: 'Uzbekistan Sum',
		country: 'Uzbekistan'
	},
	{
		currency: 'VEF',
		symbol: 'Bs F',
		digitalCode: '937',
		name: 'Bolivar Fuerte',
		country: 'Venezuela'
	},
	{
		currency: 'VND',
		symbol: '₫',
		digitalCode: '704',
		name: 'Dong',
		country: 'Vietnam'
	},
	{
		currency: 'VUV',
		symbol: 'Vt',
		digitalCode: '548',
		name: 'Vatu',
		country: 'Vanuatu'
	},
	{
		currency: 'WST',
		symbol: 'T',
		digitalCode: '882',
		name: 'Tala',
		country: 'Samoa'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Benin'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Burkina Faso'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Cameroon'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Central African Republic'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Chad'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Congo (Brazzaville)'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: `Côte d'Ivoire`
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Equatorial Guinea'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Gabon'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Guinea-Bissau'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Mali'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Niger'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Senegal'
	},
	{
		currency: 'XAF',
		symbol: '₣',
		digitalCode: '950',
		name: 'CFA Franc BCEAO',
		country: 'Togo'
	},
	{
		currency: 'XCD',
		symbol: '$',
		digitalCode: '951',
		name: 'East Caribbean Dollar',
		country: 'Anguilla'
	},
	{
		currency: 'XCD',
		symbol: '$',
		digitalCode: '951',
		name: 'East Caribbean Dollar',
		country: 'Antigua and Barbuda'
	},
	{
		currency: 'XCD',
		symbol: '$',
		digitalCode: '951',
		name: 'East Caribbean Dollar',
		country: 'Dominica'
	},
	{
		currency: 'XCD',
		symbol: '$',
		digitalCode: '951',
		name: 'East Caribbean Dollar',
		country: 'Grenada'
	},
	{
		currency: 'XCD',
		symbol: '$',
		digitalCode: '951',
		name: 'East Caribbean Dollar',
		country: 'Montserrat'
	},
	{
		currency: 'XCD',
		symbol: '$',
		digitalCode: '951',
		name: 'East Caribbean Dollar',
		country: 'Saint Kitts and Nevis'
	},
	{
		currency: 'XCD',
		symbol: '$',
		digitalCode: '951',
		name: 'East Caribbean Dollar',
		country: 'Saint Lucia'
	},
	{
		currency: 'XCD',
		symbol: '$',
		digitalCode: '951',
		name: 'East Caribbean Dollar',
		country: 'Saint Vincent and Grenadine'
	},
	{
		currency: 'XPF',
		symbol: '₣',
		digitalCode: '953',
		name: 'CFP Franc',
		country: 'French Polynesia'
	},
	{
		currency: 'XPF',
		symbol: '₣',
		digitalCode: '953',
		name: 'CFP Franc',
		country: 'New Caledonia'
	},
	{
		currency: 'XPF',
		symbol: '₣',
		digitalCode: '953',
		name: 'CFP Franc',
		country: 'Wallis and Futuna'
	},
	{
		currency: 'YER',
		symbol: '﷼',
		digitalCode: '886',
		name: 'Yemeni Rial',
		country: 'Yemen'
	},
	{
		currency: 'ZAR',
		symbol: 'R',
		digitalCode: '710',
		name: 'Rand',
		country: 'Lesotho'
	},
	{
		currency: 'ZAR',
		symbol: 'R',
		digitalCode: '710',
		name: 'Rand',
		country: 'Namibia'
	},
	{
		currency: 'ZAR',
		symbol: 'R',
		digitalCode: '710',
		name: 'Rand',
		country: 'South Africa'
	},
	{
		currency: 'ZMW',
		symbol: 'ZK',
		digitalCode: '967',
		name: 'Zambian Kwacha',
		country: 'Zambia'
	},
	{
		currency: 'ZWL',
		symbol: '$',
		digitalCode: '932',
		name: 'Zimbabwe Dollar',
		country: 'Zimbabwe'
	}
					
];