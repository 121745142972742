/* eslint-disable react-hooks/exhaustive-deps */
import { Button, createStyles, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import { FetchPolicy } from 'apollo-client';
import { FormApi } from 'final-form';
import gql from 'graphql-tag';
// import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import { AnyObject, Field, Form, FormSpy } from 'react-final-form';
import { client } from '../..';
import { showNotification } from '../../App';
import { RvLoader } from '../../components/Loader';
// import { DocumentFile } from '../../types/DocumentFile';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        textField: {
            width: '100%'
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            }
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
        formGroup: {
            alignItems: 'flex-start',
            '& .MuiFormControlLabel-root': {
                marginLeft: 0,
            }
        }
    })
);

interface FormState {
    firmName:            string | null;
    firmAddress:         string | null;
    firmPhone:           string | null;
    firmWebsite:         string | null;
    firmEmailContact:    string | null;
    firmHelpDeskContact: string | null;
    // alternativeLogo: DocumentFile[];
}
interface FirmDetailsTabState {
    isLoading: boolean;
    isFormChanged: boolean;
    onSubmitLoading: boolean;
}

export default function FirmDetailsTab() {

    const classes = useStyles();

    const [state, setState] = useState<FirmDetailsTabState>({
        isLoading: true,
        isFormChanged: false,
        onSubmitLoading: false,
    });

    const [formState, setFormState] = useState<FormState>({
        firmName:            null,
        firmAddress:         null,
        firmPhone:           null,
        firmWebsite:         null,
        firmEmailContact:    null,
        firmHelpDeskContact: null,
        // alternativeLogo: []
    });

    useEffect(() => {
        fetchData();
    // tslint:disable-next-line: align
    }, []);
    
    const fetchData = () => {
        retrieveFirmDetailsConfigData(
            true,
            (data: FirmDetailsConfigData) => onFirmDetailsConfigRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onFirmDetailsConfigRetrieved = (data: FirmDetailsConfigData) => {
        if (data && data.settings && data.settings.systems && data.settings.systems.firmDetails && data.settings.systems.firmDetails.config) {

            const config = data.settings.systems.firmDetails.config;

            setFormState((prevFormState) => {
                return {
                    ...prevFormState,
                    firmName: config.firmName,
                    firmAddress: config.firmAddress,
                    firmPhone: config.firmPhone,
                    firmWebsite: config.firmWebsite,
                    firmEmailContact: config.firmEmailContact,
                    firmHelpDeskContact: config.firmHelpDeskContact,
                };
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false
                };
            });
        }
    };

    // const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = event.target.value;
    //     const name = event.target.name;

    //     setState((prevState) => {
    //         return {
    //             ...prevState,
    //             [name]: value
    //         };
    //     });
        
    // };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: AnyObject) => {

        setState((_prevState) => {
            return {
                ..._prevState,
                onSubmitLoading: true
            };
        });

        client
            .mutate({
                mutation: UpdateFirmDetailsMutation,
                variables: {
                    firmName: values.firmName,
                    firmAddress: values.firmAddress,
                    firmPhone: values.firmPhone,
                    firmWebsite: values.firmWebsite,
                    firmEmailContact: values.firmEmailContact,
                    firmHelpDeskContact: values.firmHelpDeskContact
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    showNotification(null, 'Successfully submitted', 'info');   
                    setState((_prevState) => {
                        return {
                            ..._prevState,
                            onSubmitLoading: false
                        };
                    });  
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to update firm details', reason, 'error');
                setState((_prevState) => {
                    return {
                        ..._prevState,
                        onSubmitLoading: false
                    };
                });
            });
    };

    return (
        <div className={classes.root}>
            {state.isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : (
                <Form
                    onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}  
                    initialValues={formState}
                    initialValuesEqual={() => true}
                    keepDirtyOnReinitialize={true}
                    subscription={{
                        submitting: true,
                        pristine: true
                    }}
                    render={({
                        handleSubmit,
                        form,
                        submitting,
                        pristine,
                        values
                    }) => (
                            <form onSubmit={handleSubmit}>
                                <FormSpy 
                                    subscription={{ pristine: true }}
                                    // tslint:disable-next-line: no-shadowed-variable
                                    onChange={props => {
                                        onFormValueChanged(props);
                                    }}
                                />
                                
                                <Grid container={true} spacing={3}>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="firmName"
                                            validate={required}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Firm Name"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    required={true}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Firm Name is required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="firmAddress"
                                            // validate={required}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Firm Address"
                                                    name="firmAddress"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    multiline={true}
                                                    rows={5}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Firm Address is required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="firmPhone"
                                            // validate={required}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Firm Phone"
                                                    // required={true}
                                                    name="firmPhone"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Firm Phone is required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="firmWebsite"
                                            // validate={required}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Firm Web Site"
                                                    name="firmWebsite"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Firm Web Site is required'
                                                            : ''
                                                    }
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="firmEmailContact"
                                            type="email"
                                            // validate={required}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Firm Email Contact"
                                                    name="firmEmailContact"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Firm Email Contact is required'
                                                            : ''
                                                    }
                                                    type="email"
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item={true} xs={12} md={6} lg={6} sm={12}>
                                        <Field
                                            name="firmHelpDeskContact"
                                            type="email"
                                            // validate={required}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Firm Help Desk Contact"
                                                    name="firmHelpDeskContact"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={
                                                        meta.error &&
                                                        meta.touched
                                                            ? 'Firm Help Desk Contact is required'
                                                            : ''
                                                    }
                                                    type="email"
                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    {/* <Grid item={true} xs={12} md={12} lg={12} sm={12}>
                                        <Field
                                            name="alternativeLogo"
                                            // subscription={{touched: true, error: true, value: true}}
                                        >
                                            {({ input, meta }) => (
                                                <DropzoneArea
                                                    {...input}
                                                    maxFileSize={15000000}
                                                    // acceptedFiles={['.msg', '.jpg', '.jpeg', '.png', '.docx', '.zip']}
                                                    showFileNames={true}
                                                    useChipsForPreview={true}
                                                    filesLimit={1}
                                                    onChange={(files: File[]) => {
                                                        let existingFiles: DocumentFile[] = [...input.value];
                                                        const newFiles = files.filter((file: File) => {
                                                            return !existingFiles.some((item: DocumentFile) => {
                                                                return item.name === file.name && item.status !== 2;
                                                            });
                                                        });
                                                        if (newFiles && newFiles.length > 0) {
                                                            newFiles.forEach((file: File) => {
                                                                const documentFile: DocumentFile = {
                                                                    guidID: null,
                                                                    file: file,
                                                                    status: 1,
                                                                    name: file.name,
                                                                    url: ''
                                                                };
                                                                existingFiles.push(documentFile);
                                                            });  
                                                            input.onChange(existingFiles);
                                                        }
                                                    }}
                                                    onDelete={(file: File) => {
                                                        let existingFiles: DocumentFile[] = [...input.value];
                                                        // eslint-disable-next-line array-callback-return
                                                        existingFiles.map((item: DocumentFile) => {
                                                            if (file.name === item.name) {
                                                                item.file = file;
                                                                item.status = 2; // deleted
                                                            }
                                                        });  
                                                        // tslint:disable-next-line: max-line-length
                                                        const filteredFiles = existingFiles.filter((item: DocumentFile) => {
                                                            return item.status !== 2 || item.guidID !== null;
                                                        });
                                                        input.onChange(filteredFiles);
                                                    }}                                                    
                                                    // tslint:disable-next-line:no-any
                                                    onDrop={(files: File[], event: any) => {
                                                        // let existingFiles: DocumentFile[] = [...input.value];
                                                        // tslint:disable-next-line: max-line-length
                                                        // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
                                                        // tslint:disable-next-line:no-console
                                                        console.log(event);
                                                    }}                                                  
                                                    initialFiles={input.value.map((file: { url: string; }) => file.url)}
                                                    dropzoneText="Alternate Mobile App Logo"
                                                />
                                            )}
                                        </Field>
                                    </Grid> */}
                                    <Grid item={true} xs={12} md={12} justify="flex-end">
                                        <div className={classes.buttonWrapper}>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="submit"
                                                disabled={submitting || pristine || state.onSubmitLoading}
                                            >
                                                Save
                                            </Button>
                                            <Button 
                                                variant="outlined" 
                                                color="primary"
                                                type="button"
                                                disabled={submitting || pristine}
                                                onClick={onReset(form)}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </Grid>
                                    {/* {printJson(values)} */}
                            </Grid>
                            </form>
                        )}
                />
            )}
        </div>
    );
}

export function retrieveFirmDetailsConfigData(
    refreshData: boolean,
    onSuccess: (data: FirmDetailsConfigData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
            query: FirmDetailsConfigQuery,
            variables: {},
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any ) => { onError(reason); });
}

export interface FirmDetailsConfigData {
    settings: Settings;
}

export interface Settings {
    systems: Systems;
}

export interface Systems {
    id:          number;
    firmDetails: FirmDetails;
}

export interface FirmDetails {
    id:     number;
    config: Config;
}

export interface Config {
    id:                  number;
    firmName:            string;
    firmAddress:         string;
    firmPhone:           string;
    firmWebsite:         string;
    firmEmailContact:    string;
    firmHelpDeskContact: string;
}

export const FirmDetailsConfigQuery = gql`
    query FirmDetailsConfig{
        settings {
            id,
            systems {
                id,
                firmDetails {
                    id,
                    config {
                        id,
                        firmName,
                        firmAddress,
                        firmPhone,
                        firmWebsite,
                        firmEmailContact,
                        firmHelpDeskContact,
                    }
                }
            }
        }
    }
`;

const UpdateFirmDetailsMutation = gql`
    mutation updateFirmDetails(
        $firmName: String, 
        $firmAddress: String, 
        $firmPhone: String,
        $firmWebsite: String, 
        $firmEmailContact: String, 
        $firmHelpDeskContact: String
    ) {
      settings{
        firmDetails {
             update (
                firmName: $firmName, 
                firmAddress: $firmAddress, 
                firmPhone: $firmPhone,
                firmWebsite: $firmWebsite, 
                firmEmailContact: $firmEmailContact, 
                firmHelpDeskContact: $firmHelpDeskContact
            ) {
                    status,
                    error
                }
            }
        }
    }
`;

// tslint:disable-next-line: no-any
export function printJson(values: any) {
    if (values) {
        return (
            <>
                <pre>
                    {JSON.stringify(values, undefined, 2)}
                </pre>
            </>
        );
    } else {
        return (
            <>
                <FormSpy subscription={{ values: true }}>
                    {/* tslint:disable-next-line: no-shadowed-variable */}
                    {({ values }) => (
                        <pre>
                            {JSON.stringify(values, undefined, 2)}
                            {/* <RenderCount /> */}
                        </pre>
                    )}
                </FormSpy>
            </>
        );
    }
}