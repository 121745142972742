/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar, EditComponentProps, Column } from 'material-table';
import { 
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Breadcrumbs,
    Link,
    Typography,
    Chip
} from '@material-ui/core';
import { Colors, hashString } from '../../tasksSummary/board/BoardCardDraggable';
import { SemanticCOLORS } from 'semantic-ui-react';
import { RouterProps, showNotification } from '../../App';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '../..';
import { Icons } from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { UserAutocompleteSelector } from '../../components/UserAutocompleteSelector';
import { useStyles } from '../../lookupConfiguration/common/tabsStyle';

interface TemporaryDataFormat {
    // tslint:disable-next-line: no-any
    user: IAutoCompleteItem | any;
    guidId: string;
}

interface LookupUserAssistantMemberMaintenanceProps extends RouterProps {}

interface TableData extends TemporaryDataFormat {
    tableData: { 
        checked: boolean;
        id: number;
    };
}

interface LookupUserAssistantMemberMaintenanceState {
    // tslint:disable-next-line: no-any
    data: TemporaryDataFormat[];
    isLoading: boolean;
    onDeleteItems: TableData[];
}

export default function LookupUserAssistantMemberMaintenance(props: LookupUserAssistantMemberMaintenanceProps) {

    const classes = useStyles();

    const [state, setState] = useState<LookupUserAssistantMemberMaintenanceState>({
        isLoading: false,
        data: [],
        onDeleteItems: [],
    });

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    useEffect(() => {
        
        // groupId
        const { command } = props.match.params;

        const guID = command.split(';key=', 2)[1];

        if (command) {
            fetchData(guID);
        }

        const subscribeFirmObj = subscribeFirm();

        return () => {
            subscribeFirmObj.unsubscribe();
        };
    // tslint:disable-next-line: align
    }, []);

    const tableIcons: Icons = {
        Add: forwardRef((iconProps, ref) => <AddBox {...iconProps} ref={ref}  className={classes.icon} />),
        Check: forwardRef((iconProps, ref) => <Check {...iconProps} ref={ref}  className="check-button" />),
        Clear: forwardRef((iconProps, ref) => <Clear {...iconProps} ref={ref}  className={classes.icon} />),
        Delete: forwardRef((iconProps, ref) => <DeleteOutline {...iconProps} ref={ref}  className={classes.icon} />),
        DetailPanel: forwardRef((iconProps, ref) => <ChevronRight {...iconProps} ref={ref}  className={classes.icon} />),
        Edit: forwardRef((iconProps, ref) => <Edit {...iconProps} ref={ref}  className={classes.icon} />),
        Export: forwardRef((iconProps, ref) => <SaveAlt {...iconProps} ref={ref}  className={classes.icon} />),
        Filter: forwardRef((iconProps, ref) => <FilterList {...iconProps} ref={ref}  className={classes.icon} />),
        FirstPage: forwardRef((iconProps, ref) => <FirstPage {...iconProps} ref={ref}  className={classes.icon} />),
        LastPage: forwardRef((iconProps, ref) => <LastPage {...iconProps} ref={ref}  className={classes.icon} />),
        NextPage: forwardRef((iconProps, ref) => <ChevronRight {...iconProps} ref={ref}  className={classes.icon} />),
        PreviousPage: forwardRef((iconProps, ref) => <ChevronLeft {...iconProps} ref={ref}  className={classes.icon} />),
        ResetSearch: forwardRef((iconProps, ref) => <Clear {...iconProps} ref={ref}  className={classes.icon} />),
        Search: forwardRef((iconProps, ref) => <Search {...iconProps} ref={ref}  className={classes.icon} />),
        SortArrow: forwardRef((iconProps, ref) => <ArrowDownward {...iconProps} ref={ref} />),
        ThirdStateCheck: forwardRef((iconProps, ref) => <Remove {...iconProps} ref={ref}  className={classes.icon} />),
        ViewColumn: forwardRef((iconProps, ref) => <ViewColumn {...iconProps} ref={ref}  className={classes.icon} />)
    };

    const subscribeFirm = () => {
        return (
            // let subscription =
            client
                .subscribe({
                    fetchPolicy: 'no-cache',
                    query: userAssistantSubscription,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results: any) {
                        if (results.data.userAssistantNotification.status) {

                            const { userAssistantNotification } = results.data;

                            showNotification('Success', userAssistantNotification.message, 'info');

                            if (userAssistantNotification.message.includes('UserAssistant updated successfully')) {
                                setState((prevState) => {

                                    const tdata = [...prevState.data];

                                    const temp = tdata[tdata.length - 1];

                                    tdata[tdata.length - 1] = {
                                        ...temp,
                                        guidId: userAssistantNotification.guid
                                    };

                                    return {
                                        ...prevState,
                                        data: tdata
                                    };
                                });
                            }

                        } else {
                            showNotification('Failed', results.data.userAssistantNotification.message, 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };

    const fetchData = (userGuid: string) => {

        setState({
            ...state,
            isLoading: true,
        });

        const userAssistantsQueryParams: UserAssistantsQueryParams = {
            userGuid: userGuid
        };

        retrieveUserAssistantsData(
            userAssistantsQueryParams,
            true, // refresh
            (data) => onRetrieveData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onRetrieveData = (data: UserAssistantsData) => {

        const userAssistants = data.userAssistants.userAssistants;

        const tempData = userAssistants.map((user) => {
            return {
                user: {
                    value: user.userGuid, 
                    label: user.friendlyName
                },
                guidId: user.guid
            };
        });

        setState({
            ...state,
            data: userAssistants.length > 0 ? tempData : [],
            isLoading: false
        });
    };

    const updateRowData = (newData: TemporaryDataFormat, oldData: TemporaryDataFormat) => {

        setState((prevState) => {

            const tdata = [...prevState.data];
            
            if (tdata.some((e: TemporaryDataFormat) => e.user.value.toLowerCase() === newData.user.value.toLowerCase())) {
                return { 
                    ...prevState, 
                };
            } 

            tdata[tdata.indexOf(oldData)] = newData;
            
            return { 
                ...prevState, 
                data: tdata
            };
            
        });
    };

    const updateData = (rowData: TemporaryDataFormat) => {
        const userToAssistId = props.match.params.command.split(';key=', 2)[1];

        if (rowData) {
            client
                .mutate({
                    mutation: UpdateUserAssistantMutation,
                    variables: {
                        input: {
                            guid: rowData.guidId,
                            userToAssistId: userToAssistId,
                            assistantId: rowData.user.value,
                        }
                    },
                })
                // tslint:disable-next-line: no-any
                .then((results: { data: any }) => {
                    if (results.data) {
                        if (results.data.error !== undefined) {
                            showNotification( 'Failed to Update User Assistant', results.data.error, 'error');
                        }
                    }
                })
                // tslint:disable-next-line:no-any
                .catch((reason: any) => {
                    showNotification('Failed to Update User Assistant', reason, 'error');
                });
        }
    };

    // tslint:disable-next-line: no-any
    const insertRowData = (newData: TemporaryDataFormat) => {
        
        setState((prevState) => {

            const tdata = [...prevState.data];

            if (tdata.some((e: TemporaryDataFormat) => e.user && e.user.value.toLowerCase() === newData.user.value.toLowerCase())) {
                return { 
                    ...prevState, 
                };
            } 

            tdata.push(newData);

            return { 
                ...prevState, 
                data: tdata
            };
        });
    };

    // tslint:disable-next-line: no-any
    const deleteRowData = (oldData: any) => {

        setState((prevState) => {
            const tdata = [...prevState.data];
            tdata.splice(tdata.indexOf(oldData), 1);
            return { 
                ...prevState, 
                data: tdata 
            };
        });
        
    };

    // tslint:disable-next-line: no-any
    const createData = (newData: any) => {
        if (newData) {

            const userToAssistId = props.match.params.command.split(';key=', 2)[1];

            client
                .mutate({
                    mutation: CreateUserAssistantMutation,
                    variables: {
                        input: {
                            guid: null,
                            userToAssistId: userToAssistId,
                            assistantId: newData.user.value,
                        }
                    },
                })
                // tslint:disable-next-line: no-any
                .then((results: { data: any }) => {
                    if (results.data) {
                        if (results.data.error !== undefined) {
                            showNotification( 'Failed to Create User Assitant', results.data.error, 'error');
                        }
                    }
                })
                // tslint:disable-next-line:no-any
                .catch((reason: any) => {
                    showNotification('Failed to Create User Assitant', reason, 'error');
                });
        }
    };

    const deleteData = (oldData: TemporaryDataFormat) => {
        client
            .mutate({
                mutation: DeleteUserAssistant,
                variables: {
                    guid: oldData.guidId,
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error !== undefined) {
                        showNotification( 'Failed to Delete User Assitant', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Delete User Assitant', reason, 'error');
            });
    };

    const handleBreadCrumbClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        props.history.goBack();
    };

    // tslint:disable-next-line: no-any
    const onDeleteData = (data: any) => {

        setShowDeleteConfirm(true);

        setState((prevState) => {
            return {
                ...prevState,
                onDeleteItems: data,
            };
        });
        // onDeleteItems = data;

    };

    const onDeleteConfirm = (agree: boolean) => {
        if (agree) {
            // tslint:disable-next-line: no-any
            state.onDeleteItems.forEach((selected: any) => {

                // delete check data
                deleteData(selected);

                setState((prevState) => {
                    const tdata = [...prevState.data];
                    tdata.splice(tdata.indexOf(selected), 1);
                    return { 
                        ...prevState, 
                        data: tdata 
                    };
                });

            });

            setState((prevState) => {
                return {
                    ...prevState,
                    onDeleteItems: []
                };
            });

        }

        setShowDeleteConfirm(false);
    };

    const tableHeading = () => {

        const title = decodeURIComponent(props.match.params.command.split(';key=', 2)[0]);

        return (
            <div className={classes.breadcrumbsWrapper}>
                <Breadcrumbs separator="›" aria-label="breadcrumb" className={classes.breadcrumbs}>
                    <Link color="inherit" href="/" onClick={handleBreadCrumbClick}>
                        User Assistant
                    </Link>
                    <Typography color="textPrimary" className={classes.breadcrumbsTitle}>{title}</Typography>
                </Breadcrumbs>
                <Chip className={classes.chip} label={state.data.length} />
            </div>
        );
    };

    // tslint:disable-next-line: no-any
    const userSelectorComponent = (componentProps: EditComponentProps<any> | any) => {
        
        return (
            <UserAutocompleteSelector 
                name="user"
                label="User"
                required={true}
                value={componentProps.value}
                isExternal={false}
                onSelection={(selection: IAutoCompleteItem, name: string) => {
                    componentProps.onChange(selection);
                }}
                error={componentProps.error}
                disablePortal={false}
                className={classes.textField}
                helperText={componentProps.helperText}
            />
        );
    };
    
    // tslint:disable-next-line: no-any
    const userRender = (data: any) => {

        if (data.user) {

            const user = data.user;

            let bColor: SemanticCOLORS = Colors[(user.label === '' || user.label === null) ? 
            Colors[Colors.length] : (hashString(user.label) % Colors.length) - 1];
            // tslint:disable-next-line: no-any
            let stringSplit = user.label
            .replace(/[^\w\s]/gi, '') // remove special character
            .split(' ')
            // tslint:disable-next-line: no-any
            .map((n: any) => n[0])
            .join('');

            let stringInitials = stringSplit.slice(0, 2);
            
            return (
                <React.Fragment>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                style={{ backgroundColor: bColor ? bColor : 'grey', color: '#fff' }}
                                className={classes.avatar}
                            >
                                {stringInitials}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.label} />
                    </ListItem>
                </React.Fragment>
            );
        }

        return undefined;
    };

    // tslint:disable-next-line: no-any
    const customFilterAndSearch = (filter: any, rowData: TemporaryDataFormat, columnDef: Column<TemporaryDataFormat>) => {
        return rowData && rowData.user && rowData.user.label.toLowerCase().includes(filter.toLowerCase());
    };

    return (
        <div className={classes.root}>
            <DialogBox
                title="Assistant"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to delete the selected row?`}
                show={showDeleteConfirm}
                isAgree={onDeleteConfirm}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
                className={classes.dialogBox}
            />
            <MaterialTable
                isLoading={state.isLoading}
                title={tableHeading()}
                icons={tableIcons}
                columns={[
                    { 
                        title: 'Users', 
                        field: 'user', 
                        validate: (rowData) => rowData.hasOwnProperty('user') && rowData.user 
                            ? true 
                            : { isValid: false, helperText: 'User is required'},
                        render: (rowData) => userRender(rowData), cellStyle: { flex: 3 }, headerStyle: { flex: 3 },
                        editComponent: (componentData) => userSelectorComponent(componentData),
                        customFilterAndSearch: customFilterAndSearch
                    }
                ]}
                data={state.data}  
                components={
                    {
                        // tslint:disable-next-line: max-line-length
                        Toolbar: toolbarProps => (
                            <div className={classes.customToolbar}>
                                <MTableToolbar classes={{ highlight: classes.highlight, actions: classes.actions }} {...toolbarProps} />
                            </div>
                        )
                    }
                }      
                options={{
                    // search: true,
                    addRowPosition: 'first',
                    selection: true,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 40, 50],
                    // showTitle: true,
                    // toolbar: true,
                    actionsColumnIndex: -1,
                }}
                editable={{
                    onRowAdd: (newData) =>
                        // tslint:disable-next-line: no-any
                        new Promise((resolve: any) => {
                            setTimeout(() => {
                                resolve();
                                if (newData) {
                                    createData(newData);
                                    insertRowData(newData);
                                }
                            // tslint:disable-next-line: align
                            }, 600);
                        }),
                    onRowUpdate: (newData, oldData) =>
                        // tslint:disable-next-line: no-any
                        new Promise((resolve: any) => {
                            setTimeout(() => {
                            resolve();
                            if (oldData) {
                                updateData(newData);
                                updateRowData(newData, oldData);
                            }
                            // tslint:disable-next-line: align
                            }, 600);
                        }),
                    onRowDelete: (oldData) =>
                        // tslint:disable-next-line: no-any
                        new Promise((resolve: any) => {
                            setTimeout(() => {
                                resolve();
                                if (oldData) {
                                    deleteData(oldData);
                                    deleteRowData(oldData);
                                }
                            // tslint:disable-next-line: align
                            }, 600);
                        }),
                }}
                actions={[
                    {
                    tooltip: 'Remove All Selected Users',
                    icon: DeleteOutline,
                        // tslint:disable-next-line: no-any
                        onClick: (evt: any, data: any) => onDeleteData(data)
                    }
                ]}
            />
        </div>
    );
}

export interface UserAssistantsData {
    loading?: boolean;
    networkStatus?: number;
    userAssistants: UserAssistants;
}

export interface UserAssistants {
    userAssistants: UserAssistant[];
}

export interface UserAssistant {
    userGuid: string;
    email:    string;
    friendlyName: string;
    guid: string;
}
export interface UserAssistantsQueryParams {
    userGuid: string | null;
}

export function retrieveUserAssistantsData(
    query: UserAssistantsQueryParams,
    refreshData: boolean,
    onSuccess: (data: UserAssistantsData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: UserAssistantsQuery,
            variables: {
                userGuid: query.userGuid
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const UserAssistantsQuery = gql`
    query UserAssistants($userGuid: String) {
        userAssistants(userGuid: $userGuid) {
            userAssistants {
                userGuid
                email
                friendlyName
                guid
            }
        }
    }
`;

const CreateUserAssistantMutation = gql`
    mutation createUserAssistant(
        $input: CreateUserAssistantInputType, 
    ) {
        createUserAssistant(
            input: $input, 
        ) {
            status,
            error
        }
    }
`;

const UpdateUserAssistantMutation = gql`
    mutation updateUserAssistant(
        $input: UpdateUserAssistantInputType, 
    ) {
        updateUserAssistant(
            input: $input, 
        ) {
            status,
            error
        }
    }
`;
  
const DeleteUserAssistant = gql`
    mutation DeleteUserAssistant($guid: String) {
        deleteUserAssistant(guid: $guid) {
            error
            status
        }
    }
`;

export const userAssistantSubscription = gql`
    subscription {
        userAssistantNotification  {
            id
            status
            message
            description
            guid
        }
    }
`;