import * as React from 'react';
import { CardMedia, CardContent, withStyles, WithStyles, Typography, FormControlLabel, Card, Switch, IconButton } from '@material-ui/core';
import glamorous from 'glamorous';
import { styles } from './styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';

// tslint:disable-next-line: no-any
const CardDraggable = glamorous.div<{theme?: any}> ((props) => ({
    // paddingRight: '25px', 
}));

export interface FeatureLayoutCardCloneProps extends WithStyles<typeof styles> {
    featureGuid: string;
    feature: string;
    title: string;
    description: string;
    isHidden: boolean;
    sequence: number;
    onIsHiddenUpdated?: (featureGuid: string, isHidden: boolean) => void;
    itemPerRow: number;
    windowInnterWidth: number;
    infoUrl: string | null;
}

export interface FeatureLayoutCardCloneState {
    isVisible: boolean;
    hiddenText: string;
}

class FeatureLayoutCardClone 
    extends React.Component<FeatureLayoutCardCloneProps, FeatureLayoutCardCloneState>  {
    constructor(props: FeatureLayoutCardCloneProps, state: FeatureLayoutCardCloneState) {
        super(props, state);

        this.onHiddenChange = this.onHiddenChange.bind(this);
        this.getWidthPerRow = this.getWidthPerRow.bind(this);
        
        this.state = {
            isVisible: false,
            hiddenText: 'Visible'
        };
    }

    // tslint:disable-next-line: no-empty
    componentDidMount() {
        this.setState({
            isVisible: !this.props.isHidden,
            hiddenText: this.props.isHidden ? 'Hidden' : 'Visible'
        });
    }

    render() {
        const { classes } = this.props;
        
        return (
            <CardDraggable
                style={{
                    width: '100%',
                    height: '100%',                            
                    display: 'flex', 
                    padding: '8px', 
                    // maxWidth: 400,
                    // minWidth: 400,       
                    // tslint:disable-next-line: max-line-length
                    minWidth: this.getWidthPerRow(this.props.itemPerRow, this.props.windowInnterWidth),
                    // tslint:disable-next-line: max-line-length
                    maxWidth: this.getWidthPerRow(this.props.itemPerRow, this.props.windowInnterWidth)                                                   
                }}                    
            >
                <div className={classes.cardWrapper}>
                    <Card 
                        className={classes.cardContainer}
                        style={{
                            backgroundColor: !this.state.isVisible ? '#C7C7C7' : '#fff'
                        }}
                    >
                        <div className={classes.cardMedia}>
                            <div className="image">
                                <CardMedia
                                    className={classes.cardCover}                                                
                                    // image={                                                   
                                    //     require('../images/TrendingUp.png')
                                    // }                                                
                                    title="Sample Image"
                                >                                       
                                    <TrendingUpIcon color={'primary'} fontSize={'large'}/>
                                </CardMedia>  
                            </div>
                            <div className="text">
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="subtitle1">
                                        {this.props.title}
                                    </Typography>
                                </CardContent>
                            </div>
                        </div>
                        <div className={classes.controlContainer}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size="small"
                                        checked={this.state.isVisible}
                                        onChange={this.onHiddenChange}
                                        value={this.state.isVisible}
                                        color="primary"
                                    />
                                }
                                label={this.state.hiddenText}
                            />
                            <div className={classes.rightControl}>
                                {this.props.infoUrl && (
                                    <IconButton 
                                        aria-label="learn more" 
                                        size="small" 
                                        className={classes.videoButton}
                                    >
                                        <VideoLibraryOutlinedIcon />
                                    </IconButton>
                                )}
                                <InfoIcon
                                    key={this.props.feature}
                                    aria-label="More Info"
                                    color={'primary'}
                                />
                            </div>
                        </div>                               
                    </Card>
                </div>      
            </CardDraggable>          
        );
    }

    private getWidthPerRow(itemPerRow: number, windowInnterWidth: number) {
        if (itemPerRow === 4) {
            return `${(windowInnterWidth - 173) / 4}px`;
        } else if (itemPerRow === 3) {
            if (windowInnterWidth >= 1025) {
                return `${(windowInnterWidth - 173) / 3}px`;
            } else {
                return `${(windowInnterWidth - 105) / 3}px`;
            }
        } else if (itemPerRow === 2) {
            return `${(windowInnterWidth - 105) / 2}px`;
        } else {
            return '100%';
        }
    }

    private onHiddenChange(event: React.FormEvent<HTMLInputElement>) {
        this.setState({
            isVisible: !this.state.isVisible,
            hiddenText: !this.state.isVisible ? 'Visible' : 'Hidden'
        });

        if (this.props.onIsHiddenUpdated) {
            this.props.onIsHiddenUpdated(this.props.featureGuid, !this.state.isVisible);
        }
    }
}

// tslint:disable-next-line: no-any
export default withStyles(styles as any, { withTheme: true })(FeatureLayoutCardClone as any) as any;