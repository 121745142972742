/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import MaterialTable, { Column, EditComponentProps, MTableToolbar } from 'material-table';
import { 
    Avatar, 
    ListItem, 
    ListItemAvatar, 
    ListItemText, 
    Badge,
    Typography, 
    Chip
} from '@material-ui/core';
import { Colors, hashString } from '../../tasksSummary/board/BoardCardDraggable';
import { SemanticCOLORS } from 'semantic-ui-react';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { browserHistory } from '../../components/AppContent';
import { Icons } from 'material-table';
import { forwardRef } from 'react';
 
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { retrieveUserListData, UserAutocompleteSelector, UserListData, UserQueryParams } from '../../components/UserAutocompleteSelector';
import { showNotification } from '../../App';
import { useStyles } from '../../lookupConfiguration/common/tabsStyle';
// import { FirmData, Team } from '../models/Firm';

interface TemporaryDataFormat {
    // tslint:disable-next-line: no-any
    user: IAutoCompleteItem | any;
    count: number;
}

interface LookupUserAssistantMaintenanceState {
    // tslint:disable-next-line: no-any
    selectedRow: any | null;
    data: TemporaryDataFormat[];
    isLoading: boolean;
}

// tslint:disable-next-line: no-any
let onDeleteItems: any = [];

export default function LookupUserAssistantMaintenance() {

    const classes = useStyles();

    const [state, setState] = useState<LookupUserAssistantMaintenanceState>({
        selectedRow: null,
        data: [],
        isLoading: false,
    });

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    useEffect(() => {   

        fetchData();

        // const subscribeFirmObj = subscribeFirm();

        // return () => {
        //     subscribeFirmObj.unsubscribe();
        // };
    // tslint:disable-next-line: align
    }, []);

    const tableIcons: Icons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}  className={classes.icon} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref}  className="check-button" />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}  className={classes.icon} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}  className={classes.icon} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}  className={classes.icon} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}  className={classes.icon} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}  className={classes.icon} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}  className={classes.icon} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}  className={classes.icon} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}  className={classes.icon} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}  className={classes.icon} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}  className={classes.icon} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}  className={classes.icon} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref}  className={classes.icon} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}  className={classes.icon} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}  className={classes.icon} />)
    };

    // const subscribeFirm = () => {
    //     return (
    //         // let subscription =
    //         client
    //             .subscribe({
    //                 fetchPolicy: 'no-cache',
    //                 query: firmSubscription,
    //                 variables: {},
    //             })
    //             .subscribe({
    //                 // tslint:disable-next-line:no-any
    //                 next(results: any) {
    //                     if (results.data.lookupNotification.status) {

    //                         const { lookupNotification } = results.data;
    //                         showNotification('Success', lookupNotification.message, 'info');

    //                         if (lookupNotification.message.includes('created successfully')) {
    //                             setState((prevState) => {

    //                                 const tdata = [...prevState.data];

    //                                 const temp = tdata[tdata.length - 1];

    //                                 tdata[tdata.length - 1] = {
    //                                     ...temp,
    //                                     guidID: lookupNotification.guidId
    //                                 };

    //                                 return {
    //                                     ...prevState,
    //                                     data: tdata
    //                                 };
    //                             });
    //                         }

    //                     } else {
    //                         showNotification('Failed', results.data.lookupNotification.message, 'error');
    //                     }
    //                 },
    //                 // tslint:disable-next-line:no-any
    //                 error(err: any) {
    //                     // tslint:disable-next-line:no-console
    //                     console.error(err);
    //                     showNotification(null, err, 'error');
    //                 },
    //             })
    //     );
    // };

    const fetchData = () => {

        setState({
            ...state,
            isLoading: true,
        });
        const userQueryParams: UserQueryParams = {
            // filter: filter,
            // first: loadBlockLimit,
            // offset: state.offset,
            isStaff: null,
            isExternal: false
        };

        retrieveUserListData(
            userQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveData(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');

                setState((prevState) => {
                    return {
                        ...prevState,
                        isNextPageLoading: false,
                    };
                });
            }
        );
    };

    const onRetrieveData = (data: UserListData) => {

        const userData = data.administrator.users.user;

        const tempData = userData.map((user) => {
            return {
                user: {
                    value: user.userID, 
                    label: user.isStaff ? user.staffName : user.friendlyName,
                },
                count: user.assistantCount
            };
        });

        setState((prevState) => {
            return {
                ...prevState,
                data: tempData,
                isLoading: false
            };
        });
    };

    // tslint:disable-next-line: no-any
    const userRender = (data: any) => {

        if (data.user) {

            const user = data.user;

            let bColor: SemanticCOLORS = Colors[(user.label === '' || user.label === null) ? 
            Colors[Colors.length] : (hashString(user.label) % Colors.length) - 1];
            // tslint:disable-next-line: no-any
            let stringSplit = user.label
            .replace(/[^\w\s]/gi, '') // remove special character
            .split(' ')
            // tslint:disable-next-line: no-any
            .map((n: any) => n[0])
            .join('');

            let stringInitials = stringSplit.slice(0, 2);
            
            return (
                <React.Fragment>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar
                                style={{ backgroundColor: bColor ? bColor : 'grey', color: '#fff' }}
                                className={classes.avatar}
                            >
                                {stringInitials}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={user.label} />
                    </ListItem>
                </React.Fragment>
            );
        }

        return undefined;
    };

    // tslint:disable-next-line: no-any
    const memberCountRender = (data: any) => {

        let count = 0;

        if (data && data.count) {
            count = data.count;
        }

        return (
            <div>
                <Badge color="primary" badgeContent={count}>
                    <PeopleOutlineIcon />
                </Badge>
            </div>
        );
    };

    // tslint:disable-next-line: no-any
    const handleRowClick = (event?: React.MouseEvent, rowData?: any, toggleDetailPanel?: (panelIndex?: number) => void) => {

        // tslint:disable-next-line: no-console
        console.log('rowData', rowData);

        setState({
            ...state,
            selectedRow: rowData.tableData.id
        });

        browserHistory.push(`/firmStructureConfiguration/userAssistant/${encodeURIComponent(rowData.user.label)};key=${rowData.user.value}`);
    };

    // const updateRowData = (newData: TemporaryDataFormat, oldData: TemporaryDataFormat) => {

    //     setState((prevState) => {

    //         const tdata = [...prevState.data];
            
    //         if (tdata.some((e: TemporaryDataFormat) => e.user.value.toLowerCase() === newData.user.value.toLowerCase())) {
    //             return { 
    //                 ...prevState, 
    //             };
    //         } 

    //         tdata[tdata.indexOf(oldData)] = newData;
            
    //         return { 
    //             ...prevState, 
    //             data: tdata
    //         };
            
    //     });
    // };

    // const updateData = (rowData: TemporaryDataFormat) => {
    //     // if (rowData) {
    //     //     client
    //     //         .mutate({
    //     //             mutation: updateFirmTeam,
    //     //             variables: {
    //     //                 guidId: rowData.guidID,
    //     //                 description: rowData.description,
    //     //             },
    //     //         })
    //     //         // tslint:disable-next-line: no-any
    //     //         .then((results: { data: any }) => {
    //     //             if (results.data) {
    //     //                 if (results.data.error !== undefined) {
    //     //                     showNotification( 'Failed to Update Team', results.data.error, 'error');
    //     //                 }
    //     //             }
    //     //         })
    //     //         // tslint:disable-next-line:no-any
    //     //         .catch((reason: any) => {
    //     //             showNotification('Failed to Update Team', reason, 'error');
    //     //         });
    //     // }
    // };

    // const deleteRowData = (oldData: TemporaryDataFormat) => {

    //     setState((prevState) => {
    //         const tdata = [...prevState.data];
    //         tdata.splice(tdata.indexOf(oldData), 1);
    //         return { 
    //             ...prevState, 
    //             data: tdata 
    //         };
    //     });
        
    // };

    const deleteData = (oldData: TemporaryDataFormat) => {
        // client
        //     .mutate({
        //         mutation: deleteFirmTeam,
        //         variables: {
        //             guidId: oldData.guidID,
        //         },
        //     })
        //     // tslint:disable-next-line: no-any
        //     .then((results: { data: any }) => {
        //         if (results.data) {
        //             if (results.data.error !== undefined) {
        //                 showNotification( 'Failed to Delete Team', results.data.error, 'error');
        //             }
        //         }
        //     })
        //     // tslint:disable-next-line:no-any
        //     .catch((reason: any) => {
        //         showNotification('Failed to Delete Team', reason, 'error');
        //     });
    };

    // tslint:disable-next-line: no-any
    // const insertRowData = (newData: TemporaryDataFormat) => {

    //     setState((prevState) => {

    //         const tdata = prevState.data;

    //         if (tdata.some((e: TemporaryDataFormat) => e.user.value.toLowerCase() === newData.user.value.toLowerCase())) {
    //             return { 
    //                 ...prevState, 
    //             };
    //         } 

    //         tdata.push(newData);

    //         return {    
    //             ...prevState,
    //             data: tdata
    //         };

    //     });
    // };

    // // tslint:disable-next-line: no-any
    // const createData = (newData: TemporaryDataFormat) => {
    //     if (newData) {
    //         client
    //             .mutate({
    //                 mutation: CreateUserAssistantMutation,
    //                 variables: {
    //                     input: {
    //                         userToAssistId: newData.user.value,
    //                     }
    //                 },
    //             })
    //             // tslint:disable-next-line: no-any
    //             .then((results: { data: any }) => {

    //                 if (results.data) {

    //                     if (results.data.error !== undefined || results.data.error !== null) {
    //                         showNotification( 'Failed to Create User Assistant', results.data.error, 'error');
    //                     }
    //                 }
    //             })
    //             // tslint:disable-next-line:no-any
    //             .catch((reason: any) => {
    //                 showNotification('Failed to Create User Assistant', reason, 'error');
    //             });
    //     }
    // };

    // tslint:disable-next-line: no-any
    // const onDeleteData = (data: any) => {

    //     setShowDeleteConfirm(true);

    //     onDeleteItems = data;

    // };

    const onDeleteConfirm = (agree: boolean) => {
        if (agree) {
            // tslint:disable-next-line: no-any
            onDeleteItems.forEach((selected: any) => {

                // delete check data
                deleteData(selected);

                setState((prevState) => {
                    const tdata = [...prevState.data];
                    tdata.splice(tdata.indexOf(selected), 1);
                    return { 
                        ...prevState, 
                        data: tdata 
                    };
                });

            });
        }

        setShowDeleteConfirm(false);
    };

    const getTitle = (title: string, count: number) => {
        return (
            <div className={classes.tableTitle}>
                <Typography className={classes.tableHeading}>{title}</Typography>
                <Chip className={classes.chip} label={count} />
            </div>
        );
    };

    // tslint:disable-next-line: no-any
    const userSelectorComponent = (componentProps: EditComponentProps<any>) => {
        
        return (
            <UserAutocompleteSelector 
                name="user"
                label="User"
                required={true}
                value={componentProps.value}
                isExternal={false}
                onSelection={(selection: IAutoCompleteItem, name: string) => {
                    componentProps.onChange(selection);
                }}
                error={componentProps.error}
                disablePortal={false}
                className={classes.textField}
            />
        );
    };

    // tslint:disable-next-line: no-any
    const customFilterAndSearch = (filter: any, rowData: TemporaryDataFormat, columnDef: Column<TemporaryDataFormat>) => {
        return rowData && rowData.user && rowData.user.label.toLowerCase().includes(filter.toLowerCase());
    };

    return (
        <div className={classes.root}>
            <DialogBox
                title="User Assistant"
                // tslint:disable-next-line:max-line-length
                content={`Are you sure you want to delete the selected row?`}
                show={showDeleteConfirm}
                isAgree={onDeleteConfirm}
                disAgreeLabel={'No'}
                agreeLabel={'Yes'}
                className={classes.dialogBox}
            />
            <MaterialTable
                isLoading={state.isLoading}
                title={getTitle('User Assistant', state.data.length)}
                icons={tableIcons}
                onRowClick={handleRowClick}
                columns={[
                    { 
                        title: 'Users', 
                        field: 'user', 
                        validate: (rowData) => rowData.hasOwnProperty('user') && rowData.user 
                            ? true 
                            : { isValid: false, helperText: 'User is required'},
                        render: (rowData) => userRender(rowData), cellStyle: { flex: 3 }, headerStyle: { flex: 3 },
                        editComponent: (componentData) => userSelectorComponent(componentData),
                        customFilterAndSearch: customFilterAndSearch
                    },
                    { 
                        title: 'Assistant Count', 
                        field: 'count', render: (rowData) => 
                        memberCountRender(rowData), editable: 'never', 
                        cellStyle: { justifyContent: 'center' }, 
                        headerStyle: { justifyContent: 'center' },
                    },
                ]}
                components={
                    {
                        Toolbar: props => (
                            <div className={classes.customToolbar}>
                                <MTableToolbar classes={{ highlight: classes.highlight, actions: classes.actions }} {...props} />
                            </div>
                        )
                    }
                }
                data={state.data}        
                options={{
                    addRowPosition: 'first',
                    search: true,
                    selection: true,
                    paging: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 40, 50],
                    showTitle: true,
                    toolbar: true,
                    actionsColumnIndex: -1,
                    rowStyle: rowData => ({
                        backgroundColor: (state.selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
                    })
                }}
                // editable={{
                //     onRowAdd: (newData: TemporaryDataFormat) =>
                //     // tslint:disable-next-line: no-any
                //     new Promise((resolve: any) => {
                //         setTimeout(() => {
                //             resolve();
                //             if (newData.user) {
                //                 createData(newData);
                //                 insertRowData(newData);
                //             }
                //         // tslint:disable-next-line: align
                //         }, 600);
                //     }),
                //     onRowUpdate: (newData, oldData) =>
                //     // tslint:disable-next-line: no-any
                //     new Promise((resolve: any) => {
                //         setTimeout(() => {
                //         resolve();
                //         if (oldData) {
                //             updateData(newData);
                //             updateRowData(newData, oldData);
                //         }
                //         // tslint:disable-next-line: align
                //         }, 600);
                //     }),
                //     onRowDelete: (oldData) =>
                //     // tslint:disable-next-line: no-any
                //     new Promise((resolve: any) => {
                //         setTimeout(() => {
                //             resolve();
                //             if (oldData) {
                //                 deleteData(oldData);
                //                 deleteRowData(oldData);
                //             }
                //         // tslint:disable-next-line: align
                //         }, 600);
                //     }),
                // }}
                // actions={[
                //     {
                //         tooltip: 'Remove All Selected Team',
                //         icon: DeleteOutline,
                //         // tslint:disable-next-line: no-any
                //         onClick: (evt: any, data: any) => onDeleteData(data)
                //     },
                // ]}
            />
        </div>
    );
}

// const createFirmTeam = gql`
//     mutation createFirmTeam($description: String) {
//         createFirmTeam(description: $description)
//     }
// `;

// const updateFirmTeam = gql`
//     mutation updateFirmTeam($description: String, $guidId: String) {
//         updateFirmTeam(description:$description, guidId: $guidId)
//     }
// `;

// const deleteFirmTeam = gql`
//     mutation deleteFirmTeam($guidId: String) {
//         deleteFirmTeam(guidId: $guidId)
//     }  
// `;

// export const firmSubscription = gql`
//     subscription firm{
//         lookupNotification{
//             id,
//             guidId,
//             lookupDescription,
//             message,
//             status
//         }
//     }
// `;