import gql from 'graphql-tag';
import { client } from '..';
import { FetchPolicy } from 'apollo-client';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';

// New Matter Summary Query params
export interface FeatureLayoutConfigurationQueryParams {
    layoutId: number;
    includeHidden: boolean;
    isInternalRole: boolean | null;
    isAdministrator: boolean | null;
    roleId?: string | null;
    userEmail?: string | null;
    isPersonal?: boolean | null;
    dashboardPanelId?: string | null;
}

export interface FeatureLayoutConfigurationListData {
    loading?: boolean;
    networkStatus?: number;
    featureLayoutConfigurationList: FeatureLayoutConfigurationList;
}

export interface FeatureLayoutConfigurationList {
    recordCount: number;
    featureLayoutConfiguration: FeatureLayoutConfiguration[];
}

export interface FeatureLayoutConfiguration {
    id: number;
    feature: string;
    featureGuid: string;
    description: string;
    title: string;
    isHidden: boolean;
    sequence: number;
    infoUrl: string;
}

export interface FeatureSearchChanges {
    feature: string;
    featureGuid: string;
    isHidden: boolean;
    sequence: number;
}

export interface RoleListData {
    loading?: boolean;
    networkStatus?: number;
    roleList: RoleList;
}

export interface RoleList {
    role: Role[];
}

export interface Role {
    roleIdString: string;
    rolename: string;
    isExternal: boolean;
}

export enum RoleCategoryType {
    External = 0,
    Internal = 1,
    Administrator = 2,
}

export interface DropDownData {
    value: string;
    text: string;
    isInternal?: boolean;
}

export interface DropDownRoleCategoryData {
    value: RoleCategoryType;
    text: string;
}

export interface DropDownSearchData {
    title: string;
    description: string;
}

export function fetchScopeFeatureData(value?: string, isPersonalise?: boolean): DropDownData[] {
    var scope = [];

    // This is selected through features. Which are passed on through the accessToken while logging in.
    if (UserFeature.HasAccess(UserFeatureEnum.hasDashboardSecurityScope)) {
        scope.push({ value: 'DashboardSecurityScope', text: 'Firm' });
    }
    if (UserFeature.HasAccess(UserFeatureEnum.hasDashboardSecurityRoleCategory)) {
        scope.push({ value: 'DashboardSecurityRoleCategory', text: 'Role Category' });
    }
    if (UserFeature.HasAccess(UserFeatureEnum.hasDashboardSecurityRole)) {
        scope.push({ value: 'DashboardSecurityRole', text: 'Role' });
    }
    if (UserFeature.HasAccess(UserFeatureEnum.hasDashboardSecurityUser)) {
        scope.push({ value: 'DashboardSecurityUser', text: 'User' });
    }
    if (UserFeature.HasAccess(UserFeatureEnum.hasDashboardSecurityPersonal) || isPersonalise === true) {
        scope.push({ value: 'DashboardSecurityPersonal', text: 'Personal' });
    }

    if (value) {
        scope = scope.filter((x) => x.value === value);
    }

    if (scope && scope.length === 0 && (value === 'DashboardSecurityPersonal' || isPersonalise === true)) {
        // For Personalise
        scope.push({ value: 'DashboardSecurityPersonal', text: 'Personal' });
    }

    return scope;
}

export function fetchRoleCategoryData(isIncludeAdmin?: boolean): DropDownRoleCategoryData[] {
    var roleCategoryData = [];

    roleCategoryData.push({ value: RoleCategoryType.Internal, text: 'Internal' });
    roleCategoryData.push({ value: RoleCategoryType.External, text: 'External' });

    if (isIncludeAdmin) {
        roleCategoryData.push({ value: RoleCategoryType.Administrator, text: 'Administrator' });
    }

    return roleCategoryData;
}

export function fetchFeatureLayoutConfigurationData(
    query: FeatureLayoutConfigurationQueryParams,
    refreshData: boolean,
    onSuccess: (data: FeatureLayoutConfigurationListData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: FeatureLayoutConfigurationData,
            variables: {
                layoutId: query.layoutId,
                includeHidden: query.includeHidden,
                isInternalRole: query.isInternalRole,
                isAdministrator: query.isAdministrator,
                roleId: query.roleId,
                userEmail: query.userEmail,
                isPersonal: query.isPersonal,
                dashboardPanelId: query.dashboardPanelId,
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export function fetchRoleData(
    refreshData: boolean,
    onSuccess: (data: RoleListData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: RoleSelectorData,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

const FeatureLayoutConfigurationData = gql`
    query featureLayoutConfiguration(
        $layoutId: Int
        $includeHidden: Boolean
        $isInternalRole: Boolean
        $isAdministrator: Boolean
        $roleId: String
        $userEmail: String
        $isPersonal: Boolean
        $dashboardPanelId: String
    ) {
        featureLayoutConfigurationList(
            layoutId: $layoutId
            includeHidden: $includeHidden
            isInternalRole: $isInternalRole
            isAdministrator: $isAdministrator
            roleId: $roleId
            userEmail: $userEmail
            isPersonal: $isPersonal
            dashboardPanelId: $dashboardPanelId
        ) {
            recordCount
            featureLayoutConfiguration {
                id
                feature
                featureGuid
                description
                title
                isHidden
                sequence
                infoUrl
            }
        }
    }
`;

const RoleSelectorData = gql`
    query roleSelectorQuery {
        roleList {
            role {
                roleIdString
                rolename
                isExternal
            }
        }
    }
`;

export const UpdateFeatureLayoutConfigData = gql`
    mutation UpdateFeatureLayoutConfiguration(
        $featureLayoutConfigurationList: [FeatureLayoutConfigurationInput]!
        $layoutId: Int!
        $isInternalRole: Boolean
        $isAdministrator: Boolean
        $roleId: String
        $userEmail: String
        $isPersonal: Boolean
        $dashboardPanelId: String
    ) {
        updateFeatureLayoutConfiguration(
            featureLayoutConfigurationList: $featureLayoutConfigurationList
            layoutId: $layoutId
            isInternalRole: $isInternalRole
            isAdministrator: $isAdministrator
            roleId: $roleId
            userEmail: $userEmail
            isPersonal: $isPersonal
            dashboardPanelId: $dashboardPanelId
        )
    }
`;

export const DeleteFeatureLayoutConfigData = gql`
    mutation deleteFeatureLayoutConfiguration(
        $layoutId: Int!
        $isInternalRole: Boolean
        $isAdministrator: Boolean
        $roleId: String
        $userEmail: String
        $isPersonal: Boolean
        $dashboardPanelId: String
    ) {
        deleteFeatureLayoutConfiguration(
            layoutId: $layoutId
            isInternalRole: $isInternalRole
            isAdministrator: $isAdministrator
            roleId: $roleId
            userEmail: $userEmail
            isPersonal: $isPersonal
            dashboardPanelId: $dashboardPanelId
        )
    }
`;

export const featureLayoutConfigSubscription = gql`
    subscription featureLayoutConfigurationSubscription {
        featureLayoutConfigurationNotification {
            id
            status
            message
            description
        }
    }
`;

export interface CurrentUserData {
    appUser: AppUser;
}

export interface AppUser {
    userId: string;
    staffGuid: string;
    name: string;
    userName: string;
    isAdministrator: boolean;
    role: string;
    isInternal: boolean;
    isInternalAdmin: boolean;
}

export function fetchCurrentUserData(
    refreshData: boolean,
    onSuccess: (data: CurrentUserData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: CURRENT_USER_QUERY,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export const CURRENT_USER_QUERY = gql`
    query CurrentUser {
        appUser {
            userId
            staffGuid
            name
            userName
            isAdministrator
            role
            isInternal
            isInternalAdmin
        }
    }
`;

export const UpdateFeatureSubscription = gql`
    subscription updateFeatureNotification {
        featureUpdateNotification{
            id,
            description,
            message,
            status
        }
    }
`;