import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '..';

export interface SystemSettingsNamesData {
    settings: Settings;
    loading?: boolean;
    networkStatus?: number;
}

export interface Settings {
    systems: Systems;
}

export interface Systems {
    id: number;
    settingNames: SettingNames;
    enquiryDefaults: EnquiryDefaults;
    emailDefaults: EmailDefaults;
}

export interface SettingNames {
    settingName: SettingName[];
}

export interface SettingName {
    id: number;
    guidID: string;
    title: string;
    description: string;
    internalName: string;
}

export interface EnquiryDefaults {
    id:     number;
    config: EnquiryDefaultConfig;
}

export interface EnquiryDefaultConfig {
    id: number;
    isManualEntry: boolean;
    isCalculatedDays: boolean;
    isCalculatedHours: boolean;
    timeOfDay: string;
    addDays: number;
    addHours: number;
    defaultMeetingLength: string;
    reminder: string;
    overdueReminder: string;
    isSendReminderOnWeekend: boolean;
    isSendInvitationToEnquirer: boolean;
    isSendInvitationToAssignee: boolean;
    isSendReminderOnWeekendForOverDueReminders: boolean;
    isLocationFieldMandatory: boolean;
    periodBeforeFirstUnassignedReminder: string;
    periodAfterFirstUnassignedReminder: string;
    isSendFistUnassignedReminderOnWeekends: boolean;
    isSendUnassignedAfterFirstReminderOnWeekends: boolean;
}

export interface EmailDefaults {
    config: EmailConfig;
}

export interface EmailConfig {
    fromName: null;
    replyTo: null;
    sendEmailOnBehalfOf: null;
    confirmationBcc: null;
}

export function retrieveSystemSettingsNamesQueryData(
    refreshData: boolean,
    onSuccess: (data: SystemSettingsNamesData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
            query: SystemSettingsNamesQuery,
            variables: {},
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any ) => { onError(reason); });
}

export const SystemSettingsNamesQuery = gql`
    query SystemSettingsNames{
        settings {
            id
            systems {
                id
                settingNames {
                    settingName {
                        id,
                        guidID,
                        title,
                        description,
                        internalName
                    }
                }
            }
        }
    }
`;

export function retrieveEnquiryDefaultConfigData(
    refreshData: boolean,
    onSuccess: (data: SystemSettingsNamesData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
            query: EnquiryDefaultConfigQuery,
            variables: {},
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any ) => { onError(reason); });
}

const EnquiryDefaultConfigQuery = gql`
   query EnquiryDefaultConfig {
        settings {
            id,
            systems {
                id,
                enquiryDefaults {
                    id,
                    config {
                        id,
                        isManualEntry,
                        isCalculatedDays,
                        isCalculatedHours,
                        timeOfDay,
                        addDays,
                        addHours,
                        defaultMeetingLength,
                        reminder,
                        overdueReminder,
                      	isSendReminderOnWeekend,
                        isSendInvitationToEnquirer
                        isSendInvitationToAssignee
                        isSendReminderOnWeekendForOverDueReminders
                      	isLocationFieldMandatory
                        periodBeforeFirstUnassignedReminder
                        periodAfterFirstUnassignedReminder
                        isSendFistUnassignedReminderOnWeekends
                        isSendUnassignedAfterFirstReminderOnWeekends
                    }
                }
            }
        }
    }
`;

export const UpdateEnquiryDefaultConfigMutation = gql`
    # Write your query or mutation here
mutation updateEnquiryDefaultConfigMutation(
        $isManualEntry: Boolean,
        $isCalculatedDays: Boolean,
        $isCalculatedHours: Boolean,
        $timeOfDay: String,
        $addDays: Int,
        $addHours: Decimal,
        $defaultMeetingLength: String,
        $reminder: String,
        $overdueReminder: String,
        $isSendReminderOnWeekend: Boolean,
        $isSendInvitationToEnquirer: Boolean,
        $isSendInvitationToAssignee: Boolean,
        $isSendReminderOnWeekendForOverDueReminders: Boolean,
        $isLocationFieldMandatory: Boolean,
        $periodBeforeFirstUnassignedReminder: String,
        $periodAfterFirstUnassignedReminder: String,
        $isSendFistUnassignedReminderOnWeekends: Boolean,
        $isSendUnassignedAfterFirstReminderOnWeekends: Boolean
    ){
        settings {
        enquiryDefaultConfig {
            update(
                isManualEntry: $isManualEntry,
                isCalculatedDays: $isCalculatedDays,
                isCalculatedHours: $isCalculatedHours,
                timeOfDay: $timeOfDay,
                addDays: $addDays,
                addHours: $addHours,
                defaultMeetingLength: $defaultMeetingLength,
                reminder: $reminder,
                overdueReminder: $overdueReminder,
              	isSendReminderOnWeekend: $isSendReminderOnWeekend,
                isSendInvitationToEnquirer: $isSendInvitationToEnquirer,
                isSendInvitationToAssignee: $isSendInvitationToAssignee,
                isSendReminderOnWeekendForOverDueReminders: $isSendReminderOnWeekendForOverDueReminders,
              	isLocationFieldMandatory: $isLocationFieldMandatory,
              	periodBeforeFirstUnassignedReminder: $periodBeforeFirstUnassignedReminder,
                periodAfterFirstUnassignedReminder: $periodAfterFirstUnassignedReminder,
                isSendFistUnassignedReminderOnWeekends: $isSendFistUnassignedReminderOnWeekends,
                isSendUnassignedAfterFirstReminderOnWeekends: $isSendUnassignedAfterFirstReminderOnWeekends
            ) {
                    error,
                    status
                }
            }
        }
    }
`;

export function retrieveEmailConfigData(
    refreshData: boolean,
    onSuccess: (data: SystemSettingsNamesData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
            query: EmailConfigQuery,
            variables: {},
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any ) => { onError(reason); });
}

const EmailConfigQuery = gql`
query EmailConfig {
    settings {
        id,
        systems {
            id
            emailDefaults {
                id
                config {
                    id
                    fromName
                    replyTo
                    sendEmailOnBehalfOf
                    confirmationBcc
                }
            }
        }
    }
}`;

export const UpdateEmailConfigMutation = gql`
    mutation UpdateEmailConfig($sendEmailOnBehalfOf: String, $fromName: String, $replyTo: String, $confirmationBcc: String) {
        settings {
            emailConfig {
                update(sendEmailOnBehalfOf: $sendEmailOnBehalfOf, fromName: $fromName, replyTo: $replyTo, confirmationBcc: $confirmationBcc) {
                    error
                    status
                }
            }
        }
    }
`;

export const SettingNotificationSubscription = gql`
    subscription SettingfigNotification {
        settingNotification {
            description
            id
            message
            status
        }
    }
`;

export interface TaskAndPhoneMessageDefaultConfigMutationParams {
    isSendReminderOnWeekend?: boolean;
    reminder?: string;
    isSendReminderOnWeekendForOverDueReminders?: boolean;
    overdueReminder?: string;
}

export const UPDATE_TASK_DEFAULT_CONFIG_MUTATION = gql`
    mutation updateTaskDefaultConfigMutation(
        $isSendReminderOnWeekend: Boolean,
        $reminder: String,
        $isSendReminderOnWeekendForOverDueReminders: Boolean,
        $overdueReminder: String
    ) {
            settings {
                taskDefaultConfig {
                    update(
                        isSendReminderOnWeekend: $isSendReminderOnWeekend,
                        reminder: $reminder,
                        isSendReminderOnWeekendForOverDueReminders: $isSendReminderOnWeekendForOverDueReminders,
                        overdueReminder: $overdueReminder
                    ) {
                    error
                    status
                }
            }
        }
    }
`;

export const UPDATE_PHONE_MESSAGE_DEFAULT_CONFIG_MUTATION = gql`
    mutation updatePhoneMessageDefaultConfigMutation(
        $isSendReminderOnWeekend: Boolean,
        $reminder: String,
        $isSendReminderOnWeekendForOverDueReminders: Boolean,
        $overdueReminder: String
    ) {
            settings {
                phoneMessageDefaultConfig {
                    update(
                        isSendReminderOnWeekend: $isSendReminderOnWeekend,
                        reminder: $reminder,
                        isSendReminderOnWeekendForOverDueReminders: $isSendReminderOnWeekendForOverDueReminders,
                        overdueReminder: $overdueReminder
                    ) {
                    error
                    status
                }
            }
        }
    }
`;

export interface PhoneMessageDefaultsData {
    settings: Settings;
}

export interface TaskDefaultsData {
    settings: Settings;
}

export interface Settings {
    systems: Systems;
    id:      number;
}

export interface Systems {
    id:      number;
    phoneMessageDefaults: PhoneMessageDefaults;
    taskDefaults: TaskDefaults;
    clientAndContactDefaults: ClietnAdnContactDefaults;
}

export interface PhoneMessageDefaults {
    id:      number;
    config: TaskAndPhoneMessageConfig;
}
export interface TaskDefaults {
    id:      number;
    config: TaskAndPhoneMessageConfig;
}

export interface TaskAndPhoneMessageConfig {
    isSendReminderOnWeekend:                    boolean;
    isSendReminderOnWeekendForOverDueReminders: boolean;
    reminder:                                   string | null;
    overdueReminder:                            string | null;
}

export const PHONE_MESSAGE_DEFAULTS_QUERY = gql`
    query phoneMessageDefaultsQuery {
        settings {
            id
            systems {
                id
                phoneMessageDefaults {
                    id
                    config {
                        id
                        isSendReminderOnWeekend
                        isSendReminderOnWeekendForOverDueReminders
                        reminder
                        overdueReminder
                    }
                }
            }
        }
    }
`;

export function retrievePhoneMessageDefaultConfigData(
    refreshData: boolean,
    onSuccess: (data: PhoneMessageDefaultsData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
            query: PHONE_MESSAGE_DEFAULTS_QUERY,
            variables: {},
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any ) => { onError(reason); });
}

export const TASK_DEFAULTS_QUERY = gql`
    query TaskDefaultsQuery {
        settings {
            id
            systems {
                id
                taskDefaults {
                    id
                    config {
                        id
                        isSendReminderOnWeekend
                        isSendReminderOnWeekendForOverDueReminders
                        reminder
                        overdueReminder
                    }
                }
            }
        }
    }

`;

export function retrieveTaskDefaultConfigData(
    refreshData: boolean,
    onSuccess: (data: TaskDefaultsData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
        var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

        client.query({
            query: TASK_DEFAULTS_QUERY,
            variables: {},
            fetchPolicy: fetchPolicy
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any ) => { onError(reason); });
}

export interface ClientAndContactsDefaultsData {
    settings: Settings;
}

export interface ClietnAdnContactDefaults {
    config: ClientAndContactDefaultsData;
}

export interface ClientAndContactDefaultsData {
    isNoteTypeMandatory: boolean;
    isNoteSubjectMandatory: boolean;
    outlookAddinNoteTypeGuid: string;
    outlookAddinNoteTypeDescription: string;
    outlookAddinNoteSubjectId: number;
    outlookAddinNoteSubjectDescription: string;

    eventCreationNoteTypeGuid: string;
    eventCreationNoteTypeDescription: string;
    eventCreationNoteSubjectId: number;
    eventCreationNoteSubjectDescription: string;

    campaignCreationNoteTypeGuid: string;
    campaignCreationNoteTypeDescription: string;
    campaignCreationNoteSubjectId: number;
    campaignCreationNoteSubjectDescription: string;
}

export interface ClientAndContactDefaultConfigParams {
    isNoteTypeMandatory: boolean;
    isNoteSubjectMandatory: boolean;
    outlookAddinNoteTypeGuid: string | number | null;
    outlookAddinNoteSubjectId:  string | number | null;
    eventCreationNoteTypeGuid:  string | number | null;
    eventCreationNoteSubjectId:  string | number | null;
    campaignCreationNoteTypeGuid:  string | number | null;
    campaignCreationNoteSubjectId:  string | number | null;
}

export const ClientAndContacsDefaultConfigurationQuery = gql`
    query ClientAndContactDefaultConfig {
        settings {
            id
            systems {
                id  
                clientAndContactDefaults {
                    id
                    config {
                        id
                        isNoteTypeMandatory
                        isNoteSubjectMandatory
                        outlookAddinNoteTypeGuid
                        outlookAddinNoteTypeDescription
                        outlookAddinNoteSubjectId
                        outlookAddinNoteSubjectDescription
                        eventCreationNoteTypeGuid
                        eventCreationNoteTypeDescription
                        eventCreationNoteSubjectId
                        eventCreationNoteSubjectDescription
                        campaignCreationNoteTypeGuid
                        campaignCreationNoteTypeDescription
                        campaignCreationNoteSubjectId
                        campaignCreationNoteSubjectDescription
                    }
                }
            } 
        }
    }
`;

export const ClientAndContactsDefaultConfigurationMutation = gql`
mutation ClientAndContactDefaultConfig (
    $isNoteTypeMandatory: Boolean,
    $isNoteSubjectMandatory: Boolean,
    $outlookAddinNoteTypeGuid: String,
    $outlookAddinNoteSubjectId: Int,
    $eventCreationNoteTypeGuid: String,
    $eventCreationNoteSubjectId: Int,
    $campaignCreationNoteTypeGuid: String,
    $campaignCreationNoteSubjectId: Int
    ) 
    {
    settings {
        clientAndContactDefaultConfig {
            update (
                isNoteTypeMandatory: $isNoteTypeMandatory,
                isNoteSubjectMandatory: $isNoteSubjectMandatory,
                outlookAddinNoteTypeGuid: $outlookAddinNoteTypeGuid,
                outlookAddinNoteSubjectId: $outlookAddinNoteSubjectId,
                eventCreationNoteTypeGuid: $eventCreationNoteTypeGuid,
                eventCreationNoteSubjectId: $eventCreationNoteSubjectId,
                campaignCreationNoteTypeGuid: $campaignCreationNoteTypeGuid,
                campaignCreationNoteSubjectId: $campaignCreationNoteSubjectId
            )
            {
            error
            status
            }
        }
    }
}
`;

export function retrieveClientAndContactsDefaultConfigData(
    refreshData: boolean,
    onSuccess: (data: ClientAndContactsDefaultsData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void)
    : void {
        
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: ClientAndContacsDefaultConfigurationQuery,
        variables: {},
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); });
}