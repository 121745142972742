/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FirmSettingConfigurationBanner } from './FirmSettingConfigurationBanner';
import { RouterProps, showNotification } from '../App';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import SettingsIcon from '@material-ui/icons/Settings';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import EmailingTab from './tabs/EmailingTab';
import { retrieveSystemSettingsNamesQueryData, SettingName, SettingNotificationSubscription, SystemSettingsNamesData } from './FirmSettingConfigurationRepository';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import EnquiryDefaultTab from './tabs/EnquiryDefaultTab';
import { RvLoader } from '../components/Loader';
import { client } from '..';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import SecurityTab from './tabs/SecurityTab';
import FirmDetailsTab from './tabs/FirmDetailsTab';
import RegionalTab from './tabs/RegionalTab';
import TimeRecordingTab from './tabs/TimeRecordingTab';
import TimeRecordingDefaultsTab from './tabs/TimeRecordingDefaultsTab';
import ConnectTab from './tabs/ConnectTab';
import TimeRecordingWarningDefaultsTab from './tabs/TimeRecordingWarningDefaultsTab';
import InprotechTab from './tabs/InprotechTab';
// import AgeingTab from './tabs/AgeingTab';
// import PollIcon from '@material-ui/icons/Poll';
// import ChartDefaultsTab from './tabs/ChartDefaultsTab';
import PhoneMessageDefaultConfigurationTab from './tabs/PhoneMessageDefaultConfigurationTab';
import TaskDefaultConfigurationTab from './tabs/TaskDefaultConfigurationTab';
// import { LearnMoreEnum } from '../learnMore/LearnMorePage';
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import ClientAndContactsDefaultConfigurationTab from './tabs/ClientAndContactsDefaultConfigurationTab';

const useStyles = makeStyles({
    root: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column',
    },
    wrapper: {
        flex: 1,
        display: 'flex',
        backgroundColor: '#fff',
        margin: '0 -15px',
        padding: '15px',
    },
    sidebar: {
        padding: '0 15px',
        maxWidth: '400px',
        width: '100%',
        position: 'relative',
        flex: 1,
        display: 'flex',
    },
    sidebarInner: {
        position: 'relative',
        flex: 1,
        // left: 0,
        // right: 0,
        // top: 0,
        // bottom: 0,
        // overflow: 'auto',
    },
    sidebarList: {
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        overflow: 'auto',
        paddingRight: '10px',
        '& .Mui-selected': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        },
        '& .Mui-selected:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }
    },
    content: {
        padding: '0 15px',
        flexGrow: 1,
        display: 'flex',
        flexFlow: 'column',
        '& > div': {
            flexGrow: 1,
            maxWidth: '800px',
        }
    },
    tabLoaderWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
    }
});

export enum FirmSettingConfigurationList {
    // Ageing = 0,
    Security,
    EmailConfiguration,
    EnquiryDefaultConfiguration,
    FirmDetails,
    Regional,
    TimeRecording,
    TimeRecordingDefaults,
    Connect,
    TimeRecordingWarningDefaults,
    Inprotech,
    ChartDefaults,
    PhoneMessageDefaultConfiguration,
    TaskDefaultConfiguration,
    ClientAndContactsDefaultConfiguration
}

interface FirmSettingConfigurationPageState {
    selectedTable: FirmSettingConfigurationList;
    tabs: SettingName[];
    isTabLoading: boolean;
}

interface FirmSettingConfigurationPageProps extends RouterProps {

}

export default function FirmSettingConfigurationPage(props: FirmSettingConfigurationPageProps) {

    const classes = useStyles();

    const [pageState, setPageState] = useState<FirmSettingConfigurationPageState>({
        selectedTable: 0,
        tabs: [],
        isTabLoading: true,
    });

    // tslint:disable-next-line: no-any
    let routeCommand = props.match.params.command;

    useEffect(() => {

        fetchTabData();

        var routeTab: FirmSettingConfigurationList = FirmSettingConfigurationList[props.match.params.tab];
        // tslint:disable-next-line: no-console
        console.log('routeCommand', routeCommand);

        setPageState({
            ...pageState,
            selectedTable: routeTab ? routeTab : pageState.selectedTable
        });

    // tslint:disable-next-line: align
    }, []);

    useEffect(() => {   
        const subscribeSettingNotificationObj = subscribeSettingNotification();
        // Cleanup
        return () => {
            subscribeSettingNotificationObj.unsubscribe();
        };

    // tslint:disable-next-line: align
    }, []);

    const subscribeSettingNotification = () => {
        return (
            client
                .subscribe({
                    fetchPolicy: 'no-cache',
                    query: SettingNotificationSubscription,
                    variables: {},
                })
                .subscribe({
                    // tslint:disable-next-line:no-any
                    next(results: any) {
                        if (results.data.settingNotification.status) {
                            showNotification('Success', results.data.settingNotification.message, 'info');

                        } else {
                            showNotification('Failed', results.data.settingNotification.message, 'error');
                        }
                    },
                    // tslint:disable-next-line:no-any
                    error(err: any) {
                        // tslint:disable-next-line:no-console
                        console.error(err);
                        showNotification(null, err, 'error');
                    },
                })
        );
    };

    const fetchTabData = () => {
        retrieveSystemSettingsNamesQueryData(
            true,
            // tslint:disable-next-line: no-console
            (data: SystemSettingsNamesData) => onFirmSettingTabRetrieved(data),
            // tslint:disable-next-line: no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            }
        );
    };

    const onFirmSettingTabRetrieved = (data: SystemSettingsNamesData) => {
        setPageState((prevState) => {
            return {
                ...prevState,
                tabs: data.settings.systems.settingNames.settingName,
                isTabLoading: false
            };
        });
    };

    const getIcon = (internalName: string) => {

        if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.Security]) {
            return (
                <Avatar>
                    <VpnKeyOutlinedIcon />
                </Avatar>
            );
        }

        if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.FirmDetails]) {
            return (
                <Avatar>
                    <ListAltOutlinedIcon />
                </Avatar>
            );
        }

        if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.Regional]) {
            return (
                <Avatar>
                    <RoomOutlinedIcon />
                </Avatar>
            );
        }

        if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.TimeRecording]) {
            return (
                <Avatar>
                    <TimerOutlinedIcon />
                </Avatar>
            );
        }

        if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.TimeRecordingDefaults]) {
            return (
                <Avatar>
                    <AccessTimeIcon />
                </Avatar>
            );
        }
        
        if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.EmailConfiguration]) {
            return (
                <Avatar>
                    <EmailOutlinedIcon />
                </Avatar>
            );
        }

        if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.EnquiryDefaultConfiguration]) {
            return (
                <Avatar>
                    <NaturePeopleIcon />
                </Avatar>
            );
        }

        // if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.ChartDefaults]) {
        //     return (
        //         <Avatar>
        //             <PollIcon />
        //         </Avatar>
        //     );
        // }

        if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.PhoneMessageDefaultConfiguration]) {
            return (
                <Avatar>
                    <PermPhoneMsgIcon />
                </Avatar>
            );
        }

        if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.ClientAndContactsDefaultConfiguration]) {
            return (
                <Avatar>
                    <NaturePeopleIcon />
                </Avatar>
            );
        }

        if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.TaskDefaultConfiguration]) {
            return (
                <Avatar>
                    <AssignmentLateIcon />
                </Avatar>
            );
        }

        return (
            <Avatar>
                <SettingsIcon />
            </Avatar>
        );
    };

    const isHasAccess = (internalName: string): boolean => {
        
        // if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.EmailConfiguration]) {
        //     return UserFeature.HasAccess(UserFeatureEnum.hasInnographyLink);
        // }

        // if (internalName === FirmSettingConfigurationList[FirmSettingConfigurationList.EnquiryDefaultConfiguration]) {
        //     return UserFeature.HasAccess(UserFeatureEnum.hasVisionSix);
        // }

        return true;
    };

    const onClickBack = () => {
        props.history.push('/configurationDashboard');
    };

    const onClickTab = (value: FirmSettingConfigurationList) => () => {
        setPageState({
            ...pageState,
            selectedTable: value
        });

        props.history.push(`/firmSettingConfiguration/${FirmSettingConfigurationList[value]}`);
    };

    // const onLearnMore = () => {
    //     props.history.push({
    //         pathname: `/learnMore/${LearnMoreEnum[LearnMoreEnum.FIRM_STRUCTURE]}`, 
    //     });
    // };
    
    return (
        <div className={classes.root}>
            <FirmSettingConfigurationBanner 
                title="Firm Setting"
                onClickBack={onClickBack}
                // onLearnMore={onLearnMore}
            />
            <div className={classes.wrapper}>
                {pageState.isTabLoading ? (
                    <div className={classes.tabLoaderWrapper}>
                        <RvLoader />
                    </div>
                ) : (
                    <>
                        <div className={classes.sidebar}>
                            <div className={classes.sidebarInner}>
                                <List className={classes.sidebarList}>
                                    {pageState.tabs.map((tab: SettingName) => isHasAccess(tab.internalName) && (
                                            <ListItem 
                                                key={tab.guidID}
                                                button={true} 
                                                onClick={onClickTab(FirmSettingConfigurationList[tab.internalName])}
                                                selected={pageState.selectedTable === FirmSettingConfigurationList[tab.internalName]}
                                            >
                                                <ListItemAvatar>
                                                    {getIcon(tab.internalName)}
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={tab.title}
                                                    secondary={tab.description}
                                                />
                                            </ListItem>
                                        )
                                    )}
                                </List>
                            </div>
                        </div>
                        <div className={classes.content}>
                            {(pageState.selectedTable === FirmSettingConfigurationList.EmailConfiguration) && (<EmailingTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.EnquiryDefaultConfiguration) && (<EnquiryDefaultTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.Security) && (<SecurityTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.FirmDetails) && (<FirmDetailsTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.Regional) && (<RegionalTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.TimeRecording) && (<TimeRecordingTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.TimeRecordingDefaults) && (<TimeRecordingDefaultsTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.Connect) && (<ConnectTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.TimeRecordingWarningDefaults) && (<TimeRecordingWarningDefaultsTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.Inprotech) && (<InprotechTab />)}
                            {/* {(pageState.selectedTable === FirmSettingConfigurationList.Ageing) && (<AgeingTab />)} */}
                            {/* {(pageState.selectedTable === FirmSettingConfigurationList.ChartDefaults) && (<ChartDefaultsTab />)} */}
                            {(pageState.selectedTable === FirmSettingConfigurationList.PhoneMessageDefaultConfiguration) && (<PhoneMessageDefaultConfigurationTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.ClientAndContactsDefaultConfiguration) && (<ClientAndContactsDefaultConfigurationTab />)}
                            {(pageState.selectedTable === FirmSettingConfigurationList.TaskDefaultConfiguration) && (<TaskDefaultConfigurationTab />)}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}