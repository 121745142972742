import React, { useState } from 'react';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { CurrenciesData, CurrencyDataProps } from '../common/Currencies';
import _ from 'lodash';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//         minWidth: '150px',
//     },
//   })
// );

interface CurrencySymbolSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: IAutoCompleteItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value?: IAutoCompleteItem | IAutoCompleteItem[], name?: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    style?: React.CSSProperties; 
    disablePortal?: boolean;
    size?: 'small' | 'medium';
}

interface CurrencySymbolSelectorState {
    isLoading: boolean;
    data: IAutoCompleteItem[];
}

export const CurrencySymbolSelector: React.FC<CurrencySymbolSelectorProps> = props => {

    // const classes = useStyles();

    const [state] = useState<CurrencySymbolSelectorState>({
        isLoading: false,
        data: _.uniqBy(CurrenciesData, 'symbol').map((item: CurrencyDataProps) => {
            return {
                label: item.symbol,
                value: item.symbol
            };
        }).filter((item: IAutoCompleteItem) => item.value.length > 0)
    });

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name);
        }
    };

    const handleOnClose = (event: React.ChangeEvent<{}>) => {

        // tslint:disable-next-line: no-string-literal
        if (event.currentTarget && event.currentTarget['value']) {
            // tslint:disable-next-line: no-string-literal
            let newValue = event.currentTarget['value'];

            if (props.onSelection) {
                // tslint:disable-next-line: no-string-literal
                let tempValue = { value: newValue, label: newValue };
                props.onSelection(tempValue, props.name);
            }
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <Autocomplete
            style={props.style}
            className={props.className}
            value={props.value}
            loading={state.isLoading}
            disabled={props.disabled}
            onChange={handleChange}
            onClose={handleOnClose}
            options={state.data}
            disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
            // tslint:disable-next-line: jsx-no-lambda
            getOptionLabel={(option: IAutoCompleteItem) => option.label}         
            // tslint:disable-next-line: jsx-no-lambda
            renderInput={params => (
                <TextField 
                    {...params} 
                    label={props.label}
                    variant="standard" 
                    fullWidth={true} 
                    margin="none" 
                    required={props.required}
                    InputProps={getInputProps(params, state.isLoading)}
                    error={props.error}    
                    helperText={props.helperText}     
                    size={props.size}            
                />
            )}
            // tslint:disable-next-line: jsx-no-lambda
            renderOption={(option, { inputValue }) => {
                const matches = match(option.label, inputValue);
                const parts = parse(option.label, matches);
        
                return (
                    <div>
                    {parts.map((part, index) => (
                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                        {part.text}
                        </span>
                    ))}
                    </div>
                );
            }}
        />
    );
};