/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, createStyles, FormControlLabel, FormGroup, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { FormApi } from 'final-form';
import React, { useMemo, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { RvLoader } from '../../components/Loader';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { showNotification } from '../../App';
import { client } from '../..';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
    ClientAndContacsDefaultConfigurationQuery,
    ClientAndContactDefaultConfigParams,
    ClientAndContactsDefaultConfigurationMutation,
    ClientAndContactsDefaultsData,
} from '../FirmSettingConfigurationRepository';
import { CorrespondenceActivityData } from '../../hooks/useCorrespondenceActivity';
// import { useClietAndMatterNoteSubjectQuery } from '../../hooks/useClientAndMatterNoteSubject';
// import { GenericSelectorIAutoCompateItem } from '../../components/selectors/GenericSelector';
import { CorrespondenceActivitySelector } from '../../lookupConfiguration/selectors/CorrespondenceActivitySelector';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { ClientAndMatterNoteSubjectData, SubjectSelector } from '../selectors/SubjectSelector';
import { useQuery } from 'react-apollo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flex: 1,
            flexFlow: 'column',
            padding: '0 30px',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: 600,
            color: theme.palette.primary.main,
        },
        radioGroup: {
            flexFlow: 'row wrap',
        },
        accordionDetails: {
            flexFlow: 'column',
        },
        buttonWrapper: {
            margin: '0 -10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            '& > button': {
                margin: '0 10px',
            },
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
        },
    }),
);

interface FormState {
    isNoteTypeMandatory: boolean;
    isNoteSubjectMandatory: boolean;

    outlookAddinType: IAutoCompleteItem | null;
    outlookAddinSubject: IAutoCompleteItem | null;
    eventCreationType: IAutoCompleteItem | null;
    eventCreationSubject: IAutoCompleteItem | null;
    campaignCreationType: IAutoCompleteItem | null;
    campaignCreationSubject: IAutoCompleteItem | null;
}

interface ClientAndContactsDefaultConfigurationTabState {
    isFormChanged: boolean;
    onSubmitLoading: boolean;
    noteTypeData: CorrespondenceActivityData | null | undefined;
    noteSubjectData: ClientAndMatterNoteSubjectData | null | undefined;
}

export const ClientAndContactsDefaultConfigurationTab: React.FC = () => {
    const classes = useStyles();

    const [state, setState] = useState<ClientAndContactsDefaultConfigurationTabState>({
        isFormChanged: false,
        onSubmitLoading: false,
        noteTypeData: null,
        noteSubjectData: null,
    });

    const [formState, setFormState] = useState<FormState>({
        isNoteTypeMandatory: false,
        isNoteSubjectMandatory: false,
        campaignCreationSubject: null,
        campaignCreationType: null,
        eventCreationSubject: null,
        eventCreationType: null,
        outlookAddinSubject: null,
        outlookAddinType: null,
    });

    // tslint:disable-next-line: no-any
    const clientAndContactDefaultConfigQuery = useQuery<ClientAndContactsDefaultsData>(ClientAndContacsDefaultConfigurationQuery);

    useMemo(() => {

        if (!clientAndContactDefaultConfigQuery.loading && !clientAndContactDefaultConfigQuery.error && clientAndContactDefaultConfigQuery.data) {
            
            const { config } = clientAndContactDefaultConfigQuery.data.settings.systems.clientAndContactDefaults;

            setFormState((prevFormState) => {
                return {
                    ...prevFormState,
                    isNoteTypeMandatory: config.isNoteTypeMandatory,
                    isNoteSubjectMandatory: config.isNoteSubjectMandatory,
                    outlookAddinType:
                        config.outlookAddinNoteTypeGuid && config.outlookAddinNoteTypeDescription
                            ? { value: config.outlookAddinNoteTypeGuid, label: config.outlookAddinNoteTypeDescription }
                            : null,
                    outlookAddinSubject:
                        config.outlookAddinNoteSubjectId && config.outlookAddinNoteSubjectDescription
                            ? { value: config.outlookAddinNoteSubjectId.toString(), label: config.outlookAddinNoteSubjectDescription }
                            : null,
                    eventCreationType:
                        config.eventCreationNoteTypeGuid && config.eventCreationNoteTypeDescription
                            ? { value: config.eventCreationNoteTypeGuid, label: config.eventCreationNoteTypeDescription }
                            : null,
                    eventCreationSubject:
                        config.eventCreationNoteSubjectId && config.eventCreationNoteSubjectDescription
                            ? { value: config.eventCreationNoteSubjectId.toString(), label: config.eventCreationNoteSubjectDescription }
                            : null,
                    campaignCreationType:
                        config.campaignCreationNoteTypeGuid && config.campaignCreationNoteTypeDescription
                            ? { value: config.campaignCreationNoteTypeGuid, label: config.campaignCreationNoteTypeDescription }
                            : null,
                    campaignCreationSubject:
                        config.campaignCreationNoteSubjectId && config.campaignCreationNoteSubjectDescription
                            ? { value: config.campaignCreationNoteSubjectId.toString(), label: config.campaignCreationNoteSubjectDescription }
                            : null,
                };
            });
        }
    // tslint:disable-next-line: align
    }, [clientAndContactDefaultConfigQuery.data, clientAndContactDefaultConfigQuery.loading, clientAndContactDefaultConfigQuery.error]);

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (changeProps: any) => {
        if (!changeProps.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true,
                };
            });
        }
    };

    const onReset = (form: FormApi<FormState>) => () => {
        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.reset();
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const onSubmit = (form: FormApi<FormState>, values: FormState) => {

        setState((prevState) => {
            return {
                ...prevState,
                onSubmitLoading: true,
            };
        });

        client
            // tslint:disable-next-line: no-any
            .mutate<any, ClientAndContactDefaultConfigParams>({
                mutation: ClientAndContactsDefaultConfigurationMutation,
                variables: {
                    isNoteSubjectMandatory: values.isNoteSubjectMandatory,
                    isNoteTypeMandatory: values.isNoteTypeMandatory,
                    outlookAddinNoteTypeGuid: values.outlookAddinType ? values.outlookAddinType?.value : null,
                    outlookAddinNoteSubjectId: values.outlookAddinSubject ? values.outlookAddinSubject.value : null,

                    eventCreationNoteTypeGuid: values.eventCreationType ? values.eventCreationType?.value : null,
                    eventCreationNoteSubjectId: values.eventCreationSubject ? values.eventCreationSubject?.value : null,

                    campaignCreationNoteTypeGuid: values.campaignCreationType ? values.campaignCreationType?.value : null,
                    campaignCreationNoteSubjectId: values.campaignCreationSubject ? values.campaignCreationSubject?.value : null,
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    showNotification(null, 'Successfully submitted', 'info');
                    setState((_prevState) => {
                        return {
                            ..._prevState,
                            onSubmitLoading: false,
                        };
                    });

                    // reset the field and set to the current changes after saved.
                    form.setConfig('keepDirtyOnReinitialize', false);
                    form.batch(() => {
                        form.reset(values);
                    });
                    form.setConfig('keepDirtyOnReinitialize', true);
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to update enquiry default', reason, 'error');
                setState((_prevState) => {
                    return {
                        ..._prevState,
                        onSubmitLoading: false,
                    };
                });
            });
    };

    if (clientAndContactDefaultConfigQuery.loading || !clientAndContactDefaultConfigQuery.data) {
        return (
            <div className={classes.root}>
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Form
                onSubmit={(values, form: FormApi<FormState>) => onSubmit(form, values)}
                initialValues={formState}
                initialValuesEqual={() => true}
                keepDirtyOnReinitialize={true}
                subscription={{ submitting: true, pristine: true, values: true, initialValues: true }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <FormSpy
                            subscription={{ pristine: true }}
                            // tslint:disable-next-line: no-shadowed-variable
                            onChange={(props) => {
                                onFormValueChanged(props);
                            }}
                        />

                        <Grid container={true} spacing={3}>
                            <Grid item={true} xs={12} md={12}>
                                <Accordion defaultExpanded={true}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content">
                                        <Typography className={classes.heading}>Client and Contact Defaults</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordionDetails}>
                                        <Grid container={true} spacing={3}>
                                            <Grid item={true} md={12} lg={12} sm={12} xs={12}>
                                                <Grid container={true} spacing={3}>
                                                    <Grid md={12} item={true}>
                                                        <Typography>
                                                            Provide a new section to define default settings for Note Subject and 
                                                            Type in various circumstances.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid md={4} item={true} />
                                                    <Grid md={4} item={true}>
                                                        <FormGroup className={classes.radioGroup}>
                                                            <Field name="isNoteSubjectMandatory" subscription={{ touched: true, error: true, value: true }}>
                                                                {({ input, meta }) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...input}
                                                                                checked={input.value}
                                                                                onChange={input.onChange}
                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                            />
                                                                        }
                                                                        label="Subject Mandatory"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Grid>
                                                    <Grid md={4} item={true}>
                                                        <FormGroup className={classes.radioGroup}>
                                                            <Field name="isNoteTypeMandatory" subscription={{ touched: true, error: true, value: true }}>
                                                                {({ input, meta }) => (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                {...input}
                                                                                checked={input.value}
                                                                                onChange={input.onChange}
                                                                                checkedIcon={<CheckBoxIcon color="primary" />}
                                                                            />
                                                                        }
                                                                        label="Type Mandatory"
                                                                    />
                                                                )}
                                                            </Field>
                                                        </FormGroup>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item={true} md={12} lg={12} sm={12} xs={12}>
                                                <Grid container={true} spacing={3}>
                                                    <Grid md={4} item={true}>
                                                        <Typography>Outlook Addin</Typography>
                                                    </Grid>
                                                    <Grid md={4} item={true}>
                                                        <Field name="outlookAddinSubject" subscription={{ touched: true, error: true, value: true }}>
                                                            {({ input, meta }) => (
                                                                <SubjectSelector
                                                                    {...input}
                                                                    label="Subject"
                                                                    onSelection={input.onChange}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                    <Grid md={4} item={true}>
                                                        <Field name="outlookAddinType" subscription={{ touched: true, error: true, value: true }}>
                                                            {({ input, meta }) => (
                                                                <CorrespondenceActivitySelector
                                                                    {...input}
                                                                    label="Type"
                                                                    onSelection={input.onChange}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item={true} md={12} lg={12} sm={12} xs={12}>
                                                <Grid container={true} spacing={3}>
                                                    <Grid md={4} item={true}>
                                                        <Typography>Event Creation</Typography>
                                                    </Grid>
                                                    <Grid md={4} item={true}>
                                                        <Field name="eventCreationSubject" subscription={{ touched: true, error: true, value: true }}>
                                                            {({ input, meta }) => (
                                                                <SubjectSelector
                                                                    {...input}
                                                                    label="Subject"
                                                                    onSelection={input.onChange}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                    {/* <Grid md={4} item={true}>
                                                        <Field name="eventCreationType" subscription={{ touched: true, error: true, value: true }}>
                                                            {({ input, meta }) => (
                                                                <CorrespondenceActivitySelector
                                                                    {...input}
                                                                    label="Type"
                                                                    onSelection={input.onChange}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                            <Grid item={true} md={12} lg={12} sm={12} xs={12}>
                                                <Grid container={true} spacing={3}>
                                                    <Grid md={4} item={true}>
                                                        <Typography>Campaign Creation</Typography>
                                                    </Grid>
                                                    <Grid md={4} item={true}>
                                                        <Field name="campaignCreationSubject" subscription={{ touched: true, error: true, value: true }}>
                                                            {({ input, meta }) => (
                                                                <SubjectSelector
                                                                    {...input}
                                                                    label="Subject"
                                                                    onSelection={input.onChange}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid>
                                                    {/* <Grid md={4} item={true}>
                                                        <Field name="campaignCreationType" subscription={{ touched: true, error: true, value: true }}>
                                                            {({ input, meta }) => (
                                                                <CorrespondenceActivitySelector
                                                                    {...input}
                                                                    label="Type"
                                                                    onSelection={input.onChange}
                                                                />
                                                            )}
                                                        </Field>
                                                    </Grid> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                            <Grid item={true} xs={12} md={12} justify="flex-end">
                                <div className={classes.buttonWrapper}>
                                    <Button variant="outlined" color="primary" type="submit" disabled={submitting || pristine || state.onSubmitLoading}>
                                        Save
                                    </Button>
                                    <Button variant="outlined" color="primary" type="button" disabled={submitting || pristine} onClick={onReset(form)}>
                                        Reset
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                )}
            />
        </div>
    );
};

export default ClientAndContactsDefaultConfigurationTab;
