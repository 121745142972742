import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '..';

export const EventsAndCampginQuery = gql`
	query eventandcampaign(
		$offset: Int,
		$first: Int,
		$filter: String,
    $isIncludeCompleted: Boolean
	){
		eventAndCampaign{
			summaryList(
				offset: $offset,
				first: $first,
				filter: $filter,
        isIncludeCompleted: $isIncludeCompleted
			){
				recordCount
				actualRowCountForSearch
				eventAndCampaignSummaries {
					guid
					name
					category
					type
					typeGuid
					startDate
					endDate
					rsvpDueDate
					venue
					availableSeats
					positiveResponse
					negativeResponse
					noResponse
					attended
					status
					statusGuid
					inviteeCount
					personResponsible
          			personResponsibleGuid
					attendeeCost
					notes
					duration
					isCompleted
					attendedPercentage
					acceptedPercentage
					campaignDate
				}
			}
		}
	}
`;

export interface EventsAndCampaignQueryData {
    eventAndCampaign: EventAndCampaign;
}

export interface EventAndCampaign {
    summaryList: SummaryList;
}

export interface SummaryList {
    recordCount:               number;
    actualRowCountForSearch:   number;
    eventAndCampaignSummaries: EventAndCampaignSummary[];
}

export interface EventAndCampaignSummary {
    guid:                  string;
    name:                  string;
    category:              number;
    type:                  string;
    typeGuid:              string;
    startDate:             Date;
    endDate:               Date;
    rsvpDueDate:           Date;
    venue:                 string;
    availableSeats:        number;
    positiveResponse:      number;
    negativeResponse:      number;
    noResponse:            number;
    attended:              number;
    status:                string;
    statusGuid:            string;
    inviteeCount:          number;
    personResponsible:     string;
    personResponsibleGuid: string;
    attendeeCost:          number;
    notes:                 string;
    duration:              string;
    isCompleted:           boolean;
    attendedPercentage:    number;
    acceptedPercentage:    number;
    campaignDate:          Date;
}

export interface EventsAndCampaignQueryParams {
    offset: number | null;
    first: number | null;
    filter?: string | null;
    isIncludeCompleted?: boolean;
}

export function retrieveEventsAndCampaignData(
    query: EventsAndCampaignQueryParams,
    refreshData: boolean,
    onSuccess: (data: EventsAndCampaignQueryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: EventsAndCampginQuery,
            variables: {
                offset: query.offset,
                first: query.first,
                filter: query.filter,
                isIncludeCompleted: query.isIncludeCompleted
            },
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface CreateEventAndCampaignMutationInput {
    category: number;
    name: string;
    eventAndCampaignTypeGuid?: string;
    eventStatusGuid?: string;
    responsibleGuid?: string;
    startDate?: Date;
    endDate?: Date;
    rsvpDueDate?: Date;
    availableSeats?: number;
    attendeeCost?: number;
    notes?: string;
    venue?: string;
    campaignDate?: Date;
    duration?: string;
    isCompleted?: boolean;
}

export interface CreateEventAndCampaignMutationParams {
    input: CreateEventAndCampaignMutationInput;
}

export interface EventsAndCampaignData {
    eventsAndCampaign: EventsAndCampaign;
}

export interface EventsAndCampaign {
    create: EventAndCampaignResult;
        update: EventAndCampaignResult;
        delete: EventAndCampaignResult;
}

export interface EventAndCampaignResult {
    status: boolean;
    error: null;
}

export const CreateEventAndCampaignMutation = gql`
	mutation createEventAndCampaign($input: CreateEventAndCampaignInput) {
		eventsAndCampaign {
			create(input: $input) {
				status
				error
			}
		}
	}
`;

export interface UpdateEventAndCampaignMutationParams {
    input: {
        guid?: string;
        category?: number;
        name?: string;
        eventAndCampaignTypeGuid?: string;
        eventStatusGuid?: string;
        responsibleGuid?: string;
        startDate?: Date;
        endDate?: Date;
        rsvpDueDate?: Date;
        availableSeats?: number;
        attendeeCost?: number;
        notes?: string;
        venue?: string;
    };
}

export const UpdateEventsAndCampaignMutation = gql`
	mutation UpdateEventAndCampaign($input: UpdateEventAndCampaignInput) {
		eventsAndCampaign {
			update(input: $input) {
				status
				error
			}
		}
	}
`;

export interface DeleteEventAndCampaignMutationParams {
    guid?: string;
}

export const DeleteEventsAndCampaignMutation = gql`
	mutation DeleteEventCampaign(
		$guid: String
		) {
			eventsAndCampaign {
				delete(
					guid: $guid
				) {
					status
					error
				}
			}
		}
`;

export const CreateEventsAndCampaignNotification = gql`
	subscription createEventAndCampaign{
		createEventAndCampaignNotification{
			id
				message
				status
				description
		}
			}
`;

export const UpdateEventsAndCampaignNotification = gql`
	subscription updateEventAndCampaign{
		updateEventAndCampaignNotification{
			id
				message
				status
				description
		}
	}
`;

export const DeleteEventsAndCampaignNotification = gql`
	subscription deleteEventAndCampaign{
		deleteEventAndCampaignNotification{
			id
				message
				status
				description
		}
	}
`;
