export interface CountryDataInterface {
    countryCode: string;
    country: string;
}

export const CountryData: CountryDataInterface[] = [
    { countryCode: 'BD', country: 'Bangladesh', },
    { countryCode: 'BE', country: 'Belgium', },
    { countryCode: 'BF', country: 'Burkina Faso', },
    { countryCode: 'BG', country: 'Bulgaria', },
    { countryCode: 'BA', country: 'Bosnia and Herzegovina', },
    { countryCode: 'BB', country: 'Barbados', },
    { countryCode: 'WF', country: 'Wallis and Futuna', },
    { countryCode: 'BL', country: 'Saint Barthelemy', },
    { countryCode: 'BM', country: 'Bermuda', },
    { countryCode: 'BN', country: 'Brunei', },
    { countryCode: 'BO', country: 'Bolivia', },
    { countryCode: 'BH', country: 'Bahrain', },
    { countryCode: 'BI', country: 'Burundi', },
    { countryCode: 'BJ', country: 'Benin', },
    { countryCode: 'BT', country: 'Bhutan', },
    { countryCode: 'JM', country: 'Jamaica', },
    { countryCode: 'BV', country: 'Bouvet Island', },
    { countryCode: 'BW', country: 'Botswana', },
    { countryCode: 'WS', country: 'Samoa', },
    { countryCode: 'BQ', country: 'Bonaire, Saint Eustatius and Saba ', },
    { countryCode: 'BR', country: 'Brazil', },
    { countryCode: 'BS', country: 'Bahamas', },
    { countryCode: 'JE', country: 'Jersey', },
    { countryCode: 'BY', country: 'Belarus', },
    { countryCode: 'BZ', country: 'Belize', },
    { countryCode: 'RU', country: 'Russia', },
    { countryCode: 'RW', country: 'Rwanda', },
    { countryCode: 'RS', country: 'Serbia', },
    { countryCode: 'TL', country: 'East Timor', },
    { countryCode: 'RE', country: 'Reunion', },
    { countryCode: 'TM', country: 'Turkmenistan', },
    { countryCode: 'TJ', country: 'Tajikistan', },
    { countryCode: 'RO', country: 'Romania', },
    { countryCode: 'TK', country: 'Tokelau', },
    { countryCode: 'GW', country: 'Guinea-Bissau', },
    { countryCode: 'GU', country: 'Guam', },
    { countryCode: 'GT', country: 'Guatemala', },
    { countryCode: 'GS', country: 'South Georgia and the South Sandwich Islands', },
    { countryCode: 'GR', country: 'Greece', },
    { countryCode: 'GQ', country: 'Equatorial Guinea', },
    { countryCode: 'GP', country: 'Guadeloupe', },
    { countryCode: 'JP', country: 'Japan', },
    { countryCode: 'GY', country: 'Guyana', },
    { countryCode: 'GG', country: 'Guernsey', },
    { countryCode: 'GF', country: 'French Guiana', },
    { countryCode: 'GE', country: 'Georgia', },
    { countryCode: 'GD', country: 'Grenada', },
    { countryCode: 'GB', country: 'United Kingdom', },
    { countryCode: 'GA', country: 'Gabon', },
    { countryCode: 'SV', country: 'El Salvador', },
    { countryCode: 'GN', country: 'Guinea', },
    { countryCode: 'GM', country: 'Gambia', },
    { countryCode: 'GL', country: 'Greenland', },
    { countryCode: 'GI', country: 'Gibraltar', },
    { countryCode: 'GH', country: 'Ghana', },
    { countryCode: 'OM', country: 'Oman', },
    { countryCode: 'TN', country: 'Tunisia', },
    { countryCode: 'JO', country: 'Jordan', },
    { countryCode: 'HR', country: 'Croatia', },
    { countryCode: 'HT', country: 'Haiti', },
    { countryCode: 'HU', country: 'Hungary', },
    { countryCode: 'HK', country: 'Hong Kong', },
    { countryCode: 'HN', country: 'Honduras', },
    { countryCode: 'HM', country: 'Heard Island and McDonald Islands', },
    { countryCode: 'VE', country: 'Venezuela', },
    { countryCode: 'PR', country: 'Puerto Rico', },
    { countryCode: 'PS', country: 'Palestinian Territory', },
    { countryCode: 'PW', country: 'Palau', },
    { countryCode: 'PT', country: 'Portugal', },
    { countryCode: 'SJ', country: 'Svalbard and Jan Mayen', },
    { countryCode: 'PY', country: 'Paraguay', },
    { countryCode: 'IQ', country: 'Iraq', },
    { countryCode: 'PA', country: 'Panama', },
    { countryCode: 'PF', country: 'French Polynesia', },
    { countryCode: 'PG', country: 'Papua New Guinea', },
    { countryCode: 'PE', country: 'Peru', },
    { countryCode: 'PK', country: 'Pakistan', },
    { countryCode: 'PH', country: 'Philippines', },
    { countryCode: 'PN', country: 'Pitcairn', },
    { countryCode: 'PL', country: 'Poland', },
    { countryCode: 'PM', country: 'Saint Pierre and Miquelon', },
    { countryCode: 'ZM', country: 'Zambia', },
    { countryCode: 'EH', country: 'Western Sahara', },
    { countryCode: 'EE', country: 'Estonia', },
    { countryCode: 'EG', country: 'Egypt', },
    { countryCode: 'ZA', country: 'South Africa', },
    { countryCode: 'EC', country: 'Ecuador', },
    { countryCode: 'IT', country: 'Italy', },
    { countryCode: 'VN', country: 'Vietnam', },
    { countryCode: 'SB', country: 'Solomon Islands', },
    { countryCode: 'ET', country: 'Ethiopia', },
    { countryCode: 'SO', country: 'Somalia', },
    { countryCode: 'ZW', country: 'Zimbabwe', },
    { countryCode: 'SA', country: 'Saudi Arabia', },
    { countryCode: 'ES', country: 'Spain', },
    { countryCode: 'ER', country: 'Eritrea', },
    { countryCode: 'ME', country: 'Montenegro', },
    { countryCode: 'MD', country: 'Moldova', },
    { countryCode: 'MG', country: 'Madagascar', },
    { countryCode: 'MF', country: 'Saint Martin', },
    { countryCode: 'MA', country: 'Morocco', },
    { countryCode: 'MC', country: 'Monaco', },
    { countryCode: 'UZ', country: 'Uzbekistan', },
    { countryCode: 'MM', country: 'Myanmar', },
    { countryCode: 'ML', country: 'Mali', },
    { countryCode: 'MO', country: 'Macao', },
    { countryCode: 'MN', country: 'Mongolia', },
    { countryCode: 'MH', country: 'Marshall Islands', },
    { countryCode: 'MK', country: 'Macedonia', },
    { countryCode: 'MU', country: 'Mauritius', },
    { countryCode: 'MT', country: 'Malta', },
    { countryCode: 'MW', country: 'Malawi', },
    { countryCode: 'MV', country: 'Maldives', },
    { countryCode: 'MQ', country: 'Martinique', },
    { countryCode: 'MP', country: 'Northern Mariana Islands', },
    { countryCode: 'MS', country: 'Montserrat', },
    { countryCode: 'MR', country: 'Mauritania', },
    { countryCode: 'IM', country: 'Isle of Man', },
    { countryCode: 'UG', country: 'Uganda', },
    { countryCode: 'TZ', country: 'Tanzania', },
    { countryCode: 'MY', country: 'Malaysia', },
    { countryCode: 'MX', country: 'Mexico', },
    { countryCode: 'IL', country: 'Israel', },
    { countryCode: 'FR', country: 'France', },
    { countryCode: 'IO', country: 'British Indian Ocean Territory', },
    { countryCode: 'SH', country: 'Saint Helena', },
    { countryCode: 'FI', country: 'Finland', },
    { countryCode: 'FJ', country: 'Fiji', },
    { countryCode: 'FK', country: 'Falkland Islands', },
    { countryCode: 'FM', country: 'Micronesia', },
    { countryCode: 'FO', country: 'Faroe Islands', },
    { countryCode: 'NI', country: 'Nicaragua', },
    { countryCode: 'NL', country: 'Netherlands', },
    { countryCode: 'NO', country: 'Norway', },
    { countryCode: 'NA', country: 'Namibia', },
    { countryCode: 'VU', country: 'Vanuatu', },
    { countryCode: 'NC', country: 'New Caledonia', },
    { countryCode: 'NE', country: 'Niger', },
    { countryCode: 'NF', country: 'Norfolk Island', },
    { countryCode: 'NG', country: 'Nigeria', },
    { countryCode: 'NZ', country: 'New Zealand', },
    { countryCode: 'NP', country: 'Nepal', },
    { countryCode: 'NR', country: 'Nauru', },
    { countryCode: 'NU', country: 'Niue', },
    { countryCode: 'CK', country: 'Cook Islands', },
    { countryCode: 'XK', country: 'Kosovo', },
    { countryCode: 'CI', country: 'Ivory Coast', },
    { countryCode: 'CH', country: 'Switzerland', },
    { countryCode: 'CO', country: 'Colombia', },
    { countryCode: 'CN', country: 'China', },
    { countryCode: 'CM', country: 'Cameroon', },
    { countryCode: 'CL', country: 'Chile', },
    { countryCode: 'CC', country: 'Cocos Islands', },
    { countryCode: 'CA', country: 'Canada', },
    { countryCode: 'CG', country: 'Republic of the Congo', },
    { countryCode: 'CF', country: 'Central African Republic', },
    { countryCode: 'CD', country: 'Democratic Republic of the Congo', },
    { countryCode: 'CZ', country: 'Czech Republic', },
    { countryCode: 'CY', country: 'Cyprus', },
    { countryCode: 'CX', country: 'Christmas Island', },
    { countryCode: 'CR', country: 'Costa Rica', },
    { countryCode: 'CW', country: 'Curacao', },
    { countryCode: 'CV', country: 'Cape Verde', },
    { countryCode: 'CU', country: 'Cuba', },
    { countryCode: 'SZ', country: 'Swaziland', },
    { countryCode: 'SY', country: 'Syria', },
    { countryCode: 'SX', country: 'Sint Maarten', },
    { countryCode: 'KG', country: 'Kyrgyzstan', },
    { countryCode: 'KE', country: 'Kenya', },
    { countryCode: 'SS', country: 'South Sudan', },
    { countryCode: 'SR', country: 'Suriname', },
    { countryCode: 'KI', country: 'Kiribati', },
    { countryCode: 'KH', country: 'Cambodia', },
    { countryCode: 'KN', country: 'Saint Kitts and Nevis', },
    { countryCode: 'KM', country: 'Comoros', },
    { countryCode: 'ST', country: 'Sao Tome and Principe', },
    { countryCode: 'SK', country: 'Slovakia', },
    { countryCode: 'KR', country: 'South Korea', },
    { countryCode: 'SI', country: 'Slovenia', },
    { countryCode: 'KP', country: 'North Korea', },
    { countryCode: 'KW', country: 'Kuwait', },
    { countryCode: 'SN', country: 'Senegal', },
    { countryCode: 'SM', country: 'San Marino', },
    { countryCode: 'SL', country: 'Sierra Leone', },
    { countryCode: 'SC', country: 'Seychelles', },
    { countryCode: 'KZ', country: 'Kazakhstan', },
    { countryCode: 'KY', country: 'Cayman Islands', },
    { countryCode: 'SG', country: 'Singapore', },
    { countryCode: 'SE', country: 'Sweden', },
    { countryCode: 'SD', country: 'Sudan', },
    { countryCode: 'DO', country: 'Dominican Republic', },
    { countryCode: 'DM', country: 'Dominica', },
    { countryCode: 'DJ', country: 'Djibouti', },
    { countryCode: 'DK', country: 'Denmark', },
    { countryCode: 'VG', country: 'British Virgin Islands', },
    { countryCode: 'DE', country: 'Germany', },
    { countryCode: 'YE', country: 'Yemen', },
    { countryCode: 'DZ', country: 'Algeria', },
    { countryCode: 'US', country: 'United States', },
    { countryCode: 'UY', country: 'Uruguay', },
    { countryCode: 'YT', country: 'Mayotte', },
    { countryCode: 'UM', country: 'United States Minor Outlying Islands', },
    { countryCode: 'LB', country: 'Lebanon', },
    { countryCode: 'LC', country: 'Saint Lucia', },
    { countryCode: 'LA', country: 'Laos', },
    { countryCode: 'TV', country: 'Tuvalu', },
    { countryCode: 'TW', country: 'Taiwan', },
    { countryCode: 'TT', country: 'Trinidad and Tobago', },
    { countryCode: 'TR', country: 'Turkey', },
    { countryCode: 'LK', country: 'Sri Lanka', },
    { countryCode: 'LI', country: 'Liechtenstein', },
    { countryCode: 'LV', country: 'Latvia', },
    { countryCode: 'TO', country: 'Tonga', },
    { countryCode: 'LT', country: 'Lithuania', },
    { countryCode: 'LU', country: 'Luxembourg', },
    { countryCode: 'LR', country: 'Liberia', },
    { countryCode: 'LS', country: 'Lesotho', },
    { countryCode: 'TH', country: 'Thailand', },
    { countryCode: 'TF', country: 'French Southern Territories', },
    { countryCode: 'TG', country: 'Togo', },
    { countryCode: 'TD', country: 'Chad', },
    { countryCode: 'TC', country: 'Turks and Caicos Islands', },
    { countryCode: 'LY', country: 'Libya', },
    { countryCode: 'VA', country: 'Vatican', },
    { countryCode: 'VC', country: 'Saint Vincent and the Grenadines', },
    { countryCode: 'AE', country: 'United Arab Emirates', },
    { countryCode: 'AD', country: 'Andorra', },
    { countryCode: 'AG', country: 'Antigua and Barbuda', },
    { countryCode: 'AF', country: 'Afghanistan', },
    { countryCode: 'AI', country: 'Anguilla', },
    { countryCode: 'VI', country: 'U.S. Virgin Islands', },
    { countryCode: 'IS', country: 'Iceland', },
    { countryCode: 'IR', country: 'Iran', },
    { countryCode: 'AM', country: 'Armenia', },
    { countryCode: 'AL', country: 'Albania', },
    { countryCode: 'AO', country: 'Angola', },
    { countryCode: 'AQ', country: 'Antarctica', },
    { countryCode: 'AS', country: 'American Samoa', },
    { countryCode: 'AR', country: 'Argentina', },
    { countryCode: 'AU', country: 'Australia', },
    { countryCode: 'AT', country: 'Austria', },
    { countryCode: 'AW', country: 'Aruba', },
    { countryCode: 'IN', country: 'India', },
    { countryCode: 'AX', country: 'Aland Islands', },
    { countryCode: 'AZ', country: 'Azerbaijan', },
    { countryCode: 'IE', country: 'Ireland', },
    { countryCode: 'ID', country: 'Indonesia', },
    { countryCode: 'UA', country: 'Ukraine', },
    { countryCode: 'QA', country: 'Qatar', },
    { countryCode: 'MZ', country: 'Mozambique' },
];