/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@material-ui/core';
// import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
// import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { convertToDraftWysiwygContent } from '../../helpers/commonHelper';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import { RvLoader } from '../../components/Loader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialogRoot: {
            '& .downshift-wrapper': {
                flex: 1,
                padding: 0,
            },
        },
        header: {
            textTransform: 'capitalize',
        },
        content: {
            flex: 1,
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '20px'
        },
        button: {
            margin: theme.spacing(1),
            minWidth: '83px',
        },
        hidden: {
            display: 'none',
        },
        loaderWrapper: {
            display: 'flex',
            justifyContent: 'center',
            padding: '10px',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255,255,255,0.8)',
        },
        formLabel: {
            paddingBottom: '10px',
        },
        editorClassName: {
            padding: '0 10px',
            minHeight: '300px',
            maxHeight: '500px',
        },
        wrapperClassName: {
            border: '1px solid #949494',
            display: 'flex',
            flexFlow: 'column-reverse',
        },
        toolbarClassName: {
            border: 0,
            // borderBottom: '1px solid #F1F1F1',
            margin: 0,
            padding: 0,
            '& .rdw-dropdown-wrapper': {
                position: 'relative',
            },
            '& .rdw-dropdown-optionwrapper': {
                position: 'absolute',
            },
        },
        paperRoot: {
            overflow: 'auto',
            position: 'relative',
            minHeight: 'calc(100vh - 50px)',
            display: 'flex',
            flexFlow: 'column',
        },
        dialogContent: {
            overflow: 'inherit',
            // minHeight: '538px',
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
        },
    }),
);

interface FeatureNotesPreviewDialogProps {
    open: boolean;
    onClose?: () => void;
    description: string;
}

export interface FeatureNotesPreviewDialogForm {
    notes: string; // EditorState;
}

export const FeatureNotesPreviewDialog: React.FC<FeatureNotesPreviewDialogProps> = (props) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = React.useState(true);

    let tempDesription = props.description;

    if (props.description) {    
        if (props.description.indexOf('blocks":') !== -1) {
            const draftContent = convertToDraftWysiwygContent(JSON.parse(props.description));
            const contentState = convertFromRaw(draftContent);
            tempDesription = draftToHtml(convertToRaw(EditorState.createWithContent(contentState).getCurrentContent()));
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="md"
            fullWidth={true}
            className={classes.dialogRoot}
            scroll={'paper'}
            // disablePortal={false}
            disableScrollLock={true}
            disableEnforceFocus={true}
            disableAutoFocus={true}
            classes={{
                paper: classes.paperRoot,
            }}
        >
            {isLoading ? (
                <div className={classes.loaderWrapper}>
                    <RvLoader />
                </div>
            ) : null}
            <DialogContent dividers={true} className={classes.dialogContent}>
                <Grid container={true} spacing={3} style={{ flex: 1 }}>
                    <Grid item={true} xs={12} md={12}>
                        <TinyEditor
                            apiKey={'k74nddluc9fmtf75en31ew8mxzqcvovgpjkzomdtgeje7b0h'}
                            initialValue={tempDesription}
                            disabled={true}
                            init={{
                                height: '100%',
                                autoresize_min_height: '100%',
                                autoresize_max_height: '100%',
                                menubar: false,
                                disabled: true, // Set the editor in read-only mode
                                plugins: '', // No plugins needed for read-only mode
                                toolbar: '', // No toolbar needed for read-only mode
                                statusbar: false, // Hide the status bar
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                toolbar_sticky: true,
                                autosave_ask_before_unload: true,
                                autosave_interval: '30s',
                                autosave_prefix: '{path}{query}-{id}-',
                                autosave_restore_when_empty: false,
                                autosave_retention: '2m',
                                image_advtab: true,
                                content_css: '//www.tiny.cloud/css/codepen.min.css',
                                image_class_list: [
                                    { title: 'None', value: '' },
                                    { title: 'Some class', value: 'class-name' },
                                ],
                                importcss_append: true,
                                min_height: 500,
                                // min_width: 300,
                            }}
                            onInit={(evt, editor) => {
                                setIsLoading(false);
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <div className={classes.buttonContainer}>
                    <div>
                        <Button color="primary" type="button" className={classes.button} onClick={props.onClose}>
                            Close
                        </Button>
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    );
};
