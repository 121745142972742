import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) => createStyles({
    addButtonWrapper: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-end',
        // paddingTop: '20px',
    },
    button: {
        margin: theme.spacing(),
    },
    buttonWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 15px',
    },
    checkbox: {

    },
    checkboxContainer: {
        margin: '0 8px',
    },
    checkboxWrapper: {
        margin: '0 8px',
        paddingTop: '20px',
    },
    clientGrid: {
        margin: '0 -12px',
    },
    col: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        // display: 'flex',
        // flexWrap: 'wrap',
    },
    customExpandablePanel: {
        marginBottom: 0,
    },
    customGrid: {
        '@media (min-width: 1280px)': {
            flexBasis: '25%',
            flexGrow: 0,
            maxWidth: '25%',
        },
        '@media (min-width: 960px)': {
            flexBasis: '25%',
            flexGrow: 0,
            maxWidth: '25%',
        },
        flexBasis: '50%',
        flexGrow: 0,
        margin: 0,
        maxWidth: '50%',
    },
    dateField: {
        alignItems: 'flex-end',
        display: 'flex',
    },
    expansionPanel: {
        marginTop: '20px',
    },
    extendedIcon: {
        marginRight: theme.spacing(),
    },
    flexEnd: {
        justifyContent: 'flex-end',
    },
    formContainer: {
        padding: '12px',
    },
    formControl: {
        margin: theme.spacing(),
    },    
    grid: {
        display: 'flex',
        padding: '2px 12px 2px 12px !important',
    },
    gridContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        padding: '0 !important',
    },
    gridWrapper: {
        flexGrow: 1,
    },
    group: {
        flexFlow: 'row wrap',
        margin: `${theme.spacing()}px 0`,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightRegular,
    },
    label: {
        marginLeft: '8px',
        paddingRight: '10px',
    },
    menu: {
        width: 200,
    },
    panelAction: {
        padding: '8px',
    },
    textField: {
        '& .form-control': {
            width: '100%',
        },
        flexGrow: 1,
        marginBottom: theme.spacing(),
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    typeTitle: {
        marginBottom: 0,
    },
    upperButton: {
        position: 'fixed',
        right: '15px',
        top: '300px',
        zIndex: 2,
        [theme.breakpoints.up('md')]: {
            top: '240px',
        },
    },
    voiPadding: {
        paddingTop: '20px'
    },
    cardRoot: {
        display: 'flex',
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardContent: {
        flex: '1 0 auto',
        padding: '0 15px !important',
        width: '100%',
    },
    cardCover: {
        display: 'flex',
    },
    cardControls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
        maxWidth: '960px',
    },
    controlContainer: {
        display: 'flex',
        padding: '0 15px 10px 15px',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .Mui-checked': {
            '& .MuiSwitch-thumb': {
                color: '#5173FF'
            }
        }
    },
    cardMedia: {
        display: 'flex',
        flex: 1,
        padding: '10px 0',
        '& .image': {
            padding: '0 15px',
            // flex: 1,
            display: 'flex',
            alignItems: 'center'
        },
        '& .text': {
            flex: 2,
            display: 'flex',
            alignItems: 'center',
        }
    },
    cardWrapper: {
        display: 'flex',
        flex: 1,
    },
    cardContainer: {
        display: 'flex',
        flexFlow: 'column',
        flex: 1,
    },
    rightControl: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '5px'
    },
    videoButton: {
        // marginRight: '5px',
        color: '#5173FF'
    }
});