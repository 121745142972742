import React, { useState } from 'react';
// import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { IAutoCompleteItem } from '../../typings/autoComplete';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//         minWidth: '150px',
//     },
//   })
// );

interface PasswordStrengthItem {
    value: number;
    label: string;
}

interface PassowrdStrengtthSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: PasswordStrengthItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value?: IAutoCompleteItem | IAutoCompleteItem[], name?: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    style?: React.CSSProperties; 
    disablePortal?: boolean;
    size?: 'small' | 'medium';
}

interface PassowrdStrengtthSelectorState {
    isLoading: boolean;
    data: PasswordStrengthItem[];
}

export const PassowrdStrengtthSelector: React.FC<PassowrdStrengtthSelectorProps> = props => {

    // const classes = useStyles();

    const [state] = useState<PassowrdStrengtthSelectorState>({
        isLoading: false,
        data: [
            {
                label: 'Basic',
                value: 1
            },
            {
                label: 'Good',
                value: 2
            },
            {
                label: 'Strong',
                value: 3
            },
            {
                label: 'Stronger',
                value: 4
            },
            {
                label: 'Very Strong',
                value: 5
            }
        ],
    });

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name);
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <>
            { props.multiple ? 
                (
                    <Autocomplete
                        style={props.style}
                        className={props.className}
                        value={props.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.data}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}                    
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}      
                                helperText={props.helperText}      
                                size={props.size}                
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                    {parts.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        style={props.style}
                        className={props.className}
                        value={props.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.data}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}         
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}        
                                helperText={props.helperText}  
                                size={props.size}                     
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            const matches = match(option.label, inputValue);
                            const parts = parse(option.label, matches);
                    
                            return (
                                <div>
                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                    </span>
                                ))}
                                </div>
                            );
                        }}
                    />
                )
            }
        </>
    );
};