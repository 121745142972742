import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { RvButtonFabric, RvButtonType } from '../components/Button';
import { RvLabel, LabelStyle } from '../components/Label/Label';
import { mainTheme } from '../Theme';
import { Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { LearnMoreEnum } from '../learnMore/LearnMorePage';
import { useHistory } from 'react-router-dom';
// import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            padding: '0 15px',
        },
        container: {},
        buttonContainer: {
            position: 'relative',
            padding: '15px 34px',
            color: mainTheme.BrandColors.TextPrimary,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media (max-width: 1024px)': {
                padding: '15px 30px',
            },
            '& .rvlabel': {
                paddingLeft: '82px',
                fontFamily: mainTheme.Font.Main,
                fontSize: mainTheme.FontSize.PX.size8,
                letterSpacing: '0.86px',
                lineHeight: mainTheme.LineHeight.ExtraLarge,
            },
            '& .back-button': {
                paddingRight: '32px',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                '& .ms-Button': {
                    width: '50px',
                    height: '50px',
                },
                '& .ms-Button-icon': {
                    fontSize: mainTheme.FontSize.PX.size10,
                    color: mainTheme.BrandColors.TemplatePrimary,
                },
            },
        },
        button: {
            margin: '0 10px',
            textTransform: 'none',
            fontSize: '14px',
        },
    }),
);

interface HeaderProps {
    onClickBack?: () => void;
    key: string;
    title: string;
    hasBack: boolean;
}

export const FeatureLayoutConfigurationHeader: React.FC<HeaderProps> = (props) => {
    const classes = useStyles();

    const history = useHistory();

    const onLearnMore = () => {
        history.push({
            pathname: `learnMore/${LearnMoreEnum[LearnMoreEnum.Configuring_Dashboards]}`,
        });
    };

    const onResources = () => {
        history.push({
            pathname: `/acuityResourceCenter`,
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classes.buttonContainer}>
                    <div>
                        {!!props.hasBack && (
                            <RvButtonFabric
                                rvKey={props.key}
                                rvbuttonType={RvButtonType.icon}
                                onClick={props.onClickBack}
                                rvIcon={'NavigateForwardMirrored'}
                                rvClassName="back-button"
                            />
                        )}
                        <RvLabel label={props.title} style={LabelStyle.Heading4} cssStyle={{ paddingLeft: props.hasBack ? '82px' : '34px' }} />
                    </div>
                    <div>
                        <Button variant="text" color="primary" size="small" className={classes.button} onClick={onLearnMore} startIcon={<VideoLibraryOutlinedIcon />}>
                            Learn More
                        </Button>
                        <Button variant="text" color="primary" size="small" className={classes.button} onClick={onResources} startIcon={<ErrorOutlineIcon />}>
                            Resources
                        </Button>
                        {/* <Button
                            href="https://redraincorp.sharepoint.com/:b:/s/RedViewProduct/EW9mRmJya29MrT_EsXVcKQYBiuNk1iI6RbkAKQmcWNOG-w?e=OJSb2e"
                            variant="text"
                            color="primary"
                            size="small"
                            className="learnmore"
                            startIcon={<SystemUpdateAltOutlinedIcon />}
                            download={true}
                            target="_blank"
                        >
                            Further Information
                        </Button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};
